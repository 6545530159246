<app-card [title]="'ManageAccessControl.UserDetails.Title' | translate">
  <div body class="flex items-start flex-wrap">
    <div>
      <ngx-avatars
        class="avatar box"
        size="80"
        [src]="imageUrl"
        [name]="userDetails?.fullName"
        [textSizeRatio]="3"
        [initialsSize]="2"
        [round]="true"
      ></ngx-avatars>
    </div>

    <div class="mx-5 flex items-end justify-between">
      <div class="me-20">
        <!-- name and role  -->
        <div class="py-3 flex items-center" *ngIf="userDetails?.fullName">
          <div
            @scaleIn
            class="p-2 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <span class="flex">
              <img src="assets/img/resources/user-square.svg" alt="" />
            </span>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1 font-bold">{{ userDetails?.fullName }}</p>
            <!-- <p class="m-0 ">
                  {{ "ManageAccessControl.UserDetails.Name" | translate }}
                </p> -->
          </div>
        </div>
        <!-- email -->
        <div class="py-3 flex items-center" *ngIf="userDetails?.email">
          <div
            @scaleIn
            class="rounded-full bg-primary/10 text-primary p-2 ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <img src="assets/img/resources/mail.svg" alt="" />
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1 font-bold">
              {{ userDetails?.email }}
            </p>
  
          </div>
        </div>
        <!-- department -->
        <div class="py-3 flex items-center" *ngIf="userDetails?.department">
          <div
            @scaleIn
            class="rounded-full bg-primary/10 text-primary p-2 ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <img src="assets/img/resources/tabler-icon-briefcase.svg" alt="" />
          </div>
          <div @fadeInRight>
            <p class="m-0">
              {{ "ManageAccessControl.UserDetails.Department" | translate }}
            </p>
            <p class="m-0 body-1 font-bold">
              {{ userDetails?.department }}
            </p>
          </div>
        </div>
      </div>
      <div class="me-20">
        <!-- phone  -->
        <div class="py-3 flex items-center" *ngIf="userDetails?.phone">
          <div
            @scaleIn
            class="p-2 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <span class="flex">
              <img
                src="assets/img/resources/tabler-icon-device-mobile.svg"
                alt=""
              />
            </span>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">{{ userDetails?.phone }}</p>
    
          </div>
        </div>
        <!-- reports to -->
        <div class="py-3 flex items-center" *ngIf="userDetails?.reportsTo">
          <div
            @scaleIn
            class="rounded-full bg-primary/10 text-primary p-2 ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <img
              src="assets/img/resources/tabler-icon-file-description.svg"
              alt=""
            />
          </div>

          <div @fadeInRight>
            <p class="m-0">
              {{ "ManageAccessControl.UserDetails.reportsTo" | translate }}
            </p>
            <p class="m-0 body-1 font-bold">
              {{ userDetails?.reportsTo }}
            </p>
          </div>
        </div>
        <!-- ID -->
        <div class="py-3 flex items-center" *ngIf="userDetails?.Id">
          <div
            @scaleIn
            class="p-2 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <span class="flex">
              <img src="assets/img/resources/tabler-icon-id.svg" alt="" />
            </span>
          </div>

          <div @fadeInRight>
            <p class="m-0">
              {{ "ManageAccessControl.UserDetails.idNum" | translate }}
            </p>
            <p class="m-0 body-1 font-bold">{{ userDetails?.Id }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-card>
