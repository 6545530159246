import {
  Component,
  Input,
  OnChanges,
  Output,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fadeInRight400ms } from 'src/@hodhod/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@hodhod/animations/scale-in.animation';
import {
  ActionStatus,
  ReportingType,
  ReportingTypes,
  ReportNearMissStatuses,
} from 'src/@hodhod/common/enum';
import { NearMissImage } from '../../models/near-miss-image';
import {
  NearMissReport,
  NearMissReportHistory,
  witnessescolumns,
} from '../../models/near-miss-report';
import moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Constants } from 'src/@hodhod/common/constants';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { NearMissReportService } from 'src/backend/services/reportings/near-miss-report.service';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/backend/services/user.service';
import { InvestigationService } from 'src/backend/services/investigation/investigation.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { WorkFlowService } from 'src/backend/services/work-flow.service';
import {
  SetInstanceParam,
  WorkFlow,
  WorkFlowByScreenParam,
} from 'src/backend/models/work-flows/work-flow';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { BaseApi } from 'src/backend/api/base-api';
import { ActionTrackerService } from 'src/backend/services/action-tracker/action-tracker.service';
import { Action } from 'src/app/action-tracker/models/action';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { ActionComponent } from 'src/app/action-tracker/components/action/action.component';
import { RootCauseService } from 'src/backend/services/root-cause/root-cause.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { LanguageService } from 'src/@hodhod/services/language.service';
import { style } from '@angular/animations';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-near-miss-report-view',
  templateUrl: './near-miss-report-view.component.html',
  styleUrls: ['./near-miss-report-view.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms,
    scaleFadeIn400ms,
    fadeInRight400ms,
    scaleIn400ms,
  ],
})
export class NearMissReportViewComponent implements OnInit, OnChanges {
  @Input() nearMissReport!: NearMissReport;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(ActionComponent) actionsComponent!: ActionComponent;
  @Output() onInvestigate: EventEmitter<boolean> = new EventEmitter();
  pageSize = Constants.PAGE_SIZE;
  investigationForm!: FormGroup;
  teamLeadForm!: FormGroup;
  teamMemberForm!: FormGroup;
  commentForm!: FormGroup;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<NearMissReportHistory>([]);
  nearMissHistory: NearMissReportHistory[];
  public data: NearMissReport;
  public selectedImg: NearMissImage;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  loadingLabel: string = '';
  users: any[] = [];
  userDetail: any;
  currentDate: Date = new Date();
  membersArray: any[] = [];
  actions: Action[];
  @ViewChild('imageBigView') imageBigView!: TemplateRef<any>;
  @ViewChild('teamLeadModal') teamLeadModal!: TemplateRef<any>;
  @ViewChild('teamMemberModal') teamMemberModal!: TemplateRef<any>;
  @ViewChild('acknowledgeModal') acknowledgeModal!: TemplateRef<any>;
  @ViewChild('rejectTeamModal') rejectTeamModal!: TemplateRef<any>;
  @ViewChild('closeModal') closeModal!: TemplateRef<any>;
  ReportingType = ReportingType;
  public translationsList: any = {};
  private destroy$ = new Subject();

  companyKey: string = '';
  company: any;
  instanceKey: string = '';
  loggedUser: LoggedUser;
  rootCauses: any[] = [];
  closeTeamForm: FormGroup;
  teamMemberFormArray: FormArray;
  reportType = ReportingTypes.NearMiss;
  rulesApplied: Array<{
    condition: string;
    leftOperand: string;
    rightOperand: string;
    operator: string;
    order: number;
  }> = [];
  rulesAppliedAcknowledge: Array<{
    condition: string;
    leftOperand: string;
    rightOperand: string;
    operator: string;
    order: number;
  }> = [];
  isInvestorsLoading: boolean = false;

  closerDetail: any;
  ReportNearMissStatus = ReportNearMissStatuses;
  hasEditPermissions = ApplicationPermission.REPORTING_NEARMISS_UPDATE;
  hasSpecialPermissions = ApplicationPermission.REPORTING_NEARMISS_APPROVE;
  closeForm: FormGroup;
  trimedDepartments: any;
  hasCreateUserPermissions = ApplicationPermission.USER_CREATE;
  hasRootCauseUpdatePermission =
    ApplicationPermission.CONTENT_REPORTING_ROOTCAUSE_UPDATE;

  reportTypes: string = '';

  workFlow: WorkFlow;
  constructor(
    private dialog: MatDialog,
    private nearMissService: NearMissReportService,
    private feedBackService: AsyncFeedbackService,
    private fb: FormBuilder,
    private userService: UserService,
    private investigationService: InvestigationService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private workFlowService: WorkFlowService,
    private rootService: RootCauseService,
    private baseApi: BaseApi,
    private trackerService: ActionTrackerService,
    private router: Router,
    private route: ActivatedRoute,
    private langService: LanguageService
  ) {
    this.translate
      .get(['Errors', 'Success', 'Role', 'User', 'CheckAlerts'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  get visibleWitnessesColumns() {
    return this.witnessescolumns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  columns: TableColumn<NearMissReportHistory>[] = [
    { label: 'CreatedBy', property: 'createdBy', type: 'text', visible: true },
    { label: 'CreatedAt', property: 'created', type: 'text', visible: true },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    { label: 'Comments', property: 'comment', type: 'text', visible: true },
  ];
  witnessescolumns: TableColumn<witnessescolumns>[] = [
    { label: 'Name', property: 'fullName', type: 'text', visible: true },
    {
      label: 'Department',
      property: 'department',
      type: 'text',
      visible: true,
    },
    { label: 'Role', property: 'role', type: 'text', visible: true },
  ];
  public rtl: string;

  ngOnInit() {
    this.closeTeamForm = this.fb.group({
      closeTeamLeadName: ['', Validators.required],
      closeTeamMemberForm: this.fb.array([]),
    });
    this.teamMemberFormArray = this.closeTeamForm.get(
      'closeTeamMemberForm'
    ) as FormArray;
    this.companyKey = this.baseApi.getCompanyIdFromStorage();
    this.company = this.baseApi.getCompanyFromStorage();
    this.loggedUser = this.baseApi.getUserSession();
    this.investigationForm = this.fb.group({
      investCheckbox: [false],
    });
    this.teamLeadForm = this.fb.group({
      teamLeadName: [''],
    });
    this.teamMemberForm = this.fb.group({
      teamMember: ['', Validators.required],
      targetDate: ['', Validators.required],
      task: ['', Validators.required],
    });
    this.commentForm = this.fb.group({
      comment: ['', Validators.required],
    });
    this.closeForm = this.fb.group({
      investCheckbox: [false],
      comment: [''],
      rootCause: [''],
      rcaFile: [[]],
    });
    this.dataSource.paginator = this.paginator;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = Constants.PAGE_SIZE;
    this.langService.currentLanguage$.subscribe((language) => {
      this.rtl = language.isoCode;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.companyKey = this.baseApi.getCompanyIdFromStorage();
    this.company = this.baseApi.getCompanyFromStorage();
    this.loggedUser = this.baseApi.getUserSession();
    if (changes['nearMissReport']) {
      this.data = this.nearMissReport;
      if (this.data) {
        this.getWorkFlow();
        this.reportTypes = this.data?.typesOfReport
          .map((item) => item?.typeName)
          .toString();
        if (this.data?.isInvestigated) {
          this.investigationForm.get('investCheckbox').setValue(true);
          this.closeForm.get('investCheckbox').setValue(true);
        }
        this.getUsers();
        this.getActionById();
        this.getHistoryData();
        this.getCloserDetail();
      }
    }
  }
  get closeTeamMemberArray(): FormArray {
    return this.closeTeamForm.get('closeTeamMemberForm') as FormArray;
  }

  getRootCause(): void {
    this.rootService.getRootCauseByCompany(this.reportType).subscribe({
      next: (response) => {
        this.rootCauses = response;
        if (!this.rootCauses.some((item) => item.id == this.data.rootCauseId)) {
          this.closeForm.get('rootCause').setValue('');
        } else {
          this.closeForm.get('rootCause').setValue(this.data?.rootCauseId);
        }
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getWorkFlow(): void {
    const data: WorkFlowByScreenParam = {
      screenName: Constants.WORKFLOW_NEARMISS_SCREEN,
      tenantId: this.companyKey,
      companyKey: Constants.WORK_FLOW_COMPANY_KEY,
    };
    this.workFlowService.getWorkFlowByScreen(data).subscribe({
      next: (response) => {
        this.workFlow = response;
        this.getWorkflowInstanceKeyById(this.workFlow.workFlowKey);
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getWorkflowInstanceKeyById(workflowKey: string) {
    this.workFlowService
      .getInstanceKeyByTableId(workflowKey, this.data?.id)
      .subscribe({
        next: (res) => {
          this.instanceKey = res?.instanceKey
            ? res?.instanceKey
            : this.data?.instanceKey;
          if (this.instanceKey) {
            this.getTransitionRules();
            this.getTransitionRulesForAcknowledge();
          }
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
        },
      });
  }

  getCloserDetail(): void {
    if (this.data?.closerId) {
      this.userService.getUserDetails(this.data?.closerId).subscribe({
        next: (response) => {
          this.closerDetail = response;
          this.trimedDepartments =
            this.closerDetail?.departmentName?.substring(0, 10) + ' ...';
          this.loadingService.stopLoading();
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.loadingService.stopLoading();
        },
      });
    }
  }

  getActionById() {
    const data = {
      sourceId: this.data?.id,
      SourceType: ReportingType.NearMiss + ',' + 'INVESTIGATION-NEAR_MISS',
    };
    this.trackerService.getActionById(data).subscribe({
      next: (res) => {
        this.actions = res?.data;
      },
    });
  }

  getUsers(): void {
    this.isInvestorsLoading = true;
    this.userService.getReportToUsers().subscribe({
      next: (response) => {
        this.users = response;
        if (this.data?.isInvestigated) {
          this.investigationForm.get('investCheckbox').setValue(true);
          this.userDetail = this.users.find(
            (x) => x.userId == this.data.investigation?.teamLeadId
          );
          this.teamLeadForm
            .get('teamLeadName')
            ?.setValue(this.data?.investigation?.teamLeadId);
          //
          if (this.data.teamMembers?.length > 0) {
            this.membersArray = [];
            for (
              let index = 0;
              index < this.data.teamMembers?.length;
              index++
            ) {
              const user = this.users.find(
                (x) => x.userId == this.data.teamMembers[index]?.userId
              );
              this.membersArray.push({
                userId: user?.userId,
                fullName: user?.fullName,
                role: user?.roles[0],
                userImage: user?.userImage,
                task: this.data?.teamMembers[index].task,
                targetDate: this.data?.teamMembers[index].targetDate,
                email: user?.email,
              });
            }
          }
        }
        this.isInvestorsLoading = false;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.isInvestorsLoading = false;
      },
    });
  }
  getUsersHierarchy(userIds: string[]): void {
    this.userService
      .getUserHierarchy({ userIds: userIds?.toString() })
      .subscribe({
        next: (response) => {
          this.setUsersHierarchy(response);
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.loadingService.stopLoading();
        },
      });
  }
  setUsersHierarchy(usersLevels: any[]): void {
    const data = {
      instanceKey: this.instanceKey,
      isReminder: false,
      isEscalation: true,
      reminderUsers: [],
      escalationUsers: usersLevels?.map((ele) => {
        return {
          level: ele?.level,
          email: ele?.email,
          userId: ele?.userId,
        };
      }),
    };
    this.workFlowService.setCustomUserForReminderOrEscalation(data).subscribe({
      next: (response) => {
        this.onInvestigate.emit(true);
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.loadingService.stopLoading();
      },
    });
  }
  getNextTransitionAssigneesFromWorkFlow(): void {
    this.workFlowService
      .getNextTransitionAssignees(this.instanceKey)
      .subscribe({
        next: (response) => {
          this.getUsersHierarchy(response);
        },
        error: ({ error }) => {
          this.loadingService.stopLoading();
        },
      });
  }

  getHistoryData() {
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.nearMissService.getNearMissReportHistory(this.data.id).subscribe({
      next: (response) => {
        this.nearMissHistory = response?.data?.map((ele) => {
          return {
            ...ele,
            created: moment(ele.created)
              .local(true)
              .format('MMM DD YYYY hh mm A') as any,
          };
        });
        this.dataSource = new MatTableDataSource(this.nearMissHistory);
        this.paginator.length = response?.totalCount;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  openBigView(id: number): void {
    const img = this.data?.images.find((x) => x.id === id);
    if (img) {
      const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
      const extension = img.imageUrl.split('.').pop();
      if (imageExtensions.includes(extension)) {
        this.selectedImg = img;
        this.dialog.open(this.imageBigView, {
          maxHeight: '95vh',
          width: 'auto',
        });
      } else {
        const downloadTag = document.createElement('a');
        downloadTag.href = img.imageUrl;
        downloadTag.addEventListener('click', () => {
          downloadTag.download;
        });
        downloadTag.click();
      }
    }
  }
  downloadDocument(url: any): void {
    const downloadTag = document.createElement('a');
    downloadTag.href = url;
    downloadTag.addEventListener('click', () => {
      downloadTag.download;
    });
    downloadTag.click();
  }

  getAttachmentImage(url: string) {
    const extension = url.split('.').pop()?.toLocaleLowerCase();
    if (extension.includes('xlsx') || extension.includes('xls')) {
      return '../../../../assets/img/resources/xls.png';
    } else if (extension.includes('docx') || extension.includes('doc')) {
      return '../../../../assets/img/resources/doc.png';
    } else if (extension.includes('pdf')) {
      return '../../../../assets/img/resources/pdf.png';
    } else {
      return url;
    }
  }
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL('image/png');

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }
  getAttachementTypeName(url: string): any {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
    const extension = url.split('.').pop();
    if (imageExtensions.includes(extension)) {
      return 'Image';
    }
    return 'Document';
  }

  openTeamLeadModal(): void {
    this.dialog.open(this.teamLeadModal, { height: 'auto', width: '30%' });
  }

  openTeamMemberModal(): void {
    this.teamMemberForm.reset();
    this.teamMemberForm.get('teamMember')?.setValue('');
    this.teamMemberForm.get('task')?.setValue('');
    this.dialog.open(this.teamMemberModal, { height: 'auto', width: '50%' });
  }

  changeCheckBox(): void {
    this.chooseInvestigation();
  }

  getTeamLeadDetail(closeForm?) {
    const userId = closeForm
      ? this.closeTeamForm.value.closeTeamLeadName
      : this.teamLeadForm.value.teamLeadName;
    this.userDetail = this.users.find((x) => x.userId == userId);

    this.closeDialog();
  }
  areArraysEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }
    return arr1.every(
      (element, index) => element?.userId === arr2[index]?.userId
    );
  }

  getTeamMemberDetail(closeform?) {
    if (closeform) {
      this.closeTeamForm.value.closeTeamMemberForm.forEach((item) => {
        const { teamMember, targetDate, task } = item;
        const userData = this.users.find((x) => x.userId == teamMember);
        if (this.membersArray.length > 0) {
          const data = this.membersArray.find((x) => x.userId == teamMember);
          if (data) {
            this.feedBackService.showFeedback(
              new FeedbackModel(
                FeedbackType.Warning,
                'Already selected this user'
              )
            );
            return;
          }
        }
        if (teamMember && targetDate && task) {
          this.membersArray.push({
            userId: teamMember,
            fullName: userData?.fullName,
            role: userData?.roles[0],
            userImage: userData?.userImage,
            task: task,
            targetDate: targetDate
              ? moment(new Date(targetDate)).format('YYYY-MM-DDThh:mm:ssZ')
              : '',
          });
        }
        this.closeDialog();
      });
    } else {
      const { teamMember, targetDate, task } = this.teamMemberForm.value;
      const userData = this.users.find((x) => x.userId == teamMember);
      if (this.membersArray.length > 0) {
        const data = this.membersArray.find((x) => x.userId == teamMember);
        if (data) {
          this.feedBackService.showFeedback(
            new FeedbackModel(
              FeedbackType.Warning,
              'Already selected this user'
            )
          );
          return;
        }
      }
      if (teamMember && targetDate && task) {
        this.membersArray.push({
          userId: teamMember,
          fullName: userData?.fullName,
          role: userData?.roles[0],
          userImage: userData?.userImage,
          task: task,
          targetDate: targetDate
            ? moment(new Date(targetDate)).format('YYYY-MM-DDThh:mm:ssZ')
            : '',
        });
      }
      this.closeDialog();
    }
  }
  removeTeamMember(userId: string) {
    this.membersArray = this.membersArray.filter((x) => x.userId !== userId);
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }
  observActionById() {
    const data = {
      sourceId: this.data?.id,
      SourceType: ReportingType.NearMiss,
    };
    return this.trackerService.getActionById(data);
  }
  printNearMissReport() {
    if (this.data) {
      this.observActionById()
        .pipe(
          tap((res) => {
            this.actions = res?.data;
          })
        )
        .subscribe(() => {
          this.takePrint(this.data);
        });
    }
  }

  submitInvestigationData(close?) {
    if (close) {
      this.getTeamLeadDetail(true);
      this.getTeamMemberDetail(true);
    } else {
      this.getTeamLeadDetail();
      this.getTeamMemberDetail();
    }
    this.loadingService.startLoading(true, '');
    const data = {
      teamLeadId: close
        ? this.closeTeamForm.value.closeTeamLeadName
        : this.teamLeadForm.value.teamLeadName,
      reportId: this.data.id,
      type: ReportingType.NearMiss,
      teamMembers: this.membersArray,
    };
    this.investigationService.saveInvestigation(data).subscribe({
      next: (res) => {
        this.data.isInvestigated = true;
        this.loadingService.stopLoading();
        this.actionsComponent.getData();
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, res?.message)
        );
      },
      error: (error) => {
        this.loadingService.stopLoading();
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.error.message)
        );
      },
    });
  }
  addTeamMemberRow(): void {
    this.teamMemberFormArray.push(this.createteamMemberFormGroup());
    this.closeTeamForm.get('closeTeamMemberForm')['controls'] = [
      ...this.teamMemberFormArray.controls,
    ];

    this.teamMemberFormArray.controls = [...this.teamMemberFormArray.controls];
  }
  createteamMemberFormGroup(): FormGroup {
    return this.fb.group({
      teamMember: ['', Validators.required],
      targetDate: ['', Validators.required],
      task: ['', Validators.required],
    });
  }
  removeMember(i: any) {
    this.teamMemberFormArray.removeAt(i);
  }
  updateInvestigationData(teamselected?) {
    if (
      this.areArraysEqual(this.membersArray, this.data?.teamMembers) === false
    ) {
      this.loadingService.startLoading(true, '');
      const data = {
        investigationId: this.data?.investigation
          ? this.data?.investigation?.id
          : 0,
        teamLeadId: this.teamLeadForm.value.teamLeadName,
        reportId: this.data.id,
        type: ReportingType.NearMiss,
        teamMembers: this.membersArray,
      };
      this.investigationService.updateInvestigation(data).subscribe({
        next: (res) => {
          this.data.isInvestigated = true;
          this.loadingService.stopLoading();
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Success, res?.message)
          );
          this.onInvestigate.emit(true);
          this.actionsComponent.getData();
          if (teamselected) {
            this.teamSelected();
          }
        },
        error: (error) => {
          this.loadingService.stopLoading();
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.error.message)
          );
        },
      });
      this.closeDialog();
    } else {
      if (teamselected) {
        this.teamSelected();
      }
    }
  }

  async takePrint(data: any): Promise<void> {
    const doc = new jsPDF({
      orientation: 'portrait',
    });
    doc.addFont('assets/fonts/Amiri-Regular.ttf', 'Amiri', 'normal');
    doc.setFont('Amiri', 'italic', 'bold');
    const date = moment(data?.updatedOn)
      .local(true)
      .format('MMM DD YYYY hh mm A');
    let y = 5;

    // Logo and Company Data
    const companyLogo =
      JSON.parse(localStorage.getItem('company')).companyImage || '';
    if (companyLogo) {
      doc.addImage(companyLogo, 'PNG', 10, y, 20, 20); // Adjust positioning and size as needed
      doc.text(
        JSON.parse(localStorage.getItem('company')).companyName,
        35,
        y + 10
      );
      y += 45;
    } else {
      doc.text(JSON.parse(localStorage.getItem('company')).companyName, 10, y, {
        align: 'center',
      });
      y += 20;
    }
    doc.setFont('Amiri', 'normal');
    // Title Line
    doc.setLineWidth(1);
    doc.line(10, 30, 200, 30);
    doc.addImage(
      '../../../../assets/img/resources/saned-white-logo.png',
      'PNG',
      140,
      24,
      40,
      10
    );
    y += 10;
    // Report Title
    doc.setFontSize(18);
    doc.text(
      this.rtl === 'ar' ? 'تقرير المشاكل القريبة' : 'Report Near Miss',
      105,
      40,
      { align: 'center' }
    );
    doc.setFont('Amiri', 'normal');
    //Details
    function addTextWithOverflow(
      doc,
      text,
      x,
      y,
      maxWidth,
      rtl,
      pageHeight,
      bottomMargin,
      desc?
    ) {
      const lines = doc.splitTextToSize(text, maxWidth);
      lines.forEach((line) => {
        if (y + 10 > pageHeight - bottomMargin) {
          doc.addPage();
          y = 10;
        }
        if (desc) {
          if (checkIfArabic(line)) {
            doc.text(line, 190, y, { align: 'right' });
          } else {
            doc.text(line, x, y);
          }
        } else {
          doc.text(line, x, y);
        }
        y += 5;
      });
      return y;
    }
    function checkIfArabic(text: string) {
      if (text.includes('ا' || 'أ' || 'ل')) {
        return true;
      } else {
        return false;
      }
    }
    const details = [
      {
        label: 'Title',
        arLabel: 'العنوان',
        value: data?.title,
        x: 12,
        yOffset: 0,
      },
      {
        label: 'Department',
        arLabel: 'القسم',
        value: data?.parentDepartment || data?.department,
        x: 12,
        yOffset: 10,
      },
      {
        label: 'Sub Department',
        arLabel: 'القسم الفرعي',
        value: data?.parentDepartment ? data?.department : '--',
        x: 12,
        yOffset: 20,
      },
      {
        label: 'Reported At',
        arLabel: 'تحول في',
        value: date,
        x: 12,
        yOffset: 30,
      },
      {
        label: 'Shift',
        arLabel: 'الوردية',
        value: data?.shift,
        x: 12,
        yOffset: 40,
      },
    ];
    const secDetails = [
      {
        label: 'Status',
        arLabel: 'الحالة',
        value: data?.status,
        x: 110,
        yOffset: 0,
        rect: true,
        rectColor: '#C1E6DA',
        textColor: '#046240',
      },
      {
        label: 'Root Cause',
        arLabel: 'السبب الجذري',
        value: data?.rootCause,
        x: 110,
        yOffset: 10,
      },
      {
        label: '6M Classification',
        arLabel: 'تصنيف العصف الذهني',
        value: data?.cause,
        x: 110,
        yOffset: 20,
      },
      {
        label: 'Type of Hazard',
        arLabel: 'نوع الخطر',

        value: this.reportTypes,
        x: 110,
        yOffset: 30,
      },
      {
        label: 'Reported By',
        arLabel: 'تم بواسطة',
        value: data?.reportedBy,
        x: 110,
        yOffset: 40,
      },
    ];
    let pageHeight = doc.internal.pageSize.height;
    let bottomMargin = 10;
    const lineHeight = 5;
    const maxLength = Math.max(details.length, secDetails.length);

    doc.setFontSize(12);
    let Y = y;
    for (let i = 0; i < maxLength; i++) {
      if (i < details.length) {
        doc.setTextColor('#1F36B4');
        doc.addImage(
          'assets/img/resources/pdfArrow.png',
          'PNG',
          8,
          y - 3,
          5,
          5
        );
        if (this.rtl === 'ar') {
          doc.text(details[i].arLabel, 15, y);
        } else {
          doc.text(details[i].label, 15, y);
        }
        y += 5;
        doc.setTextColor('black');
        y = addTextWithOverflow(
          doc,
          details[i].value || '',
          15,
          y,
          90,
          this.rtl,
          pageHeight,
          bottomMargin
        );
        y += 5;
      }

      if (i < secDetails.length) {
        doc.setTextColor('#1F36B4');
        doc.addImage(
          'assets/img/resources/pdfArrow.png',
          'PNG',
          103,
          Y - 3,
          5,
          5
        );
        if (this.rtl === 'ar') {
          doc.text(secDetails[i].arLabel, 110, Y);
        } else {
          doc.text(secDetails[i].label, 110, Y);
        }
        Y += 5;
        doc.setTextColor('black');
        Y = addTextWithOverflow(
          doc,
          secDetails[i].value || '',
          110,
          Y,
          90,
          this.rtl,
          pageHeight,
          bottomMargin
        );
        Y += 5;
      }
    }
    // Description
    doc.setTextColor('#1F36B4');
    doc.addImage('assets/img/resources/pdfArrow.png', 'PNG', 8, y - 3, 5, 5);
    if (this.rtl === 'ar') {
      doc.text('الوصف', 15, y);
    } else {
      doc.text('Description', 15, y);
    }
    doc.setTextColor('black');
    doc.setFontSize(12);
    y += 5;
    y = addTextWithOverflow(
      doc,
      data?.description || '',
      15,
      y,
      180,
      this.rtl,
      pageHeight,
      bottomMargin,
      true
    );
    y += 10;
    //investgation
    if (data?.isInvestigated) {
      if (y + 10 > pageHeight - bottomMargin) {
        doc.addPage();
        y = 10;
      }
      doc.setFontSize(18);
      doc.text(this.rtl === 'ar' ? `التحقيق` : `Investigation`, 105, y, {
        align: 'center',
      });
      y += 5;
      doc.setLineWidth(0.5);
      doc.line(10, y, 200, y);
      y += 10;

      doc.setFontSize(16);
      doc.setTextColor('#1F36B4');
      doc.addImage('assets/img/resources/pdfArrow.png', 'PNG', 8, y - 3, 5, 5);
      doc.text(this.rtl === 'ar' ? `قائد الفريق` : `Team Lead`, 15, y);
      Y = y;
      doc.setFontSize(12);
      y += 5;
      y = addTextWithOverflow(
        doc,
        this.userDetail?.fullName || '',
        15,
        y,
        90,
        this.rtl,
        pageHeight,
        bottomMargin
      );
      y = addTextWithOverflow(
        doc,
        this.userDetail?.roles[0] || '',
        15,
        y,
        90,
        this.rtl,
        pageHeight,
        bottomMargin
      );
      y += 5;
      if (this.membersArray.length > 0) {
        doc.setFontSize(16);
        doc.setTextColor('#1F36B4');
        doc.addImage(
          'assets/img/resources/pdfArrow.png',
          'PNG',
          8,
          y - 3,
          5,
          5
        );
        doc.text(this.rtl === 'ar' ? `اعضاء الفريق` : `Team Mambers`, 15, y);
        doc.setFontSize(12);
        for (let i = 0; i < this.membersArray.length; i++) {
          y += 5;
          this.membersArray[i]?.fullName
            ? (y = addTextWithOverflow(
                doc,
                i + 1 + '-' + this.membersArray[i]?.fullName || '',
                15,
                y,
                90,
                this.rtl,
                pageHeight,
                bottomMargin
              ))
            : null;
          this.membersArray[i]?.role
            ? (y = addTextWithOverflow(
                doc,
                this.membersArray[i]?.role || '',
                15,
                y,
                90,
                this.rtl,
                pageHeight,
                bottomMargin
              ))
            : null;
          Y += 7;
        }
      }
      y += 20;
    }
    // Images Section
    if (data?.images.length > 0) {
      doc.setFontSize(18);
      doc.text(this.rtl === 'ar' ? 'الملفات' : 'Documents', 105, y, {
        align: 'center',
      });
      y += 5;

      doc.setLineWidth(0.5);
      doc.line(10, y, 200, y);
      y += 5;

      let x = 10;
      let count = 0;
      const pageWidth = doc.internal.pageSize.width - 20; // Adjust for margins
      const imageWidth = 25;
      const imageHeight = 25;
      const spacing = 10;

      for (let i = 0; i < data?.images.length; i++) {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
        const extension = data?.images[i].imageUrl.split('.').pop();
        let imgData = null;
        if (imageExtensions.includes(extension)) {
          imgData = data?.images[i].imageUrl;
        } else {
          imgData = await this.getBase64ImageFromURL(
            this.getAttachmentImage(data?.images[i].imageUrl)
          );
        }
        if (imgData) {
          if (x + imageWidth > pageWidth) {
            x = 10;
            y += imageHeight + spacing;
          }

          // Add the image to the PDF
          doc.addImage(imgData, 'JPEG', x, y, imageWidth, imageHeight);

          // Add clickable annotation
          doc.link(x, y, imageWidth, imageHeight, {
            url: data?.images[i].imageUrl,
          });

          x += imageWidth + spacing;
          count++;
        }
      }
      y += imageHeight + spacing;
      if (y + 10 > pageHeight - bottomMargin) {
        doc.addPage();
        y = 10;
      }
    }

    // Function to create table headers based on language direction
    // Translation function for Arabic headers
    const translateKey = (key) => {
      const translations = {
        Description: 'الوصف',
        title: 'العنوان',
        Responsibility: 'المسؤل',
        priority: 'الأفضلية',
        targetDate: 'تاريخ التسليم',
        closingDate: 'تاريخ الانتهاء',
        actionStatus: 'الحاله',
        status: 'الحاله',
        assignToName: 'المسؤل',
        fullName: 'الاسم',
        department: 'القسم',
        role: 'الدور',
        created: 'تاريخ الإنشاء',
        comments: 'التعليقات',
      };
      return translations[key] || key;
    };
    const createHeaders = (keys, rtl) => {
      const translatedKeys = rtl === 'ar' ? keys.map(translateKey) : keys;
      return translatedKeys.map((key) => ({
        id: key,
        name: key,
        prompt: key.replace(/([A-Z])/g, ' $1').trim(),
        width: 40,
        align: 'center',
        padding: 5,
      }));
    };
    // Function to generate table data
    const generateTableData = (data, fields) => {
      return data.map((item) =>
        fields.reduce((acc, field) => {
          acc[field] =
            field === 'targetDate' || field === 'closingDate'
              ? moment.utc(item[field]).local(true).format('MMM DD yyyy')
              : item[field];
          return acc;
        }, {})
      );
    };

    // Function to add a table to the document and return the new Y position
    const addTable = (doc, startY, data, headers, rtl) => {
      autoTable(doc, {
        startY: startY,
        head: [headers.map((header) => header.prompt)],
        body: data.map((row) => Object.values(row)),
        styles: { fontSize: 12, font: 'Amiri', overflow: 'linebreak' },
      });
      return doc.lastAutoTable.finalY + 20; // Add space after the table
    };
    // Actions Section
    if (this.actions.length > 0) {
      if (y + 30 > pageHeight - bottomMargin) {
        doc.addPage();
        y = 10;
      }
      doc.setFontSize(18);
      doc.text(this.rtl === 'ar' ? 'الأجرائات' : 'Actions', 105, y, {
        align: 'center',
      });
      y += 5;

      doc.setLineWidth(0.5);
      doc.line(10, y, 200, y);
      y += 5;

      doc.setFontSize(12);
      doc.setTextColor('black');

      const actionsFields = [
        'title',
        'assignToName',
        'priority',
        'targetDate',
        'closingDate',
        'actionStatus',
      ];
      const actionsHeaders = createHeaders(actionsFields, this.rtl);
      const actionsTableData = generateTableData(this.actions, actionsFields);
      y = addTable(doc, y, actionsTableData, actionsHeaders, this.rtl);
    }

    // Witnesses Section
    if (data?.witnesses?.length > 0) {
      if (y + 30 > pageHeight - bottomMargin) {
        doc.addPage();
        y = 10;
      }
      doc.setFontSize(18);
      doc.text(this.rtl === 'ar' ? 'الشهود' : 'Witnesses', 105, y, {
        align: 'center',
      });
      y += 5;

      doc.setLineWidth(0.5);
      doc.line(10, y, 200, y);
      y += 5;

      doc.setFontSize(12);
      doc.setTextColor('black');

      const witnessesFields = ['fullName', 'department', 'role'];
      const witnessesHeaders = createHeaders(witnessesFields, this.rtl);
      const witnessesTableData = generateTableData(
        data.witnesses,
        witnessesFields
      );
      y = addTable(doc, y, witnessesTableData, witnessesHeaders, this.rtl);
    }
    // Near Miss History Section

    if (this.nearMissHistory.length > 0) {
      if (y + 30 > pageHeight - bottomMargin) {
        doc.addPage();
        y = 10;
      }
      doc.setFontSize(18);
      doc.text(
        this.rtl === 'ar' ? 'تاريخ الحوادث القريبة' : 'Near Miss History',
        105,
        y,
        { align: 'center' }
      );
      y += 5;

      doc.setLineWidth(0.5);
      doc.line(10, y, 200, y);
      y += 5;

      doc.setFontSize(12);
      doc.setTextColor('black');

      const nearMissHistoryFields = ['created', 'status', 'comment'];
      const nearMissHistoryHeaders = createHeaders(
        nearMissHistoryFields,
        this.rtl
      );
      const nearMissHistoryTableData = generateTableData(
        this.nearMissHistory,
        nearMissHistoryFields
      );
      y = addTable(
        doc,
        y,
        nearMissHistoryTableData,
        nearMissHistoryHeaders,
        this.rtl
      );
    }

    doc.save(`report-nearmiss-${data?.id}.pdf`);
  }
  chooseInvestigation(): void {
    this.investigationForm.get('investCheckbox')?.setValue(false);
    this.closeForm.get('investCheckbox')?.setValue(false);
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['CheckAlerts']['ChooseInvestigation']['Title'],
          this.translationsList['CheckAlerts']['ChooseInvestigation']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.investigationForm.get('investCheckbox')?.setValue(true);
          this.closeForm.get('investCheckbox')?.setValue(true);
          this.rulesApplied = this.rulesApplied?.map((ele) => {
            return {
              ...ele,
              rightOperand: 'Yes',
            };
          });
          this.setWorkFlowInstance(true, false);
        } else {
          this.investigationForm.get('investCheckbox')?.setValue(false);
          this.closeForm.get('investCheckbox')?.setValue(false);
          if (!this.investigationForm.get('investCheckbox').value) {
            this.userDetail = null;
            this.membersArray = [];
            this.teamLeadForm.reset();
            this.teamMemberForm.reset();
          }
          this.rulesApplied = this.rulesApplied?.map((ele) => {
            return {
              ...ele,
              rightOperand: 'No',
            };
          });
          this.setWorkFlowInstance(false, false);
        }
      });
  }

  requireInvestigation(): void {
    this.nearMissService.requireInvestigationNearMiss(this.data?.id).subscribe({
      next: (response) => {
        this.getNextTransitionAssigneesFromWorkFlow();
        this.submitInvestigationData(true);
      },
      error: ({ error }) => {
        this.loadingService.stopLoading();
        this.investigationForm.get('investCheckbox')?.setValue(false);
        this.closeForm.get('investCheckbox')?.setValue(false);
      },
    });
  }
  nearMissStatusChangesToInprogress(): void {
    this.nearMissService
      .nearMissStatusChangesToInprogress(this.data?.id)
      .subscribe({
        next: (response) => {
          this.onInvestigate.emit(true);
          this.loadingService.stopLoading();
        },
        error: ({ error }) => {
          this.loadingService.stopLoading();
        },
      });
  }

  acknowledgeModalOpen(): void {
    this.dialog.open(this.acknowledgeModal, {
      height: 'auto',
      width: '50%',
      disableClose: true,
    });
  }

  teamSelected(): void {
    const data: SetInstanceParam = {
      instanceKey: this.instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.fullName,
      actionName: Constants.APP_SELECT_ACTION,
      rules: [],
      roleId: this.loggedUser.roleIds,
      payload: this.data,
    };
    const users = this.membersArray?.map((ele, index) => {
      return {
        level: index + 1,
        email: ele?.email,
        userId: ele?.userId,
      };
    });
    users.push({
      level: users.length + 1,
      email: this.userDetail?.email,
      userId: this.userDetail?.userId,
    });
    this.loadingService.startLoading(true, '');
    this.workFlowService.setWorkFlowInstance(data).subscribe({
      next: (response) => {
        this.setCustomUsersHierarchy(users);
        this.teamHasbeenSelected();
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, 'Team has been selected')
        );
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  completeInvestigation(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          const data: SetInstanceParam = {
            instanceKey: this.instanceKey,
            userId: this.loggedUser.userId,
            userName: this.loggedUser.fullName,
            actionName: Constants.APP_INVESTIGATE_ACTION,
            rules: [],
            roleId: this.loggedUser.roleIds,
            payload: this.data,
          };
          this.loadingService.startLoading(true, '');
          this.workFlowService.setWorkFlowInstance(data).subscribe({
            next: (response) => {
              this.investigationCompleted();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }

  teamHasbeenSelected(): void {
    this.nearMissService.teamSelectedNearMiss(this.data?.id).subscribe({
      next: (response) => {
        this.onInvestigate.emit(true);
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.loadingService.stopLoading();
      },
    });
  }

  openRejectTeamModal(): void {
    this.commentForm.get('comment')?.setValue('');
    this.dialog.open(this.rejectTeamModal, { height: 'auto', width: '50%' });
  }

  teamDeSelected(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['CheckAlerts']['Reject']['Title'],
          this.translationsList['CheckAlerts']['Reject']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.rulesAppliedAcknowledge = this.rulesAppliedAcknowledge?.map(
            (ele) => {
              return {
                ...ele,
                rightOperand: 'No',
              };
            }
          );
          const data: SetInstanceParam = {
            instanceKey: this.instanceKey,
            userId: this.loggedUser.userId,
            userName: this.loggedUser.fullName,
            actionName: Constants.APP_APPROVE_REJECT_ACTION,
            rules: this.rulesAppliedAcknowledge,
            roleId: this.loggedUser.roleIds,
            payload: this.data,
          };
          this.loadingService.startLoading(true, '');
          this.workFlowService.setWorkFlowInstance(data).subscribe({
            next: (response) => {
              this.nearMissService
                .teamDeSelectNearMiss(
                  this.data?.id,
                  this.commentForm.value.comment
                )
                .subscribe({
                  next: (response) => {
                    this.onInvestigate.emit(true);
                    this.getHistoryData();
                    this.loadingService.stopLoading();
                    this.closeDialog();
                    this.feedBackService.showFeedback(
                      new FeedbackModel(
                        FeedbackType.Success,
                        'Team has been rejected'
                      )
                    );
                  },
                  error: ({ error }) => {
                    this.loadingService.stopLoading();
                  },
                });
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }
  investigationCompleted(): void {
    this.nearMissService
      .investigationCompletedNearMiss(this.data?.id)
      .subscribe({
        next: (response) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Success, response?.message)
          );
          this.onInvestigate.emit(true);
          this.loadingService.stopLoading();
        },
        error: ({ error }) => {
          this.loadingService.stopLoading();
        },
      });
  }
  teamApproved(): void {
    this.nearMissService.nearMissTeamApproved(this.data?.id).subscribe({
      next: (response) => {
        this.getNextTransitionAssigneesFromWorkFlow();
      },
      error: ({ error }) => {
        this.loadingService.stopLoading();
      },
    });
  }

  acknowledge(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['CheckAlerts']['Acknowledge']['Title'],
          this.translationsList['CheckAlerts']['Acknowledge']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.rulesAppliedAcknowledge = this.rulesAppliedAcknowledge?.map(
            (ele) => {
              return {
                ...ele,
                rightOperand: 'Yes',
              };
            }
          );
          const data: SetInstanceParam = {
            instanceKey: this.instanceKey,
            userId: this.loggedUser.userId,
            userName: this.loggedUser.fullName,
            actionName: Constants.APP_APPROVE_REJECT_ACTION,
            rules: this.rulesAppliedAcknowledge,
            roleId: this.loggedUser.roleIds,
            payload: this.data,
          };
          this.loadingService.startLoading(true, '');
          this.workFlowService.setWorkFlowInstance(data).subscribe({
            next: (response) => {
              this.dialog.closeAll();
              this.feedBackService.showFeedback(
                new FeedbackModel(
                  FeedbackType.Success,
                  'Team has been Acknowledged'
                )
              );
              this.teamApproved();
              // this.setCustomReminderEscalationMessage("Team has been Acknowledged");
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }
  rejectTeam(): void {
    this.dialog.closeAll();
    this.openRejectTeamModal();
  }

  setCustomReminderEscalationMessage(message: any): void {
    const data = {
      instanceKey: this.instanceKey,
      isReminder: true,
      isEscalation: false,
      message: message,
    };
    this.workFlowService.setReminderEscalationMessage(data).subscribe({
      next: (res) => {
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.loadingService.stopLoading();
      },
    });
  }
  setCustomUsersHierarchy(users: any[]): void {
    const data = {
      instanceKey: this.instanceKey,
      isReminder: true,
      isEscalation: false,
      reminderUsers: users?.map((ele) => {
        return {
          level: ele?.level,
          email: ele?.email,
          userId: ele?.userId,
        };
      }),
      escalationUsers: [],
    };
    this.workFlowService.setCustomUserForReminderOrEscalation(data).subscribe({
      next: (res) => {
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.loadingService.stopLoading();
      },
    });
  }

  setWorkFlowInstance(isRequired: boolean, isDirectClose: boolean): void {
    const data: SetInstanceParam = {
      instanceKey: this.instanceKey,
      userId: this.loggedUser?.userId,
      userName: this.loggedUser?.fullName,
      actionName: Constants.APP_YESORNO_ACTION,
      rules: this.rulesApplied,
      roleId: this.loggedUser?.roleIds,
      payload: this.data,
    };
    this.loadingService.startLoading(true, '');
    this.workFlowService.setWorkFlowInstance(data).subscribe({
      next: (response) => {
        if (isRequired) {
          this.requireInvestigation();
          this.getTransitionRulesForAcknowledge();
        } else {
          if (isDirectClose) {
            this.setCloseWorkFlowInstance();
          } else {
            this.nearMissStatusChangesToInprogress();
          }
        }
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.investigationForm.get('investCheckbox')?.setValue(false);
        this.closeForm.get('investCheckbox')?.setValue(false);
        this.loadingService.stopLoading();
      },
    });
  }
  getTransitionRules(): void {
    const data = {
      instanceKey: this.instanceKey,
      actionName: Constants.APP_YESORNO_ACTION,
    };
    this.workFlowService.getTransitionRules(data).subscribe({
      next: (response) => {
        this.rulesApplied = response;
      },
      error: ({ error }) => {
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
  getTransitionRulesForAcknowledge(): void {
    const data = {
      instanceKey: this.instanceKey,
      actionName: Constants.APP_APPROVE_REJECT_ACTION,
    };
    this.workFlowService.getTransitionRules(data).subscribe({
      next: (response) => {
        this.rulesAppliedAcknowledge = response;
      },
      error: ({ error }) => {
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  onCreateAction(event: boolean): void {
    this.getActionById();
  }

  goBack(): void {
    this.route.queryParams.subscribe((params) => {
      this.router.navigate(
        [
          SharedConstants.REPORTING_MANAGEMENT +
            '/' +
            SharedConstants.REPORT_NEAR_MISS,
        ],
        {
          queryParams: params,
        }
      );
    });
  }

  editReportNearMiss(): void {
    this.router.navigate([
      SharedConstants.REPORTING_MANAGEMENT +
        '/' +
        SharedConstants.REPORT_NEAR_MISS +
        '/' +
        SharedConstants.REPORT_NEAR_MISS_EDIT +
        '/' +
        this.data?.id,
    ]);
  }

  openCloseModal(): void {
    this.getRootCause();
    this.closeForm.get('comment')?.setValue('');
    this.closeForm.get('rcaFile')?.setValue([]);
    this.closeForm.get('rootCause').setValue(this.data?.rootCauseId);
    if (this.data?.id && this.data.instanceKey) {
      this.dialog.open(this.closeModal, { maxHeight: '90vh', width: '50%' });
    }
  }

  closeNearMissReport(): void {
    const { comment, rcaFile } = this.closeForm.value;
    const data = {
      id: this.data.id,
      comment: comment,
    };
    const formData = new FormData();
    formData.append('file', rcaFile[0]);
    formData.append('data', JSON.stringify(data));
    this.nearMissService.closeNearMissReport(formData).subscribe({
      next: (res) => {
        this.loadingService.stopLoading();
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, res?.message)
        );
        this.closeDialog();
        this.goBack();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  checkCloseOrNot(): void {
    if (
      this.closeForm.get('investCheckbox')?.value &&
      this.data?.status === 'NEW'
    ) {
      this.investigationForm.get('investCheckbox')?.setValue(true);
      this.closeForm.get('investCheckbox')?.setValue(true);
      this.rulesApplied = this.rulesApplied?.map((ele) => {
        return {
          ...ele,
          rightOperand: 'Yes',
        };
      });
      this.setWorkFlowInstance(true, false);
      this.closeDialog();
    } else {
      const obj = {
        id: this.data?.id,
        rootCauseId: this.closeForm.get('rootCause').value,
      };
      const NewFormData = new FormData();
      NewFormData.append('content', JSON.stringify(obj));
      this.nearMissService.editNearMissReport(NewFormData).subscribe({
        next: () => {
          this.investigationForm.get('investCheckbox')?.setValue(false);
          this.closeForm.get('investCheckbox')?.setValue(false);
          if (!this.investigationForm.get('investCheckbox').value) {
            this.userDetail = null;
            this.membersArray = [];
            this.teamLeadForm.reset();
            this.teamMemberForm.reset();
          }
          this.rulesApplied = this.rulesApplied?.map((ele) => {
            return {
              ...ele,
              rightOperand: 'No',
            };
          });
          this.data.rootCauseId = obj.rootCauseId;
          if (this.data?.isInvestigationCompleted) {
            this.setCloseWorkFlowInstance();
          } else {
            this.setWorkFlowInstance(false, true);
          }
        },
      });
    }
  }

  setCloseWorkFlowInstance(): void {
    const data: SetInstanceParam = {
      instanceKey: this.instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.fullName,
      actionName: Constants.APP_CLOSE_ACTION,
      rules: [],
      roleId: this.loggedUser.roleIds,
      payload: this.data,
    };
    this.loadingService.startLoading(true, '');
    this.workFlowService.setWorkFlowInstance(data).subscribe({
      next: (response) => {
        this.closeNearMissReport();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  convertUtcToLocal(date) {
    return moment.utc(date).local().format('MMM DD YYYY hh mm A');
  }
}
