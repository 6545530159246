<app-card [title]="'ManageAccessControl.MobileDevices.Title' | translate">
  <div body *ngIf="mobileDevices?.length > 0; else noDataTemplate">
    <mat-tab-group>
      <mat-tab
        *ngFor="
          let device of mobileDevices;
          let i = index;
          trackBy: trackByIndex
        "
        [bodyClass]="'w-full h-full'"
        [label]="device?.manufacturer + device?.model"
      >
        <div>
          <div>
            <div class="mb-2 border rounded-[10px] p-2 min-h-[400px]">
              <div class="flex flex-col">
                <div class="w-10/12 p-4 flex justify-between">
                  <div>
                    <p class="font-bold">
                      {{ device?.manufacturer }} {{ device?.model }}
                    </p>
                    <p>Device ID: {{ device?.id }}</p>
                    <p>{{ device?.osVersion }}</p>
                  </div>
                  <div>
                    <p class="font-bold">{{ device?.applicationVersion }}</p>
                    <p>{{ device?.secureElementType }}</p>
                    <p>
                      NFC Capability:
                      {{ device?.nfcCapability === "Y" ? "Yes" : "No" }}
                    </p>
                    <p>
                      HCE Capability:
                      {{ device?.hceCapability === "Y" ? "Yes" : "No" }}
                    </p>
                  </div>
                </div>

                <div class="w-full">
                  <div class="w-full h-full">
                    <mat-tab-group preserveContent class="w-full h-full">
                      <mat-tab
                        [label]="
                          'Current Mobile IDs ( ' +
                          device.activeCredentials.length +
                          ' )'
                        "
                        [bodyClass]="'w-full h-full'"
                      >
                        <div
                          class="w-full h-full flex rounded-[10px]"
                          *ngIf="
                            device.activeCredentials.length > 0;
                            else NoDevices
                          "
                        >
                          <div
                            #card
                            [id]="'cards-container' + i"
                            class="grid md:grid-cols-3 xl:grid-cols-4 sm:grid-cols-2 gap-2 w-full"
                          >
                            <app-mobile-card-id
                              *ngFor="
                                let card of device.activeCredentials;
                                trackBy: trackByIndex
                              "
                              [cardDetails]="card"
                              [userDetails]="userDetails"
                              (onInvokeCredential)="invokeCredential($event)"
                            ></app-mobile-card-id>
                          </div>
                        </div>
                        <!-- no data -->
                        <ng-template #NoDevices>
                          <div
                            class="flex justify-center items-center border rounded-[10px]"
                          >
                            <div class="flex column items-center">
                              <img
                                style="width: 250px; margin: auto"
                                src="assets/img/no-dashboards.png"
                              />
                              <h1 style="color: #00000099; font-size: large">
                                {{
                                  "ManageAccessControl.MobileDevices.NoDevices"
                                    | translate
                                }}
                              </h1>
                            </div>
                          </div>
                        </ng-template>
                      </mat-tab>

                      <mat-tab
                        [label]="
                          'Revoked Mobile IDs( ' +
                          device.revokedCredentials.length +
                          ' )'
                        "
                        [bodyClass]="'w-full h-full'"
                      >
                        <div
                          class="w-full h-full border flex rounded-[10px]"
                          *ngIf="
                            device.revokedCredentials.length > 0;
                            else NoDevices
                          "
                        >
                          <div
                            #card
                            [id]="'cards-container-revoked' + i"
                            class="grid md:grid-cols-3 xl:grid-cols-4 sm:grid-cols-2 gap-2 w-full"
                          >
                            <app-mobile-card-id
                              *ngFor="
                                let card of device.revokedCredentials;
                                trackBy: trackByIndex
                              "
                              [cardDetails]="card"
                              [userDetails]="userDetails"
                            ></app-mobile-card-id>
                          </div>
                        </div>

                        <!-- no data -->
                        <ng-template #NoDevices>
                          <div
                            class="flex justify-center items-center border rounded-[10px]"
                          >
                            <div class="flex column items-center">
                              <img
                                style="width: 250px; margin: auto"
                                src="assets/img/no-dashboards.png"
                              />
                              <h1 style="color: #00000099; font-size: large">
                                {{
                                  "ManageAccessControl.MobileDevices.NoDevices"
                                    | translate
                                }}
                              </h1>
                            </div>
                          </div>
                        </ng-template>
                      </mat-tab>
                    </mat-tab-group>
                  </div>
                </div>

                <div class="flex justify-end mt-2">
                  <app-basic-button
                    [btnType]="'Approve'"
                    [btnText]="'IssueMobileId'"
                    [isDisabled]="checkSelectedCards(i)"
                    (onClick)="openIssueMobileId(i)"
                  >
                  </app-basic-button>
                  <app-basic-button
                    [btnType]="'Delete'"
                    [btnText]="'DeleteDevice'"
                    (onClick)="deleteDevice(device?.id)"
                  >
                  </app-basic-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</app-card>
<ng-template #noDataTemplate>
  <div class="flex justify-center items-center">
    <div class="flex column items-center">
      <img
        style="width: 250px; margin: auto"
        src="assets/img/no-dashboards.png"
      />
      <h1 style="color: #00000099; font-size: large">
        {{ "ManageAccessControl.MobileDevices.NoMobileIds" | translate }}
      </h1>
    </div>
  </div>
</ng-template>

<ng-template #issueMobileIdModal>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">
      {{ "ManageAccessControl.IssueMobileId" | translate }}
    </h2>
    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

  <mat-dialog-content class="flex flex-col flex-wrap justify-between">
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
      <div
        *ngFor="let card of filteredCards"
        (click)="toggleSelection(card)"
        [class.selected]="card.selected"
        class="p-0 w-[240px] h-[140px] cursor-pointer transition hover:shadow-lg border-2 border-[#F3F3F3] rounded-[15px] flex flex-col"
      >
        <div class="p-2">
          <div class="mb-2 flex justify-between">
            <img
              class="w-[30%] object-contain"
              src="assets/img/resources/saned-logo.png"
              alt="saned"
            />
            <mat-checkbox
              class="step-checkbox"
              disabled
              [checked]="card.selected"
            ></mat-checkbox>
          </div>

          <div class="flex items-center justify-between mt-4">
            <div class="flex items-center">
              <strong>{{ card?.friendlyName }}</strong>
            </div>
          </div>
        </div>

        <div class="flex justify-between hid-cover h-full">
          <div class="flex items-end flex-grow p-2">
            <img
              class="w-[20%]"
              src="assets/img/resources/hid-logo.svg"
              alt="saned"
            />
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div class="flex items-center">
      <app-basic-button
        [btnType]="'Cancel'"
        [btnText]="'Cancel'"
        (onClick)="closeDialog()"
      ></app-basic-button>
      <app-basic-button
        [btnType]="'Add'"
        [btnText]="'Add'"
        [isDisabled]="selectedCardsIds.length === 0"
        (onClick)="confirmIssueMobileId()"
      ></app-basic-button>
    </div>
  </mat-dialog-actions>
</ng-template>
