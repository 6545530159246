<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'BreadCrumb.WorkPermit' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <app-work-permit-form
      *ngIf="mode === 'edit'"
      [workPermit]="workPermit"
      [workPermitId]="workPermitId"
      (formSubmit)="editWorkPermit($event)"
    >
    </app-work-permit-form>

    <app-view-work-permit
      *ngIf="mode === 'view'"
      [workPermit]="workPermit"
      (onReloadData)="reloadData($event)"
    ></app-view-work-permit>
  </hodhod-page-layout-content>
</hodhod-page-layout>
