<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.OrganizationSettings' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <app-state-section
      body
      [flexibleHeight]="true"
      [state]="sectionState"
      [loadingLabel]="'organization.LoadingInfo' | translate"
      [errorLabel]="'Errors.ErrorHappened' | translate"
    >
      <app-basic-button
        class="flex flex-row-reverse p-2"
        [btnType]="'Add'"
        [btnText]="'Edit'"
        (onClick)="Edit()"
      ></app-basic-button>

      <div>
        <app-organization-detail
          [orgInfo]="orgInfo"
          [orgLicenseInfo]="orgLicenseInfo"
          [isAdmin]="true"
        ></app-organization-detail>
      </div>

      <div class="mt-3">
        <app-organization-permissions
          [features]="selectedFeatures"
        ></app-organization-permissions>
      </div>
    </app-state-section>
  </hodhod-page-layout-content>
</hodhod-page-layout>
