import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-manage-user-access-control',
  templateUrl: './manage-user-access-control.component.html',
  styleUrls: ['./manage-user-access-control.component.scss'],
})
export class ManageUserAccessControlComponent implements OnInit {
  public loadEmployees = false;
  public loadVisitors = false;
  constructor() {}

  ngOnInit(): void {}
  tabChanged(event: MatTabChangeEvent) {
    const selectedTabIndex = event.index;
    if (selectedTabIndex == 1 && !this.loadEmployees) {
      this.loadEmployees = true;
    } else if (selectedTabIndex == 2 && !this.loadVisitors) {
      this.loadVisitors = true;
    }
  }
}
