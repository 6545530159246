
<div class="my-2">
  <!--Card 1-->
  <div class="search_card bg-white cursor-pointer transition-all rounded w-full lg:max-w-full lg:flex hover:shadow hover:border-2 hover:border-primary"
   (click)="viewPage()">
    <div class="px-4">
      <ngx-avatars class="logo box mr-4 p-3" size="60"
            [src]="'assets/img/resources/' + setSearchTypeIcon(searchData?.searchType)" [name]="searchData?.title"
            [textSizeRatio]="2" [initialsSize]="2" [round]="false">
        </ngx-avatars>
    </div>
    <div class="rounded-b lg:rounded-b-none lg:rounded-r p-4 
     flex-1 flex flex-col justify-between leading-normal">
      <div class="mb-4">
        <div class="flex justify-between items-center flex-grow">
           <div>
            <p class="text-xs flex items-center">
              <mat-icon svgIcon="mat:calendar_month"></mat-icon>
              {{searchData?.createdAt | date: 'mediumDate'}}
            </p>
            <div class="font-bold text-base text-primary mb-2 hover:text-primary hover:underline">{{searchData?.title | titlecase}}</div>
           </div>
           <div>
            <div class="flex justify-center align-center" [attr.id]="searchData?.searchType">
              <span>
                {{ ('Enum.SearchStatus.' + searchData?.searchType) | translate }}
              </span>
            </div>
           </div>
        </div>
        <p class="text-muted">{{searchData?.description}}</p>
      </div>
      <div class="flex items-center">
        <ngx-avatars class="logo box mr-2" size="30"
            [src]="searchData?.userDetails[0]?.userImage ? searchData?.userDetails[0]?.userImage : ''" [name]="searchData?.userDetails[0]?.userName" [borderColor]="'#1F36B4'"
            [textSizeRatio]="2" [initialsSize]="2" [round]="true">
        </ngx-avatars>
        <div class="text-sm">
          <p class="leading-none">{{searchData?.userDetails[0]?.userName}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
