import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { CompanyService } from 'src/backend/services/company.service';
@UntilDestroy()
@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public comCode: string = '';
  comCardInfo: any;
  constructor(
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private feedBackService: AsyncFeedbackService,
    private router: Router
  ) {
    super();
  }

  override ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        this.comCode = params.get('id') as string;
        this.getComCardInfo();
      }
    });
  }
  getComCardInfo(): void {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.companyService.getCompCardDetails(+this.comCode).subscribe({
      next: (response) => {
        this.comCardInfo = response['message'];
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Error;
      },
    });
  }
  goBack(): void {
    const orgId = this.route.snapshot.queryParams['orgId'];
    this.route.queryParams.subscribe(() => {
      this.router.navigate([
        '/' +
          SharedConstants.ORGANIZATION_SETUP +
          '/' +
          SharedConstants.ORGANIZATION_DETAIL,+
          orgId,
      ]);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }
}
