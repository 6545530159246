import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fadeInRight400ms } from 'src/@hodhod/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { scaleIn400ms } from 'src/@hodhod/animations/scale-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import {
  ChecklistSectionType,
  WorkPermitApproverTypes,
  WorkPermitStatus,
} from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { ChecklistSectionService } from 'src/backend/services/checklist-section/checklist-section.service';
import { WorkConditionsService } from 'src/backend/services/work-conditions/work-conditions.service';
import { WorkPermitService } from 'src/backend/services/work-permits/work-permit.service';
import moment from 'moment';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { WorkFlowService } from 'src/backend/services/work-flow.service';
import { BaseApi } from 'src/backend/api/base-api';
import { ActionTrackerService } from 'src/backend/services/action-tracker/action-tracker.service';
import { ReportingType } from 'src/@hodhod/common/enum';
import { PermissionService } from 'src/backend/services/permission.service';
import {
  SetInstanceParam,
  WorkFlow,
  WorkFlowByScreenParam,
} from 'src/backend/models/work-flows/work-flow';
import { jsPDF } from 'jspdf';
import { LanguageService } from 'src/@hodhod/services/language.service';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-view-work-permit',
  templateUrl: './view-work-permit.component.html',
  styleUrls: ['./view-work-permit.component.scss'],
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    scaleIn400ms,
    stagger40ms,
    scaleFadeIn400ms,
  ],
})
export class ViewWorkPermitComponent implements OnInit, OnChanges {
  @Input() workPermit: any;
  public data: any;
  public selectedImg: any;
  @ViewChild('imageBigView') imageBigView!: TemplateRef<any>;
  @ViewChild('approveRejDialouge') private approveModel!: TemplateRef<any>;
  @ViewChild('extendDateModal') private extendDateModel!: TemplateRef<any>;
  @ViewChild('viewExtendModal') private viewExtendModel!: TemplateRef<any>;
  @ViewChild('closeWorkPermitModal')
  private closeWorkPermitModal!: TemplateRef<any>;
  @ViewChild('closePermitRequestModal')
  private closePermitRequestModal!: TemplateRef<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('pdf') pdf!: ElementRef;
  @Output() onReloadData: EventEmitter<boolean> = new EventEmitter();
  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<any>([]);
  ReportingType = ReportingType;
  approverDataSource = new MatTableDataSource<any>([]);
  jobSafetyDataSource: any = new MatTableDataSource<any>([]);
  public rtl: string;

  riskStatus = [
    {
      text: 'HIGH',
      color: '#D52A46',
    },
    {
      text: 'MEDIUM',
      color: '#E49114',
    },
    {
      text: 'LOW',
      color: '#FFEA30',
    },
  ];
  columns: TableColumn<any>[] = [
    {
      label: 'RequestedBy',
      property: 'requestedBy',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    { label: 'Created', property: 'created', type: 'text', visible: true },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    { label: 'Comments', property: 'comment', type: 'text', visible: true },
  ];
  approverColumns: TableColumn<any>[] = [
    {
      label: 'Approver',
      property: 'approver',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    { label: 'Status', property: 'isApproved', type: 'boolean', visible: true },
  ];
  jobSafetyColumns: TableColumn<any>[] = [
    { label: 'Number', property: 'number', type: 'text', visible: true },
    { label: 'JobSteps', property: 'jobStep', type: 'text', visible: true },
    { label: 'Hazards', property: 'theHazards', type: 'text', visible: true },
    { label: 'RiskLevel', property: 'riskLevel', type: 'text', visible: true },
    {
      label: 'MitigatingSteps',
      property: 'mitigatingSteps',
      type: 'text',
      visible: true,
    },
    {
      label: 'ResidualRisk',
      property: 'residualRisk',
      type: 'text',
      visible: true,
    },
    {
      label: 'ToolsAndEquipments',
      property: 'toolsAndEquipments',
      type: 'text',
      visible: true,
    },
  ];

  WorkPermitStatuses = WorkPermitStatus;
  public workConditions: any[] = [];
  public checklist: any[] = [];
  public checkListScore: string = '0.00%';
  public checkListChange: number = 0;
  gaugeType = 'full';
  gaugeValue = 0;

  gaugeAppendText = '%';
  statusForm: FormGroup;
  extendDateForm: FormGroup;
  closeForm: FormGroup;
  closeRequestForm: FormGroup;
  formMode: 'Approve' | 'Reject' = 'Approve';
  private destroy$ = new Subject();
  public translationsList: any = {};
  hasApprovePermissions = ApplicationPermission.WORK_PERMIT_LIST_APPROVE;
  hasViewPermissions = ApplicationPermission.ACTION_TRACKER_VIEW;
  companyKey: string = '';
  company: any;
  instanceKey: string = '';
  loggedUser: LoggedUser;
  isUserCan: boolean = false;
  isUserAlreadyApproved: boolean = false;
  extendTimeApproved: boolean = false;
  ppes: any = [];
  rulesApplied: Array<{
    condition: string;
    leftOperand: string;
    rightOperand: string;
    operator: string;
    order: number;
  }> = [];
  extendTimeRule: Array<{
    condition: string;
    leftOperand: string;
    rightOperand: string;
    operator: string;
    order: number;
  }> = [];
  extendTimeApprovalRule: Array<{
    condition: string;
    leftOperand: string;
    rightOperand: string;
    operator: string;
    order: number;
  }> = [];
  imageBase64: string;
  viewPermission: boolean;
  workFlow: WorkFlow;
  constructor(
    private loadingService: LoadingService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private asyncFeedbackService: AsyncFeedbackService,
    private workConditionService: WorkConditionsService,
    private checklistService: ChecklistSectionService,
    private fb: FormBuilder,
    private router: Router,
    private workPermitService: WorkPermitService,
    private confirmationService: ConfirmationService,
    private workFlowService: WorkFlowService,
    private baseApi: BaseApi,
    private trackerService: ActionTrackerService,
    private permissionService: PermissionService,
    private langService: LanguageService
  ) {
    this.translate
      .get(['Errors', 'Success', 'ConfirmDeleteRecord', 'User', 'CheckAlerts'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.companyKey = this.baseApi.getCompanyIdFromStorage();
    this.company = this.baseApi.getCompanyFromStorage();
    this.loggedUser = this.baseApi.getUserSession();
    this.statusForm = this.fb.group({
      comment: [''],
    });
    this.closeForm = this.fb.group({
      comment: [''],
    });
    this.closeRequestForm = this.fb.group({
      isExtendTime: [false],
    });
    this.extendDateForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      comment: [''],
      attachments: [[]],
    });
    this.viewPermission = this.permissionService.isUserHasPermission(
      this.hasViewPermissions
    );
    this.langService.currentLanguage$.subscribe((language) => {
      this.rtl = language.isoCode;
    });
  }
  getAttachmentImage(url: string) {
    const extension = url.split('.').pop()?.toLocaleLowerCase();
    if (extension.includes('xlsx') || extension.includes('xls')) {
      return '../../../../assets/img/resources/xls.png';
    } else if (extension.includes('docx') || extension.includes('doc')) {
      return '../../../../assets/img/resources/doc.png';
    } else if (extension.includes('pdf')) {
      return '../../../../assets/img/resources/pdf.png';
    } else {
      return url;
    }
  }
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL('image/png');

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }

  getColor(value: string) {
    return this.riskStatus.find((item) => item.text == value)?.color;
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  get visibleApproverColumns() {
    return this.approverColumns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  get visibleJobSafetyColumns() {
    return this.jobSafetyColumns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['workPermit']) {
      this.data = this.workPermit;
      this.getWorkConditions();
      if (this.data) {
        this.getWorkFlow();
        this.formationOfCheckList();
        this.dataSource = new MatTableDataSource(
          this.data?.histories?.map((ele) => {
            return {
              ...ele,
              created: moment(ele.created)
                .local(true)
                .format('MMM DD YYYY hh mm A'),
            };
          })
        );
        this.approverDataSource = new MatTableDataSource(
          this.data?.additionalApprovers
        );
        this.jobSafetyDataSource = new MatTableDataSource(
          this.data?.jobSafetyAnalyisses
        );
        this.ppes = this.data?.ppeDtos;
        this.paginator.length = this.data?.histories?.length;
        this.checkUserCanApproverReject();
        this.checkUserAlreadyApproved();
        this.checkUserHaveApprovedExtendTimeRequest();
      }
    }
  }

  getWorkFlow(): void {
    const data: WorkFlowByScreenParam = {
      screenName: Constants.WORKFLOW_WORKPERMIT_SCREEN,
      tenantId: this.companyKey,
      companyKey: Constants.WORK_FLOW_COMPANY_KEY,
    };
    this.workFlowService.getWorkFlowByScreen(data).subscribe({
      next: (response) => {
        this.workFlow = response;
        this.getWorkflowInstanceKeyById(this.workFlow.workFlowKey);
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getWorkflowInstanceKeyById(workflowKey: string) {
    this.workFlowService
      .getInstanceKeyByTableId(workflowKey, this.data?.id)
      .subscribe({
        next: (res) => {
          this.instanceKey = res?.instanceKey
            ? res?.instanceKey
            : this.data?.instanceKey;
          this.getTransitionRules();
          this.getExtentTimeRules();
          this.getExtendTimeApprovalRules();
        },
        error: ({ error }) => {
          this.asyncFeedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
        },
      });
  }

  checkUserCanCloseRequest(): boolean {
    const permission = this.data?.additionalApprovers?.find(
      (x) =>
        x.approverId === this.loggedUser.userId &&
        x.approverType === WorkPermitApproverTypes.InitialApprover
    );
    if (permission) {
      return true;
    }
    return false;
  }
  checkUserCanApproverReject(): any {
    const permission = this.data?.additionalApprovers?.find(
      (x) => x.approverId === this.loggedUser.userId
    );
    if (permission) {
      this.isUserCan = true;
    } else {
      this.isUserCan = false;
    }
  }
  checkUserAlreadyApproved(): any {
    const permission = this.data?.additionalApprovers?.find(
      (x) => x.approverId === this.loggedUser.userId && x.isApproved === true
    );
    if (permission) {
      this.isUserAlreadyApproved = true;
    } else {
      this.isUserAlreadyApproved = false;
    }
  }
  checkUserHaveApprovedExtendTimeRequest(): any {
    const permission = this.data?.additionalApprovers?.find(
      (x) => x.extendTimeApproved === true
    );
    if (permission) {
      this.extendTimeApproved = true;
    } else {
      this.extendTimeApproved = false;
    }
  }

  getAdditionalApproversString(): string {
    return this.data?.additionalApprovers
      .filter(
        (x) => x.approverType === WorkPermitApproverTypes.AdditionalApprover
      )
      ?.map((ele) => ele.approver)
      .toString();
  }
  getInitialApproversString(): string {
    return this.data?.additionalApprovers
      .filter((x) => x.approverType === WorkPermitApproverTypes.InitialApprover)
      ?.map((ele) => ele.approver)
      .toString();
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  getWorkConditions(): void {
    this.loadingService.startLoading(true, '');
    this.workConditionService
      .getWorkConditionsWithCheckListExecution()
      .subscribe({
        next: (response) => {
          const conditions = [];

          for (let condition of this.data?.workConditions) {
            const found = response.find((x) => x.id === condition.id);
            conditions.push(found);
          }
          this.workConditions = conditions;
          this.loadingService.stopLoading();
          this.GetScorePercentage();
        },
        error: ({ error }) => {
          this.asyncFeedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.loadingService.stopLoading();
        },
      });
  }

  formationOfCheckList(): void {
    const mergeSections: any[] = [];
    this.data?.sections?.forEach((ele) => {
      mergeSections.push(...ele?.section);
    });
    this.checklist = this.formatCheckListData(mergeSections);
    this.calculateChecklistAnswers();
    this.GetScorePercentage();
  }

  formatCheckListData(data: any[]): any[] {
    const formatedData = data.map((section, i) => {
      return {
        sectionId: section?.sectionId,
        title: section?.sectionTitle,
        questionType: section?.sectionType,
        questions: section?.questions?.map((question, j) => {
          let params: any[] = [];
          if (section?.sectionType === ChecklistSectionType.MachineParameter) {
            this.checklistService
              .getParameters(parseInt(question?.answer, 10))
              .subscribe((res) => {
                params = res;
              });
          }
          return {
            questionId: question?.questionId,
            questionText:
              section?.sectionType === ChecklistSectionType.MachineParameter
                ? parseInt(question?.questionTitle, 10)
                : question?.questionTitle,
            answer: question?.answer,
            target:
              section?.sectionType === ChecklistSectionType.ValueVsTarget
                ? question?.answer.split(' ')[1]
                : '',
            parameters: [...params],
            userAnswer:
              section?.sectionType === ChecklistSectionType.ValueVsTarget
                ? question?.userAnswer
                  ? question?.userAnswer.split(' ')[1]
                  : ''
                : question?.userAnswer,
            comment: question?.comment,
            isNotApplicable: question?.isNotApplicable,
            media: question?.media,
          };
        }),
      };
    });
    return formatedData;
  }

  calculateChecklistAnswers(): void {
    const questions: any[] = [];
    for (const section of this.checklist) {
      questions.push(...section?.questions);
    }
  }

  openBigView(id: number): void {
    const img = this.data?.images.find((x) => x.id === id);
    if (img) {
      const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
      const extension = img.imageUrl.split('.').pop();
      if (imageExtensions.includes(extension)) {
        this.selectedImg = img;
        this.dialog.open(this.imageBigView, {
          maxHeight: '95vh',
          width: 'auto',
        });
      } else {
        const downloadTag = document.createElement('a');
        downloadTag.href = img.imageUrl;
        downloadTag.addEventListener('click', () => {
          downloadTag.download;
        });
        downloadTag.click();
      }
    }
  }

  downloadDocument(url: any): void {
    const downloadTag = document.createElement('a');
    downloadTag.href = url;
    downloadTag.addEventListener('click', () => {
      downloadTag.download;
    });
    downloadTag.click();
  }

  getAttachementTypeName(url: string): any {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
    const extension = url.split('.').pop();
    if (imageExtensions.includes(extension)) {
      return 'Image';
    }
    return 'Document';
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  openApproveModel(): void {
    this.statusForm.reset();
    this.formMode = 'Approve';
    this.dialog.open(this.approveModel, {
      height: 'auto',
      width: '50%',
    });
  }
  openRejectModel(): void {
    this.statusForm.reset();
    this.formMode = 'Reject';
    this.dialog.open(this.approveModel, {
      height: 'auto',
      width: '50%',
    });
  }

  checkExtendTimeRequired(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['CheckAlerts']['ExtendTime']['Title'],
          this.translationsList['CheckAlerts']['ExtendTime']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingService.startLoading(true, '');
          this.extendTimeRule = this.extendTimeRule?.map((ele) => {
            return {
              ...ele,
              rightOperand: 'Yes',
            };
          });
          this.setExtendTimeInstance(true);
        } else {
          this.loadingService.startLoading(true, '');
          this.extendTimeRule = this.extendTimeRule?.map((ele) => {
            return {
              ...ele,
              rightOperand: 'No',
            };
          });
          this.setExtendTimeInstance(false);
        }
      });
  }

  openExtendDateModel(): void {
    this.setDataExtendForm();
    this.dialog.open(this.extendDateModel, {
      height: 'auto',
      width: '50%',
    });
  }

  viewExtendDateModel(): void {
    this.dialog.open(this.viewExtendModel, {
      height: 'auto',
      width: '50%',
    });
  }

  setDataExtendForm(): void {
    if (this.data) {
      this.extendDateForm.get('startDate').setValue(this.data?.startDate);
      this.extendDateForm.get('endDate').setValue(this.data?.endDate);
      this.extendDateForm.get('startTime').setValue(this.data?.startTime);
      this.extendDateForm.get('endTime').setValue(this.data?.endTime);
    }
  }

  saveExtendDate(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingService.startLoading(true, '');
          const data: SetInstanceParam = {
            instanceKey: this.instanceKey,
            userId: this.loggedUser.userId,
            userName: this.loggedUser.fullName,
            actionName: Constants.APP_EXTENDED_ACTION,
            rules: [],
            roleId: this.loggedUser.roleIds,
            payload: this.data,
          };
          this.workFlowService.setWorkFlowInstance(data).subscribe({
            next: (response) => {
              this.extendDate();
            },
            error: ({ error }) => {
              this.asyncFeedbackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }

  extendDate(): void {
    const { startDate, endDate, startTime, endTime, comment, attachments } =
      this.extendDateForm.value;
    const formData = new FormData();
    const data = {
      startDate: moment(new Date(startDate)).format('YYYY-MM-DDThh:mm:ssZ'),
      endDate: moment(new Date(endDate)).format('YYYY-MM-DDThh:mm:ssZ'),
      startTime: startTime,
      endTime: endTime,
      workPermitId: this.data?.id,
      comment: comment,
    };
    attachments.forEach((element) => {
      formData.append('files', element);
    });
    formData.append('content', JSON.stringify(data));
    this.workPermitService.saveExtendDate(formData).subscribe({
      next: (response) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, response?.message)
        );
        this.loadingService.stopLoading();
        this.closeDialog();
        this.setDataExtendForm();
        this.onReloadData.emit(true);
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  openCloseModal(): void {
    this.closeForm.get('comment')?.setValue('');
    this.dialog.open(this.closeWorkPermitModal, {
      height: 'auto',
      width: '50%',
    });
  }

  openCloseWorkPermitRequest(): void {
    this.closeRequestForm.get('isExtendTime')?.setValue(false);
    this.dialog.open(this.closePermitRequestModal, {
      height: 'auto',
      width: '50%',
    });
  }
  closeSubmitPermitRequest(): void {
    this.loadingService.startLoading(true, '');
    if (this.closeRequestForm.value.isExtendTime) {
      this.extendTimeRule = this.extendTimeRule?.map((ele) => {
        return {
          ...ele,
          rightOperand: 'Yes',
        };
      });
      this.setExtendTimeInstance(true);
    } else {
      this.extendTimeRule = this.extendTimeRule?.map((ele) => {
        return {
          ...ele,
          rightOperand: 'No',
        };
      });
      this.closeWorkPermitRequest();
    }
  }
  closeWorkPermitRequest(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['CheckAlerts']['CloseWorkPermit']['Title'],
          this.translationsList['CheckAlerts']['CloseWorkPermit']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.setExtendTimeInstance(false);
        } else {
          this.loadingService.stopLoading();
        }
      });
  }

  closeWorkPermit(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['CheckAlerts']['CloseWorkPermit']['Title'],
          this.translationsList['CheckAlerts']['CloseWorkPermit']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingService.startLoading(true, '');
          const data: SetInstanceParam = {
            instanceKey: this.instanceKey,
            userId: this.loggedUser.userId,
            userName: this.loggedUser.fullName,
            actionName: Constants.APP_CLOSE_ACTION,
            rules: [],
            roleId: this.loggedUser.roleIds,
            payload: this.data,
          };
          this.workFlowService.setWorkFlowInstance(data).subscribe({
            next: (response) => {
              this.close();
            },
            error: ({ error }) => {
              this.asyncFeedbackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }

  close(): void {
    const data = {
      workPermitId: this.data?.id,
      comment: this.closeForm.value.comment ? this.closeForm.value.comment : '',
    };
    this.workPermitService.closeWorkPermit(data).subscribe({
      next: (response) => {
        this.loadingService.stopLoading();
        this.closeDialog();
        this.onReloadData.emit(true);
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  approveExtendDate(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['CheckAlerts']['ApproveExtendTimeRequest'][
            'Title'
          ],
          this.translationsList['CheckAlerts']['ApproveExtendTimeRequest'][
            'Message'
          ]
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingService.startLoading(true, '');
          const obj = {
            instanceKey: this.instanceKey,
            userKey: this.loggedUser.userId,
            actionName: Constants.APP_APPROVE_REJECT_ACTION,
            tableId: this.data?.id,
            workflowKey: this.workFlow.workFlowKey,
            roleId: this.loggedUser.roleIds,
          };
          this.workFlowService.getUserPermission(obj).subscribe({
            next: (res: any) => {
              if (res?.isSuccess) {
                const data = {
                  id: this.data?.extendDateDetail?.id,
                  workPermitId: this.data.id,
                };
                this.workPermitService.approveExtendDate(data).subscribe({
                  next: (response) => {
                    this.extendTimeApprovalRule =
                      this.extendTimeApprovalRule?.map((ele) => {
                        return {
                          ...ele,
                          rightOperand: 'Yes',
                        };
                      });
                    this.setTimeApprovalInstance();
                  },
                  error: ({ error }) => {
                    this.asyncFeedbackService.showFeedback(
                      new FeedbackModel(FeedbackType.Failure, error?.message)
                    );
                    this.loadingService.stopLoading();
                  },
                });
              }
            },
            error: ({ error }) => {
              this.asyncFeedbackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }

  rejectExtendDate(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['CheckAlerts']['RejectExtendTimeRequest'][
            'Title'
          ],
          this.translationsList['CheckAlerts']['RejectExtendTimeRequest'][
            'Message'
          ]
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingService.startLoading(true, '');
          const obj = {
            instanceKey: this.instanceKey,
            userKey: this.loggedUser.userId,
            actionName: Constants.APP_APPROVE_REJECT_ACTION,
            tableId: this.data?.id,
            workflowKey: this.workFlow.workFlowKey,
            roleId: this.loggedUser.roleIds,
          };
          this.workFlowService.getUserPermission(obj).subscribe({
            next: (res: any) => {
              if (res?.isSuccess) {
                const data = {
                  id: this.data?.extendDateDetail?.id,
                  workPermitId: this.data.id,
                };
                this.workPermitService.rejectExtendDate(data).subscribe({
                  next: (response) => {
                    this.extendTimeApprovalRule =
                      this.extendTimeApprovalRule?.map((ele) => {
                        return {
                          ...ele,
                          rightOperand: 'No',
                        };
                      });
                    this.setTimeApprovalInstance();
                  },
                  error: ({ error }) => {
                    this.asyncFeedbackService.showFeedback(
                      new FeedbackModel(FeedbackType.Failure, error?.message)
                    );
                    this.loadingService.stopLoading();
                  },
                });
              }
            },
            error: ({ error }) => {
              this.asyncFeedbackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }

  approveWorkPermit(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['CheckAlerts']['AcknowledgeWorkPermit'][
            'Title'
          ],
          this.translationsList['CheckAlerts']['AcknowledgeWorkPermit'][
            'Message'
          ]
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingService.startLoading(true, '');
          const obj = {
            instanceKey: this.instanceKey,
            userKey: this.loggedUser.userId,
            actionName: Constants.APP_APPROVE_REJECT_ACTION,
            tableId: this.data?.id,
            workflowKey: this.workFlow.workFlowKey,
            roleId: this.loggedUser.roleIds,
          };
          this.workFlowService.getUserPermission(obj).subscribe({
            next: (res: any) => {
              if (res?.isSuccess) {
                const data = {
                  workPermitId: this.data.id,
                  comment: this.statusForm.value.comment
                    ? this.statusForm.value.comment
                    : '',
                  type: 'APPROVE',
                };
                this.workPermitService
                  .approveOrRejectWorkPermit(data)
                  .subscribe({
                    next: (response) => {
                      if (response?.isApprovedAll) {
                        this.rulesApplied = this.rulesApplied?.map((ele) => {
                          return {
                            ...ele,
                            rightOperand: 'Yes',
                          };
                        });
                        this.setWorkFlowInstance();
                      } else {
                        this.loadingService.stopLoading();
                        this.closeDialog();
                        this.goBack();
                      }
                      this.asyncFeedbackService.showFeedback(
                        new FeedbackModel(
                          FeedbackType.Success,
                          response?.message
                        )
                      );
                    },
                    error: ({ error }) => {
                      this.asyncFeedbackService.showFeedback(
                        new FeedbackModel(FeedbackType.Failure, error?.message)
                      );
                      this.loadingService.stopLoading();
                    },
                  });
              }
            },
            error: ({ error }) => {
              this.asyncFeedbackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }
  rejectWorkPermit(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingService.startLoading(true, '');
          const obj = {
            instanceKey: this.instanceKey,
            userKey: this.loggedUser.userId,
            actionName: Constants.APP_APPROVE_REJECT_ACTION,
            tableId: this.data?.id,
            workflowKey: this.workFlow.workFlowKey,
            roleId: this.loggedUser.roleIds,
          };
          this.workFlowService.getUserPermission(obj).subscribe({
            next: (res: any) => {
              if (res?.isSuccess) {
                const data = {
                  workPermitId: this.data.id,
                  comment: this.statusForm.value.comment
                    ? this.statusForm.value.comment
                    : '',
                  type: 'REJECT',
                };
                this.workPermitService
                  .approveOrRejectWorkPermit(data)
                  .subscribe({
                    next: (response) => {
                      this.rulesApplied = this.rulesApplied?.map((ele) => {
                        return {
                          ...ele,
                          rightOperand: 'No',
                        };
                      });
                      this.setWorkFlowInstance();
                      this.asyncFeedbackService.showFeedback(
                        new FeedbackModel(
                          FeedbackType.Success,
                          response?.message
                        )
                      );
                    },
                    error: ({ error }) => {
                      this.asyncFeedbackService.showFeedback(
                        new FeedbackModel(FeedbackType.Failure, error?.message)
                      );
                      this.loadingService.stopLoading();
                    },
                  });
              }
            },
            error: ({ error }) => {
              this.asyncFeedbackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }

  goBack(): void {
    this.router.navigate([
      '/' +
        SharedConstants.WORK_PERMIT +
        '/' +
        SharedConstants.WORK_PERMIT_LIST,
    ]);
  }
  GetScorePercentage(): any {
    let correctanswers = 0;
    let NoOfQuestions = 0;
    let Percentage = 0;
    let sectionsFormArray = this.checklist;
    sectionsFormArray.forEach((element) => {
      if (element.questionType === 'Value Vs Target') {
        element.questions.forEach((el1) => {
          if (!el1.isNotApplicable) {
            if (
              el1.answer + ' ' + ' ' + el1.target ==
              el1.answer + ' ' + ' ' + el1.userAnswer
            ) {
              correctanswers++;
            }
            NoOfQuestions++;
          }
        });
      } else if (element.questionType === 'Select from range') {
        element.questions.forEach((el1) => {
          if (!el1.isNotApplicable) {
            switch (el1.userAnswer) {
              case '0':
                correctanswers = correctanswers + 0;
                break;
              case '1':
                correctanswers = correctanswers + 0.25;
                break;
              case '2':
                correctanswers = correctanswers + 0.5;
                break;
              case '3':
                correctanswers = correctanswers + 0.75;
                break;
              case '4':
                correctanswers++;
                break;
              default:
                break;
            }
            NoOfQuestions++;
          }
        });
      } else {
        element.questions.forEach((el1) => {
          if (!el1.isNotApplicable) {
            if (el1.answer == el1.userAnswer) {
              correctanswers++;
            }
            NoOfQuestions++;
          }
        });
      }
    });

    Percentage = (correctanswers / NoOfQuestions) * 100;
    this.gaugeValue = Number(Percentage.toFixed(1));
  }

  getGuageColors(value: number): string {
    if (value < 40) {
      return '#fa2020';
    }
    if (value >= 40 && value < 70) {
      return '#2041fa';
    }
    if (value >= 70) {
      return '#42f548';
    }
    return '';
  }

  printWorkPermitReport() {
    if (this.data) {
      this.takePrint(this.data);
    }
  }

  getDate(dt) {
    const date = moment(dt).local(true).format('MMM-DD-YYYY hh:mm A');
    return date;
  }
  async takePrint(data: any): Promise<void> {
    const doc = new jsPDF({
      orientation: 'portrait',
    }).setProperties({ title: 'Work Permit' });
    doc.addFont('assets/fonts/Amiri-Regular.ttf', 'Amiri', 'normal');
    doc.setFont('Amiri', 'italic', 'bold');
    const date = moment(data?.updatedOn)
      .local(true)
      .format('MMM DD YYYY hh mm A');
    let y = 5;

    // Logo and Company Data
    const companyLogo =
      JSON.parse(localStorage.getItem('company')).companyImage || '';
    if (companyLogo) {
      doc.addImage(companyLogo, 'PNG', 10, y, 20, 20); // Adjust positioning and size as needed
      doc.text(
        JSON.parse(localStorage.getItem('company')).companyName,
        35,
        y + 10
      );
      y += 45;
    } else {
      doc.text(JSON.parse(localStorage.getItem('company')).companyName, 10, y, {
        align: 'center',
      });
      y += 20;
    }
    // Title Line
    doc.setLineWidth(1);
    doc.line(10, 30, 200, 30);
    doc.addImage(
      '../../../../assets/img/resources/saned-white-logo.png',
      'PNG',
      140,
      24,
      40,
      10
    );
    y += 10;
    // Report Title
    doc.setFont('Amiri', 'normal');
    doc.setFontSize(18);
    doc.text(this.rtl === 'ar' ? ' تصريح العمل' : 'Work Permit', 105, 40, {
      align: 'center',
    });
    //Details
    let Y = y;

    const details = [
      { label: 'Code', arLabel: 'الكود', value: data?.code },
      { label: 'Title', arLabel: 'العنوان', value: data?.title },
      {
        label: 'Department',
        arLabel: 'القسم',
        value: data?.departmentParentName || data?.department,
      },
      {
        label: 'Sub Department',
        arLabel: 'القسم الفرعي',
        value: data?.departmentParentName ? data?.department : '--',
      },
      {
        label: 'No Of Work',
        arLabel: 'عدد العاملين ',
        value: data?.noOfWorkers.toString(),
      },
      {
        label: 'Start Date',
        arLabel: 'تاريخ البداية',
        value: this.getDate(data?.startDate),
      },
      {
        label: 'End Date',
        arLabel: 'تاريخ النهاية',
        value: this.getDate(data?.endDate),
      },
      {
        label: 'Additional Approvers',
        arLabel: 'الموافقين الأضافيين',
        value: this.data?.additionalApprovers
          .filter(
            (x) => x.approverType === WorkPermitApproverTypes.AdditionalApprover
          )
          ?.map((ele) => ele.approver),
      },
    ];

    const secDetails = [
      { label: 'Status', arLabel: 'الحالة', value: data?.status },
      { label: 'ٍStart Time', arLabel: 'وقت البداية', value: data?.startTime },
      { label: 'End Time', arLabel: 'وقت النهاية ', value: data?.endTime },
      {
        label: 'Updated At',
        arLabel: 'تحديث في',
        value: this.getDate(data?.updatedOn),
      },
      { label: 'Requested By', arLabel: ' بتوصية من', value: data?.requestor },
      {
        label: 'Work Permit Approver',
        arLabel: 'الموافق على تصريح العمل',
        value: this.data?.additionalApprovers
          .filter(
            (x) => x.approverType === WorkPermitApproverTypes.InitialApprover
          )
          ?.map((ele) => ele.approver),
      },
    ];

    doc.setFontSize(12);
    let pageHeight = doc.internal.pageSize.height;
    let bottomMargin = 10;
    const lineHeight = 5;

    y = 50;
    Y = 50;

    function addTextWithOverflow(
      doc,
      text,
      x,
      y,
      maxWidth,
      rtl,
      pageHeight,
      bottomMargin,
      desc?
    ) {
      const lines = doc.splitTextToSize(text, maxWidth);
      lines.forEach((line) => {
        if (y + 10 > pageHeight - bottomMargin) {
          doc.addPage();
          y = 10;
        }
        if (desc) {
          if (checkIfArabic(line)) {
            doc.text(line, 190, y, { align: 'right' });
          } else {
            doc.text(line, x, y);
          }
        } else {
          doc.text(line, x, y);
        }
        y += 5;
      });
      return y;
    }
    function checkIfArabic(text: string) {
      if (text.includes('ا' || 'أ' || 'ل')) {
        return true;
      } else {
        return false;
      }
    }

    const maxLength = Math.max(details.length, secDetails.length);

    for (let i = 0; i < maxLength; i++) {
      if (i < details.length) {
        doc.setTextColor('#1F36B4');
        doc.addImage(
          'assets/img/resources/pdfArrow.png',
          'PNG',
          8,
          y - 3,
          5,
          5
        );
        if (this.rtl === 'ar') {
          doc.text(details[i].arLabel, 15, y);
        } else {
          doc.text(details[i].label, 15, y);
        }
        y += 5;
        doc.setTextColor('black');
        y = addTextWithOverflow(
          doc,
          details[i].value || '',
          15,
          y,
          90,
          this.rtl,
          pageHeight,
          bottomMargin
        );
        y += 5;
      }

      if (i < secDetails.length) {
        doc.setTextColor('#1F36B4');
        doc.addImage(
          'assets/img/resources/pdfArrow.png',
          'PNG',
          103,
          Y - 3,
          5,
          5
        );
        if (this.rtl === 'ar') {
          doc.text(secDetails[i].arLabel, 110, Y);
        } else {
          doc.text(secDetails[i].label, 110, Y);
        }
        Y += 5;
        doc.setTextColor('black');
        Y = addTextWithOverflow(
          doc,
          secDetails[i].value || '',
          110,
          Y,
          90,
          this.rtl,
          pageHeight,
          bottomMargin
        );
        Y += 5;
      }
    }
    y += 5;
    doc.setTextColor('#1F36B4');
    doc.addImage('assets/img/resources/pdfArrow.png', 'PNG', 8, y - 3, 5, 5);
    if (this.rtl === 'ar') {
      doc.text('وصف النشاط المخطط', 15, y);
    } else {
      doc.text('Planned Activity Description', 15, y);
    }
    y += 5;
    y = addTextWithOverflow(
      doc,
      data?.plannedActivityDescription || '',
      15,
      y,
      200,
      this.rtl,
      pageHeight,
      bottomMargin,
      true
    );
    y += 5;
    doc.setTextColor('#1F36B4');
    doc.addImage('assets/img/resources/pdfArrow.png', 'PNG', 8, y - 3, 5, 5);
    if (this.rtl === 'ar') {
      doc.text('المعدات التي سيتم استخدامها ', 15, y);
    } else {
      doc.text('Equipment Description', 15, y);
    }
    y += 5;
    y = addTextWithOverflow(
      doc,
      data?.equipmentDescription || '',
      15,
      y,
      210,
      this.rtl,
      pageHeight,
      bottomMargin,
      true
    );
    y += 10;
    // Images Section
    const checkPageOverflow = (incrementHeight) => {
      if (y + incrementHeight > pageHeight) {
        doc.addPage();
        y = 20;
      }
    };
    if (data?.images.length > 0) {
      doc.setFontSize(18);
      if (this.rtl === 'ar') {
        doc.text('الملفات', 103, y);
      } else {
        doc.text('Documents', 103, y);
      }
      let x = 10;
      let count = 0;
      const pageWidth = doc.internal.pageSize.width - 20; // Adjust for margins
      const imageWidth = 25;
      const imageHeight = 25;
      const spacing = 10;

      for (let i = 0; i < data?.images.length; i++) {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
        const extension = data?.images[i].imageUrl.split('.').pop();
        let imgData = null;
        if (imageExtensions.includes(extension)) {
          imgData = data?.images[i].imageUrl;
        } else {
          imgData = await this.getBase64ImageFromURL(
            this.getAttachmentImage(data?.images[i].imageUrl)
          );
        }
        if (imgData) {
          if (x + imageWidth > pageWidth) {
            x = 10;
            y += imageHeight + spacing;
          }

          // Add the image to the PDF
          doc.addImage(imgData, 'JPEG', x, y, imageWidth, imageHeight);

          // Add clickable annotation
          doc.link(x, y, imageWidth, imageHeight, {
            url: data?.images[i].imageUrl,
          });

          x += imageWidth + spacing;
          count++;
        }
      }
      y += imageHeight + spacing;
    }
    //PPE
    if (data?.ppeDtos?.length > 0) {
      y += 5;
      doc.setTextColor('#1F36B4');
      doc.addImage('assets/img/resources/pdfArrow.png', 'PNG', 8, y - 3, 5, 5);
      doc.setFontSize(16);
      if (this.rtl === 'ar') {
        doc.text('معدات الحماية', 15, y);
      } else {
        doc.text('PPE List', 15, y);
      }
      y += 5;
      doc.setLineWidth(0.5);
      doc.line(10, y, 200, y);
      y += 5;
      Y = y;
      let x = 15;
      const pageHeight = doc.internal.pageSize.height;
      const bottomMargin = 20;
      const lineHeight = 5;

      for (let j = 0; j < data?.ppeDtos?.length; j++) {
        if (data?.ppeDtos[j]) {
          doc.setTextColor('black');
          doc.setFontSize(12);
          doc.text(j + 1 + '-', x - 2, y + 5);
          if (data?.ppeDtos[j]?.imageUrl) {
            doc.addImage(data?.ppeDtos[j]?.imageUrl, 'PNG', x + 3, y, 10, 10);
          }

          doc.text(data?.ppeDtos[j]?.ppeName || '', x + 15, y + 5, {
            maxWidth: 200,
          });
          if (y + lineHeight > pageHeight - bottomMargin) {
            doc.addPage();

            y = 10;
          }

          y += 12;
        }
      }
    }
    // worker Infos
    y += 20;
    doc.setTextColor('#1F36B4');
    doc.setFontSize(18);
    doc.addImage('assets/img/resources/pdfArrow.png', 'PNG', 8, y - 3, 5, 5);
    if (this.rtl === 'ar') {
      doc.text('معلومات العامل', 15, y);
    } else {
      doc.text('Worker Info', 15, y);
    }
    y += 5;
    doc.setLineWidth(0.5);
    doc.line(10, y, 200, y);
    y += 10;
    for (let i = 0; i < data?.workerInfos.length; i++) {
      doc.setFontSize(14);
      if (this.rtl === 'ar') {
        doc.text('اسم العامل', 15, y);
      } else {
        doc.text('Worker Name', 15, y);
      }
      doc.setFontSize(12);
      doc.text(data?.workerInfos[i].workerName, 15, y + 5, { maxWidth: 250 });
      doc.setFontSize(14);
      if (this.rtl === 'ar') {
        doc.text('وظيفة العامل', 110, y);
      } else {
        doc.text('Worker Role', 110, y);
      }
      doc.setFontSize(12);
      doc.text(data?.workerInfos[i].workerRole, 110, y + 5, { maxWidth: 250 });
      if (y + lineHeight > pageHeight - bottomMargin) {
        doc.addPage();
        y = 10;
      }
      y += 15;
    }
    const translateKey = (key) => {
      const translations = {
        jobStep: 'خطوات الوظيفة',
        theHazards: 'المخاطر',
        riskLevel: 'مستوى الخطر',
        mitigatingSteps: 'خطوات التخفيف',
        residualRisk: 'المخاطر المتبقية',
        toolsAndEquipments: 'أدوات ومعدات',
        requestedBy: 'تم بواسطة',
        Responsibility: 'المسؤل',
        priority: 'الأفضلية',
        status: 'الحاله',
        assignToName: 'المسؤل',
        fullName: 'الاسم',
        department: 'القسم',
        role: 'الدور',
        created: 'تاريخ الإنشاء',
        comments: 'التعليقات',
      };
      return translations[key] || key;
    };
    const createHeaders = (keys, rtl) => {
      const translatedKeys = rtl === 'ar' ? keys.map(translateKey) : keys;
      return translatedKeys.map((key) => ({
        id: key,
        name: key,
        prompt: key.replace(/([A-Z])/g, ' $1').trim(),
        width: 40,
        align: 'center',
        padding: 5,
      }));
    };
    // Function to generate table data
    const generateTableData = (data, fields) => {
      return data.map((item) =>
        fields.reduce((acc, field) => {
          acc[field] =
            field === 'targetDate' || field === 'closingDate'
              ? moment.utc(item[field]).local(true).format('MMM-DD-yyyy')
              : item[field];
          return acc;
        }, {})
      );
    };

    // Function to add a table to the document and return the new Y position
    const addTable = (doc, startY, data, headers, rtl) => {
      autoTable(doc, {
        startY: startY,
        head: [headers.map((header) => header.prompt)],
        body: data.map((row) => Object.values(row)),
        styles: { fontSize: 12, font: 'Amiri', overflow: 'linebreak' },
      });
      return doc.lastAutoTable.finalY + 20; // Add space after the table
    };
    //
    //job Safety Analysis
    if (this.data?.jobSafetyAnalyisses.length > 0) {
      y += 10;
      doc.setFontSize(18);
      if (this.rtl === 'ar') {
        doc.text('تحليل سلامة العمل', 105, y, { align: 'center' });
      } else {
        doc.text('job Safety Analysis', 105, y, { align: 'center' });
      }
      y += 5;

      doc.setLineWidth(0.5);
      doc.line(10, y, 200, y);
      y += 5;

      doc.setFontSize(12);
      doc.setTextColor('black');
      const SafetyAnalyissesFields = [
        'jobStep',
        'theHazards',
        'riskLevel',
        'mitigatingSteps',
        'residualRisk',
        'toolsAndEquipments',
      ];
      const SafetyAnalyissesHeaders = createHeaders(
        SafetyAnalyissesFields,
        this.rtl
      );
      const SafetyAnalyissesTableData = generateTableData(
        data?.jobSafetyAnalyisses,
        SafetyAnalyissesFields
      );
      y = addTable(
        doc,
        y,
        SafetyAnalyissesTableData,
        SafetyAnalyissesHeaders,
        this.rtl
      );
      if (y + lineHeight > pageHeight - bottomMargin) {
        doc.addPage();
        y = 10;
      }
    }
    //hazards
    if (data?.workConditions.length > 0) {
      if (y + lineHeight > pageHeight - bottomMargin) {
        doc.addPage();
        y = 10;
      }
      doc.setFontSize(18);
      if (this.rtl === 'ar') {
        doc.text('المخاطر', 103, y);
      } else {
        doc.text('Hazards Info', 103, y);
      }
      y += 5;

      doc.setLineWidth(0.5);
      doc.line(10, y, 200, y);
      y += 5;

      data.workConditions.forEach((workCondition) => {
        y += 7;
        doc.addImage(
          'assets/img/resources/pdfArrow.png',
          'PNG',
          8,
          y - 3,
          5,
          5
        );
        doc.setFontSize(14);
        doc.text(workCondition.name || '', 15, y + 1, { maxWidth: 90 });
        y += 5; // Adjust spacing below the work condition name
        doc.setFontSize(12);
        workCondition.hazards.forEach((hazard) => {
          y += 5; // Increment y to move down for each hazard
          doc.text('• ' + hazard, 15, y);
          checkPageOverflow(10);
        });

        y += 5; // Add extra space after each work condition
      });
    }

    if (this.data?.histories.length > 0) {
      if (y + lineHeight > pageHeight - bottomMargin) {
        doc.addPage();
        y = 10;
      }
      doc.setFontSize(18);
      if (this.rtl === 'ar') {
        doc.text('الأحداث السابقة', 105, y, { align: 'center' });
      } else {
        doc.text('Work Permit History', 105, y, { align: 'center' });
      }
      y += 5;

      doc.setLineWidth(0.5);
      doc.line(10, y, 200, y);
      y += 5;

      doc.setFontSize(12);
      doc.setTextColor('black');
      const HistoryFields = ['requestedBy', 'created', 'status', 'comments'];
      const HistoryHeaders = createHeaders(HistoryFields, this.rtl);
      const HistoryTableData = generateTableData(
        data?.histories,
        HistoryFields
      );
      y = addTable(doc, y, HistoryTableData, HistoryHeaders, this.rtl);
    }

    //checklists

    if (this.checklist.length > 0) {
      const addText = (text, fontSize, color, x, y, maxWidth, align?) => {
        doc.setFontSize(fontSize);
        doc.setTextColor(color);
        doc.text(text, x, y, { maxWidth: maxWidth, align: align });
      };

      const addImage = (src, format, x, y, width, height) => {
        doc.addImage(src, format, x, y, width, height);
      };

      doc.setFontSize(18);
      if (this.rtl === 'ar') {
        addText('ظروف العمل', 18, '#000000', 103, y, 180);
      } else {
        addText('Work Conditions', 18, '#000000', 95, y, 180);
      }
      y += 10;

      doc.setLineWidth(0.5);
      doc.line(10, y, 200, y);
      y += 10;

      this.checklist.forEach((list) => {
        checkPageOverflow(10);
        addImage('assets/img/resources/pdfArrow.png', 'PNG', 8, y - 3, 5, 5);
        addText(list?.title || '', 14, '#000000', 15, y + 1, 90);
        y += 10;

        list?.questions.forEach((Q) => {
          checkPageOverflow(15); // Check if space for the question and answer
          addText(
            'Question: ' + (Q?.questionText || ''),
            12,
            '#9CA3AF',
            15,
            y + 1,
            180
          );
          y += 7;
          addText(
            'Answer: ' + (Q?.answer || ''),
            12,
            '#000000',
            15,
            y + 1,
            180
          );
          y += 10; // Adjust spacing below the answer
        });
      });
      addText(
        'Response Score: ' + this.gaugeValue.toString() + '%',
        18,
        '#000000',
        60,
        y + 1,
        180
      );
      y += 15;
    }
    doc.save(`Work Permit-${data?.id}.pdf`);
  }
  setWorkFlowInstance(): void {
    const data: SetInstanceParam = {
      instanceKey: this.instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.fullName,
      actionName: Constants.APP_APPROVE_REJECT_ACTION,
      rules: this.rulesApplied,
      roleId: this.loggedUser.roleIds,
      payload: this.data,
    };
    this.workFlowService.setWorkFlowInstance(data).subscribe({
      next: (response) => {
        this.loadingService.stopLoading();
        this.closeDialog();
        this.goBack();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  setExtendTimeInstance(isRequired: boolean): void {
    const data: SetInstanceParam = {
      instanceKey: this.instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.fullName,
      actionName: Constants.APP_EXTEND_ACTION,
      rules: this.extendTimeRule,
      roleId: this.loggedUser.roleIds,
      payload: this.data,
    };
    this.workFlowService.setWorkFlowInstance(data).subscribe({
      next: (response) => {
        if (!isRequired) {
          this.workPermitService
            .closeWorkPermitRequest(this.data?.id)
            .subscribe({
              next: (response) => {
                this.loadingService.stopLoading();
                this.onReloadData.emit(true);
              },
              error: ({ error }) => {
                this.asyncFeedbackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.loadingService.stopLoading();
              },
            });
        } else {
          this.workPermitService.ExtendTimeRequired(this.data?.id).subscribe({
            next: (response) => {
              this.loadingService.stopLoading();
              this.onReloadData.emit(true);
            },
            error: ({ error }) => {
              this.loadingService.stopLoading();
            },
          });
        }
        this.closeDialog();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  getWorkflowUserPermission(actionName: string) {
    const obj = {
      instanceKey: this.instanceKey,
      userKey: this.loggedUser.userId,
      actionName: actionName,
      tableId: this.data?.id,
      workflowKey: this.workFlow.workFlowKey,
      roleId: this.loggedUser.roleIds,
    };
    this.workFlowService.getUserPermission(obj).subscribe({
      next: (res: any) => {},
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  setTimeApprovalInstance(): void {
    const data: SetInstanceParam = {
      instanceKey: this.instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.fullName,
      actionName: Constants.APP_APPROVE_REJECT_ACTION,
      rules: this.extendTimeApprovalRule,
      roleId: this.loggedUser.roleIds,
      payload: this.data,
    };
    this.workFlowService.setWorkFlowInstance(data).subscribe({
      next: (response) => {
        this.workPermitService.ExtendTimeRequired(this.data?.id).subscribe({
          next: (response) => {
            this.loadingService.stopLoading();
            this.closeDialog();
            this.onReloadData.emit(true);
          },
          error: ({ error }) => {
            this.loadingService.stopLoading();
          },
        });
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  getTransitionRules(): void {
    const data = {
      instanceKey: this.instanceKey,
      actionName: Constants.APP_APPROVE_REJECT_ACTION,
    };
    this.workFlowService.getTransitionRules(data).subscribe({
      next: (response) => {
        this.rulesApplied = response;
      },
      error: ({ error }) => {},
    });
  }
  getExtentTimeRules(): void {
    const data = {
      instanceKey: this.instanceKey,
      actionName: Constants.APP_EXTEND_ACTION,
    };
    this.workFlowService.getTransitionRules(data).subscribe({
      next: (response) => {
        this.extendTimeRule = response;
      },
      error: ({ error }) => {},
    });
  }
  getExtendTimeApprovalRules(): void {
    const data = {
      instanceKey: this.instanceKey,
      actionName: Constants.APP_APPROVE_REJECT_ACTION,
    };
    this.workFlowService.getTransitionRules(data).subscribe({
      next: (response) => {
        this.extendTimeApprovalRule = response;
      },
      error: ({ error }) => {},
    });
  }
  getActionById() {
    const data = {
      sourceId: this.data?.id,
      SourceType: 'Work-permit',
    };
    this.trackerService.getActionById(data).subscribe({
      next: (res) => {},
    });
  }
  onCreateAction(event: boolean): void {
    this.getActionById();
  }
}
