<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'ManageAccessControl.Title' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <app-license-data-card></app-license-data-card>
    <h1 class="text-lg font-bold my-4">
      {{ "ManageAccessControl.AllUsers" | translate }}
    </h1>
    <!-- <mat-tab-group (selectedTabChange)="tabChanged($event)">
      <mat-tab
        class="tab-btn"
        [label]="'ManageAccessControl.Employees' | translate"
      >
        <app-employees-list></app-employees-list>
      </mat-tab>
      <mat-tab
        class="tab-btn"
        [label]="'ManageAccessControl.Visitors' | translate"
      >
        <app-visitors-list [loadData]="loadVisitors"></app-visitors-list>
      </mat-tab>
    </mat-tab-group> -->
    <app-employees-list></app-employees-list>
  </hodhod-page-layout-content>
</hodhod-page-layout>
