<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'ManageAccessControl.Title' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <app-state-section
      body
      [flexibleHeight]="true"
      [state]="sectionState"
      [errorLabel]="'Errors.ErrorHappened' | translate"
    >
      <app-user-details-card
        [userDetails]="userDetails"
      ></app-user-details-card>
      <div class="mt-3">
        <app-user-mobile-devices
          [mobileDevices]="mobileDevices"
          [userDetails]="userDetails"
          (onDeleteDevice)="deleteDevice($event)"
          (onIssueMobileIds)="issueMobileId($event)"
          (onInvokeCredential)="invokeCredential($event)"
        ></app-user-mobile-devices>
      </div>
      <div class="mt-3">
        <app-user-invitations-log-card
          [invitationsLogs]="invitationsLogs"
        ></app-user-invitations-log-card>
      </div>
    </app-state-section>
  </hodhod-page-layout-content>
</hodhod-page-layout>
