import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { fadeInRight400ms } from 'src/@hodhod/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@hodhod/animations/scale-in.animation';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';

@Component({
  selector: 'app-license-data-card',
  templateUrl: './license-data-card.component.html',
  styleUrls: ['./license-data-card.component.scss'],
  animations: [fadeInUp400ms, fadeInRight400ms, scaleIn400ms],
})
export class LicenseDataCardComponent implements OnInit, OnChanges {
  @Input() licenseInfo!: any;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  constructor() {}
  licenseData:any;
  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    this.licenseData = changes['licenseInfo'].currentValue;
    if (this.licenseData) {
      this.sectionState = SectionStateStatus.Ready;
    }
  }
}
