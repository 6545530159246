import { Component, OnInit, ViewChild } from '@angular/core';
import { Constants } from 'src/@hodhod/common/constants';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { UntypedFormControl } from '@angular/forms';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { CompanyUserStatus, VerificationValues } from 'src/@hodhod/common/enum';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { filter, merge, Observable, of, ReplaySubject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatTableDataSource } from '@angular/material/table';
import { LostIdCard } from 'src/app/shared/models/lost-id-card/lost-id-card.model';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';

@UntilDestroy()
@Component({
  selector: 'app-lost-id-card',
  templateUrl: './lost-id-card.component.html',
  styleUrls: ['./lost-id-card.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class LostIdCardComponent extends BaseComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  selection = new SelectionModel<LostIdCard>(true, []);
  searchCtrl = new UntypedFormControl();
  dataSource: MatTableDataSource<LostIdCard> | null;
  public selectedUserStatus: CompanyUserStatus = null;
  public selectedUserVerification: CompanyUserStatus = null;

  subject$: ReplaySubject<LostIdCard[]> = new ReplaySubject<LostIdCard[]>(1);
  data$: Observable<LostIdCard[]> = this.subject$.asObservable();
  idCards: LostIdCard[];

  CompanyUserStatus = CompanyUserStatus;
  SharedConstants = SharedConstants;
  VerificationValues = VerificationValues;
  private translationsList: any = {};

  columns: TableColumn<LostIdCard>[] = [
    {
      label: 'RequestID',
      property: 'requestId',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'EmployeeCode',
      property: 'employeeCode',
      type: 'text',
      visible: true,
    },
    {
      label: 'EmployeeName',
      property: 'employeeName',
      type: 'text',
      visible: true,
    },
    {
      label: 'EmployeeID',
      property: 'employeeId',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Email',
      property: 'email',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Department',
      property: 'department',
      type: 'text',
      visible: true,
    },
    {
      label: 'Role',
      property: 'role',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'UpdateAt', property: 'UpdateAt', type: 'date', visible: true },
    // { label: 'Status', property: 'status', type: 'button', visible: false },
    { label: 'Status', property: 'isActive', type: 'boolean', visible: true },
    { label: 'Contact', property: 'contact', type: 'button', visible: true },
  ];

  constructor(
    private translate: TranslateService,
    private router: Router,
    private dialog: MatDialog
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit(): void {
    this.getData().subscribe((idCards) => {
      this.subject$.next(idCards);
    });

    this.dataSource = new MatTableDataSource([]);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = Constants.PAGE_SIZE;

    this.sort.sortChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => console.log('Lazy Loading Data....'))
      )
      .subscribe();

    this.data$.pipe(filter<LostIdCard[]>(Boolean)).subscribe((idCards) => {
      this.idCards = idCards;
      this.dataSource.data = idCards;
    });

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  getData() {
    return of([].map((idCard) => new LostIdCard(idCard)));
  }

  deactivateUser(event: any, userId: string): void {
    console.log('deactivate', event, userId);
  }
  activateUser(event: any, userId: string): void {
  }

  updateUser(event: any, user: LostIdCard) {

  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  onSelectUserStatus(event: MatSelectChange) {
    this.selectedUserStatus = event.value;
    // this.getData();
  }

  viewUser(event: any, userId: string): void {
    this.router.navigate([
      '/' +
      SharedConstants.USER_MANAGEMENT +
      '/' +
      SharedConstants.VIEW_USER +
      '/' +
      userId,
    ]);
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  onSelectUserVerification(event: MatSelectChange) {
    this.selectedUserVerification = event.value;
    this.getData();
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.paginator.pageIndex = 0;
    this.dataSource.filter = value;
  }

  deleteSelectedUser(idCard: LostIdCard) { }

  deleteUsers() { }

  createUser() {

  }
}
