import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { fadeInRight400ms } from 'src/@hodhod/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { scaleIn400ms } from 'src/@hodhod/animations/scale-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { GetHistoryLog } from 'src/backend/models/history-log/history-log.model';
import { ManageAccessControlService } from 'src/backend/services/manage-access-control/manage-access-control.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';

@Component({
  selector: 'app-hid-subscription-info',
  templateUrl: './hid-subscription-info.component.html',
  styleUrls: ['./hid-subscription-info.component.scss'],
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    scaleIn400ms,
    stagger40ms,
    scaleFadeIn400ms,
  ],
})
export class HidSubscriptionInfoComponent implements OnInit {
  mobileIdentities: any;
  organizationSummary: any;
  subscription: any;
  subscriptionHistory: any;
  dataSource = new MatTableDataSource<any>([]);
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  loadingLabel: string = '';
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  pageSize = Constants.PAGE_SIZE;

  columns: TableColumn<any>[] = [
    {
      label: 'partNumber',
      property: 'partNumber',
      type: 'text',
      visible: true,
    },
    { label: 'status', property: 'status', type: 'boolean', visible: true },
    { label: 'qty', property: 'qty', type: 'text', visible: true },
    { label: 'endDate', property: 'endDate', type: 'date', visible: true },
    { label: 'startDate', property: 'startDate', type: 'date', visible: true },
  ];
  constructor(
    private manageAccessControlService: ManageAccessControlService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.getData();
  }
  getData() {
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.loadingService.startLoading(true)
    this.manageAccessControlService.getHIDAdminPage().subscribe({
      next: (res) => {
        this.organizationSummary = res['response']?.organizationSummary;
        this.mobileIdentities = res['response']?.mobileIdentities;
        this.subscription = res['response']?.subscription;
        this.subscriptionHistory = res['response']?.subscriptionHistory;
        this.loadingService.stopLoading()
        this.dataSource = new MatTableDataSource(this.subscriptionHistory);
        this.sectionState = SectionStateStatus.Ready;

      },
      error: (err) => {},
    });
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
}
