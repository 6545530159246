import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChatbotService } from 'src/backend/services/chatbot/chatbot.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { BaseApi } from 'src/backend/api/base-api';
import { LanguageService } from 'src/@hodhod/services/language.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';

@Component({
  selector: 'hodhod-config-panel-toggle',
  templateUrl: './config-panel-toggle.component.html',
  styleUrls: ['./config-panel-toggle.component.scss'],
})
export class ConfigPanelToggleComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild('chatbotModal') chatbotModal: TemplateRef<any>;
  @ViewChild('messageInput') messageInput: ElementRef;
  @ViewChild('scrollMe') myScrollContainer: ElementRef;
  @ViewChild('rateMessage') rateMessage: TemplateRef<any>;
  @Output() openConfig = new EventEmitter();

  isDialogOpen: boolean = false;
  companyId: any;

  sessionId: string = '';
  customStyle = {
    backgroundColor: '#ffffff',
    border: '1px solid #000',
    color: '#000',
  };
  messages = [];

  public loggedUser: LoggedUser;
  public userFeedBackMessage: string = '';
  public feedBackModal: any;
  public isFullScreen: boolean = false;
  public currentLanguage: string;

  public showMessages: boolean = false;
  public layer: string = 'tools';
  public userSelectLayer: boolean = false;
  public layers = ['SanedAI', 'SanedTrustedSources', 'Help'];

  chatbotModalRef: MatDialogRef<any> | null = null;
  rateMessageRef: MatDialogRef<any> | null = null;
  disableSend: boolean = false;
  private translationsList: any = {};

  constructor(
    private dialog: MatDialog,
    private chatbotService: ChatbotService,
    private feedbackService: AsyncFeedbackService,
    private loadingService: LoadingService,
    private baseApi: BaseApi,
    private clipboard: Clipboard,
    private translate: TranslateService,
    private breakpointObserver: BreakpointObserver,
    private langService: LanguageService
  ) {
    super();
    this.translate
      .get(['Chatbot'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit() {
    this.companyId = localStorage.getItem('company_id');
    this.loggedUser = this.baseApi.getUserSession();
    this.langService.currentLanguage$.subscribe((language) => {
      this.currentLanguage = language.isoCode;
    });
  }

  openChatbotModal() {
    if (!this.isDialogOpen) {
      const dialogSize = this.getDialogConfig();
      if (!this.sessionId) {
        this.loadingService.startLoading(true);
        this.chatbotService.initializeChatSession().subscribe({
          next: (res) => {
            this.sessionId = res.session_id;
            this.chatbotModalRef = this.dialog.open(this.chatbotModal, {
              disableClose: true,
              panelClass: 'chatbot-dialog',
              width: dialogSize.width,
              height: dialogSize.height,
              hasBackdrop: false,
              position:
                this.currentLanguage == 'en'
                  ? { right: '100px', bottom: '100px' }
                  : { left: '100px', bottom: '100px' },
            });

            this.chatbotModalRef.afterClosed().subscribe((res) => {
              this.isDialogOpen = false;
            });
            this.isDialogOpen = true;
            this.loadingService.stopLoading();
          },
          error: (error) => {
            this.feedbackService.showFeedback(
              new FeedbackModel(FeedbackType.Failure, error?.message)
            );
            this.loadingService.stopLoading();
          },
        });
      } else {
        this.chatbotModalRef = this.dialog.open(this.chatbotModal, {
          disableClose: true,
          panelClass: 'chatbot-dialog',
          width: dialogSize.width,
          height: dialogSize.height,
          hasBackdrop: false,
          position:
            this.currentLanguage == 'en'
              ? { right: '100px', bottom: '100px' }
              : { left: '100px', bottom: '100px' },
        });

        this.chatbotModalRef.afterClosed().subscribe((res) => {
          this.isDialogOpen = false;
        });
        this.isDialogOpen = true;
      }
    } else {
      this.minimizeChatbot();
    }
  }

  startChat() {
    this.showMessages = true;
  }

  chooseLayer(layer) {
    this.showMessages = true;
    this.userSelectLayer = true;
    if (layer === 'SanedAI') {
      this.layer = 'documents';
    } else if (layer === 'SanedTrustedSources') {
      this.layer = 'trusted_sources';
    } else {
      this.layer = 'tools';
    }
    const helloMsg = this.translationsList['Chatbot']['HelloMsg'];
    const message = {
      question: this.translationsList['Chatbot']['Layers'][layer],
      message: '',
      isItLiked: null,
    };
    this.messages.push(message);

    let count = 0;
    const id = setInterval(() => {
      if (message.message.length === helloMsg.length) {
        clearInterval(id);
      }
      message.message += helloMsg.charAt(count);
      count += 1;
      this.scrollToBottom();
    }, 50);
  }

  sendMessage(messageContent: string) {
    this.messageInput.nativeElement.disabled = true;
    this.disableSend = true;
    const message = {
      question: messageContent,
      message: '',
      isItLiked: null,
    };

    this.messages.push(message);
    const data = {
      msg: messageContent,
      session_id: this.sessionId,
      company_name: JSON.parse(localStorage.getItem('company'))?.companyName,
      username: JSON.parse(localStorage.getItem('logged_in_user'))?.fullName,
      language: this.currentLanguage,
    };
    this.scrollToBottom();
    this.messageInput.nativeElement.value = '';
    this.chatbotService.processChatMessage(data, this.layer).subscribe({
      next: (res) => {
        let count = 0;
        const interval = setInterval(() => {
          if (count < res.response.length) {
            message.message += res.response.charAt(count);
            count++;
          } else {
            clearInterval(interval);
            this.disableSend = false;
            this.messageInput.nativeElement.disabled = false;
          }
        }, 50);
      },
      error: (error) => {
        this.feedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.messageInput.nativeElement.disabled = false;
      },
    });
  }

  likeMessage(message, index) {
    const data = {
      question: message.question,
      answer: message.message,
      isItLiked: true,
      sessionId: this.sessionId,
      feedbackComment: this.userFeedBackMessage,
    };
    this.userFeedBackMessage = '';
    this.chatbotService.saveUserFeedBack(data).subscribe({
      next: (res) => {
        this.messages[index].isItLiked = true;
        this.feedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, res?.message)
        );
      },
      error: (error) => {
        this.feedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
    // this.feedBackModal = this.dialog.open(this.rateMessage, {
    //   disableClose: true,
    //   panelClass: 'rounded-mat-dialog',
    //   width: '30%',
    //   height: 'auto',
    // });
    // this.feedBackModal.afterClosed().subscribe((res) => {
    // });
  }

  dislikeMessage(message, index) {
    this.feedBackModal = this.dialog.open(this.rateMessage, {
      disableClose: true,
      panelClass: 'rounded-mat-dialog',
      width: '30%',
      height: 'auto',
    });
    this.feedBackModal.afterClosed().subscribe((res) => {
      const data = {
        question: message.question,
        answer: message.message,
        isItLiked: false,
        sessionId: this.sessionId,
        feedbackComment: this.userFeedBackMessage,
      };
      this.userFeedBackMessage = '';
      this.chatbotService.saveUserFeedBack(data).subscribe({
        next: (res) => {
          this.messages[index].isItLiked = false;
          this.feedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Success, res?.message)
          );
        },
        error: (error) => {
          this.feedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
        },
      });
    });
  }

  copyMessage(message: string) {
    this.clipboard.copy(message);
  }

  scrollToBottom = () => {
    try {
      setTimeout(() => {
        this.myScrollContainer.nativeElement.scrollTop =
          this.myScrollContainer.nativeElement.scrollHeight;
      }, 100);
    } catch (err) {}
  };

  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      this.chatbotModalRef.updateSize('50%', '80%');
    } else {
      this.chatbotModalRef.updateSize('25%', '80%');
    }
  }

  closeDialog() {
    this.sessionId = '';
    this.messages = [];
    this.showMessages = false;
    this.layer = '';
    this.userSelectLayer = false;
    this.chatbotModalRef.close();
  }

  minimizeChatbot() {
    this.chatbotModalRef.close();
  }

  closeFeedBackModal() {
    this.feedBackModal.close();
  }

  getDialogConfig() {
    if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      return { width: '80%', height: '70%' };
    } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
      return { width: '60%', height: '80%' };
    } else {
      return { width: '25%', height: '80%' };
    }
  }
}
