<div>
  <app-state-section
    body
    [flexibleHeight]="false"
    [state]="sectionStateCompanyLicense"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <div>
      <h1 class="text-lg font-bold py-2">
        {{ "ManageAccessControl.Title" | translate }}
      </h1>

      <div class="flex flex-wrap-reverse justify-between py-2">
        <div
          class="w-full sm:w-[49.5%] md:w-[24%] min-w-[200px] min-h-[110px]"
          *ngFor="let item of licenseDataItems"
        >
          <div
            class="rounded-[10px] border-gray-200 border flex justify-start p-4 bg-white shadow-lg"
          >
            <div class="flex">
              <span
                class="border-2 border-gray-200 rounded-xl p-1.5 flex"
                style="width: 40px; height: 40px"
              >
                <img [src]="item.imgSrc" alt="" />
              </span>
              <div class="ms-3">
                <p class="mb-2 text-base text-[#344054] font-semibold">
                  {{ "ManageAccessControl." + item.label | translate }}
                </p>
                <p class="m-0 text-2xl font-extrabold">
                  {{ item.value }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-state-section>
</div>
