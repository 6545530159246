export enum LanguageEnum {
  English = 'en',
  Arabic = 'ar',
}

export enum VerificationValues {
  Verified = 'Verified',
  NotVerified = 'NotVerified',
}
export enum CompanyUserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}
export enum EquipmentStatus {
  Operable = 'Operable',
  NonOperable = 'Non-Operable',
}

export enum IdCardStatus {
  INPROGRESS = 'INPROGRESS',
  PENDING_HR_CONFIRMATION = 'PENDING_HR_CONFIRMATION',
  PENDING_COMPANY_ADMIN_APPROVAL = 'PENDING_COMPANY_ADMIN_APPROVAL',
  PENDING_HR_APPROVAL = 'PENDING_HR_APPROVAL',
  PENDING_SECURITY_OFFICER_APPROVAL = 'PENDING_SECURITY_OFFICER_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  RETURNED = 'RETURNED',
}
export enum RolesStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum OrganizationStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}
export enum CompanyStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum ReasonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum CameraStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum VideoAnalyticsStatus {
  Configured = 'Configured',
  UnConfigured = 'Unconfigured',
}

export enum VoilationStatus {
  New = 'New',
  Close = 'Close',
}

export enum ReportHazardStatus {
  New = 'NEW',
  Closed = 'CLOSED',
}

export enum HazardStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum DbTargetStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum ReportNearMissStatus {
  All = 'ALL',
  New = 'NEW',
  Inprogress = 'INPROGRESS',
  Closed = 'CLOSED',
}

export enum SeverityStatus {
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW',
}

export enum RiskStatus {
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW',
}

export enum WorkConditionsStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum GeneralStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}
export enum InspectionStatus {
  Inspected = 'Inspected',
  Uninspected = 'Uninspected',
  Unsafe = 'Unsafe',
}
export enum equipmentStatus {
  Inspected = 'Inspected',
  Uninspected = 'Uninspected',
  Missed = 'Missed',
  Unsafe = 'Unsafe',
}

export enum MenuProps {
  Write = 'isWrite',
  Read = 'isRead',
  Update = 'isUpdate',
  Delete = 'isDelete',
  Approve = 'isApprove',
  Reject = 'isReject',
  Activate = 'isActivate',
  Deactivate = 'isDeactivate',
}

export enum JWTTokenValidation {
  Valid = 'valid',
  NotFound = 'NotFound',
  Expired = 'Expired',
}

export enum PermissionKeys {
  Organizations = 'Organizations',
  OrganizationPermissions = 'OrganizationPermissions',
  Companies = 'Companies',
  CompanyPermissions = 'CompanyPermissions',
  Departments = 'Departments',
  Machines = 'Machines',
  EquipmentCategories = 'EquipmentCategories',
  PerformInspections = 'PerformInspections',
  ReviewInspections = 'ReviewInspections',
  InspectionManagement = 'InspectionManagement',
  ManageUsers = 'ManageUsers',
  ManageRoles = 'ManageRoles',
  EmergencyContacts = 'EmergencyContacts',
  ManageIdCard = 'ManageIdCard',
  LostIDCard = 'LostIDCard',
  RenewIDCard = 'RenewIDCard',
  ManageChecklist = 'ManageChecklist',
  ChecklistSection = 'ChecklistSection',
  Reportings = 'Reportings',
  ContentReporting = 'ContentReporting',
  ReportHazard = 'ReportHazard',
  ReportNearMiss = 'ReportNearMiss',
  ReportIncident = 'ReportIncident',
  IncidentCategory = 'IncidentCategory',
  NatureInjury = 'NatureInjury',
  Reasons = 'Reasons',
  RootCause = 'RootCause',
  IncidentClassification = 'IncidentClassification',
  LossModes = 'LossModes',
  WorkPermitMaster = 'WorkPermitMaster',
  WorkPermits = 'WorkPermits',
  WorkPermitHazards = 'WorkPermitHazards',
  WorkPermitWorkConditions = 'WorkPermitWorkConditions',
  WorkConditionChecklists = 'WorkConditionChecklists',
  ValueTypes = 'ValueTypes',
  UnitOfMeasures = 'UnitOfMeasures',
  Ppe = 'PPE',
  Shift = 'Shift',
  Area = 'Area',
  Team = 'Team',
  CheckListSection = 'CheckListSection',
  CreateAudit = 'CreateAudit',
  ActionsTracker = 'ActionsTracker',
  Actions = 'Actions',
  VideoAnalytics = 'VideoAnalytics',
  YourAudits = 'YourAudits',
  Violations = 'Violations',
  VideoAnalyticsDashboard = 'VideoAnalyticsDashboard',
  ReportingDashboard = 'ReportingDashboard',
  SafetyAnnouncement = 'SafetyAnnouncement',
  SafetyKPIsManagement = 'SafetyKPIsManagement',
  SafetyKPIsDashboard = 'SafetyKPIsDashboards',
  DashboardTaragets = 'DashboardTaragets',
  Msds = 'MSDS',
  AuditChecklists = 'AuditChecklists',
  IOTDashboards = 'IOTDashboards',
  Dashboard = 'DashboardsList',
  PenaltyManagement = 'PenaltyManagement',
  InspectionChecklists = 'InspectionChecklists',
  MobileAccessControl = 'MobileAccessControl',
}

export enum PermissionTypes {
  IsWrite = 'isWrite',
  IsRead = 'isRead',
  IsUpdate = 'isUpdate',
  IsDelete = 'isDelete',
  IsReject = 'isReject',
  IsActivate = 'isActivate',
  IsApprove = 'isApprove',
  IsDeactivate = 'isDeactivate',
  IsViewAll = 'isViewAll',
}

export enum SortDirection {
  None = '',
  Ascending = 'asc',
  Descending = 'desc',
}

export enum OperatorType {
  Equale = '=',
  NotEquale = '!=',
  GreaterThan = '>',
  LessThan = '<',
  GreaterThanOrEquale = '>=',
  LessThanOrEquale = '<=',
}

export enum MachineType {
  Digital = 'DIGITAL',
  Analog = 'ANALOG',
}

export enum InspectionFrequencyTypes {
  Shift = 'Shift',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Yearly = 'Yearly',
}

export enum ChecklistSectionType {
  YesNo = 'Yes/No',
  ValueVsTarget = 'Value Vs Target',
  Range = 'Select from range',
  MachineParameter = 'Machine Parameter',
}

export enum ChecklistType {
  Audit = 'Audit',
  WorkCondition = 'WorkCondition',
  Inspection = 'Inspection',
}

export enum Shift {
  SHIFT_A = 'SHIFT_A',
  SHIFT_B = 'SHIFT_B',
  SHIFT_C = 'SHIFT_C',
  SHIFT_D = 'SHIFT_D',
  SHIFT_E = 'SHIFT_E',
}

export enum ActionStatus {
  INPROGRESS = 'INPROGRESS',
  DUE = 'DUE',
  OVERDUE = 'OVERDUE',
  CLOSE = 'CLOSE',
}

export enum ActionPriority {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export enum ReportingType {
  Investigation = 'INVESTIGATION',
  NearMiss = 'NEAR_MISS',
  Incident = 'INCIDENT',
  Hazard = 'HAZARD',
  Task = 'TASK',
  Voilation = 'VIOLATION',
  workPermit = 'WORK_PERMIT',
  PenaltyManagement = 'PENALTY_MANAGEMENT',
  InspectionManagement = 'INSPECTION_MANAGEMENT',
}

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
}

export enum SkeletonParts {
  HeadFront = 'HEAD_FRONT',
  HeadBack = 'HEAD_BACK',
  Eyes = 'EYES',
  Neck = 'NECK',
  Back = 'BACK',
  LowerBack = 'LOWER_BACK',
  UpperRightArm = 'UPPER_RIGHT_ARM',
  LowerRightArm = 'LOWER_RIGHT_ARM',
  RightHand = 'RIGHT_HAND',
  UpperLeftArm = 'UPPER_LEFT_ARM',
  LowerLeftArm = 'LOWER_LEFT_ARM',
  LeftHand = 'LEFT_HAND',
  Thorax = 'THORAX',
  UpperRightLeg = 'UPPER_RIGHT_LEG',
  LowerRightLeg = 'LOWER_RIGHT_LEG',
  UpperLeftLeg = 'UPPER_LEFT_LEG',
  LowerLeftLeg = 'LOWER_LEFT_LEG',
  LeftFootFront = 'LEFT_FOOT_FRONT',
  RightFootFront = 'RIGHT_FOOT_FRONT',
  LeftFootBack = 'LEFT_FOOT_BACK',
  RightFootBack = 'RIGHT_FOOT_BACK',
}

export enum DefaultRoleType {
  OrganizationAdmin = 'ORGANIZATION_ADMIN',
  CompanyAdmin = 'COMPANY_ADMIN',
  FireManager = 'FIRE_MANAGER',
  FireOfficer = 'FIRE_OFFICER',
  SafetyManager = 'SAFETY_MANAGER',
  SafetyOfficer = 'SAFETY_OFFICER',
  EnvironmentManager = 'ENVIRONMENT_MANAGER',
  EnvironmentOfficer = 'ENVIRONMENT_OFFICER',
  SecurityManager = 'SECURITY_MANAGER',
  SecurityOfficer = 'SECURITY_OFFICER',
  DepartmentManager = 'DEPARTMENT_MANAGER',
  EvacuationTeam = 'EVACUATION_TEAM',
  EmergencyTeamMember = 'EMERGENCY_TEAM_MEMBER',
  EmergencyTeamLeader = 'EMERGENCY_TEAM_LEADER',
  NormalUser = 'NORMAL_USER',
  Supervisor = 'SUPERVISOR',
  GeneralManager = 'GENERAL_MANAGER',
  HRMember = 'HR_MEMBER',
}

export enum MediaType {
  None = 'None',
  Main = 'Main',
  Video = 'Video',
}

export enum ReportingTypes {
  All = 'ALL',
  Incident = 'INCIDENT',
  NearMiss = 'NEAR_MISS',
  Hazard = 'HAZARD',
}

export enum ReportIncidentStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  CLOSED = 'CLOSED',
}

export enum ReportNearMissStatuses {
  NEW = 'NEW',
  UPDATE = 'UPDATE',
  INVESTIGATED = 'INVESTIGATED',
  INPROGRESS = 'INPROGRESS',
  CLOSED = 'CLOSED',
}
export enum ReportNearMissHistoryStatuses {
  NEW = 'NEW',
  INPROGRESS = 'INPROGRESS',
  CLOSED = 'CLOSED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}
export enum Severity {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export enum IdCardActions {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  CONFIRM = 'CONFIRM',
  RETURN = 'RETURN',
  RENEW = 'RENEW',
  RESUBMIT = 'RESUBMIT',
  SUBMIT = 'SUBMIT',
}

export enum WorkPermitStatus {
  NEW = 'NEW',
  UPDATE = 'UPDATE',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  INPROGRESS = 'INPROGRESS',
  CLOSED = 'CLOSED',
}

export enum RiskMatrix {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export enum TaskStatus {
  NEW = 'NEW',
  REVIEW = 'REVIEW',
  // NEED_ACTION = 'NEED_ACTION',
  MISSED = 'MISSED',
  CLOSED = 'CLOSED',
  REJECTED = 'REJECTED',
  // NeedConfirmation = 'NEED_CONFIRMATION',
  LATE_CLOSED = 'LATE_CLOSED',
  RESCHEDULE = 'RESCHEDULE',
}

export enum DaysOfWeek {
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
}

export enum Weeks {
  first = 'first',
  second = 'second',
  third = 'third',
  fourth = 'fourth',
  last = 'last',
}

export enum Months {
  January = 'January',
  February = 'February',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  August = 'August',
  September = 'September',
  October = 'October',
  November = 'November',
  December = 'December',
}

export enum TaskRepeated {
  NotRepeated = 'NotRepeated',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Quaterly = 'Quaterly',
  Yearly = 'Yearly',
}

export enum OffenderNature {
  UNIDENTIFIABLEOFFENDER = 'Unidentifiable Offender',
  REPEATEDREPORT = 'Repeated Report',
  FALSEPOSITIVE = 'False Positive',
  NONAPPLICABLE = 'non-applicable',
}

export enum NotificationType {
  NearMiss = 'NearMiss',
  Task = 'Task',
  // TaskReminder = 'TaskReminder',
  ExecutedTask = 'ExecutedTask',
  // DashboardTarget = 'DashboardTarget',
  IncidentReport = 'IncidentReport',
  WorkPermit = 'WorkPermit',
  HazardReport = 'HazardReport',
  Action = 'Action',
  SafetyAnnouncement = 'SafetyAnnouncement',
  SafetyAnnouncementEdit = 'SafetyAnnouncementEdit',
  PenaltyReport = 'PenaltyReport',
  InspectionReminder = 'InspectionReminder',
  InspectionReview = 'InspectionReview',
  MSDS = 'MSDS',
  MobileAccess = 'MobileAccess',
}

export enum NotificationSeverity {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export enum Dashboards {
  ReportedHazard = 'Reported Hazards',
  ReportedNearMiss = 'Reported Near Miss',
  ReportedIncident = 'Reported Incidents',
  ReportedPenalty = 'Reported Penalty',
  TotalReported = 'Total Reports',
  AuditAdherence = 'Audit Adherence',
  AuditScore = 'Audit Gap Analysis',
  WorkPermit = 'Work Permit',
  ActionPlan = 'Action Plan Completion',
  IncidentLoss = 'Incident Loss Report',
}

export enum DashboardColors {
  ReportedPenalty = '#26326E,#5D6FCB,#96A3E6,#640F24',
  ReportedHazard = '#5D6FCB,#8B96D3',
  ReportedNearMiss = '#26326E,#3F51AF,#9FADCC',
  ReportedIncident = '#640F24,#D44343,#E27979',
  TotalReported = '#5D6FCB,#640F24,#26326E',
  AuditAdherence = '#640F24,#D44343',
  AuditScore = '#26326E,#5D6FCB',
  WorkPermit = '#26326E,#5D6FCB,#96A3E6,#640F24,#00875A',
  ActionPlan = '#26326E,#5D6FCB,#96A3E6,#640F24',
  IncidentLoss = '#640F24,#D44343,#E27979',
}

export enum DashboardTypes {
  VerticalBar = 'column',
  HorizontalBar = 'bar',
  PieChart = 'pie',
}

export enum TargetDashboardType {
  ReportedHazard = 'Reported Hazards',
  ReportedNearMiss = 'Reported Near Miss',
  ReportedIncident = 'Reported Incidents',
  ReportedPenalty = 'Reported Penalty',
  TotalReported = 'Total Reports',
  AuditAdherence = 'Audit Adherence',
  AuditScore = 'Audit Gap Analysis',
  WorkPermit = 'Work Permit',
  ActionPlan = 'Action Plan Completion',
  IncidentLoss = 'Incident Loss Report',
}

export enum UptoAtLeast {
  Upto = 'Upto',
  AtLeast = 'At least',
}

export enum Analog {
  Machine = 'Machine',
}

export enum Digital {
  Machine = 'Machine',
}

export enum TargetCalculation {
  SameTarget = 'Same Target',
  DifferentTarget = 'Different Target',
}
export enum SmartSafetyFilterDashboards {
  ThisDay = 'thisDay',
  Yesterday = 'yesterday',
  CurrentMonth = 'currentMonth',
  LastMonth = 'lastMonth',
  Past30Day = 'lastThirtyDay',
  yearToDate = 'yearToDate',
  CustomRange = 'customRange',
}
export enum IoTFilterDashboards {
  CurrentHour = 'CurrentHour',
  LastHour = 'LastHour',
  ThisDay = 'Today',
  Yesterday = 'Yesterday',
  CurrentMonth = 'CurrentMonth',
  LastMonth = 'LastMonth',
  Past30Day = 'Past30Days',
  CustomRange = 'CustomRange',
}
export enum PenaltyFilterDashboards {
  Last3months = 'lastThreeMonths',
  Last6months = 'lastSixMonths',
  Last9months = 'lastNineMonths',
  LastYear = 'lastYear',
  CustomRange = 'CustomRange',
}

export enum WorkPermitApproverTypes {
  AdditionalApprover = 'ADDITIONALAPPROVER',
  InitialApprover = 'INITIALAPPROVER',
}

export enum SearchTypes {
  WORKPERMIT = 'WORKPERMIT',
  TASK = 'TASK',
  HAZARD_REPORT = 'HAZARD_REPORT',
  NEARMISS_REPORT = 'NEARMISS_REPORT',
  INCIDENT_REPORT = 'INCIDENT_REPORT',
  ACTION_TRACKER = 'ACTION_TRACKER',
}
export enum HistoryTypes {
  REPORT_HAZARD = 'REPORT_HAZARD',
  CHECKLIST = 'CHECKLIST',
  TYPE_OF_INCIDENT = 'TYPE_OF_INCIDENT',
  NATURE_OF_INJURIES = 'NATURE_OF_INJURIES',
  REASONS = 'REASONS',
  ROOT_CAUSE = 'ROOT_CAUSE',
  INCIDENT_CLASSIFICATION = 'INCIDENT_CLASSIFICATION',
  LOSS_MODES = 'LOSS_MODES',
  HAZARD = 'HAZARD',
  WORK_CONDITION = 'WORK_CONDITION',
}

export enum SafetyAnnouncementType {
  URGENT = 'URGENT',
  GENERAL = 'GENERAL',
}

export enum MsdsHazardIdentifications {
  Flammable = 'Flammable',
  Reactive = 'Reactive',
  HealthHazard = 'HealthHazard',
  SpecialHazard = 'SpecialHazard',
}
export enum MsdsStatus {
  IN_REVIEW = 'IN_REVIEW',
  PUBLISHED = 'PUBLISHED',
  REJECTED = 'REJECTED',
  PENDING_APPROVE = 'PENDING_APPROVE',
  APPROVED = 'APPROVED',
}

export enum MsdsSpecialHazard {
  None = 'None',
  OX = 'OX',
  ACID = 'ACID',
  ALK = 'ALK',
  COR = 'COR',
  W = 'W',
  R = 'R',
  BIO = 'BIO',
  CRYO = 'CRYO',
  Explosive = 'Explosive',
  Toxic = 'Toxic',
  SA = 'SA',
}

export enum PenaltyStatusFilter {
  NEW = 'NEW',
  CLOSED = 'CLOSED',
}

export enum CrudStatusForLogs {
  NEW = 'NEW',
  UPDATE = 'UPDATE',
}

export enum SubscriptionType {
  PAID = '1',
  FREETRIAL = '2',
}
