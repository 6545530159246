<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.OrganizationSettings' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid flex items-end column">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Back'"
      (onClick)="goBack()"
      class="my-3"
    >
    </app-basic-button>
    <app-state-section
      body
      [flexibleHeight]="true"
      [state]="sectionState"
      [loadingLabel]="'organization.LoadingInfo' | translate"
      [errorLabel]="'Errors.ErrorHappened' | translate"
    >
      <app-license-data-card
        [licenseInfo]="comCardInfo"
      ></app-license-data-card>
    </app-state-section>
  </hodhod-page-layout-content>
</hodhod-page-layout>
