<div class="">
  <div class="h-16 border-b sticky left-0 flex items-center">
    <div
      class="bg-foreground px-4 flex items-center flex-1"
      style="border-radius: 5px"
    >
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input
        [formControl]="searchCtrl"
        class="px-4 py-2 border-0 outline-none w-full bg-transparent"
        [placeholder]="'SafetyAnnouncement.SearchForAnnouncement' | translate"
        type="search"
      />
    </div>


    <app-basic-button
    *ngIf="hasCreatePermission | permissionChecker"
    [matTooltip]="'General.Buttons.AddNew' | translate"
    [btnType]="'Add'"
    [btnText]="'NewAnnouncement'"
    [link]="'add.svg'"
    (onClick)="addNewAnnouncement()"
  ></app-basic-button>

  </div>

  <app-state-section
    body
    [state]="sectionState"
    [loadingLabel]="loadingLabel | translate"
    [flexibleHeight]="true"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <table [dataSource]="dataSource" class="w-full" mat-table matSort>
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container
          *ngIf="
            column.type === 'text' &&
            column.property !== 'tags' &&
            column.property !== 'engagementCounter'
          "
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
              {{ "SafetyAnnouncement.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div>
              <span
                [matTooltip]="row[column.property]"
                *ngIf="row[column.property]?.length > 10; else orignal"
              >
                {{ row[column.property] | slice : 0 : 10 }}...
              </span>
              <ng-template #orignal>
                <span>
                  {{ row[column.property] }}
                </span>
              </ng-template>
            </div>
            <span *ngIf="column.property === 'engagementCounter'">%</span>
          </td>
        </ng-container>

        <ng-container
          *ngIf="
            column.type === 'text' && column.property === 'engagementCounter'
          "
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>
            {{ "SafetyAnnouncement.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <span *ngIf="row['createdBy'] === loggedUser.userId">
              {{ row[column.property] }} %
            </span>
          </td>
        </ng-container>

        <!-- Is Read Icon -->
        <ng-container
          *ngIf="column.type === 'image'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell >
            {{ "SafetyAnnouncement.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <img
              [src]="
                row?.isRead
                  ? 'assets/img/resources/success-read.svg'
                  : 'assets/img/resources/not-read.svg'
              "
              class="w-5 h-5 rounded-full m-auto"
            />
          </td>
        </ng-container>

        <!-- Date -->
        <ng-container
          *ngIf="column.type === 'date'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>
            {{ "SafetyAnnouncement.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] | date : "medium" }}
          </td>
        </ng-container>

        <!-- Tags -->
        <ng-container
          *ngIf="column.type === 'text' && column.property === 'tags'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            {{ "SafetyAnnouncement.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div
              class="flex justify-center align-center"
              [attr.id]="row[column.property]"
            >
              <div *ngIf="row[column.property]">
                <span
                  [ngClass]="i % 2 == 0 ? 'tag-text-yellow' : 'tag-text-blue'"
                  *ngFor="
                    let tag of splitTags(row[column.property]);
                    let i = index
                  "
                >
                  {{ tag }}
                </span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Actions -->
        <ng-container
          stickyEnd
          *ngIf="column.type === 'button'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef mat-header-cell></th>

          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div (click)="$event.stopPropagation()">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon svgIcon="mat:more_vert"></mat-icon>
              </button>
              <mat-menu
                #menu="matMenu"
                yPosition="below"
                xPosition="before"
                class="shadow-lg shadow-gray-400 rounded-2xl min-h-0"
              >
                <div
                  mat-menu-item
                  class="flex items-center justify-between p-1 mat-div"
                  *ngIf="hasViewPermission | permissionChecker"
                  (click)="viewAnnouncement(row?.id)"
                >
                  <button>
                    {{ "SafetyAnnouncement.Actions.Preview" | translate }}
                  </button>
                  <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
                </div>
                <div
                  mat-menu-item
                  class="flex items-center justify-between p-1 mat-div"
                  *ngIf="
                    row['createdBy'] === loggedUser.userId &&
                      hasUpdatePermission | permissionChecker
                  "
                  (click)="editAnnouncement(row?.id)"
                >
                  <button>
                    {{ "SafetyAnnouncement.Actions.Edit" | translate }}
                  </button>
                  <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
                </div>
                <div
                  mat-menu-item
                  class="flex items-center justify-between p-1 mat-div"
                  *ngIf="hasCreatePermission | permissionChecker"
                  (click)="dublicateAnnouncement(row?.id)"
                >
                  <button>
                    {{ "SafetyAnnouncement.Actions.Reshare" | translate }}
                  </button>
                  <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
                </div>
                <div
                  mat-menu-item
                  class="flex items-center justify-between p-1 mat-div"
                  *ngIf="hasDeletePermission | permissionChecker"
                  (click)="deleteAnnouncement(row?.id)"
                >
                  <button>
                    {{ "SafetyAnnouncement.Actions.Delete" | translate }}
                  </button>
                  <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
                </div>
              </mat-menu>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: visibleColumns"
        class="hover:bg-hover trans-ease-out cursor-pointer"
        mat-row
        (click)="viewAnnouncement(row?.id)"
      ></tr>
    </table>

    <div
      *ngIf="dataSource.filteredData.length === 0"
      @scaleFadeIn
      class="flex-auto flex flex-col items-center justify-center"
    >
      <img src="assets/img/resources/empty-state.svg" alt="not found" />
      <h2 class="headline m-0 text-center text-primary">
        {{ "General.EmptyTableList" | translate }}...
      </h2>
    </div>

    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      showFirstLastButtons
      class="sticky left-0"
    >
    </mat-paginator>
  </app-state-section>
</div>
