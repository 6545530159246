<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.Organizations' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <div class=" overflow-auto p-2 min-h-[70vh]">
      <!-- <div class="flex justify-end">
        <button
          *ngIf="documents.length > 0"
          class="ml-4 flex-none"
          color="primary"
          mat-mini-fab
          [matTooltip]="'General.Buttons.DeleteAll' | translate"
          type="button"
          (click)="deleteAllDocuments()"
        >
          <mat-icon svgIcon="mat:delete"></mat-icon>
        </button>
        <button
          class="ml-4 flex-none"
          color="primary"
          mat-mini-fab
          [matTooltip]="'General.Buttons.AddNew' | translate"
          type="button"
          (click)="openUploadDocumentModal()"
        >
          <mat-icon svgIcon="mat:add"></mat-icon>
        </button>
      </div> -->

      <app-chatbot-documents-list></app-chatbot-documents-list>

      <!-- <app-state-section
        body
        [flexibleHeight]="true"
        [state]="sectionState"
        [errorLabel]="'Errors.ErrorHappened' | translate"
      >
        <div
          class="flex flex-wrap gap-2 my-4"
          *ngIf="documents.length > 0; else noDataTemplate"
        >
          <div class="relative" *ngFor="let doc of documents">
            <div
              class="cursor-pointer bg-red-700 text-white rounded-full absolute right-0 top-0 z-10 flex items-center justify-center"
              style="width: 20px; height: 20px"
              (click)="deleteDocument(doc)"
            >
              <mat-icon svgIcon="mat:close" class="icon-sm"></mat-icon>
            </div>
            <ngx-avatars
              class="cursor-pointer"
              size="60"
              [src]="getAttachmentImage(doc)"
              [name]="getAttachmentTypeName(doc)"
              [textSizeRatio]="2"
              [round]="false"
              [initialsSize]="2"
              [cornerRadius]="10"
              [matTooltip]="doc"
              [borderColor]="'#1F36B4'"
              (clickOnAvatar)="downloadDocument(doc)"
            >
            </ngx-avatars>
          </div>
        </div>
        <ng-template #noDataTemplate>
          <div class="text-center">
            <div class="flex column">
              <img
                style="width: fit-content; margin: auto"
                src="assets/img/no-dashboards.png"
              />
              <h1 style="color: #00000099; font-size: large">
                {{ "Chatbot.NoDocuments" | translate }}
              </h1>
            </div>
          </div>
        </ng-template>
      </app-state-section> -->
    </div>

    <ng-template #uploadDocumentModal>
      <div class="flex column">
        <div mat-dialog-title>
          <label>{{ "Chatbot.UploadDocument" | translate }}</label>
        </div>
        <mat-dialog-content>
          <file-upload
            [formControl]="media"
            [multiple]="true"
            [animation]="true"
            [accept]="'.doc,.docx,.ppt,.pptx,.csv,.xlsx,.pdf'"
          ></file-upload>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
          <app-basic-button
            [btnType]="'Add'"
            [btnText]="'Add'"
            (onClick)="uploadDocument()"
          ></app-basic-button>
          <app-basic-button
            [btnType]="'Cancel'"
            [btnText]="'Cancel'"
            (onClick)="closeDialog()"
          ></app-basic-button>
        </mat-dialog-actions>
      </div>
    </ng-template>
  </hodhod-page-layout-content>
</hodhod-page-layout>
