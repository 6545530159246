import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ManageAccessControlService } from 'src/backend/services/manage-access-control/manage-access-control.service';

@Component({
  selector: 'app-license-data-card',
  templateUrl: './license-data-card.component.html',
  styleUrls: ['./license-data-card.component.scss'],
})
export class LicenseDataCardComponent implements OnInit {
  companyLicenseData: any;
  licenseDataItems: { imgSrc: string; label: string; value: any }[] = [];
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public sectionStateCompanyLicense: SectionStateStatus =
    SectionStateStatus.Ready;

  ngOnInit(): void {
    this.getCompanyLicenseData();
    this.manageAccessControlService.licenseDataUpdated$.subscribe(() => {
      this.getCompanyLicenseData(); // Re-fetch license data when notified
    });
  }

  constructor(
    private manageAccessControlService: ManageAccessControlService,
    private feedBackService: AsyncFeedbackService
  ) {}
  getCompanyLicenseData() {
    const companyId = JSON.parse(localStorage.getItem('company'))?.companyId;
    this.sectionStateCompanyLicense = SectionStateStatus.Loading;
    this.manageAccessControlService.getCompanyLicenseData(companyId).subscribe({
      next: (res) => {
        this.companyLicenseData = res?.message;
        this.licenseDataItems = [
          {
            imgSrc: 'assets/img/resources/MAC-license.svg',
            label: 'Total',
            value: this.companyLicenseData?.accessControlLicenseQuota,
          },
          {
            imgSrc: 'assets/img/resources/MAC-AV-license.svg',
            label: 'Available',
            value: this.companyLicenseData?.available,
          },
          {
            imgSrc: 'assets/img/resources/MAC-employees.svg',
            label: 'ActiveEmployees',
            value: this.companyLicenseData?.active,
          },
          {
            imgSrc: 'assets/img/resources/MAC-visitors.svg',
            label: 'ActiveVisitors',
            value: this.companyLicenseData?.activeVisitors,
          },
        ];

        this.sectionStateCompanyLicense = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionStateCompanyLicense = SectionStateStatus.Ready;
      },
    });
  }
}
