import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SharedConstants } from '../models/shared-constants';

@Injectable({
  providedIn: 'root',
})
export class OrganizationAdminGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let isAdmin = JSON.parse(localStorage.getItem('logged_in_user'))['isAdmin'];
    if (!isAdmin) {
      this.router.navigate([
        '/' + SharedConstants.PAGE_ERROR + '/' + SharedConstants.ACCESS_DENIED,
      ]);
    }
    return isAdmin;
  }
}
