<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.PenaltyManagement' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <div *ngIf="data">
      <div body>
        <div class="flex justify-end mb-4">
          <app-basic-button
            *ngIf="data?.status === 'NEW' && checkCanClose()"
            [btnType]="'Delete'"
            [btnText]="'Close'"
            [isDisabled]="false"
            (onClick)="openClosePenalty()"
          >
          </app-basic-button>
        </div>
        <div class="wrapper_1">
          <app-card [title]="data?.title">
            <div body class="flex justify-between">
              <div class="flex-1">
                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">
                      {{ data?.penaltyCode }}
                    </p>
                    <p class="m-0 caption text-hint">
                      {{ "PenaltyManagement.Table.Code" | translate }}
                    </p>
                  </div>
                </div>
                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">
                      {{ data?.createdOn | date : "medium" }}
                    </p>
                    <p class="m-0 caption text-hint">
                      {{ "PenaltyManagement.Table.ReportedOn" | translate }}
                    </p>
                  </div>
                </div>
                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{ data?.requestor }}</p>
                    <p class="m-0 caption text-hint">
                      {{ "PenaltyManagement.Table.ReportedBy" | translate }}
                    </p>
                  </div>
                </div>
                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">
                      {{ data?.updatedOn | date : "medium" }}
                    </p>
                    <p class="m-0 caption text-hint">
                      {{ "PenaltyManagement.Table.LastUpdate" | translate }}
                    </p>
                  </div>
                </div>
                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{ data?.description }}</p>
                    <p class="m-0 caption text-hint">
                      {{ "PenaltyManagement.Table.Description" | translate }}
                    </p>
                  </div>
                </div>
                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{ data['byTeam']?.team }}</p>
                    <p class="m-0 caption text-hint">
                      {{ "PenaltyManagement.Table.Team" | translate }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <div @fadeInUp class="flex items-center">
                  <p class="text-left font-bold text-primary mr-4 rtl:ml-3">
                    {{ "General.Status" | translate }}
                  </p>
                  <div class="w-28 text-center" [attr.id]="data?.status">
                    <span>{{ data?.status }}</span>
                  </div>
                </div>
                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{ data['byArea']?.area }}</p>
                    <p class="m-0 caption text-hint">
                      {{ "PenaltyManagement.Table.Area" | translate }}
                    </p>
                  </div>
                </div>
                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{ data?.workOrderNo }}</p>
                    <p class="m-0 caption text-hint">
                      {{ "PenaltyManagement.Table.WorkOrderNo" | translate }}
                    </p>
                  </div>
                </div>

                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{ data?.cost }}</p>
                    <p class="m-0 caption text-hint">
                      {{ "PenaltyManagement.Table.Cost" | translate }}
                    </p>
                  </div>
                </div>

                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">
                      {{ approverTeam }}
                    </p>
                    <p class="m-0 caption text-hint">
                      {{ "PenaltyManagement.Table.Approvers" | translate }}
                    </p>
                  </div>
                </div>

                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">
                      {{ data?.dateReceived | date : "medium" }}
                    </p>
                    <p class="m-0 caption text-hint">
                      {{
                        "PenaltyManagement.Table.DateOfReceiving" | translate
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div body class="flex flex-wrap gap-2 mt-3" *ngIf="data">
              <ngx-avatars
                *ngFor="let img of data?.penaltyAttachments"
                class="cursor-pointer"
                size="60"
                [src]="getAttachmentImage(img.imageUrl)"
                [name]="getAttachementTypeName(img.imageUrl)"
                [textSizeRatio]="2"
                [round]="false"
                [initialsSize]="2"
                [matTooltip]="img['fileName']"
                [cornerRadius]="10"
                [borderColor]="'#1F36B4'"
                (clickOnAvatar)="openBigView(img.id)"
              >
              </ngx-avatars>
            </div>
          </app-card>
        </div>
      </div>

      <!-- Actions -->
      <div class="mt-4">
        <app-action
          *ngIf="data"
          [sourceId]="data?.id"
          [sourceType]="'PENALTY_MANAGEMENT'"
        ></app-action>
      </div>

      <!-- History -->
      <div class="mt-4">
        <app-expandable-panel
          [expanded]="true"
          #expandablePanel
          [title]="'PenaltyManagement.History' | translate"
        >
          <table
            body
            @stagger
            [dataSource]="dataSource"
            class="w-full"
            mat-table
            matSort
          >
            <ng-container
              *ngFor="let column of columns; trackBy: trackByProperty"
            >
              <ng-container
                *ngIf="column.type === 'text'"
                [matColumnDef]="column.property"
              >
                <th
                  *matHeaderCellDef
                  class="uppercase"
                  mat-header-cell
                  mat-sort-header
                >
                  {{ "WorkPermit.View.Table." + column.label | translate }}
                </th>
                <td
                  *matCellDef="let row"
                  [ngClass]="column.cssClasses"
                  mat-cell
                >
                  {{ row[column.property] }}
                </td>
              </ng-container>
              <ng-container
                *ngIf="
                  column.type === 'boolean' && column.property === 'status'
                "
                [matColumnDef]="column.property"
              >
                <th
                  *matHeaderCellDef
                  class="uppercase"
                  mat-header-cell
                  mat-sort-header
                >
                  {{ "WorkPermit.View.Table." + column.label | translate }}
                </th>
                <td
                  *matCellDef="let row"
                  [ngClass]="column.cssClasses"
                  mat-cell
                >
                  <div
                    class="flex justify-center align-center"
                    [attr.id]="row[column.property]"
                  >
                    <span>{{
                      "Enum.WorkPermitStatus." + row[column.property]
                        | translate
                    }}</span>
                  </div>
                </td>
              </ng-container>
              <ng-container
                *ngIf="column.type === 'date'"
                [matColumnDef]="column.property"
              >
                <th
                  *matHeaderCellDef
                  class="uppercase"
                  mat-header-cell
                  mat-sort-header
                >
                  Created
                </th>
                <td
                  *matCellDef="let row"
                  [ngClass]="column.cssClasses"
                  mat-cell
                >
                  {{ row[column.property] | date : "mediumDate" }}
                </td>
              </ng-container>
            </ng-container>

            <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
            <tr
              *matRowDef="let row; columns: visibleColumns"
              @fadeInUp
              class="hover:bg-hover trans-ease-out cursor-pointer"
              mat-row
            ></tr>
          </table>
          <div
            *ngIf="dataSource?.filteredData?.length === 0"
            @scaleFadeIn
            class="flex-auto flex flex-col items-center justify-center"
          >
            <img src="assets/img/resources/empty-state.svg" alt="not found" />
            <h2 class="headline m-0 text-center text-primary">
              {{ "General.EmptyTableList" | translate }}...
            </h2>
          </div>
        </app-expandable-panel>
      </div>
    </div>

    <!-- open image in big view -->
    <ng-template #imageBigView>
      <div class="flex items-center" mat-dialog-title>
        <img [src]="selectedImg?.imageUrl" class="avatar mr-5" />
        <h2 class="headline m-0 flex-auto">{{ selectedImg?.fileName }}</h2>
        <button
          class="text-secondary"
          (click)="downloadDocument(selectedImg?.imageUrl)"
          mat-icon-button
          type="button"
        >
          <mat-icon svgIcon="mat:download"></mat-icon>
        </button>

        <button
          class="text-secondary"
          (click)="closeDialog()"
          mat-icon-button
          type="button"
        >
          <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
      </div>
      <div class="flex">
        <div class="">
          <img [src]="selectedImg?.imageUrl" [alt]="data?.title" />
        </div>
      </div>
      <div class="flex justify-end items-center mt-4">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel'"
          (onClick)="closeDialog()"
        ></app-basic-button>
      </div>
    </ng-template>

    <ng-template #closeModal>
      <div class="flex items-center" mat-dialog-title>
        <h2 class="headline m-0 flex-auto">Close Action</h2>
        <button
          class="text-secondary"
          (click)="closeDialog()"
          mat-icon-button
          type="button"
        >
          <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
      </div>
      <div>
        <form [formGroup]="closeForm">
          <div class="flex-row">
            <mat-label class="field-label">Remarks</mat-label>
            <mat-form-field class="input-field" appearance="outline">
              <textarea
                matInput
                rows="5"
                formControlName="remarks"
                [placeholder]="'enter remarks'"
                matInput
              >
              </textarea>
            </mat-form-field>
          </div>
          <div class="flex column">
            <file-upload
              formControlName="media"
              [multiple]="true"
              [animation]="true"
            ></file-upload>
          </div>
        </form>
      </div>
      <div class="flex justify-end items-center mt-4">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel'"
          (onClick)="closeDialog()"
        ></app-basic-button>
        <app-basic-button
          [btnType]="'Add'"
          [btnText]="'Submit'"
          [isDisabled]="!closeForm.valid"
          (onClick)="closePenalty()"
        ></app-basic-button>
      </div>
    </ng-template>
  </hodhod-page-layout-content>
</hodhod-page-layout>
