import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { takeUntil, tap } from 'rxjs/operators';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { MatSelectChange } from '@angular/material/select';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { WorkPermit } from '../../models/work-permit';
import { SortDirection, WorkPermitStatus } from 'src/@hodhod/common/enum';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { WorkPermitService } from 'src/backend/services/work-permits/work-permit.service';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { PermissionService } from 'src/backend/services/permission.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { ExportExcelService } from 'src/backend/services/export-excel.service';
import { DashboardCalenderComponent } from 'src/app/dashboard/components/dashboard-calender/dashboard-calender.component';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { DatePipe } from '@angular/common';
import { WorkConditionsService } from 'src/backend/services/work-conditions/work-conditions.service';
@UntilDestroy()
@Component({
  selector: 'app-work-permit-list',
  templateUrl: './work-permit-list.component.html',
  styleUrls: ['./work-permit-list.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class WorkPermitListComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input()
  fixedLayout: boolean;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  workPermits: WorkPermit[];
  columns: TableColumn<WorkPermit>[] = [
    {
      label: 'Code',
      property: 'code',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Title',
      property: 'title',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'WorkConditions',
      property: 'theNewWorkConditions',
      type: 'text',
      visible: true,
    },
    {
      label: 'Department',
      property: 'departmentParentName',
      type: 'text',
      visible: true,
    },
    {
      label: 'SubDepartment',
      property: 'department',
      type: 'text',
      visible: true,
    },
    { label: 'Requester', property: 'requestor', type: 'text', visible: true },
    {
      label: 'NoOfWorkers',
      property: 'noOfWorkers',
      type: 'text',
      visible: true,
    },
    { label: 'StartDate', property: 'startDate', type: 'date', visible: true },
    {
      label: 'EndDate',
      property: 'endDate',
      type: 'date',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'StartTime',
      property: 'startTime',
      type: 'text',
      visible: false,
    },
    {
      label: 'EndTime',
      property: 'endTime',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'CreatedAt',
      property: 'createdOn',
      type: 'date',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'UpdatedAt',
      property: 'updatedOn',
      type: 'date',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    {
      label: 'Description',
      property: 'description',
      type: 'text',
      visible: false,
    },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<WorkPermit>([]);
  selection = new SelectionModel<WorkPermit>(true, []);
  searchCtrl = new UntypedFormControl();
  loadingLabel: string = '';
  searchValue: string = '';
  timer = null;
  hasPermission = false;

  SharedConstants = SharedConstants;
  WorkPermitStatues = WorkPermitStatus;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public selectedStatus: string[] = [];

  private translationsList: any = {};
  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  currentQueryParams = null;
  filteredParams = null;
  selectedWorkConditions: any = [];
  workConditions: any[] = [];

  hasAccessPermissions = ApplicationPermission.WORK_PERMIT_LIST_ACCESS;
  hasWritePermissions = ApplicationPermission.WORK_PERMIT_LIST_CREATE;
  hasEditPermissions = ApplicationPermission.WORK_PERMIT_LIST_EDIT;
  hasApprovePermissions = ApplicationPermission.WORK_PERMIT_LIST_APPROVE;
  hasDeletePermissions = ApplicationPermission.WORK_PERMIT_LIST_DELETED;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private workPermitService: WorkPermitService,
    private feedBackService: AsyncFeedbackService,
    private workConditionService: WorkConditionsService,
    private confirmationService: ConfirmationService,
    private exportToExcelService: ExportExcelService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private datePipe: DatePipe,
    private route: ActivatedRoute
  ) {
    super();
    this.translate
      .get([
        'Errors',
        'Success',
        'confirmDeactiveRecord',
        'ConfirmDeleteRecord',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  override ngOnInit(): void {
    this.dataSource = new MatTableDataSource([]);
    this.getWorkConditions();
    this.currentQueryParams = this.route.snapshot.queryParams;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);

    this.pagination.pageIndex = this.currentQueryParams?.pageIndex
      ? Number(this.currentQueryParams?.pageIndex)
      : 0;
    this.pagination.pageSize = this.currentQueryParams?.pageSize
      ? Number(this.currentQueryParams?.pageSize)
      : 10;

    this.selectedStatus = this.currentQueryParams?.orgStatus
      ? this.currentQueryParams?.orgStatus.split(',')
      : [];

    this.columns.forEach((item) => {
      if (this.currentQueryParams[item.property] === 'true') {
        item.visible = true;
      } else if (this.currentQueryParams[item.property] === 'false') {
        item.visible = false;
      }
    });

    this.sort.active = this.currentQueryParams?.sortName;
    this.sort.direction = this.currentQueryParams?.sortDirection;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        sortName: res.active,
        sortDirection: res.direction,
        pageIndex: 0,
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.currentQueryParams,
      });
      this.pagination.pageIndex = 0;
      this.getData();
    });

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getData();
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }

  getData() {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }
    this.filteredParams = {
      sortDirection: this.currentQueryParams?.sortDirection || '',
      sortField,
      status: this.currentQueryParams?.orgStatus,
      searchValue: this.currentQueryParams?.searchValue
        ? this.currentQueryParams?.searchValue
        : this.searchValue,
      pageIndex: this.currentQueryParams?.pageIndex
        ? this.currentQueryParams?.pageIndex
        : this.pagination.pageIndex,
      pageSize: this.currentQueryParams?.pageSize
        ? this.currentQueryParams?.pageSize
        : this.pagination.pageSize,

      workConditionIds: this.selectedWorkConditions.toString(),
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.workPermitService
      .getWorkPermitsByCompany(this.filteredParams)
      .subscribe({
        next: (response) => {
          this.workPermits = response?.data.map((item: any) => {
            return {
              ...item,
              departmentParentName: item.departmentParentName
                ? item.departmentParentName
                : item.department,
              department: item.departmentParentName ? item.department : '',
            };
          });
          this.dataSource = new MatTableDataSource(response?.data);
          this.pagination.length = response?.totalCount;
          this.dataSource.sort = this.sort;
          this.sectionState = SectionStateStatus.Ready;
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
  }
  onSelectWorkConditions(event: MatSelectChange) {
    this.selectedWorkConditions = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      workConditions: event.value.toString(),
    };
    this.getData();
  }
  getWorkConditions(): void {
    this.loadingService.startLoading(true, '');
    this.workConditionService
      .getWorkConditionsWithCheckListExecution()
      .subscribe({
        next: (response) => {
          this.workConditions = response;
          this.loadingService.stopLoading();
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.loadingService.stopLoading();
        },
      });
  }

  onSelectStatus(event: MatSelectChange) {
    this.selectedStatus = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgStatus: event.value.toString(),
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      searchValue: this.searchValue,
      pageIndex: 0,
    };
    this.pagination.pageIndex = 0;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      [column.property]: column.visible,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  editWorkPermit(event: any, id: number): void {
    this.router.navigate(
      [
        SharedConstants.WORK_PERMIT +
          '/' +
          SharedConstants.WORK_PERMIT_LIST +
          '/' +
          SharedConstants.VIEW_EDIT_WORK_PERMIT +
          '/' +
          id,
      ],
      {
        queryParams: { ...this.currentQueryParams, editMode: true },
      }
    );
  }
  addWorkPermit(): void {
    this.router.navigate([
      '/' +
        SharedConstants.WORK_PERMIT +
        '/' +
        SharedConstants.WORK_PERMIT_LIST +
        '/' +
        SharedConstants.ADD_WORK_PERMIT,
    ]);
  }
  viewWorkPermit(event: any, id: number): void {
    this.router.navigate(
      [
        SharedConstants.WORK_PERMIT +
          '/' +
          SharedConstants.WORK_PERMIT_LIST +
          '/' +
          SharedConstants.VIEW_EDIT_WORK_PERMIT +
          '/' +
          id,
      ],
      {
        queryParams: { ...this.currentQueryParams, editMode: false },
      }
    );
  }

  removeWorkPermit(event: any, id: number): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['ConfirmDeleteRecord']['Title'],
          this.translationsList['ConfirmDeleteRecord']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.workPermitService.removeWorkPermit(id).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  exportToExcel(exportType?: string) {
    const tableHeaders = this.columns.map((item) => ({
      label: item.label.toUpperCase(),
      property: item.property.charAt(0).toUpperCase() + item.property.slice(1),
    }));
    const params: any = {
      columnHeaders: [...tableHeaders],
      reportType: 'WORK_PERMIT',
      departments: '',
      sheetName: 'WorkPermit',
      allWorkPermits: this.hasPermission,
    };
    if (exportType === 'custom') {
      const today = new Date();
      const dialogRef = this.dialog.open(DashboardCalenderComponent, {
        width: window.innerWidth < 850 ? '90%' : '60%',
        height: 'auto',
        data: {
          toDate: new Date(today),
          fromDate: new Date(today),
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        params.startDate = this.datePipe.transform(
          new Date(result?.data?.fromDate),
          'yyyy-MM-ddT00:00:00.000'
        );
        params.endDate = this.datePipe.transform(
          new Date(result?.data?.toDate),
          'yyyy-MM-ddT23:59:59.000'
        );
        this.loadingService.startLoading(true);
        this.exportToExcelService.exportToExcelFiltered(params).subscribe({
          next: (res) => {
            const a = document.createElement('a');
            const objectURL = URL.createObjectURL(res);
            a.href = objectURL;
            a.download = `workpermits-${params.startDate.split('T')[0]} - ${
              params.endDate.split('T')[0]
            }.xlsx`;
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(objectURL);
            document.body.removeChild(a);
            this.loadingService.stopLoading();
          },
          error: (error) => {
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Failure, error?.message)
            );
            this.loadingService.stopLoading();
          },
        });
      });
    } else {
      this.loadingService.startLoading(true);
      this.exportToExcelService.exportToExcel(params).subscribe({
        next: (res) => {
          const a = document.createElement('a');
          const objectURL = URL.createObjectURL(res);
          a.href = objectURL;
          a.download = 'all-workpermits.xlsx';
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(objectURL);
          document.body.removeChild(a);
          this.loadingService.stopLoading();
        },
        error: (error) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.loadingService.stopLoading();
        },
      });
    }
  }
}
