import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Constants } from 'src/@hodhod/common/constants';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { Organization } from 'src/app/organization-setup/models/organization';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { CompanyService } from 'src/backend/services/company.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseApi } from 'src/backend/api/base-api';
import { UpdateQoutaPopupComponent } from '../update-qouta-popup/update-qouta-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
@UntilDestroy()
@Component({
  selector: 'app-list-of-companies',
  templateUrl: './list-of-companies.component.html',
  styleUrls: ['./list-of-companies.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class ListOfCompaniesComponent implements OnInit, OnChanges {
  @Input() licenseInfo!: any;

  searchCtrl = new UntypedFormControl();
  public dataSource = new MatTableDataSource<Organization>([]);
  searchValue: string = '';
  columns: TableColumn<Organization>[] = [
    {
      label: 'Logo',
      property: 'logo',
      type: 'image',
      visible: true,
    },
    {
      label: 'Name',
      property: 'name',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Code',
      property: 'code',
      type: 'text',
      visible: true,
      cssClasses: ['font-bold'],
    },
    {
      label: 'LicenseQuota',
      property: 'accessControlLicenseQuota',
      type: 'text',
      visible: true,
      cssClasses: ['font-bold'],
    },
    {
      label: 'Active',
      property: 'active',
      type: 'text',
      visible: true,
      cssClasses: ['font-bold'],
    },
    {
      label: 'Available',
      property: 'available',
      type: 'text',
      visible: true,
      cssClasses: ['font-bold'],
    },
    {
      label: 'SubscriptionDate',
      property: 'subscriptionDate',
      type: 'date',
      visible: true,
    },
    {
      label: 'UpdatedAt',
      property: 'updatedAt',
      type: 'date',
      visible: true,
    },

    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  loadingLabel: string = '';
  licenseData;
  public pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  public pageSize = Constants.PAGE_SIZE;
  orgId: number;
  constructor(
    private compService: CompanyService,
    private route: ActivatedRoute,
    private baseApi: BaseApi,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.orgId = this.route.snapshot.params['id'];
    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getData(this.orgId);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.licenseData = changes['licenseInfo'].currentValue;
    if (this.licenseData) {
      this.sectionState = SectionStateStatus.Ready;
    }
  }

  getData(orgId) {
    this.compService.GetMobileAccessCompaniesByOrg(orgId).subscribe({
      next: (res) => {
        this.dataSource = new MatTableDataSource(res['message']);
      },
    });
  }
  onFilterChange(value: string) {
    // if (!this.dataSource) {
    //   return;
    // }
    // this.searchValue = value;
    // this.paginator.pageIndex = 0;
    // clearTimeout(this.timer);
    // this.timer = setTimeout(() => {
    //   this.getData();
    // }, 700);
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }
  updateQouta(event: any, com) {
    this.dialog
      .open(UpdateQoutaPopupComponent, {
        width: '30%',
        height: 'auto',
        disableClose: false,
        data: {
          details: com,
          type: 'com',
          quota: this.licenseData?.licenseInfo
            ? this.licenseData?.licenseInfo[0]['availableQty']
            : this.licenseData?.available,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        this.getData(this.orgId);
      });
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  viewCompany(event, id, orgId) {
    this.router.navigate(
      [
        '/' +
          SharedConstants.ORGANIZATION_SETUP +
          '/' +
          SharedConstants.COMPANY_DETAIL +
          '/' +
          id,
      ],
      {
        queryParams: { orgId },
      }
    );
  }
}
