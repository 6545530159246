<app-state-section
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="'' | translate"
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <form [formGroup]="hazardForm">
    <div class="flex flex-row flex-wrap justify-between">
      <div class="field flex column" [style.width.%]="100">
        <mat-label class="field-label"
          >{{ "AddReportHazard.Title" | translate }}
          <span
            [ngClass]="
              hazardForm.invalid && hazardForm.get('title').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            formControlName="title"
            [placeholder]="'Placeholder.AddText' | translate"
            matInput
            type="text"
          />
          <mat-error
            *ngIf="
              hazardForm.invalid && hazardForm.get('title').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              hazardForm.invalid &&
              hazardForm.get('title').hasError('whitespace')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.InValidData" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="field flex column" [style.width.%]="100">
        <mat-label class="field-label"
          >{{ "AddReportHazard.Description" | translate }}
          <span
            [ngClass]="
              hazardForm.invalid &&
              hazardForm.get('description').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <textarea
            matInput
            rows="5"
            formControlName="description"
            [placeholder]="'AddReportHazard.Description' | translate"
            matInput
          >
          </textarea>
          <mat-error
            *ngIf="
              hazardForm.invalid &&
              hazardForm.get('description').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              hazardForm.invalid &&
              hazardForm.get('description').hasError('whitespace')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.InValidData" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="field flex column">
        <mat-label class="field-label"
          >{{ "AddReportHazard.RootCause" | translate }}
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            ngxMatSelect
            #msf1="ngxMatSelect"
            [displayMember]="'name'"
            [valueMember]="'id'"
            [source]="rootCauses"
            [useInfiniteScroll]="true"
            [multiple]="false"
            [hasPermission]="hasRootCauseCreatePermission | permissionChecker"
            [title]="'Root Cause'"
            [hasSearchBox]="false"
            [manageRoute]="'/reporting-management/root-cause'"
            [placeholder]="'Placeholder.Select' | translate"
            formControlName="rootCause"
            [disabled]="false"
          >
            <mat-option
              *ngFor="let lm of msf1.filteredSource"
              [value]="lm.id"
              >{{ lm.rootCauseName }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              hazardForm.invalid &&
              hazardForm.get('lossMode').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field flex column">
        <mat-label class="field-label"
          >{{ "AddReportHazard.Cause" | translate }}
          <span
            [ngClass]="
              hazardForm.invalid && hazardForm.get('cause').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            ngxMatSelect
            #msf2="ngxMatSelect"
            [displayMember]="'causeName'"
            [valueMember]="'id'"
            [source]="reasons"
            [useInfiniteScroll]="true"
            [multiple]="false"
            [hasPermission]="hasCreateReasonsPermission | permissionChecker"
            [title]="'Cause'"
            [hasSearchBox]="false"
            [manageRoute]="'/reporting-management/reasons'"
            [placeholder]="'Placeholder.Select' | translate"
            formControlName="cause"
            [disabled]="false"
          >
            <mat-option
              *ngFor="let rc of msf2.filteredSource"
              [value]="rc.id"
              >{{ rc.causeName }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              hazardForm.invalid && hazardForm.get('cause').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="field flex column">
        <mat-label class="field-label"
          >{{ "AddReportHazard.Shift" | translate }}
          <span
            [ngClass]="
              hazardForm.invalid && hazardForm.get('shift').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field appearance="outline">
          <mat-select
            ngxMatSelect
            #msf3="ngxMatSelect"
            [displayMember]="'shiftTitle'"
            [valueMember]="'shiftTitle'"
            [source]="shifts"
            [useInfiniteScroll]="true"
            [multiple]="false"
            [hasPermission]="hasCreateShiftsPermission | permissionChecker"
            [title]="'shifts'"
            [hasSearchBox]="false"
            [manageRoute]="'/company-setup/shifts-setting'"
            [placeholder]="'AddReportIncident.Select' | translate"
            formControlName="shift"
          >
            <ng-container
              *ngFor="let shift of msf3.filteredSource; let i = index"
            >
              <mat-option [value]="shift.shiftTitle">
                {{ shift.shiftTitle }}
              </mat-option>
            </ng-container>
          </mat-select>
          <mat-error
            *ngIf="
              hazardForm.invalid && hazardForm.get('shift').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="field flex column">
        <mat-label class="field-label"
          >{{ "AddReportHazard.TypeOfHazard" | translate }}
          <span
            [ngClass]="
              hazardForm.invalid &&
              hazardForm.get('typeOfReportIds').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            ngxMatSelect
            #msf5="ngxMatSelect"
            [displayMember]="'incidentName'"
            [valueMember]="'id'"
            [source]="hazardTypes"
            [useInfiniteScroll]="true"
            [multiple]="true"
            [hasPermission]="
              hasCreateTypeOfIncidentsPermission | permissionChecker
            "
            [title]="'type of hazard'"
            [hasSearchBox]="false"
            [manageRoute]="'/reporting-management/incident-category'"
            [placeholder]="'Placeholder.Select' | translate"
            formControlName="typeOfReportIds"
            [disabled]="false"
          >
            <mat-option
              *ngFor="let toi of msf5.filteredSource"
              [value]="toi?.id"
            >
              {{ toi?.incidentName }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              hazardForm.invalid &&
              hazardForm.get('typeOfReportIds').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="field flex column">
        <mat-label class="field-label"
          >{{ "AddReportHazard.Department" | translate }}
          <span
            [ngClass]="
              hazardForm.invalid &&
              hazardForm.get('department').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            ngxMatSelect
            #msf4="ngxMatSelect"
            [displayMember]="'departmentName'"
            [valueMember]="'departmentId'"
            [source]="departments"
            [useInfiniteScroll]="true"
            [multiple]="false"
            [hasPermission]="hasDepartmentPermission | permissionChecker"
            [title]="'departments'"
            [hasSearchBox]="false"
            [manageRoute]="'/company-setup/departments'"
            [placeholder]="'Placeholder.Select' | translate"
            formControlName="department"
            [disabled]="false"
            (selectionChange)="onChangeDepartment($event)"
          >
            <mat-option
              *ngIf="msf4.filteredSource.length === 0"
              style="height: 0"
            >
            </mat-option>
            <mat-optgroup
              *ngFor="let group of msf4.filteredSource"
              [label]="group.parentName"
            >
              <mat-option
                *ngFor="let detail of group.detail"
                [value]="detail.departmentId"
              >
                {{ detail.departmentName }}
              </mat-option>
            </mat-optgroup>
          </mat-select>
          <mat-error
            *ngIf="
              hazardForm.invalid &&
              hazardForm.get('department').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Sub department -->
      <div class="field flex column" *ngIf="subDepartments.length > 0">
        <mat-label class="field-label">{{
          "AddReportHazard.SubDepartment" | translate
        }}</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            [title]="'departments'"
            [placeholder]="'Placeholder.Select' | translate"
            formControlName="subDepartment"
          >
            <mat-option *ngFor="let dep of subDepartments" [value]="dep.id">
              {{ dep.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="flex column">
      <file-upload
        formControlName="attachments"
        [multiple]="true"
        [animation]="true"
      ></file-upload>
    </div>
    <div class="flex flex-wrap gap-2 my-4" *ngIf="images.length > 0">
      <div class="relative" *ngFor="let img of images">
        <div
          class="cursor-pointer bg-red-700 text-white rounded-full absolute right-0 top-0 z-10 flex items-center justify-center"
          style="width: 20px; height: 20px"
          (click)="deleteImage(img)"
        >
          <mat-icon svgIcon="mat:close" class="icon-sm"></mat-icon>
        </div>
        <ngx-avatars
          class="cursor-pointer"
          size="60"
          [src]="getAttachmentImage(img.imageUrl)"
          [name]="getAttachementTypeName(img.imageUrl)"
          [textSizeRatio]="2"
          [round]="false"
          [initialsSize]="2"
          [cornerRadius]="10"
          [matTooltip]="img['imageName']"
          [borderColor]="'#1F36B4'"
          (clickOnAvatar)="openBigView(img.id)"
        >
        </ngx-avatars>
      </div>
    </div>
    <div class="flex items-center justify-end my-3">
      <app-basic-button
        [btnType]="'Cancel'"
        [btnText]="'Cancel'"
        (onClick)="cancelForm()"
      ></app-basic-button>
      <app-basic-button
        [btnType]="'Add'"
        [btnText]="'Submit'"
        [isDisabled]="!hazardForm.valid"
        (onClick)="onSubmit()"
      ></app-basic-button>
    </div>
  </form>
</app-state-section>
