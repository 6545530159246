import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { takeUntil, tap } from 'rxjs/operators';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { MatSelectChange } from '@angular/material/select';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import {
  CompanyUserStatus,
  SortDirection,
  VerificationValues,
} from 'src/@hodhod/common/enum';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { User } from '../../models/user';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { RoleService } from 'src/backend/services/role.service';
import { UserService } from 'src/backend/services/user.service';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import {
  EmailValidator,
  noWhitespaceValidator,
} from 'src/app/shared/custom-validators/form-validators';
import { Role } from '../../models/role';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { BaseApi } from 'src/backend/api/base-api';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { PermissionService } from 'src/backend/services/permission.service';
import { AuthService } from 'src/backend/services/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class UsersListComponent extends BaseComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('userCreateUpdateModal') userCreateUpdateModal!: TemplateRef<any>;
  userForm: FormGroup;
  formMode: 'create' | 'update' = 'create';

  users: User[];
  columns: TableColumn<User>[] = [
    { label: 'Image', property: 'userImage', type: 'image', visible: true },
    {
      label: 'Name',
      property: 'fullName',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    { label: 'FirstName', property: 'firstName', type: 'text', visible: false },
    { label: 'LastName', property: 'lastName', type: 'text', visible: false },
    { label: 'IdNumber', property: 'idNumber', type: 'text', visible: false },
    {
      label: 'Code',
      property: 'userCode',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Departments',
      property: 'departmentName',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Role',
      property: 'userRoleNames',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Email',
      property: 'email',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'LastActive',
      property: 'lastActive',
      type: 'date',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Company',
      property: 'companyName',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Phone',
      property: 'phoneNumber',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Organization',
      property: 'organizationName',
      type: 'text',
      visible: false,
    },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];
  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource: MatTableDataSource<User> | null;
  selection = new SelectionModel<User>(true, []);
  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  timer = null;
  loadingLabel: string = '';
  userId: string = '';
  roles: Role[] = [];
  filteredRoles: Role[] = [];
  departments: any[] = [];
  allDepartments: any = [];
  selectedDepartments: any = [];
  reportUsers: any[] = [];
  deptList: any;
  CompanyUserStatus = CompanyUserStatus;
  SharedConstants = SharedConstants;
  VerificationValues = VerificationValues;

  public selectedUserStatus: string[] = [];
  public selectedUserVerification: string[] = [];

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public sectionStateModal: SectionStateStatus = SectionStateStatus.Ready;
  private translationsList: any = {};

  hasAccessPermissions = ApplicationPermission.USER_ACCESS;
  hasWritePermissions = ApplicationPermission.USER_CREATE;
  hasEditPermissions = ApplicationPermission.USER_UPDATE;
  hasActivatePermissions = ApplicationPermission.USER_ACTIVATE;

  hasCreateUserPermission = ApplicationPermission.USER_CREATE;
  hasReadPermission = ApplicationPermission.USER_ACCESS;
  hasCreateDepartmentPermission = ApplicationPermission.DEPARTMENTS_CREATE;
  hasCreateRolesPermission = ApplicationPermission.ROLE_CREATE;
  isOrganizationAdmin;
  queryParams = {};
  foundUser: any;

  currentQueryParams = null;
  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private userService: UserService,
    private roleService: RoleService,
    private authService: AuthService,
    private feedBackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService,
    private departmentService: DepartmentsService,
    private baseApi: BaseApi,
    private permissionService: PermissionService
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  override ngOnInit(): void {
    this.userForm = this.fb.group({
      firstName: ['', [Validators.required, noWhitespaceValidator]],
      lastName: ['', noWhitespaceValidator],
      phoneNumber: ['', Validators.pattern(/^[0-9]*$/)],
      reportTo: [''],
      email: ['', [Validators.required, noWhitespaceValidator, EmailValidator]],
      departments: [[], Validators.required],
      roles: [[], Validators.required],
      idNumber: [''],
      isExternal: [false],
      isGuest: [false],
    });
    let rolesArray = [];
    rolesArray = JSON.parse(localStorage.getItem('logged_in_user'))['roles'];
    this.isOrganizationAdmin = rolesArray.includes('Organization Admin');
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.sort = this.sort;
    this.currentQueryParams = this.route.snapshot.queryParams;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);

    this.pagination.pageIndex = this.currentQueryParams?.pageIndex
      ? Number(this.currentQueryParams?.pageIndex)
      : 0;
    this.pagination.pageSize = this.currentQueryParams?.pageSize
      ? Number(this.currentQueryParams?.pageSize)
      : 10;

    this.selectedUserStatus = this.currentQueryParams?.orgStatus
      ? this.currentQueryParams?.orgStatus.split(',')
      : [];

    this.selectedUserVerification = this.currentQueryParams?.verificationStatus
      ? this.currentQueryParams?.verificationStatus.split(',')
      : [];

    this.columns.forEach((item) => {
      if (this.currentQueryParams[item.property] === 'true') {
        item.visible = true;
      } else if (this.currentQueryParams[item.property] === 'false') {
        item.visible = false;
      }
    });

    this.sort.active = this.currentQueryParams?.sortName;
    this.sort.direction = this.currentQueryParams?.sortDirection;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        sortName: res.active,
        sortDirection: res.direction,
        pageIndex: 0,
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.currentQueryParams,
      });
      this.pagination.pageIndex = 0;
      this.getData();
    });

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getReportUsers();
    const user = this.route.snapshot.queryParams['user'];
    if (user) {
      this.searchValue = user;
    }
    this.getData();
    this.getAllDepartments();
  }

  onExternalUserSelect(event) {
    if (event.checked) {
      this.userForm.get('departments').clearValidators();
      this.userForm.get('departments').setValue([]);
      this.userForm.get('reportTo').setValue('');
      this.userForm.get('idNumber').setValue('');
      this.filteredRoles = this.roles.filter((item) => item.isExternal);
    } else {
      this.userForm.get('departments').setValidators(Validators.required);
      this.userForm.get('departments').setValue([]);
      this.userForm.get('reportTo').setValue('');
      this.userForm.get('idNumber').setValue('');
      this.filteredRoles = this.roles;
    }
    this.userForm.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  getRoles(): void {
    this.roleService.getAllRoles().subscribe({
      next: (response) => {
        this.roles = response;
        this.filteredRoles = this.roles;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  selectedvalue(e: any) {
    const deptid = this.userForm.get('departments').value;
    const types = this.departments.map((x) => x.departmentId);
    const all = deptid.find((x) => x == 'ALL');
    if (all == 'ALL') {
      types.push('ALL');
      this.userForm.get('departments').setValue(types);
    } else {
      this.userForm.get('departments').setValue('');
    }
  }

  selectAll(e: any) {
    const deptId = this.userForm.get('departments').value;
    const index = deptId.indexOf('ALL');
    if (index > -1) {
      deptId.splice(index, 1);
    }
    if (deptId.length == this.departments.length) {
      deptId.push('ALL');
      this.userForm.get('departments').setValue(deptId);
    } else {
      this.userForm.get('departments').setValue(deptId);
    }
  }

  getCompanyDepartments(): void {
    this.departmentService.getCompanyDepartments().subscribe({
      next: (response) => {
        this.departments = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }
  getAllDepartments() {
    this.departmentService.getDepartmentList().subscribe({
      next: (res) => {
        this.allDepartments = res.data;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }
  getReportUsers(): void {
    this.userService.getReportToUsers().subscribe({
      next: (response) => {
        this.reportUsers = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getData() {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }
    const filteredParams = {
      sortDirection: this.currentQueryParams?.sortDirection || '',
      sortField,
      pageIndex: this.currentQueryParams?.pageIndex
        ? this.currentQueryParams?.pageIndex
        : this.pagination.pageIndex,
      pageSize: this.currentQueryParams?.pageSize
        ? this.currentQueryParams?.pageSize
        : this.pagination.pageSize,
      searchValue: this.currentQueryParams?.searchValue
        ? this.currentQueryParams?.searchValue
        : this.searchValue,
      status: this.currentQueryParams?.orgStatus,
      departments: this.selectedDepartments.toString(),
      verifications: this.currentQueryParams?.verificationStatus,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.userService.getUsersByCompany(filteredParams).subscribe({
      next: (response) => {
        this.users = response?.data;
        this.getRoles();
        this.getCompanyDepartments();
        this.dataSource = new MatTableDataSource(
          response?.data?.map((ele) => {
            return {
              ...ele,
              userRoleNames: ele?.userRoleNames?.split(',')[0],
            };
          })
        );
        this.pagination.length = response?.totalCount;
        this.dataSource.sort = this.sort;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  onSelectUserStatus(event: MatSelectChange) {
    this.selectedUserStatus = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgStatus: event.value.toString(),
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
  onSelectDepartments(event: MatSelectChange) {
    this.selectedDepartments = event.value;
    this.getData();
  }

  onSelectUserVerification(event: MatSelectChange) {
    this.selectedUserVerification = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      verificationStatus: event.value.toString(),
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }

  openUpdateUserModal(userId: string): void {
    this.foundUser = this.users?.find((x) => x?.userId === userId);

    this.formMode = 'update';
    this.resetForm();
    this.userId = userId;
    this.userForm.get('email')?.setValue(this.foundUser?.email);
    this.userForm.get('reportTo')?.setValue(this.foundUser?.reportTo);
    this.userForm.get('phoneNumber')?.setValue(this.foundUser?.phoneNumber);
    this.userForm.get('lastName')?.setValue(this.foundUser?.lastName);
    this.userForm.get('firstName')?.setValue(this.foundUser?.firstName);
    this.userForm.get('idNumber')?.setValue(this.foundUser?.idNumber);
    this.userForm.get('isExternal')?.setValue(this.foundUser?.isExternal);
    this.userForm.get('isGuest')?.setValue(this.foundUser?.isGuest);
    this.userForm.get('roles')?.setValue(this.foundUser?.userRoles?.split(','));

    if (this.foundUser?.isExternal) {
      this.userForm.get('departments').clearValidators();
      this.userForm.get('departments').setValue([]);
      this.userForm.get('reportTo').setValue('');
      this.userForm.get('idNumber').setValue('');
      this.filteredRoles = this.roles.filter((item) => item.isExternal);
    }
    if (this.foundUser?.departmentId) {
      this.deptList = this.foundUser?.departmentId.toString().split(',');
      this.deptList = this.deptList.map((x) => parseInt(x));
      const index = this.deptList.indexOf('ALL');
      if (index > -1) {
        this.deptList.splice(index, 1);
      }
      // if (this.deptList.length == this.departments.length) {
      //   this.deptList.push('ALL');
      // }
      this.userForm
        .get('departments')
        ?.setValue(this.departments.length > 0 ? this.deptList : []);
    }
    this.dialog?.open(this.userCreateUpdateModal, {
      height: 'auto',
      width: '70%',
      disableClose: true,
    });
  }
  openCreateUserModal(): void {
    this.formMode = 'create';
    this.resetForm();
    this.dialog.open(this.userCreateUpdateModal, {
      height: 'auto',
      width: '70%',
      disableClose: true,
    });
  }

  createUser(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionStateModal = SectionStateStatus.Loading;
          const {
            email,
            departments,
            roles,
            reportTo,
            phoneNumber,
            lastName,
            firstName,
            idNumber,
            isExternal,
            isGuest,
          } = this.userForm.value;
          const org = this.baseApi.getOrganizationFromStorage();
          let deptId = [];
          const all = departments.find((x) => x == 'ALL');
          if (all == 'ALL') {
            deptId = departments.filter((x) => x != 'ALL');
          } else {
            deptId = departments;
          }
          const data = {
            email,
            firstName: firstName.replace(/\s/g, ''),
            lastName,
            phoneNumber,
            userImage: '',
            organizationCode: org?.orgCode,
            roleIds: roles,
            departmentIds: deptId,
            reportTo,
            idNumber,
            isExternal: isExternal ? true : false,
            isGuest: isGuest ? true : false,
          };
          this.userService.createUser(data).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
              this.closeDialog();
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  updateUser(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionStateModal = SectionStateStatus.Loading;
          const {
            departments,
            roles,
            reportTo,
            phoneNumber,
            lastName,
            firstName,
            idNumber,
            isExternal,
            isGuest,
          } = this.userForm.value;
          let deptId = [];
          const all = departments.find((x) => x == 'ALL');
          if (all == 'ALL') {
            deptId = departments.filter((x) => x != 'ALL');
          } else {
            deptId = departments;
          }
          const data = {
            userId: this.userId,
            firstName: firstName.replace(/\s/g, ''),
            lastName,
            phoneNumber,
            roleIds: roles,
            departmentIds: deptId,
            reportTo,
            idNumber,
            isExternal: isExternal ? true : false,
            isGuest: isGuest ? true : false,
          };
          this.userService.editUser(data).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
              this.closeDialog();
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      searchValue: this.searchValue,
      pageIndex: 0,
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.pagination.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      [column.property]: column.visible,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  deactivateUser(event: any, userId: string): any {
    // checking permissions
    if (
      !this.permissionService.isUserHasPermission(this.hasActivatePermissions)
    ) {
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          this.translationsList['StaticPages']['AccessDenied']['Title']
        )
      );
      return;
    }
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'General.Refreshing';
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.userService.deactivateUser(userId).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }
  activateUser(event: any, userId: string): any {
    // checking permissions
    if (
      !this.permissionService.isUserHasPermission(this.hasActivatePermissions)
    ) {
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          this.translationsList['StaticPages']['AccessDenied']['Title']
        )
      );
      return;
    }
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'General.Refreshing';
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.userService.activateUser(userId).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  viewUser(event: any, userId: string): void {
    this.router.navigate(
      [
        '/' +
          SharedConstants.USER_MANAGEMENT +
          '/' +
          SharedConstants.VIEW_USER +
          '/' +
          userId,
      ],
      { queryParams: { ...this.currentQueryParams } }
    );
  }
  resetForm(): void {
    this.userForm.get('email')?.setValue('');
    this.userForm.get('departments')?.setValue('');
    this.userForm.get('roles')?.setValue([]);
    this.userForm.get('reportTo')?.setValue('');
    this.userForm.get('phoneNumber')?.setValue('');
    this.userForm.get('lastName')?.setValue('');
    this.userForm.get('firstName')?.setValue('');
    this.userForm.get('idNumber')?.setValue('');
    this.userForm.get('isExternal')?.setValue('');
    this.userForm.get('isGuest')?.setValue('');
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }

  LoginAs(event: any, email: any): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['LoginConfirm']['Title'],
          this.translationsList['User']['LoginConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          const obj = {
            email: email,
          };
          this.loadingLabel = 'Company.LoginAs';
          this.authService.signInWithUser(obj);
        }
      });
  }

  openBulkUpload(): void {
    this.router.navigate(
      [
        '/' +
          SharedConstants.USER_MANAGEMENT +
          '/' +
          SharedConstants.BULK_UPLOAD,
      ],
      { queryParams: { ...this.currentQueryParams } }
    );
  }

  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
}
