import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import {
  noWhitespaceValidator,
  PhoneNumberValidator,
  EmailValidator,
} from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { Feature, SelectedFeature } from 'src/backend/models/features/feature';
import { FeatureService } from 'src/backend/services/feature.service';
import { OrganizationService } from 'src/backend/services/organization.service';

@Component({
  selector: 'app-update-organization',
  templateUrl: './update-organization.component.html',
  styleUrls: ['./update-organization.component.scss'],
  animations: [fadeInUp400ms],
})
export class UpdateOrganizationComponent implements OnInit, OnDestroy {
  @ViewChild('allSafetySelected') private allSafetySelected: MatOption;
  @ViewChild('allSecuritySelected') private allSecuritySelected: MatOption;

  @Input() orgId: number = 0;
  @Input() isAdmin: boolean = false;

  public ACTIVE_COLOR = '#67DD2D';
  public INACTIVE_COLOR = '#F76F45';
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public updateOrgForm!: FormGroup;
  public features: Feature[] = [];
  secFeatures: Feature[] = [];
  sefetyFeatures: Feature[] = [];
  setLicenseQuota: boolean = false;

  public selectedFeatures: Feature[] = [];
  public selectedFeatureCodes: string[] = [];

  private destroy$ = new Subject();
  public translationsList: any = {};
  public loadingLabel: string = 'BuildingOrganizationForm';
  public orgInfo: any;
  public orgLicenseInfo: any;
  constructor(
    private formBuilder: FormBuilder,
    private featureService: FeatureService,
    private feedBackService: AsyncFeedbackService,
    private orgService: OrganizationService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {
    this.translate
      .get(['Errors', 'Success', 'organization'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.updateOrgForm = this.formBuilder.group({
      orgCode: ['', [Validators.required, noWhitespaceValidator]],
      orgName: ['', [Validators.required, noWhitespaceValidator]],
      orgImage: [[]],
      noOfCompanies: ['', Validators.required],
      noOfUsers: ['', Validators.required],
      description: [''],
      safetyFeatures: [[]],
      securityFeatures: [[]],
      OrgQuota: [0],
      primary: ['#1f36b4'],
      secondary: ['#ffffff'],
    });

    this.updateOrgForm.get('securityFeatures').valueChanges.subscribe((res) => {
      if (res.length > 0) {
        this.setLicenseQuota = true;
        this.updateOrgForm.get('OrgQuota').setValidators(Validators.required);
      } else {
        this.setLicenseQuota = false;
        this.updateOrgForm.get('OrgQuota').setValue(0);
        this.updateOrgForm.get('OrgQuota').clearValidators();
      }
      this.updateOrgForm.get('OrgQuota').updateValueAndValidity();
    });

    this.getAllFeatures();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  getAllFeatures(): void {
    this.sectionState = SectionStateStatus.Loading;
    this.featureService.getAllFeatures().subscribe({
      next: (response) => {
        this.sectionState = SectionStateStatus.Ready;
        let security = response?.find(
          (element) => element['module'] === 'Security'
        );
        let safety = response?.find(
          (element) => element['module'] === 'Safety'
        );
        this.secFeatures = security['features'];
        this.sefetyFeatures = safety['features'];

        this.getOrganizationById();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  onSelectSafetyFeature(all) {
    if (this.allSafetySelected.selected) {
      this.allSafetySelected.deselect();
    }
    if (
      this.updateOrgForm.get('safetyFeatures').value.length ==
      this.sefetyFeatures.length
    ) {
      this.allSafetySelected.select();
    }
    console.log(this.updateOrgForm.get('safetyFeatures').value);
  }

  onSelectAllSafetyFeature() {
    if (this.allSafetySelected.selected) {
      this.updateOrgForm
        .get('safetyFeatures')
        .patchValue([
          ...this.sefetyFeatures.map((item) => item.featureCode),
          0,
        ]);
      this.selectedFeatures = [...this.sefetyFeatures];
    } else {
      this.updateOrgForm.get('safetyFeatures').patchValue([]);
    }
  }

  onSelectSecurityFeature(all) {
    if (this.allSecuritySelected.selected) {
      this.allSecuritySelected.deselect();
    }
    if (
      this.updateOrgForm.get('securityFeatures').value.length ==
      this.secFeatures.length
    ) {
      this.allSecuritySelected.select();
    }
  }

  onSelectAllSecurityFeature() {
    if (this.allSecuritySelected.selected) {
      this.updateOrgForm
        .get('securityFeatures')
        .patchValue([...this.secFeatures.map((item) => item.featureCode), 0]);
      console.log(this.updateOrgForm.get('securityFeatures').value);
    } else {
      this.updateOrgForm.get('securityFeatures').patchValue([]);
    }
  }

  allSelectedFeatures() {
    const features = this.sefetyFeatures.concat(this.secFeatures);
    const codes = this.updateOrgForm
      .get('safetyFeatures')
      ?.value?.concat(this.updateOrgForm.get('securityFeatures').value);

    const selectedFeatures: any = [
      ...features.filter((item) => codes.includes(item.featureCode)),
    ];

    return selectedFeatures;
  }

  getOrganizationById(): void {
    this.sectionState = SectionStateStatus.Loading;
    this.orgService.getOrganizationDetails(this.orgId).subscribe({
      next: (response) => {
        this.orgInfo = response;

        this.builForm(this.orgInfo);
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
  getOrgCardInfo(): void {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.orgService.getOrganizationCardDetails(this.orgId).subscribe({
      next: (response) => {
        this.orgLicenseInfo = response['message'];
        this.setLicenseQuota = true;
        this.updateOrgForm
          .get('OrgQuota')
          .setValue(response['message']?.accessControlLicenseQuota);
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Error;
      },
    });
  }

  updateOrganization(): void {
    const {
      orgName,
      description,
      safetyFeatures,
      orgImage,
      noOfCompanies,
      OrgQuota,
      noOfUsers,
      primary,
      secondary,
      securityFeatures,
    } = this.updateOrgForm.value;
    const formData = new FormData();
    formData.append('file', orgImage[0] ? orgImage[0] : null);
    let organizationData = {
      orgId: this.orgId,
      orgName,
      description,
      features: safetyFeatures.concat(securityFeatures),
      oldImage: this.orgInfo?.organizationImage,
      noOfCompanies,
      noOfUsers,
      themeColor: primary,
      secondaryColor: secondary,
    };
    if (OrgQuota) {
      organizationData['orgQuota'] = OrgQuota;
    }
    formData.append('content', JSON.stringify(organizationData));
    console.log(organizationData);

    this.loadingLabel = 'UpdatingOrganization';
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['organization']['UpdateConfirm']['Title'],
          this.translationsList['organization']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.orgService.editOrganization(formData).subscribe({
            next: (respose) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(
                  FeedbackType.Success,
                  respose?.message.message
                )
              );
              if (!this.isAdmin) {
                this.router.navigate([
                  '/' +
                    SharedConstants.ORGANIZATION_SETUP +
                    '/' +
                    SharedConstants.ORGANIZATIONS,
                ]);
              } else {
                this.router.navigate([
                  '/' +
                    SharedConstants.ORGANIZATION_SETUP +
                    '/' +
                    SharedConstants.VIEW_ORGANIZATION,
                ]);
              }
            },
            error: ({ error }) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
            },
          });
        }
      });
  }
  builForm(response: any): void {
    this.updateOrgForm.get('orgCode')?.setValue(response?.organizationCode);
    this.updateOrgForm.get('orgName')?.setValue(response?.organizationName);
    this.updateOrgForm.get('orgImage')?.setValue([]);
    this.updateOrgForm
      .get('noOfCompanies')
      ?.setValue(response?.noOfEligibleCompanies);
    this.updateOrgForm.get('noOfUsers')?.setValue(response?.noOfEligibleUsers);
    this.updateOrgForm.get('description')?.setValue(response?.description);
    response?.themeColor
      ? this.updateOrgForm.get('primary')?.setValue(response?.themeColor)
      : null;
    response?.secondaryColor
      ? this.updateOrgForm.get('secondary')?.setValue(response?.secondaryColor)
      : null;
    response?.themeColor
      ? this.updateOrgForm.get('primary')?.setValue(response?.themeColor)
      : null;
    response?.secondaryColor
      ? this.updateOrgForm.get('secondary')?.setValue(response?.secondaryColor)
      : null;
    let safetyCodes = response?.features?.find(
      (element) => element['module'] === '0'
    );

    let secCodes = response?.features?.find(
      (element) => element['module'] === '1'
    );
    if (safetyCodes) {
      safetyCodes = safetyCodes['features'].map(
        (feature) => feature.featureCode
      );

      if (safetyCodes.length == this.sefetyFeatures.length) {
        this.allSafetySelected.select();
      }
      this.updateOrgForm.get('safetyFeatures')?.setValue(safetyCodes);
    }

    if (secCodes) {
      secCodes = secCodes['features'].map((feature) => feature.featureCode);
      this.updateOrgForm.get('securityFeatures')?.setValue(secCodes);
      if (secCodes.length == this.secFeatures.length) {
        this.allSecuritySelected.select();
      }
      this.getOrgCardInfo();
    }
    this.selectedFeatureCodes = [...(secCodes || []), ...(safetyCodes || [])];
  }

  cancel(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['organization']['CancelForm']['Title'],
          this.translationsList['organization']['CancelForm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          if (!this.isAdmin) {
            this.router.navigate([
              '/' +
                SharedConstants.ORGANIZATION_SETUP +
                '/' +
                SharedConstants.ORGANIZATIONS,
            ]);
          } else {
            this.router.navigate([
              '/' +
                SharedConstants.ORGANIZATION_SETUP +
                '/' +
                SharedConstants.VIEW_ORGANIZATION,
            ]);
          }
        }
      });
  }
}
