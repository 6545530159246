import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { CompanyStatus, SortDirection } from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { CompanyService } from 'src/backend/services/company.service';
import { Company } from '../../models/company';
import { BaseApi } from 'src/backend/api/base-api';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { AuthService } from 'src/backend/services/auth.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';

@UntilDestroy()
@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class CompaniesComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  companies: Company[];
  columns: TableColumn<Company>[] = [
    { label: 'Image', property: 'companyImage', type: 'image', visible: true },
    {
      label: 'Code',
      property: 'companyCode',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Title',
      property: 'companyName',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    { label: 'Features', property: 'features', type: 'icons', visible: true },
    {
      label: 'Created At',
      property: 'createdOn',
      type: 'date',
      visible: false,
    },
    {
      label: 'Updated At',
      property: 'updatedOn',
      type: 'date',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'NoOfVerifiedUsers',
      property: 'noOfVerifiedUsers',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    {
      label: 'Description',
      property: 'description',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-small'],
    },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<Company>([]);
  selection = new SelectionModel<Company>(true, []);
  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  timer = null;
  loadingLabel: string = '';

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;

  CompanyStatuses = CompanyStatus;
  SharedConstants = SharedConstants;
  sessionOrg: any;

  hasViewPermission = ApplicationPermission.COMPANIES_ACCESS;
  hasCreatePermission = ApplicationPermission.COMPANIES_CREATE;
  hasUpdatePermission = ApplicationPermission.COMPANIES_UPDATE;
  hasDeletePermission = ApplicationPermission.COMPANIES_DELETE;
  hasActivatePermission = ApplicationPermission.COMPANIES_ACTIVATE;

  public selectedCompanyStatus: string[] = [];
  private translationsList: any = {};

  currentQueryParams = null;
  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  constructor(
    private translate: TranslateService,
    private router: Router,
    private companyService: CompanyService,
    private feedBackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService,
    private baseApi: BaseApi,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'Company'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  override ngOnInit(): void {
    this.getSessionOrganization();
    this.dataSource.sort = this.sort;
    this.currentQueryParams = this.route.snapshot.queryParams;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);

    this.pagination.pageIndex = this.currentQueryParams?.pageIndex
      ? Number(this.currentQueryParams?.pageIndex)
      : 0;
    this.pagination.pageSize = this.currentQueryParams?.pageSize
      ? Number(this.currentQueryParams?.pageSize)
      : 10;

    this.selectedCompanyStatus = this.currentQueryParams?.orgStatus
      ? this.currentQueryParams?.orgStatus.split(',')
      : [];

    this.columns.forEach((item) => {
      if (this.currentQueryParams[item.property] === 'true') {
        item.visible = true;
      } else if (this.currentQueryParams[item.property] === 'false') {
        item.visible = false;
      }
    });

    this.sort.active = this.currentQueryParams?.sortName;
    this.sort.direction = this.currentQueryParams?.sortDirection;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        sortName: res.active,
        sortDirection: res.direction,
        pageIndex: 0,
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.currentQueryParams,
      });
      this.pagination.pageIndex = 0;
      this.getData();
    });

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getData();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  getSessionOrganization(): void {
    this.sessionOrg = this.baseApi.getOrganizationFromStorage();
  }

  getData() {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }
    const filteredParams = {
      sortDirection: this.currentQueryParams?.sortDirection || '',
      sortField,
      pageIndex: this.currentQueryParams?.pageIndex
        ? this.currentQueryParams?.pageIndex
        : this.pagination.pageIndex,
      pageSize: this.currentQueryParams?.pageSize
        ? this.currentQueryParams?.pageSize
        : this.pagination.pageSize,
      searchValue: this.currentQueryParams?.searchValue
        ? this.currentQueryParams?.searchValue
        : this.searchValue,
      status: this.currentQueryParams?.orgStatus,
    };
    this.loadingLabel = 'General.Refreshing';
    const organizationId = this.sessionOrg?.orgId ? this.sessionOrg?.orgId : 0;
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.companyService
      .getCompaniesByOrg(filteredParams, organizationId)
      .subscribe({
        next: (response) => {
          this.companies = response?.data;
          this.dataSource = new MatTableDataSource(response?.data);
          this.pagination.length = response?.totalCount;
          this.dataSource.sort = this.sort;
          this.sectionState = SectionStateStatus.Ready;
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
  }

  onSelectCompanyStatus(event: MatSelectChange) {
    this.selectedCompanyStatus = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgStatus: event.value.toString(),
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      searchValue: this.searchValue,
      pageIndex: 0,
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.pagination.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      [column.property]: column.visible,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  deactivateCompany(event: any, compId: number): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['Company']['StatusConfirm']['Title'],
          this.translationsList['Company']['StatusConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'Company.DeactivatingCompany';
          this.sectionState = SectionStateStatus.Loading;
          this.companyService.deactivateCompany(compId).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }
  activateCompany(event: any, compId: number): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['Company']['StatusConfirm']['Title'],
          this.translationsList['Company']['StatusConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'Company.ActivatingCompany';
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.companyService.activateCompany(compId).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  deleteCompany(event: any, compId: number): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['Company']['StatusConfirm']['Title'],
          this.translationsList['Company']['StatusConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'Deleting Company';
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.companyService.deleteCompany(compId).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  viewCompany(event: any, orgCode: number): void {
    this.router.navigate(
      [
        '/' +
          SharedConstants.COMPANY_SETUP +
          '/' +
          SharedConstants.COMPANY_VIEW +
          '/' +
          orgCode,
      ],
      { queryParams: { ...this.currentQueryParams } }
    );
  }

  createCompany() {
    const orgId = this.sessionOrg.orgId;

    this.router.navigate(
      [
        '/' +
          SharedConstants.COMPANY_SETUP +
          '/' +
          SharedConstants.COMPANY_CREATE,
      ],

      {
        queryParams: {
          id: orgId,
          ...this.currentQueryParams,
        },
      }
    );
  }

  updateCompany(event: any, compId: number) {
    this.router.navigate(
      [
        '/' +
          SharedConstants.COMPANY_SETUP +
          '/' +
          SharedConstants.COMPANY_UPDATE +
          '/' +
          compId,
      ],
      { queryParams: { ...this.currentQueryParams } }
    );
  }
  LoginAs(event: any, compId: any): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['Company']['LoginConfirm']['Title'],
          this.translationsList['Company']['LoginConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          const obj = {
            CompanyCode: compId,
          };
          this.loadingLabel = 'Company.LoginAs';
          this.authService.signInWithOutPassword(obj);
        }
      });
  }
  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
}
