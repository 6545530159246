<a
  *ngIf="isLink(item) && !isFunction(item.route)"
  [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
  [routerLink]="item.route"
  class="hodhod-sidenav-item"
  matRipple
  matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="hodhod-sidenav-item--active"
>
  <mat-icon
    *ngIf="level === 0"
    [svgIcon]="item.icon"
    [matTooltip]="tooltipText() | translate"
    [matTooltipDisabled]="!tooltipText()"
    [matTooltipPosition]="'right'"
    class="hodhod-sidenav-item__icon"
  ></mat-icon>
  <span class="hodhod-sidenav-item__label">{{
    "NavigationBar." + item.label | translate
  }}</span>
  <span
    *ngIf="item.badge"
    [ngClass]="[item.badge.bgClass, item.badge.textClass]"
    class="hodhod-sidenav-item__badge"
    >{{ item.badge.value }}</span
  >
</a>

<div
  (click)="item.route()"
  *ngIf="isLink(item) && isFunction(item.route)"
  class="hodhod-sidenav-item"
  matRipple
  matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="hodhod-sidenav-item--active"
>
  <mat-icon
    *ngIf="level === 0"
    [svgIcon]="item.icon"
    class="hodhod-sidenav-item__icon"
  ></mat-icon>
  <span class="hodhod-sidenav-item__label">{{
    "NavigationBar." + item.label | translate
  }}</span>
  <span
    *ngIf="item.badge"
    [ngClass]="[item.badge.bgClass, item.badge.textClass]"
    class="hodhod-sidenav-item__badge"
    >{{ item.badge.value }}</span
  >
</div>

<ng-container *ngIf="isDropdown(item)">
  <div
    (click)="toggleOpen()"
    [class.hodhod-sidenav-item--active]="isOpen || isActive"
    [class.hodhod-sidenav-item--open]="isOpen"
    class="hodhod-sidenav-item myList"
    matRipple
    matRippleColor="var(--sidenav-item-ripple-color)"
  >
    <mat-icon
      *ngIf="level === 0"
      [svgIcon]="item.icon"
      [matTooltip]="tooltipText() | translate"
      [matTooltipPosition]="'right'"
      [matTooltipDisabled]="!tooltipText()"
      class="hodhod-sidenav-item__icon"
    ></mat-icon>
    <span class="hodhod-sidenav-item__label">{{
      "NavigationBar." + item.label | translate
    }}</span>
    <span
      *ngIf="item.badge"
      [ngClass]="[item.badge.bgClass, item.badge.textClass]"
      class="hodhod-sidenav-item__badge"
      >{{ item.badge.value }}</span
    >
    <mat-icon
      class="hodhod-sidenav-item__dropdown-icon"
      svgIcon="mat:keyboard_arrow_down"
    ></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="hodhod-sidenav-item__dropdown">
    <hodhod-sidenav-item
      *ngFor="let subItem of item.children"
      [item]="subItem"
      [level]="level + 1"
    ></hodhod-sidenav-item>
  </div>
</ng-container>

<ng-container *ngIf="isSubheading(item)">
  <!-- <div class="hodhod-sidenav-subheading">{{ 'NavigationBar.'+ item.label | translate}}</div> -->
  <hodhod-sidenav-item
    *ngFor="let subItem of item.children"
    [item]="subItem"
    [level]="0"
  ></hodhod-sidenav-item>
</ng-container>
