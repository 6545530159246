<div class="flex column gap-2">
  <div class="w-full">
    <app-card [title]="'Subscription.organizationSummary.title' | translate">
      <div body class="flex justify-between flex-wrap-reverse">
        <div class="flex-1">
          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">
                {{ organizationSummary?.orgnizationName }}
              </p>
              <p class="m-0 caption text-hint">
                {{ "Subscription.organizationSummary.orgName" | translate }}
              </p>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">
                {{ organizationSummary?.organizationId }}
              </p>
              <p class="m-0 caption text-hint">
                {{ "Subscription.organizationSummary.orgId" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </app-card>
  </div>
  <div class="w-full">
    <app-card [title]="'Subscription.mobileIdentities.title' | translate">
      <div body class="flex justify-between flex-wrap-reverse">
        <div class="flex-1">
          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">
                {{ mobileIdentities?.mobileKeySet }}
              </p>
              <p class="m-0 caption text-hint">
                {{ "Subscription.mobileIdentities.mobileKeySet" | translate }}
              </p>
            </div>
          </div>
        </div>
        <div class="flex-1"></div>
      </div>
    </app-card>
  </div>
  <div class="w-full">
    <app-card [title]="'Subscription.title' | translate">
      <div body>
        <div class="flex justify-between flex-wrap-reverse">
          <div class="flex-1">
            <div class="py-3 flex items-center">
              <div
                @scaleIn
                class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
              >
                <mat-icon
                  class="icon-sm"
                  svgIcon="mat:keyboard_double_arrow_right"
                ></mat-icon>
              </div>

              <div @fadeInRight>
                <p class="m-0 body-1">
                  {{ subscription?.subscriptionStatus }}
                </p>
                <p class="m-0 caption text-hint">
                  {{ "Subscription.subscriptionStatus" | translate }}
                </p>
              </div>
            </div>
            <div class="py-3 flex items-center">
              <div
                @scaleIn
                class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
              >
                <mat-icon
                  class="icon-sm"
                  svgIcon="mat:keyboard_double_arrow_right"
                ></mat-icon>
              </div>

              <div @fadeInRight>
                <p class="m-0 body-1">
                  {{ subscription?.active }}
                </p>
                <p class="m-0 caption text-hint">
                  {{ "Subscription.active" | translate }}
                </p>
              </div>
            </div>
            <div class="py-3 flex items-center">
              <div
                @scaleIn
                class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
              >
                <mat-icon
                  class="icon-sm"
                  svgIcon="mat:keyboard_double_arrow_right"
                ></mat-icon>
              </div>

              <div @fadeInRight>
                <p class="m-0 body-1">
                  {{ subscription?.totalAddedUsers }}
                </p>
                <p class="m-0 caption text-hint">
                  {{ "Subscription.totalAddedUsers" | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="flex-1">
            <div class="py-3 flex items-center">
              <div
                @scaleIn
                class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
              >
                <mat-icon
                  class="icon-sm"
                  svgIcon="mat:keyboard_double_arrow_right"
                ></mat-icon>
              </div>

              <div @fadeInRight>
                <p class="m-0 body-1">
                  {{ subscription?.total }}
                </p>
                <p class="m-0 caption text-hint">
                  {{ "Subscription.total" | translate }}
                </p>
              </div>
            </div>
            <div class="py-3 flex items-center">
              <div
                @scaleIn
                class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
              >
                <mat-icon
                  class="icon-sm"
                  svgIcon="mat:keyboard_double_arrow_right"
                ></mat-icon>
              </div>

              <div @fadeInRight>
                <p class="m-0 body-1">
                  {{ subscription?.available }}
                </p>
                <p class="m-0 caption text-hint">
                  {{ "Subscription.available" | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4">

          <app-expandable-panel
            [expanded]="true"
            #expandablePanel
            [title]="'Subscription.subscriptionHistory.title' | translate"
          >
          <div class="card overflow-visible" body>
            <app-state-section
              body
              [flexibleHeight]="true"
              [state]="sectionState"
              [loadingLabel]="loadingLabel | translate"
              [errorLabel]="'Errors.ErrorHappened' | translate"
            >
            <table
            body
            @stagger
            [dataSource]="dataSource"
            class="w-full"
            mat-table
            matSort
          >
            <ng-container
              *ngFor="let column of columns; trackBy: trackByProperty"
            >
              <ng-container
                *ngIf="column.type === 'text'"
                [matColumnDef]="column.property"
              >
                <th
                  *matHeaderCellDef
                  class="uppercase"
                  mat-header-cell
                  mat-sort-header
                >
                  {{ "Subscription.subscriptionHistory." + column.label | translate }}
                </th>
                <td
                  *matCellDef="let row"
                  [ngClass]="column.cssClasses"
                  mat-cell
                >
                  {{ row[column.property] }}
                  <span
                    *ngIf="
                      column.property === 'createdBy' && row?.isDefault
                    "
                    class="bg-primary text-white rounded-full p-1 font-normal px-3"
                    >Default</span
                  >
                </td>
              </ng-container>
              <ng-container
                *ngIf="
                  column.type === 'boolean' && column.property === 'status'
                "
                [matColumnDef]="column.property"
              >
                <th
                  *matHeaderCellDef
                  class="uppercase"
                  mat-header-cell
                  mat-sort-header
                >
                  {{ "Subscription.subscriptionHistory." + column.label | translate }}
                </th>
                <td
                  *matCellDef="let row"
                  [ngClass]="column.cssClasses"
                  mat-cell
                >
                {{row[column.property]}}
                </td>
              </ng-container>
              <ng-container
                *ngIf="column.type === 'date'"
                [matColumnDef]="column.property"
              >
                <th
                  *matHeaderCellDef
                  class="uppercase"
                  mat-header-cell
                  mat-sort-header
                >
                  {{"Subscription.subscriptionHistory." + column.label | translate }}
                </th>
                <td
                  *matCellDef="let row"
                  [ngClass]="column.cssClasses"
                  mat-cell
                >
                  {{ row[column.property] |date}}
                </td>
              </ng-container>
            </ng-container>

            <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
            <tr
              *matRowDef="let row; columns: visibleColumns"
              @fadeInUp
              class="hover:bg-hover trans-ease-out cursor-pointer"
              mat-row
            ></tr>
          </table>
          <div
                *ngIf="dataSource?.filteredData?.length === 0"
                @scaleFadeIn
                class="flex-auto flex flex-col items-center justify-center"
              >
                <img src="assets/img/resources/empty-state.svg" alt="not found" />
                <h2 class="headline m-0 text-center text-primary">
                  {{ "General.EmptyTableList" | translate }}...
                </h2>
              </div>
          
            </app-state-section>
          </div>
          </app-expandable-panel>
        </div>
      </div>
    </app-card>
  </div>
</div>
