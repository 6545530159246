<app-state-section
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="'' | translate"
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <div class="mb-2">
    <!-- Header -->
    <div class="flex justify-between">
      <h1>
        {{
          msdsForm.get("materialName").value
            ? msdsForm.get("materialName").value
            : ("MSDS.NewMSDS" | translate)
        }}
      </h1>
      <div
        class="flex column"
        *ngIf="data.formMode == 'edit' || data.formMode == 'view'"
      >
        <h6>
          {{ "MSDS.LastUpdate" | translate }}:{{
            selectedMsds?.updatedOn | date : "dd-MM-yyyy - hh:mm a"
          }}
        </h6>
        <h6>
          {{ "MSDS.UpdatedBy" | translate }}:{{ selectedMsds?.updatedBy }}
        </h6>
      </div>
    </div>
    <hr />

    <div>
      <div
        class="flex mt-2 justify-end"
        *ngIf="
          ((data.formMode == 'view' || data.formMode == 'edit') &&
            selectedMsds?.status == 'PUBLISHED') ||
          (selectedMsds?.status == 'REJECTED' &&
            selectedMsds.creator === loggedUser.userId)
        "
      >
        <div
          *ngIf="selectedMsds?.status == 'PUBLISHED'"
          (click)="generateQrCode()"
        >
          <img
            class="cursor-pointer"
            src="assets/img/resources/qrcode-icon.png"
            width="30px"
            height="30px"
          />
        </div>
        <button
          *ngIf="
            ((hasUpdatePermission | permissionChecker) &&
              (data.formMode == 'view' || data.formMode == 'edit') &&
              selectedMsds?.status == 'PUBLISHED') ||
            (selectedMsds?.status == 'REJECTED' &&
              selectedMsds.creator === loggedUser.userId)
          "
          class="mx-2"
          (click)="enableEditMsds()"
          [disabled]="data.formMode === 'edit'"
        >
          <img
            [class.cursor-pointer]="data.formMode !== 'edit'"
            [class.gray-image]="data.formMode === 'edit'"
            src="assets/img/resources/edit-icon.png"
            width="30px"
            height="30px"
          />
        </button>
        <a
          *ngIf="selectedMsds?.status == 'PUBLISHED'"
          [href]="selectedMsds?.downloadPdf"
        >
          <img
            class="cursor-pointer"
            src="assets/img/resources/download-icon.png"
            width="30px"
            height="30px"
          />
        </a>
      </div>

      <!-- Publish Actions -->
      <div
        class="flex mt-2 justify-end"
        *ngIf="
          data.formMode == 'view' &&
          (hasApproveRejectPermission | permissionChecker) &&
          (hasReviewerAccessPermission | permissionChecker)
        "
      >
        <button
          *ngIf="selectedMsds?.status == 'IN_REVIEW'"
          [matTooltip]="'Add Additional Approvers'"
          mat-button
          class="bg-[#000] text-[#fff] min-w-[5px] h-[30px] rounded-[12px]"
          (click)="startAddAdditionalApprovers()"
        >
          <mat-icon svgIcon="mat:add"></mat-icon>
        </button>

        <button
          *ngIf="
            selectedMsds?.status == 'IN_REVIEW' ||
            (selectedMsds?.status == 'APPROVED' &&
              selectedMsds?.additionalApproverRequester === loggedUser.userId)
          "
          [matTooltip]="'Reject'"
          mat-button
          class="bg-[#C53635] text-[#fff] min-w-[5px] h-[30px] rounded-[12px] mx-1"
          (click)="openRejectModal()"
        >
          <mat-icon svgIcon="mat:close"></mat-icon>
        </button>

        <button
          *ngIf="
            selectedMsds?.status == 'IN_REVIEW' ||
            (selectedMsds?.status == 'APPROVED' &&
              selectedMsds?.additionalApproverRequester === loggedUser.userId)
          "
          [matTooltip]="'Publish'"
          mat-button
          class="bg-[#00af41] text-[#fff] min-w-[5px] h-[30px] rounded-[12px]"
          (click)="startPublish()"
        >
          <mat-icon svgIcon="mat:check"></mat-icon>
        </button>
      </div>

      <!-- Submit Publish Actions -->
      <div class="flex mt-2 justify-end">
        <app-basic-button
          *ngIf="data.formMode == 'publish'"
          [btnType]="'Add'"
          [btnText]="'Publish'"
          [isDisabled]="!msdsForm.valid"
          (click)="takePrint()"
        >
        </app-basic-button>
        <app-basic-button
          *ngIf="data.formMode == 'additionalUser'"
          [btnType]="'Add'"
          [btnText]="'Submit'"
          [isDisabled]="!msdsForm.valid"
          (click)="addAdditionalUsers()"
        >
        </app-basic-button>
      </div>

      <!-- Approve Buttons -->
      <div
        class="flex mt-2 justify-end"
        *ngIf="
          data.formMode == 'view' &&
          selectedMsds?.status == 'PENDING_APPROVE' &&
          checkApprove()
        "
      >
        <button
          [matTooltip]="'Reject'"
          mat-button
          class="bg-[#C53635] text-[#fff] min-w-[5px] h-[30px] rounded-[12px] mx-1"
          (click)="openRejectModal()"
        >
          <mat-icon svgIcon="mat:close"></mat-icon>
        </button>

        <button
          [matTooltip]="'Approve'"
          mat-button
          class="bg-[#00af41] text-[#fff] min-w-[5px] h-[30px] rounded-[12px]"
          (click)="approveMsds()"
        >
          <mat-icon svgIcon="mat:check"></mat-icon>
        </button>
      </div>

      <app-dismiss-alert
        *ngIf="
          selectedMsds?.status === 'REJECTED' &&
          selectedMsds.creator == loggedUser.userId
        "
        [message]="
          selectedMsds?.comment
            ? 'Rejected: ' + selectedMsds?.comment
            : 'Rejected'
        "
      ></app-dismiss-alert>

      <form class="mt-2 flex column" [formGroup]="msdsForm">
        <mat-accordion>
          <!-- Hazard Identification -->
          <app-msds-create-step
            [expanded]="hazardIdentificationPanel"
            [title]="'MSDS.HazardIdentification' | translate"
            [checked]="msdsForm.valid || msdsForm.disabled"
          >
            <div
              class="flex flex-wrap justify-between"
              body
              [style.marginTop]="'10px'"
            >
              <!-- first row -->
              <div class="field flex column">
                <div class="form-field flex column">
                  <mat-label class="label field-label">
                    {{ "MSDS.Form.MaterialCode" | translate }}
                    <span
                      [ngClass]="
                        msdsForm.invalid &&
                        msdsForm.get('materialCode').hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>

                  <mat-form-field class="input-field" appearance="outline">
                    <input
                      [placeholder]="'Placeholder.AddText' | translate"
                      matInput
                      type="text"
                      formControlName="materialCode"
                      maxlength="65"
                      [readonly]="data.formMode == 'view'"
                    />
                    <mat-error>
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="field flex column">
                <div class="form-field flex column">
                  <mat-label class="label field-label">
                    {{ "MSDS.Form.MaterialName" | translate }}
                    <span
                      [ngClass]="
                        msdsForm.invalid &&
                        msdsForm.get('materialName').hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>

                  <mat-form-field class="input-field" appearance="outline">
                    <input
                      [placeholder]="'Placeholder.AddText' | translate"
                      matInput
                      type="text"
                      maxlength="65"
                      formControlName="materialName"
                      [readonly]="data.formMode == 'view'"
                    />
                    <mat-error>
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <!-- second row -->
              <div
                class="field flex column"
                *ngIf="data.formMode !== 'create' && data.formMode !== 'edit'"
              >
                <div class="form-field flex column">
                  <mat-label class="label field-label">
                    {{ "MSDS.Form.Department" | translate }}
                    <span
                      [ngClass]="
                        msdsForm.invalid &&
                        msdsForm.get('departmentCode').hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <mat-select
                      ngxMatSelect
                      #msf1="ngxMatSelect"
                      [displayMember]="'departmentName'"
                      [valueMember]="'departmentId'"
                      [source]="departments"
                      [useInfiniteScroll]="true"
                      [multiple]="false"
                      [hasPermission]="
                        hasCreateDepartmentPermission | permissionChecker
                      "
                      [title]="'departments'"
                      [hasSearchBox]="false"
                      [placeholder]="'Placeholder.Select' | translate"
                      [manageRoute]="'/company-setup/departments'"
                      formControlName="departmentCode"
                      (selectionChange)="onChangeDepartment($event)"
                    >
                      <mat-option
                        *ngIf="msf1.filteredSource.length === 0"
                        style="height: 0"
                      >
                      </mat-option>

                      <mat-optgroup
                        *ngFor="let group of msf1.filteredSource"
                        [label]="group.parentName"
                      >
                        <mat-option
                          *ngFor="let detail of group.detail"
                          [value]="detail.departmentCode"
                        >
                          {{ detail.departmentName }}
                        </mat-option>
                      </mat-optgroup>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        msdsForm.invalid &&
                        msdsForm.get('departmentCode').hasError('required')
                      "
                    >
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <!-- Sub department -->
              <div
                class="field flex column"
                *ngIf="
                  subDepartments.length > 0 &&
                  data.formMode !== 'create' &&
                  data.formMode !== 'edit'
                "
              >
                <mat-label class="field-label">{{
                  "AddReportHazard.SubDepartment" | translate
                }}</mat-label>
                <mat-form-field class="input-field" appearance="outline">
                  <mat-select
                    [title]="'departments'"
                    [placeholder]="'Placeholder.Select' | translate"
                    formControlName="subDepartment"
                  >
                    <mat-option
                      *ngFor="let dep of subDepartments"
                      [value]="dep.code"
                    >
                      {{ dep.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div
                class="field flex column"
                *ngIf="
                  data.formMode == 'additionalUser' ||
                  selectedMsds?.status == 'PENDING_APPROVE'
                "
              >
                <div class="form-field flex column">
                  <mat-label class="label field-label">
                    {{ "MSDS.Form.AdditionalApprovers" | translate }}
                    <span
                      [ngClass]="
                        msdsForm.invalid &&
                        msdsForm.get('PendingApprovers').hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>

                  <mat-form-field class="input-field" appearance="outline">
                    <mat-select
                      ngxMatSelect
                      #msf2="ngxMatSelect"
                      [displayMember]="'fullName'"
                      [valueMember]="'userId'"
                      [source]="approversList"
                      [useInfiniteScroll]="true"
                      [multiple]="false"
                      [title]="'approvers'"
                      [hasSearchBox]="true"
                      [multiple]="true"
                      [placeholder]="'Placeholder.Select' | translate"
                      formControlName="PendingApprovers"
                    >
                      <mat-option
                        *ngIf="msf2.filteredSource.length === 0"
                        style="height: 0"
                      >
                      </mat-option>

                      <mat-option
                        *ngFor="let detail of msf2.filteredSource"
                        [value]="detail.userId"
                      >
                        {{ detail.approverName }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        msdsForm.invalid &&
                        msdsForm.get('departmentCode').hasError('required')
                      "
                    >
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div
                class="field flex column"
                *ngIf="
                  (data.formMode == 'view' || data.formMode == 'publish') &&
                  selectedMsds?.status != 'PENDING_APPROVE'
                "
              >
                <div class="form-field flex column">
                  <mat-label class="label field-label">
                    {{ "MSDS.Form.StorageLocation" | translate }}
                    <span
                      [ngClass]="
                        msdsForm.invalid &&
                        msdsForm.get('storageLocation').hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>

                  <mat-form-field class="input-field" appearance="outline">
                    <input
                      [placeholder]="'Placeholder.AddText' | translate"
                      matInput
                      type="text"
                      maxlength="100"
                      formControlName="storageLocation"
                    />
                    <mat-error>
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <!-- third row -->
              <div class="field flex column" [style.width.%]="100">
                <div class="form-field flex column">
                  <mat-label class="label field-label">
                    {{ "MSDS.Form.MaterialDescription" | translate }}
                    <span
                      [ngClass]="
                        msdsForm.invalid &&
                        msdsForm.get('materialDescription').hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>

                  <mat-form-field class="input-field" appearance="outline">
                    <textarea
                      matInput
                      rows="5"
                      formControlName="materialDescription"
                      [readonly]="data.formMode == 'view'"
                      [placeholder]="'Placeholder.AddText' | translate"
                      matInput
                    >
                    </textarea>
                    <mat-error>
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <!-- hazard identifications -->
              <div [style.width.%]="100">
                <mat-label class="label field-label mb-2">
                  {{ "MSDS.HazardIdentification" | translate }}
                </mat-label>

                <div class="flex justify-between align-center">
                  <div class="flex column" [style.width.%]="100">
                    <div class="flex align-center justify-between">
                      <img src="/assets/img/resources/flammable.png" />
                      <span [style.width.%]="100">{{
                        "MSDS.Enum.Flammable" | translate
                      }}</span>
                      <div class="flex align-center" [style.width.%]="100">
                        <button
                          class="hazard-btn"
                          (click)="toggleLess('Flammable')"
                          [disabled]="data.formMode == 'view'"
                        >
                          <mat-icon
                            [svgIcon]="
                              rtl === 'ar'
                                ? 'mat:keyboard_arrow_right'
                                : 'mat:keyboard_arrow_left'
                            "
                          ></mat-icon>
                        </button>

                        <input
                          class="hazard-input"
                          min="0"
                          max="4"
                          formControlName="flammable"
                          [placeholder]="'Placeholder.AddText' | translate"
                          matInput
                          readonly
                        />

                        <button
                          class="hazard-btn plus-btn"
                          (click)="toggleMore('Flammable')"
                          [disabled]="data.formMode == 'view'"
                        >
                          <mat-icon
                            [svgIcon]="
                              rtl === 'ar'
                                ? 'mat:keyboard_arrow_left'
                                : 'mat:keyboard_arrow_right'
                            "
                          ></mat-icon>
                        </button>
                        <img
                          src="/assets/img/resources/info-icon.png"
                          class="mx-2"
                          [matTooltip]="
                            'MSDS.FlammableDetails.' +
                              msdsForm.get('flammable').value | translate
                          "
                          [matTooltipPosition]="'right'"
                        />
                      </div>
                    </div>

                    <div class="flex align-center justify-between">
                      <img src="/assets/img/resources/reactive.png" />
                      <span [style.width.%]="100">{{
                        "MSDS.Enum.Reactive" | translate
                      }}</span>
                      <div class="flex align-center" [style.width.%]="100">
                        <button
                          class="hazard-btn"
                          (click)="toggleLess('Reactive')"
                          [disabled]="data.formMode == 'view'"
                        >
                          <mat-icon
                            [svgIcon]="
                              rtl === 'ar'
                                ? 'mat:keyboard_arrow_right'
                                : 'mat:keyboard_arrow_left'
                            "
                          ></mat-icon>
                        </button>

                        <input
                          class="hazard-input"
                          min="0"
                          max="4"
                          formControlName="reactive"
                          [placeholder]="'Placeholder.AddText' | translate"
                          matInput
                          readonly
                        />

                        <button
                          class="hazard-btn plus-btn"
                          (click)="toggleMore('Reactive')"
                          [disabled]="data.formMode == 'view'"
                        >
                          <mat-icon
                            [svgIcon]="
                              rtl === 'ar'
                                ? 'mat:keyboard_arrow_left'
                                : 'mat:keyboard_arrow_right'
                            "
                          ></mat-icon>
                        </button>
                        <img
                          src="/assets/img/resources/info-icon.png"
                          class="mx-2"
                          [matTooltip]="
                            'MSDS.ReactiveDetails.' +
                              msdsForm.get('reactive').value | translate
                          "
                          [matTooltipPosition]="'right'"
                        />
                      </div>
                    </div>

                    <div class="flex align-center justify-between">
                      <img src="/assets/img/resources/health-hazard.png" />
                      <span [style.width.%]="100">{{
                        "MSDS.Enum.HealthHazard" | translate
                      }}</span>
                      <div class="flex align-center" [style.width.%]="100">
                        <button
                          class="hazard-btn"
                          (click)="toggleLess('HealthHazard')"
                          [disabled]="data.formMode == 'view'"
                        >
                          <mat-icon
                            [svgIcon]="
                              rtl === 'ar'
                                ? 'mat:keyboard_arrow_right'
                                : 'mat:keyboard_arrow_left'
                            "
                          ></mat-icon>
                        </button>

                        <input
                          class="hazard-input"
                          min="0"
                          max="4"
                          formControlName="healthHazard"
                          [placeholder]="'Placeholder.AddText' | translate"
                          matInput
                          readonly
                        />

                        <button
                          class="hazard-btn plus-btn"
                          (click)="toggleMore('HealthHazard')"
                          [disabled]="data.formMode == 'view'"
                        >
                          <mat-icon
                            [svgIcon]="
                              rtl === 'ar'
                                ? 'mat:keyboard_arrow_left'
                                : 'mat:keyboard_arrow_right'
                            "
                          ></mat-icon>
                        </button>
                        <img
                          src="/assets/img/resources/info-icon.png"
                          class="mx-2"
                          [matTooltip]="
                            'MSDS.HealthHazardDetails.' +
                              msdsForm.get('healthHazard').value | translate
                          "
                          [matTooltipPosition]="'right'"
                        />
                      </div>
                    </div>

                    <div class="flex justify-between">
                      <div
                        class="flex column justify-between"
                        [style.width.%]="100"
                      >
                        <div class="flex align-center">
                          <img src="/assets/img/resources/special-hazard.png" />
                          <span style="flex-grow: 0.3">{{
                            "MSDS.Enum.SpecialHazard" | translate
                          }}</span>
                          <mat-form-field
                            style="width: 210px !important"
                            class="table-input-field"
                            appearance="outline"
                          >
                            <mat-select
                              [placeholder]="'Placeholder.Select' | translate"
                              formControlName="specialHazard"
                            >
                              <mat-option
                                *ngFor="
                                  let hazard of SpecialHazardSymbols
                                    | enumToArray
                                "
                                [value]="hazard.value"
                              >
                                {{
                                  "MSDS.SpecialHazard." + hazard.value
                                    | translate
                                }}
                              </mat-option>
                            </mat-select>
                            <div></div>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Hazard Square -->
                  <div>
                    <div class="flex" [style.width.%]="100">
                      <div class="hazard-square mx-5" dir="auto">
                        <div class="flex w-100">
                          <div
                            class="hazard-sub-square"
                            [style.backgroundColor]="'#C53635'"
                          >
                            <span>{{ msdsForm.get("flammable").value }}</span>
                          </div>
                          <div
                            class="hazard-sub-square"
                            [style.backgroundColor]="'#F5CE47'"
                          >
                            <span>{{ msdsForm.get("reactive").value }}</span>
                          </div>
                        </div>
                        <div class="flex w-100">
                          <div
                            class="hazard-sub-square"
                            [style.backgroundColor]="'#245890'"
                          >
                            <span>{{
                              msdsForm.get("healthHazard").value
                            }}</span>
                          </div>
                          <div
                            class="hazard-sub-square"
                            [style.backgroundColor]="'#FFFFFF'"
                          >
                            <img
                              class="square-img"
                              *ngIf="
                                msdsForm.get('specialHazard').value !== 'None'
                              "
                              [src]="
                                'assets/img/resources/' +
                                msdsForm.get('specialHazard').value +
                                '-icon.svg'
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="flex justify-end"
                [style.width.%]="100"
                *ngIf="data.formMode !== 'view'"
              >
                <app-basic-button
                  [btnType]="'Add'"
                  [btnText]="'Next'"
                  (click)="
                    openNextPanel(hazardIdentificationPanel, firstAidPanel)
                  "
                ></app-basic-button>
              </div>
            </div>
          </app-msds-create-step>

          <!-- First Aid -->
          <app-msds-create-step
            [title]="'MSDS.FirstAid' | translate"
            [checked]="msdsForm.get('firstAid').value != ''"
            [expanded]="firstAidPanel"
            [optional]="true"
          >
            <div body>
              <div class="field flex column" [style.width.%]="100">
                <div class="form-field flex column">
                  <mat-form-field class="input-field mt-1" appearance="outline">
                    <textarea
                      matInput
                      rows="5"
                      formControlName="firstAid"
                      [readonly]="data.formMode == 'view'"
                      [placeholder]="'Placeholder.AddText' | translate"
                      matInput
                    >
                    </textarea>
                    <mat-error>
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div
                class="flex justify-end"
                [style.width.%]="100"
                *ngIf="data.formMode !== 'view'"
              >
                <app-basic-button
                  [btnType]="'Add'"
                  [btnText]="'Next'"
                  (click)="
                    openNextPanel(firstAidPanel, fireFightingMeasuresPanel)
                  "
                ></app-basic-button>
              </div>
            </div>
          </app-msds-create-step>

          <!-- Fire Fighting Measures -->
          <app-msds-create-step
            [title]="'MSDS.FireFightingMeasures' | translate"
            [checked]="msdsForm.get('fireFightingMeasures').value != ''"
            [expanded]="fireFightingMeasuresPanel"
            [optional]="true"
          >
            <div body>
              <div class="field flex column" [style.width.%]="100">
                <div class="form-field flex column">
                  <mat-form-field class="input-field mt-1" appearance="outline">
                    <textarea
                      matInput
                      rows="5"
                      formControlName="fireFightingMeasures"
                      [readonly]="data.formMode == 'view'"
                      [placeholder]="'Placeholder.AddText' | translate"
                      matInput
                    >
                    </textarea>
                    <mat-error>
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div
                class="flex justify-end"
                [style.width.%]="100"
                *ngIf="data.formMode !== 'view'"
              >
                <app-basic-button
                  [btnType]="'Add'"
                  [btnText]="'Next'"
                  (click)="
                    openNextPanel(
                      fireFightingMeasuresPanel,
                      measuresInCaseOfSpillagePanel
                    )
                  "
                ></app-basic-button>
              </div>
            </div>
          </app-msds-create-step>

          <!-- Measures In Case Of Spillage -->
          <app-msds-create-step
            [title]="'MSDS.MeasuresInCaseOfSpillage' | translate"
            [checked]="msdsForm.get('measuresInCaseOfSpillage').value != ''"
            [expanded]="measuresInCaseOfSpillagePanel"
            [optional]="true"
          >
            <div body>
              <div class="field flex column" [style.width.%]="100">
                <div class="form-field flex column">
                  <mat-form-field class="input-field mt-1" appearance="outline">
                    <textarea
                      matInput
                      rows="5"
                      formControlName="measuresInCaseOfSpillage"
                      [readonly]="data.formMode == 'view'"
                      [placeholder]="'Placeholder.AddText' | translate"
                      matInput
                    >
                    </textarea>
                    <mat-error>
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div
                class="flex justify-end"
                [style.width.%]="100"
                *ngIf="data.formMode !== 'view'"
              >
                <app-basic-button
                  [btnType]="'Add'"
                  [btnText]="'Next'"
                  (click)="
                    openNextPanel(
                      measuresInCaseOfSpillagePanel,
                      handlingAndStoragePanel
                    )
                  "
                ></app-basic-button>
              </div>
            </div>
          </app-msds-create-step>

          <!-- Handling and storage -->
          <app-msds-create-step
            [title]="'MSDS.HandlingAndStorage' | translate"
            [checked]="msdsForm.get('handlingAndStorage').value != ''"
            [expanded]="handlingAndStoragePanel"
            [optional]="true"
          >
            <div body>
              <div class="field flex column" [style.width.%]="100">
                <div class="form-field flex column">
                  <mat-form-field class="input-field mt-1" appearance="outline">
                    <textarea
                      matInput
                      rows="5"
                      formControlName="handlingAndStorage"
                      [readonly]="data.formMode == 'view'"
                      [placeholder]="'Placeholder.AddText' | translate"
                      matInput
                    >
                    </textarea>
                    <mat-error>
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div
                class="flex justify-end"
                [style.width.%]="100"
                *ngIf="data.formMode !== 'view'"
              >
                <app-basic-button
                  [btnType]="'Add'"
                  [btnText]="'Next'"
                  (click)="openNextPanel(handlingAndStoragePanel, ppeIdsPanel)"
                ></app-basic-button>
              </div>
            </div>
          </app-msds-create-step>

          <!-- Personal Protective Equipment (PPE) -->
          <app-msds-create-step
            [title]="'MSDS.PPE' | translate"
            [checked]="selectedPpes.length > 0"
            [expanded]="ppeIdsPanel"
            [optional]="true"
          >
            <div body>
              <div class="field flex column" [style.width.%]="100">
                <div class="form-field flex column">
                  <mat-form-field class="w-full" appearance="outline">
                    <mat-chip-list #chipGrid aria-label="ppe selection">
                      <mat-chip
                        *ngFor="let ppe of selectedPpes"
                        (removed)="
                          data.formMode !== 'view' ? removePpe(ppe) : ''
                        "
                      >
                        <ngx-avatars
                          matChipAvatar
                          class="logo box"
                          size="32"
                          [src]="ppe.imageUrl"
                          [name]="ppe.ppeName"
                          [textSizeRatio]="2"
                          [round]="true"
                          [initialsSize]="1"
                          class="py-2"
                        ></ngx-avatars>
                        {{ ppe.ppeName }}
                        <!-- <button
                          matChipRemove
                          [attr.aria-label]="'remove ' + ppe.ppeId"
                        >
                          <mat-icon
                            color="primary"
                            svgIcon="mat:cancel"
                          ></mat-icon>
                        </button> -->
                      </mat-chip>
                    </mat-chip-list>
                    <input
                      [placeholder]="'MSDS.SelectPpe' | translate"
                      #ppeInput
                      [formControl]="ppeCtrl"
                      formControlName="ppeIds"
                      [readonly]="data.formMode == 'view'"
                      [matChipInputFor]="chipGrid"
                      [matAutocomplete]="auto"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      matInput
                    />

                    <mat-autocomplete
                      #auto="matAutocomplete"
                      [displayWith]="displayFn"
                    >
                      <mat-option
                        disabled
                        *ngIf="filteredPpesLength === 0"
                        style="height: 90px"
                      >
                        <div style="text-align: center">
                          <img
                            style="margin: auto"
                            src="assets/img/empty-dashboard.png"
                            width="80px"
                            height="80px"
                          />
                          <p
                            style="
                              margin: auto;
                              width: 100%;
                              color: #2b2c30;
                              opacity: 0.7;
                              font-size: 12px;
                            "
                          >
                            {{ "General.No" | translate }} PPEs!
                            {{ "General.ToAddNew" | translate }}
                            <span
                              style="
                                color: #d7354f;
                                cursor: pointer;
                                text-decoration: underline;
                              "
                              (click)="goToPpe()"
                            >
                              {{ "General.ClickHere" | translate }}</span
                            >
                          </p>
                        </div>
                      </mat-option>

                      <mat-option
                        *ngIf="filteredPpesLength > 0"
                        value="all"
                        (click)="selectAllPpe()"
                      >
                        All
                      </mat-option>

                      <mat-option
                        style="height: fit-content; margin: 5px 0; padding: 5px"
                        *ngFor="let data of filteredPpes | async"
                        [value]="data.ppeId"
                      >
                        <div (click)="optionClicked($event, data)">
                          <mat-checkbox
                            [checked]="data.selected"
                            (change)="toggleSelection(data)"
                            (click)="$event.stopPropagation()"
                          >
                            {{ data.ppeName }}
                          </mat-checkbox>
                        </div>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>
              <div
                class="flex justify-end"
                [style.width.%]="100"
                *ngIf="data.formMode !== 'view'"
              >
                <app-basic-button
                  [btnType]="'Add'"
                  [btnText]="'Next'"
                  (click)="openNextPanel(ppeIdsPanel, attachmentsPanel)"
                ></app-basic-button>
              </div>
            </div>
          </app-msds-create-step>

          <!-- Attachments -->
          <app-msds-create-step
            [title]="'MSDS.Attachments' | translate"
            [checked]="files?.length > 0"
            [expanded]="attachmentsPanel"
            [optional]="true"
          >
            <div body>
              <div *ngIf="data.formMode != 'view'">
                <div class="flex mt-2 align-center">
                  <span style="margin-inline-end: 10px">{{
                    "MSDS.Attachments" | translate
                  }}</span>
                  <div class="flex" *ngIf="data.formMode !== 'view'">
                    <input
                      #fileInput
                      hidden="true"
                      type="file"
                      (change)="onFileSelected($event)"
                      onclick="this.value=null"
                      formArrayName="attachments"
                      multiple
                    />
                    <button
                      mat-flat-button
                      style="
                        background: lightgray;
                        color: #000;
                        text-align: center;
                      "
                      (click)="fileInput.click()"
                    >
                      {{ "General.DragUpload.Browse" | translate }}
                    </button>
                  </div>
                </div>
                <!-- selected files -->
                <div
                  class="flex items-center"
                  *ngFor="let file of files; let i = index"
                  style="margin: 5px"
                >
                  <div class="flex items-center">
                    <mat-icon
                      color="primary"
                      svgIcon="mat:insert_drive_file"
                    ></mat-icon>
                    <span>{{
                      file?.attachId ? getFileName(file.url) : file.name
                    }}</span>
                    <mat-icon
                      class="cursor-pointer"
                      svgIcon="mat:clear"
                      color="primary"
                      (click)="
                        file?.attachId
                          ? deleteDirectlyFile(file.attachId)
                          : removeFile(file.name)
                      "
                    ></mat-icon>
                  </div>
                </div>
              </div>

              <div *ngIf="data.formMode == 'view'">
                <div
                  *ngIf="files.length === 0"
                  @scaleFadeIn
                  class="flex-auto flex flex-col items-center justify-center"
                >
                  <img
                    src="assets/img/resources/empty-state.svg"
                    alt="not found"
                  />
                  <h2 class="headline m-0 text-center text-primary">
                    {{ "General.EmptyAttachments" | translate }}...
                  </h2>
                </div>
                <div class="text-start flex items-center mb-2 justify-around">
                  <div
                    class="flex items-center group relative"
                    *ngFor="let file of files; let i = index"
                    style="margin: 5px"
                  >
                    <ngx-avatars
                      download
                      class="cursor-pointer"
                      size="80"
                      [src]="getAttachmentImage(file.url)"
                      [textSizeRatio]="2"
                      [round]="false"
                      [initialsSize]="2"
                      [cornerRadius]="10"
                      [borderColor]="'#1F36B4'"
                      [matTooltip]="file?.fileName"
                      (clickOnAvatar)="openBigView(file.attachId)"
                    >
                    </ngx-avatars>
                  </div>
                </div>
              </div>
            </div>
          </app-msds-create-step>

          <!-- History Logs -->
          <div *ngIf="data.formMode == 'edit' || data.formMode == 'view'">
            <app-msds-create-step
              *ngIf="hasUpdatePermission | permissionChecker"
              [title]="'History'"
              [checked]="historyLogs?.filteredData?.length > 0"
              [expanded]="historyLogsPanel"
            >
              <div body>
                <div class="field flex column" [style.width.%]="100">
                  <table
                    body
                    @stagger
                    [dataSource]="historyLogs"
                    class="w-full"
                    mat-table
                  >
                    <ng-container
                      *ngFor="let column of columns; trackBy: trackByProperty"
                    >
                      <!-- text -->
                      <ng-container
                        *ngIf="
                          column.type === 'text' && column.property !== 'status'
                        "
                        [matColumnDef]="column.property"
                      >
                        <th *matHeaderCellDef class="uppercase" mat-header-cell>
                          {{ "MSDS.Logs." + column.label | translate }}
                        </th>
                        <td
                          *matCellDef="let row"
                          [ngClass]="column.cssClasses"
                          mat-cell
                        >
                          {{ row[column.property] }}
                        </td>
                      </ng-container>

                      <!-- Action -->
                      <ng-container
                        *ngIf="
                          column.type === 'text' && column.property === 'status'
                        "
                        [matColumnDef]="column.property"
                      >
                        <th *matHeaderCellDef class="uppercase" mat-header-cell>
                          {{ "MSDS.Logs." + column.label | translate }}
                        </th>
                        <td
                          *matCellDef="let row"
                          [ngClass]="column.cssClasses"
                          mat-cell
                        >
                          <span
                            [attr.id]="row[column.property].toUpperCase()"
                            >{{ row[column.property].toUpperCase() }}</span
                          >
                        </td>
                      </ng-container>

                      <!-- date -->
                      <ng-container
                        *ngIf="column.type === 'date'"
                        [matColumnDef]="column.property"
                      >
                        <th *matHeaderCellDef class="uppercase" mat-header-cell>
                          {{ "MSDS.Logs." + column.label | translate }}
                        </th>
                        <td
                          *matCellDef="let row"
                          [ngClass]="column.cssClasses"
                          mat-cell
                        >
                          {{
                            row[column.property] | date : "dd-MM-yyyy - hh:mm a"
                          }}
                        </td>
                      </ng-container>
                    </ng-container>

                    <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
                    <tr
                      *matRowDef="let row; columns: visibleColumns"
                      @fadeInUp
                      class="hover:bg-hover trans-ease-out"
                      mat-row
                    ></tr>
                  </table>
                  <div
                    *ngIf="historyLogs?.filteredData?.length === 0"
                    @scaleFadeIn
                    class="flex-auto flex flex-col items-center justify-center"
                  >
                    <img
                      src="assets/img/resources/empty-state.svg"
                      alt="not found"
                    />
                    <h2 class="headline m-0 text-center text-primary">
                      {{ "General.EmptyTableList" | translate }}...
                    </h2>
                  </div>
                </div>
              </div>
            </app-msds-create-step>
          </div>
        </mat-accordion>
        <!-- submit button -->

        <div class="flex justify-end my-2" [style.width.%]="100">
          <app-basic-button
            *ngIf="data.formMode !== 'view' && data.formMode !== 'publish'"
            [btnType]="'Add'"
            [isDisabled]="msdsForm.invalid"
            [btnText]="data.formMode == 'edit' ? 'Save' : 'Submit'"
            (onClick)="data.formMode == 'edit' ? editMsds() : createMsds()"
          ></app-basic-button>
        </div>
      </form>
    </div>
    <!-- Hazard Square -->
    <div id="hazardIdentificationSquare" class="hidden">
      <div class="flex mt-10" [style.width.%]="100">
        <div class="hazard-square mx-5" dir="auto">
          <div class="flex w-100">
            <div class="hazard-sub-square" [style.backgroundColor]="'#C53635'">
              <span>{{ msdsForm.get("flammable").value }}</span>
            </div>
            <div class="hazard-sub-square" [style.backgroundColor]="'#F5CE47'">
              <span>{{ msdsForm.get("reactive").value }}</span>
            </div>
          </div>
          <div class="flex w-100">
            <div class="hazard-sub-square" [style.backgroundColor]="'#245890'">
              <span>{{ msdsForm.get("healthHazard").value }}</span>
            </div>
            <div class="hazard-sub-square" [style.backgroundColor]="'#FFFFFF'">
              <img
                style="transform: rotate(315deg)"
                *ngIf="msdsForm.get('specialHazard').value !== 'None'"
                [src]="
                  'assets/img/resources/' +
                  msdsForm.get('specialHazard').value +
                  '-icon.svg'
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-state-section>

<ng-template #rejectModal>
  <div>
    <div class="flex items-center" mat-dialog-title>
      <h2 class="headline m-0 flex-auto">
        {{ "MSDS.Reject" | translate }}
      </h2>
      <button
        class="text-secondary"
        (click)="closeRejectDialog()"
        mat-icon-button
        type="button"
      >
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>
    <div>
      <div class="flex-row">
        <mat-label class="field-label">
          {{ "Placeholder.RejectReason" | translate }}
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <textarea
            matInput
            rows="5"
            [placeholder]="'Placeholder.RejectReason' | translate"
            [(ngModel)]="rejectReason"
          >
          </textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="flex justify-end items-center mt-4">
      <app-basic-button
        [btnType]="'Cancel'"
        [btnText]="'Cancel'"
        (onClick)="closeRejectDialog()"
      ></app-basic-button>

      <app-basic-button
        [btnType]="'Add'"
        [btnText]="'Submit'"
        (onClick)="reject()"
      ></app-basic-button>
    </div>
  </div>
</ng-template>
