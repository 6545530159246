import { Component, OnDestroy, OnInit } from '@angular/core';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { OrganizationDetail } from '../../models/organization-detail';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { OrganizationService } from 'src/backend/services/organization.service';
import { Feature } from 'src/backend/models/features/feature';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
@UntilDestroy()

@Component({
  selector: 'app-org-details-page',
  templateUrl: './org-details-page.component.html',
  styleUrls: ['./org-details-page.component.scss']
})

export class OrgDetailsPageComponent extends BaseComponent
implements OnInit, OnDestroy {
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public orgInfo: OrganizationDetail;
  public OrgCardInfo:OrganizationDetail;
  public selectedFeatures: Feature[] = [];
  public orgCode: string = '';

  constructor(
    private orgService: OrganizationService,
    private feedBackService: AsyncFeedbackService,
    private route: ActivatedRoute,


  ) {
    super();
   }

 override ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {        
        this.orgCode = params.get('id') as string;
        this.getOrgCardInfo()
      }
    });
  }
  getOrgCardInfo(): void {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.orgService.getOrganizationCardDetails(this.orgCode).subscribe({
      next: (response) => {
        this.OrgCardInfo=response['message']
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Error;
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

}
