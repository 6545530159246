import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { generateGuid } from 'src/@hodhod/common/custom_methods';
import { WorkPermitApproverTypes } from 'src/@hodhod/common/enum';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BaseApi } from 'src/backend/api/base-api';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import {
  CreateInstanceParam,
  SetInstanceParam,
  WorkFlow,
  WorkFlowByScreenParam,
} from 'src/backend/models/work-flows/work-flow';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { WorkConditionsService } from 'src/backend/services/work-conditions/work-conditions.service';
import { WorkFlowService } from 'src/backend/services/work-flow.service';
import { WorkPermitService } from 'src/backend/services/work-permits/work-permit.service';
import * as moment from 'moment';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';

@Component({
  selector: 'app-add-work-permit-page',
  templateUrl: './add-work-permit-page.component.html',
  styleUrls: ['./add-work-permit-page.component.scss'],
})
export class AddWorkPermitPageComponent implements OnInit {
  minDate = new Date();
  minEndDate = new Date();
  minEndTime = moment(new Date()).format('hh:mm a');
  workPermitForm: any;
  private destroy$ = new Subject();
  public translationsList: any = {};
  public workConditions: any[] = [];
  public selectedWorkConditions: any[] = [];
  public departments: any[] = [];
  public hazards: string[] = [];

  reportUsers: any;
  workFlow: WorkFlow;
  companyKey: string = '';
  instanceKey: string = '';
  loggedUser: LoggedUser;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  mintime: string;

  sectionId: number = 0;
  questionId: number = 0;

  hasCreateDepartmentPermission = ApplicationPermission.DEPARTMENTS_CREATE;
  hasCreateUserPermission = ApplicationPermission.USER_CREATE;
  hasCreatePpePermission = ApplicationPermission.PPE_CREATE;
  hasCreateWorkConditionPermission =
    ApplicationPermission.WORK_CONDITIONS_CREATE;

  constructor(
    private asyncFeedbackService: AsyncFeedbackService,
    private translate: TranslateService,
    private workConditionService: WorkConditionsService,
    private loadingService: LoadingService,
    private departmentService: DepartmentsService,
    private router: Router,
    private workPermitService: WorkPermitService,
    private workFlowService: WorkFlowService,
    private baseApi: BaseApi
  ) {
    this.translate
      .get(['Errors', 'Success', 'ConfirmDeleteRecord', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit() {
    this.companyKey = this.baseApi.getCompanyIdFromStorage();
    this.loggedUser = this.baseApi.getUserSession();
    this.getCurrenttime();
    this.getWorkFlow();
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  getCurrenttime(): void {
    const date = new Date();
    var n = date.toLocaleString([], { hour: '2-digit', minute: '2-digit' });
    this.mintime = n;
  }

  getWorkConditions(): void {
    this.loadingService.startLoading(true, '');
    this.workConditionService
      .getWorkConditionsWithCheckListExecution()
      .subscribe({
        next: (response) => {
          this.workConditions = response;
          this.loadingService.stopLoading();
        },
        error: ({ error }) => {
          this.asyncFeedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.loadingService.stopLoading();
        },
      });
  }

  getCompanyDepartments(): void {
    this.departmentService.getDepartmentsParentChild().subscribe({
      next: (response) => {
        this.departments = response;
        if (
          this.departments.length === 1 &&
          this.departments[0].parentName == '---Parent Department---' &&
          this.departments[0].detail == null
        ) {
          this.departments = [];
        }
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  createPermit(): void {
    const {
      title,
      plannedActivityDescription,
      equipmentDescription,
      pPE,
      departmentId,
      workConditions,
      startDate,
      endDate,
      startTime,
      endTime,
      attachments,
      workerInfos,
      jobSafetyAnalyiss,
      sections,
      approver,
      additionalApprovers,
      subDepartment,
    } = this.workPermitForm;
    const approvers = approver?.map((ele) => {
      return {
        approverId: 0,
        approver: ele,
        approverType: WorkPermitApproverTypes.InitialApprover,
      };
    });
    const formData = new FormData();
    const data = {
      title: title,
      plannedActivityDescription: plannedActivityDescription,
      equipmentDescription: equipmentDescription,
      pPE: pPE.toString(),
      departmentCode: subDepartment ? subDepartment : departmentId,
      startDate: moment(new Date(startDate)).format('YYYY-MM-DDThh:mm:ssZ'),
      endDate: moment(new Date(endDate)).format('YYYY-MM-DDThh:mm:ssZ'),
      startTime: startTime,
      endTime: endTime,
      workConditions: workConditions,
      workerInfos: workerInfos,
      jobSafetyAnalyisses: jobSafetyAnalyiss,
      instanceKey: this.instanceKey,
      additionalApprovers: [
        ...approvers,
        ...additionalApprovers?.map((ele) => {
          return {
            approverId: 0,
            approver: ele,
            approverType: WorkPermitApproverTypes.AdditionalApprover,
          };
        }),
      ],
      sections: sections?.map((ele) => {
        if (ele.questionType === 'Value Vs Target') {
          return {
            sectionId: ele.sectionId,
            sectionTitle: ele.title,
            questions: ele.questions?.map((ques) => {
              return {
                questionId: ques.questionId,
                userAnswer: ques.isNotApplicable
                  ? ''
                  : ques.answer + ' ' + ques.userAnswer.toString(),
                comment: ques.comment,
                isNotApplicable: ques.isNotApplicable,
                media: ques.media,
              };
            }),
          };
        } else {
          return {
            sectionId: ele.sectionId,
            sectionTitle: ele.title,
            questions: ele.questions?.map((ques) => {
              return {
                questionId: ques.questionId,
                userAnswer: ques.isNotApplicable
                  ? ''
                  : ques.userAnswer.toString(),
                comment: ques.comment,
                isNotApplicable: ques.isNotApplicable,
                media: ques.media,
              };
            }),
          };
        }
      }),
    };
    attachments.forEach((element) => {
      formData.append('files', element);
    });
    formData.append('content', JSON.stringify(data));
    this.workPermitService.createWorkPermit(formData).subscribe({
      next: (response) => {
        this.updateWorkFlowInstance(parseInt(response?.id, 10));
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, response?.message)
        );
        this.loadingService.stopLoading();
        this.goBack();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  goBack(): void {
    this.router.navigate([
      '/' +
        SharedConstants.WORK_PERMIT +
        '/' +
        SharedConstants.WORK_PERMIT_LIST,
    ]);
  }

  getWorkFlow(): void {
    const data: WorkFlowByScreenParam = {
      screenName: Constants.WORKFLOW_WORKPERMIT_SCREEN,
      tenantId: this.companyKey,
      companyKey: Constants.WORK_FLOW_COMPANY_KEY,
    };
    this.workFlowService.getWorkFlowByScreen(data).subscribe({
      next: (response) => {
        this.workFlow = response;
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  createWorkFlowInstance(event): void {
    this.workPermitForm = event;
    this.instanceKey = generateGuid();
    const data: CreateInstanceParam = {
      workFlowKey: this.workFlow?.workFlowKey,
      instanceKey: this.instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.userName,
      tenantId: this.companyKey,
    };
    this.loadingService.startLoading(true);
    this.workFlowService.createWorkFlowInstance(data).subscribe({
      next: (response) => {
        this.setWorkFlowInstance();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  setWorkFlowInstance(): void {
    const data: SetInstanceParam = {
      instanceKey: this.instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.fullName,
      actionName: Constants.APP_CREATE_ACTION,
      rules: [],
      roleId: this.loggedUser.roleIds,
      payload: null,
    };
    this.workFlowService.setWorkFlowInstance(data).subscribe({
      next: (response) => {
        this.createPermit();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.deleteWorkFlowInstance();
        this.loadingService.stopLoading();
      },
    });
  }

  updateWorkFlowInstance(id: number): void {
    const {
      title,
      plannedActivityDescription,
      equipmentDescription,
      pPE,
      departmentId,
      workConditions,
      startDate,
      endDate,
      startTime,
      endTime,
      workerInfos,
      jobSafetyAnalyiss,
      approver,
      additionalApprovers,
    } = this.workPermitForm;
    const departmentsDetail = this.departments.map((x) => x.detail).flat(1);
    const workPermit = {
      workPermitId: id,
      title: title,
      plannedActivityDescription: plannedActivityDescription,
      equipmentDescription: equipmentDescription,
      pPE: pPE.toString(),
      departmentName: departmentId,
      startDate: moment(new Date(startDate)).format('YYYY-MM-DDThh:mm:ssZ'),
      endDate: moment(new Date(endDate)).format('YYYY-MM-DDThh:mm:ssZ'),
      startTime: startTime,
      endTime: endTime,
      workConditions: workConditions.map((x) => x.workConditionName).toString(),
      workerInfos: workerInfos,
      jobSafetyAnalyisses: jobSafetyAnalyiss,
      instanceKey: this.instanceKey,
      additionalApprovers: additionalApprovers.map((x) => x).toString(),
      createdBy: this.loggedUser.fullName,
      createdOn: new Date(),
      isActive: true,
      isDeleted: false,
    };
    const instance = {
      tenantId: this.companyKey,
      tableId: id,
      instanceKey: this.instanceKey,
      payload: workPermit,
    };
    this.workFlowService.updateWorkFlowInstance(instance).subscribe({
      next: (response) => {},
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  deleteWorkFlowInstance(): void {
    this.workFlowService.deleteWorkFlowInstance(this.instanceKey).subscribe({
      next: (response) => {},
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
}
