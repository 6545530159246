import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';

import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { OrganizationService } from 'src/backend/services/organization.service';
@UntilDestroy()
@Component({
  selector: 'app-update-qouta-popup',
  templateUrl: './update-qouta-popup.component.html',
  styleUrls: ['./update-qouta-popup.component.scss'],
})
export class UpdateQoutaPopupComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  orgForm: FormGroup;
  private translationsList: any = {};
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private confirmationService: ConfirmationService,
    private organizationService: OrganizationService,
    private feedBackService: AsyncFeedbackService,
    private translate: TranslateService,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA)
    public data
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'organization'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit(): void {
    this.orgForm = this.fb.group({
      orgQouta: new FormControl('', [
        Validators.required,
        Validators.min(1),
        Validators.max(this.data?.quota),
      ]),
    });
  }
  closeDialog() {
    this.dialog.closeAll();
  }
  updateQouta() {
    let data = {
      id: null,
      quota: +this.orgForm.get('orgQouta').value,
    };
    this.data['type'] === 'com'
      ? (data.id = this.data['details']?.companyId)
      : (data.id = this.data['details']?.id);
    this.loadingService.startLoading();
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['organization']['UpdateConfirm']['Title'],
          this.translationsList['organization']['UpdateConfirm']['Message']
        )
      )
      .then(() => {
        if (this.data['type'] === 'com') {
          this.organizationService.updateComQuota(data).subscribe({
            next: (res) => {
              this.loadingService.stopLoading();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.dialog.closeAll();
            },
            error: (error) => {
              this.loadingService.stopLoading();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
            },
          });
        } else {
          this.organizationService.updateOrgQuota(data).subscribe({
            next: (res) => {
              this.loadingService.stopLoading();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.closeDialog();
            },
            error: (error) => {
              this.loadingService.stopLoading();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.closeDialog();
            },
          });
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
}
