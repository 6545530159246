<form [formGroup]="workPermitForm">
  <div class="flex items-center justify-end my-3">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="goBack()"
    ></app-basic-button>

    <app-basic-button
      [btnType]="'Add'"
      [btnText]="'Save'"
      [isDisabled]="
        !workPermitForm.valid ||
        workPermitForm.get('workerInfos').value?.length === 0
      "
      (onClick)="onSubmit()"
    ></app-basic-button>
  </div>
  <div>
    <app-expandable-panel
      [expanded]="true"
      #expandablePanel
      [title]="'WorkPermit.WorkPermitInfo' | translate"
    >
      <div body class="flex column">
        <div class="flex-row">
          <div class="flex column">
            <mat-label class="field-label"
              >{{ "WorkPermit.Form.Title" | translate }}
              <span
                [ngClass]="
                  workPermitForm.invalid &&
                  workPermitForm.get('title').hasError('required')
                    ? 'is-invalid'
                    : 'is-valid'
                "
                >*</span
              >
            </mat-label>
            <mat-form-field class="input-field" appearance="outline">
              <input
                [placeholder]="'title'"
                matInput
                type="text"
                formControlName="title"
              />
              <mat-error>
                <div class="flex justify-flex-start align-flex-center">
                  <img
                    class="error-img"
                    src="assets/img/resources/error-icon.svg"
                  />
                  <span>{{ "Errors.Required" | translate }}</span>
                </div>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="flex justify-between flex-wrap-reverse">
          <div class="flex-1">
            <div class="flex column">
              <mat-label class="field-label"
                >{{ "WorkPermit.WorkCondition" | translate }}
                <span
                  [ngClass]="
                    workPermitForm.invalid &&
                    workPermitForm.get('workConditions').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <mat-select
                  ngxMatSelect
                  #msf2="ngxMatSelect"
                  [displayMember]="'workConditionName'"
                  [valueMember]="'id'"
                  [source]="workConditions"
                  [useInfiniteScroll]="true"
                  [hasPermission]="
                    hasCreateWorkConditionPermission | permissionChecker
                  "
                  [title]="'NavigationBar.WorkConditions' | translate"
                  [placeholder]="'Placeholder.SelectWorkCondition' | translate"
                  formControlName="workConditions"
                  [disabled]="false"
                  [manageRoute]="'/work-permit/work-permit-work-conditions'"
                  multiple
                  (selectionChange)="onChangeConditions($event)"
                >
                  <mat-option
                    *ngFor="let wc of msf2.filteredSource"
                    [value]="wc?.id"
                    >{{ wc?.workConditionName }}</mat-option
                  >
                </mat-select>
                <mat-error>
                  <div class="flex justify-flex-start align-flex-center">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="flex column">
              <mat-label class="field-label"
                >{{ "WorkPermit.Department" | translate }}
                <span
                  [ngClass]="
                    workPermitForm.invalid &&
                    workPermitForm.get('departmentId').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <mat-select
                  ngxMatSelect
                  #msf5="ngxMatSelect"
                  [displayMember]="'departmentName'"
                  [valueMember]="'departmentCode'"
                  [source]="departments"
                  [useInfiniteScroll]="true"
                  [multiple]="false"
                  [hasPermission]="
                    hasCreateDepartmentPermission | permissionChecker
                  "
                  [title]="'departments'"
                  [manageRoute]="'/company-setup/departments'"
                  formControlName="departmentId"
                  [placeholder]="'Placeholder.SelectDepartment' | translate"
                  (selectionChange)="onChangeDepartment($event)"
                  [hasSearchBox]="true"
                >
                  <mat-option
                    *ngIf="msf5.filteredSource.length === 0"
                    style="height: 0"
                  ></mat-option>

                  <mat-option
                    *ngFor="let detail of msf5.filteredSource"
                    [value]="detail.departmentCode"
                  >
                    {{ detail.departmentName }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    workPermitForm.invalid &&
                    workPermitForm.get('departmentId').hasError('required')
                  "
                >
                  <div class="flex justify-flex-start align-flex-center">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <!-- Sub department -->
            <div class="flex-1 column" *ngIf="subDepartments.length > 0">
              <mat-label class="field-label">{{
                "AddReportHazard.SubDepartment" | translate
              }}</mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <mat-select
                  #msf7
                  [title]="'departments'"
                  [placeholder]="'Placeholder.Select' | translate"
                  formControlName="subDepartment"
                >
                  <mat-option
                    *ngFor="let dep of subDepartments"
                    [value]="dep.code"
                  >
                    {{ dep.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="flex column">
              <mat-label class="field-label"
                >{{ "WorkPermit.WorkPermitApprover" | translate }}
                <span
                  [ngClass]="
                    workPermitForm.invalid &&
                    workPermitForm.get('approver').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <mat-select
                  (selectionChange)="onChangeApprovers($event)"
                  ngxMatSelect
                  #msf3="ngxMatSelect"
                  [displayMember]="'fullName'"
                  [valueMember]="'userId'"
                  [source]="reportUsers"
                  [useInfiniteScroll]="true"
                  formControlName="approver"
                  [hasPermission]="hasCreateUserPermission | permissionChecker"
                  [title]="'users'"
                  [manageRoute]="'/user-management/users'"
                  [multiple]="true"
                  [placeholder]="'Placeholder.WorkPermitApprover' | translate"
                >
                  <mat-option
                    [value]="item.userId"
                    *ngFor="let item of msf3.filteredSource"
                  >
                    {{ item.fullName }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  <div class="flex justify-flex-start align-flex-center">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="flex column">
              <mat-label class="field-label">{{
                "WorkPermit.AdditionalWorkApprover" | translate
              }}</mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <mat-select
                  ngxMatSelect
                  #msf4="ngxMatSelect"
                  [displayMember]="'fullName'"
                  [valueMember]="'userId'"
                  (selectionChange)="onChangeAdditionalApprovers($event)"
                  [source]="reportUsers"
                  [useInfiniteScroll]="true"
                  formControlName="additionalApprovers"
                  [multiple]="true"
                  [hasPermission]="hasCreateUserPermission | permissionChecker"
                  [title]="'users'"
                  [manageRoute]="'/user-management/users'"
                  [placeholder]="
                    'Placeholder.SelectAdditionalWorkPermitApprovers'
                      | translate
                  "
                >
                  <mat-option
                    [value]="item.userId"
                    *ngFor="let item of msf4.filteredSource"
                  >
                    {{ item.fullName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flex items-center gap-3">
              <div class="flex column flex-grow">
                <mat-label class="field-label"
                  >{{ "Task.StartDate" | translate }}
                  <span
                    [ngClass]="
                      workPermitForm.invalid &&
                      workPermitForm.get('startDate').hasError('required')
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    >*</span
                  >
                </mat-label>
                <mat-form-field appearance="outline">
                  <input
                    [placeholder]="'Placeholder.SelectDate' | translate"
                    matInput
                    [matDatepicker]="startDate"
                    (click)="startDate.open()"
                    [min]="minDate"
                    (dateChange)="validateDateRange()"
                    formControlName="startDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="startDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #startDate></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="flex column flex-grow">
                <mat-label class="field-label"
                  >{{ "Task.EndDate" | translate }}
                  <span
                    [ngClass]="
                      workPermitForm.invalid &&
                      workPermitForm.get('endDate').hasError('required')
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    >*</span
                  >
                </mat-label>
                <mat-form-field appearance="outline">
                  <input
                    [placeholder]="'Placeholder.SelectDate' | translate"
                    matInput
                    [min]="minEndDate"
                    [matDatepicker]="endDate"
                    (click)="endDate.open()"
                    formControlName="endDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="endDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #endDate></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="flex items-center gap-3 mt-1">
              <div class="flex column flex-grow">
                <mat-label class="field-label"
                  >{{ "WorkPermit.StartTimeWithinWork" | translate }}
                  <span
                    [ngClass]="
                      workPermitForm.invalid &&
                      workPermitForm.get('startTime').hasError('required')
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    >*</span
                  >
                </mat-label>
                <mat-form-field class="input-field" appearance="outline">
                  <input
                    [format]="24"
                    matInput
                    [ngxMatTimepicker]="starttimepicker"
                    [placeholder]="'Placeholder.Select' | translate"
                    formControlName="startTime"
                    readonly
                  />
                  <mat-icon
                    matSuffix
                    svgIcon="mat:watch_later"
                    (click)="starttimepicker.open()"
                  >
                  </mat-icon>
                  <ngx-mat-timepicker #starttimepicker></ngx-mat-timepicker>
                </mat-form-field>
              </div>
              <div class="flex column flex-grow">
                <mat-label class="field-label"
                  >{{ "WorkPermit.EndTimeWithinWork" | translate }}
                  <span
                    [ngClass]="
                      (workPermitForm.invalid &&
                        workPermitForm.get('endTime').hasError('required')) ||
                      workPermitForm
                        .get('endTime')
                        .hasError('endTimeMustBeAfterStartTime')
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    >*</span
                  >
                </mat-label>
                <mat-form-field class="input-field" appearance="outline">
                  <input
                    [format]="24"
                    matInput
                    [ngxMatTimepicker]="endtimepicker"
                    [min]="mintime"
                    formControlName="endTime"
                    readonly
                  />
                  <mat-icon
                    matSuffix
                    svgIcon="mat:watch_later"
                    (click)="endtimepicker.open()"
                  >
                  </mat-icon>
                  <ngx-mat-timepicker #endtimepicker></ngx-mat-timepicker>
                  <mat-error
                    *ngIf="workPermitForm.get('endTime').hasError('required')"
                  >
                    <div class="flex justify-flex-start align-flex-center">
                      <img
                        class="error-img"
                        src="assets/img/resources/error-icon.svg"
                      />
                      <span>{{ "Errors.Required" | translate }}</span>
                    </div>
                  </mat-error>
                  <mat-error
                    *ngIf="
                      workPermitForm
                        .get('endTime')
                        .hasError('endTimeMustBeAfterStartTime')
                    "
                  >
                    <div class="flex justify-flex-start align-flex-center">
                      <img
                        class="error-img"
                        src="assets/img/resources/error-icon.svg"
                      />
                      <span>End time should be after start time</span>
                    </div>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-row">
          <mat-label class="field-label"
            >{{ "WorkPermit.PlannedActivityDescription" | translate }}
            <span
              [ngClass]="
                workPermitForm.invalid &&
                workPermitForm
                  .get('plannedActivityDescription')
                  .hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
              >*</span
            >
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <textarea
              matInput
              rows="5"
              [placeholder]="'Placeholder.Comment' | translate"
              formControlName="plannedActivityDescription"
            >
            </textarea>
            <mat-error>
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="flex-row">
          <mat-label class="field-label"
            >{{ "WorkPermit.Form.EquipmentDescription" | translate }}
            <span
              [ngClass]="
                workPermitForm.invalid &&
                workPermitForm.get('equipmentDescription').hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
              >*</span
            >
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <textarea
              matInput
              rows="5"
              [placeholder]="'Placeholder.Comment' | translate"
              formControlName="equipmentDescription"
            >
            </textarea>
            <mat-error>
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex column">
          <file-upload
            formControlName="attachments"
            [multiple]="true"
            [animation]="true"
          ></file-upload>
        </div>
        <div class="flex flex-wrap gap-2 my-4" *ngIf="data">
          <div class="group relative" *ngFor="let img of images">
            <ngx-avatars
              [src]="getAttachmentImage(img.imageUrl)"
              [name]="getAttachementTypeName(img.imageUrl)"
              [textSizeRatio]="2"
              [round]="false"
              [initialsSize]="2"
              [cornerRadius]="10"
              [matTooltip]="img.fileName"
              [borderColor]="'#1F36B4'"
              (clickOnAvatar)="openBigView(img.id)"
            >
            </ngx-avatars>
            <mat-icon
              class="invisible cursor-pointer absolute top-0 right-0 text-primary transition-all group-hover:visible"
              svgIcon="mat:delete"
              (click)="deleteDirectlyFile(img.id)"
            ></mat-icon>
          </div>
        </div>
      </div>
    </app-expandable-panel>
  </div>
  <div class="mt-5">
    <app-expandable-panel
      [expanded]="true"
      #expandablePanel
      [title]="'WorkPermit.Form.Ppe' | translate"
    >
      <div class="flex column" body>
        <mat-label class="field-label">{{
          "WorkPermit.Form.Ppe" | translate
        }}</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            ngxMatSelect
            #msf1="ngxMatSelect"
            [displayMember]="'ppeName'"
            [valueMember]="'id'"
            [source]="ppes"
            [useInfiniteScroll]="true"
            [hasPermission]="hasCreatePpePermission | permissionChecker"
            [title]="'ppes'"
            [placeholder]="'Placeholder.Select' | translate"
            formControlName="pPE"
            (selectionChange)="getSelectedPpes($event)"
            [manageRoute]="'/company-setup/ppe-setting'"
            multiple
          >
            <mat-option *ngFor="let ppe of msf1.filteredSource" [value]="ppe.id"
              >{{ ppe.ppeName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="py-3 flex flex-wrap">
          <div
            class="flex border border-gray-200 p-1 ms-0 w-1/6 rounded-full items-center m-2 overflow-hidden"
            *ngFor="let item of selectedPpes"
          >
            <ngx-avatars
              class="logo box"
              size="38"
              [src]="item?.imageUrl"
              [name]="item?.ppeName"
              [textSizeRatio]="2"
              [round]="true"
              [initialsSize]="2"
            ></ngx-avatars>
            <p class="text-left ps-2">{{ item?.ppeName }}</p>
          </div>
        </div>
      </div>
    </app-expandable-panel>
  </div>
  <div class="mt-5">
    <app-expandable-panel
      [expanded]="true"
      #expandablePanel
      [title]="'WorkPermit.WorkerInfo' | translate"
    >
      <div body class="flex column" formArrayName="workerInfos">
        <div
          class="flex justify-between flex-wrap-reverse items-center"
          *ngFor="let gro of repeatWorkderInfoFormGroup.controls; let i = index"
          [formGroupName]="i"
        >
          <div class="flex-1 pr-5">
            <div class="flex column">
              <mat-label class="field-label"
                >{{ "WorkPermit.WorkerName" | translate }}
                <span
                  [ngClass]="
                    gro.get('workerName').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <input
                  [placeholder]="'Placeholder.WorkerName' | translate"
                  matInput
                  type="text"
                  formControlName="workerName"
                />
                <mat-error>
                  <div class="flex justify-flex-start align-flex-center">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="flex-1">
            <div class="flex column">
              <mat-label class="field-label"
                >{{ "WorkPermit.WorkerRole" | translate }}
                <span
                  [ngClass]="
                    gro.get('workerRole').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <input
                  [placeholder]="'Placeholder.WorkerRole' | translate"
                  matInput
                  type="text"
                  formControlName="workerRole"
                />
                <mat-error>
                  <div class="flex justify-flex-start align-flex-center">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <button
            color="warn"
            mat-icon-button
            [matTooltip]="'General.Buttons.Remove' | translate"
            type="button"
            (click)="removeWorkerInfoRow(i)"
          >
            <mat-icon svgIcon="mat:delete"></mat-icon>
          </button>
        </div>
        <button
          (click)="addWorkerInfoRow()"
          class="my-4 flex-none"
          color="primary"
          mat-mini-fab
          [matTooltip]="'General.Buttons.AddNew' | translate"
          type="button"
        >
          <mat-icon svgIcon="mat:add"></mat-icon>
        </button>
      </div>
    </app-expandable-panel>
  </div>

  <div class="mt-5">
    <app-expandable-panel
      class="panal-overflow-auto"
      [expanded]="true"
      #expandablePanel
      [title]="'WorkPermit.JobSafety' | translate"
    >
      <div formArrayName="jobSafetyAnalyiss" body>
        <table
          mat-table
          [dataSource]="jobSafetyAnalyissFormArray.controls"
          class="w-full"
          matSort
        >
          <div>
            <ng-container
              *ngFor="
                let column of jobSafetyColumns;
                let k = index;
                trackBy: trackByProperty
              "
            >
              <ng-container
                *ngIf="column.type === 'text'"
                [matColumnDef]="column.property"
              >
                <th *matHeaderCellDef class="uppercase" mat-header-cell>
                  {{
                    "WorkPermit.View.JobSafetyTable." + column.label | translate
                  }}
                </th>

                <td
                  *matCellDef="let row; let h = index"
                  [ngClass]="column.cssClasses"
                  mat-cell
                >
                  <div>
                    <ng-container
                      *ngIf="column.label === 'Number'; else inputTemplate"
                    >
                      <span>
                        {{ h + 1 }}
                      </span>
                    </ng-container>
                    <ng-template #inputTemplate>
                      <div [formGroupName]="h">
                        <mat-form-field
                          class="table-input-field"
                          appearance="outline"
                        >
                          <input
                            [formControlName]="column.property"
                            [placeholder]="column.label"
                            [type]="'text'"
                            matInput
                          />
                        </mat-form-field>
                      </div>
                    </ng-template>
                  </div>
                </td>
              </ng-container>

              <ng-container
                *ngIf="column.type === 'select'"
                [matColumnDef]="column.property"
              >
                <th *matHeaderCellDef class="uppercase" mat-header-cell>
                  {{
                    "WorkPermit.View.JobSafetyTable." + column.label | translate
                  }}
                </th>

                <td
                  *matCellDef="let row; let h = index"
                  [ngClass]="column.cssClasses"
                  mat-cell
                >
                  <div [formGroupName]="h">
                    <mat-form-field
                      class="table-input-field"
                      appearance="outline"
                    >
                      <mat-select
                        [placeholder]="'Placeholder.Select' | translate"
                        [formControlName]="column.property"
                      >
                        <mat-option
                          *ngFor="let status of riskStatus | enumToArray"
                          [value]="status.value.text"
                          [ngStyle]="{
                            color: status.value.color
                          }"
                          >{{ status.value.text }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </td>
              </ng-container>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
              <td *matCellDef="let row; let i = index" mat-cell>
                <div class="flex">
                  <app-action-button
                    [btnBackgroundClass]="'bg-red-100'"
                    [btnTextClass]="'text-red-700'"
                    [buttonToolTip]="'Remove'"
                    [buttonIcon]="'delete'"
                    (onClick)="removeJobSafety(i)"
                  >
                  </app-action-button>
                </div>
              </td>
            </ng-container>
          </div>

          <tr mat-header-row *matHeaderRowDef="visibleJobSafetyColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: visibleJobSafetyColumns"
          ></tr>
        </table>
        <button
          (click)="addJobSafety()"
          class="my-4 flex-none"
          color="primary"
          mat-mini-fab
          [matTooltip]="'General.Buttons.AddNew' | translate"
          type="button"
        >
          <mat-icon svgIcon="mat:add"></mat-icon>
        </button>
      </div>
    </app-expandable-panel>
  </div>

  <div class="mt-5" *ngIf="wCHazardsMode">
    <app-expandable-panel
      [expanded]="true"
      #expandablePanel
      [title]="'WorkPermit.HazardInfo' | translate"
    >
      <div body class="flex column">
        <div
          class="item flex column"
          *ngFor="let workCondition of selectedWorkConditions"
        >
          <h3 class="font-bold text-lg">
            {{ workCondition?.workConditionName }}
          </h3>
          <div class="flex flex-wrap-reverse items-center elements">
            <div
              class="info_dark element flex center"
              *ngFor="let hazard of workCondition.hazardNames"
            >
              <p>{{ hazard }}</p>
            </div>
          </div>
        </div>
      </div>
    </app-expandable-panel>
  </div>
  <div class="mt-5" *ngIf="wCCheckListMode">
    <app-expandable-panel
      [expanded]="true"
      #expandablePanel
      [title]="'NavigationBar.WorkConditions' | translate"
    >
      <div body class="flex column">
        <div style="display: flex; padding: 14px">
          <div formArrayName="sections" style="width: 80%">
            <ol class="list-style">
              <li
                class="rounded p-2.5 mb-2.5 shadow"
                *ngFor="
                  let sectionCtrl of repeatSectionFormGroup.controls;
                  let i = index
                "
              >
                <div [formGroupName]="i">
                  <div>
                    <h4 class="font-bold">
                      {{ workPermitForm.get("sections").value[i].title }}
                    </h4>
                  </div>
                  <div
                    formArrayName="questions"
                    [ngSwitch]="
                      workPermitForm.get('sections').value[i].questionType
                    "
                  >
                    <div *ngSwitchCase="'Yes/No'">
                      <div>
                        <ul class="question-type question">
                          <li
                            *ngFor="
                              let optionCtrl of repeatQuestionsFormGroup(i)
                                .controls;
                              let j = index
                            "
                          >
                            <div
                              class="flex items-start flex-wrap"
                              [formGroupName]="j"
                            >
                              <div class="flex-1">
                                <div class="flex column items-start my-2">
                                  <h5>
                                    {{
                                      j +
                                        1 +
                                        "- " +
                                        workPermitForm.get("sections").value[i]
                                          .questions[j].questionText
                                    }}
                                  </h5>
                                  <div
                                    style="min-width: 100px"
                                    class="flex mt-2"
                                  >
                                    <mat-radio-group
                                      (change)="GetScorePercentage()"
                                      formControlName="userAnswer"
                                      class="mr-4 ml-4"
                                      [disabled]="
                                        workPermitForm.get('sections').value[i]
                                          .questions[j].isNotApplicable
                                      "
                                    >
                                      <mat-radio-button
                                        [style]="
                                          workPermitForm.get('sections').value[
                                            i
                                          ].questions[j].answer === 'Yes'
                                            ? 'color: #42f548;'
                                            : 'color: #f54242;'
                                        "
                                        value="Yes"
                                        >{{
                                          "General.Yes" | translate
                                        }}</mat-radio-button
                                      >
                                      <mat-radio-button
                                        [style]="
                                          workPermitForm.get('sections').value[
                                            i
                                          ].questions[j].answer === 'No'
                                            ? 'color: #42f548;'
                                            : 'color: #f54242;'
                                        "
                                        class="ml-3"
                                        value="No"
                                        >{{ "General.No" | translate }}
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                </div>
                                <mat-checkbox
                                  class="my-2 ml-2"
                                  formControlName="isNotApplicable"
                                  color="primary"
                                  (change)="notApplicableChange()"
                                >
                                  Not Applicable
                                </mat-checkbox>
                                <br />
                                <a
                                  mat-button
                                  *ngIf="
                                    workPermitForm.get('sections').value[i]
                                      .questions[j].media
                                  "
                                  color="primary"
                                  href="{{
                                    workPermitForm.get('sections').value[i]
                                      .questions[j].media
                                  }}"
                                  download
                                >
                                  <mat-icon
                                    class="mr-2"
                                    svgIcon="mat:download"
                                  ></mat-icon
                                  >View Attachment
                                </a>
                              </div>
                              <div class="flex-1">
                                <button
                                  mat-button
                                  color="primary"
                                  (click)="
                                    openMediaModal(
                                      workPermitForm.get('sections').value[i]
                                        .sectionId,
                                      workPermitForm.get('sections').value[i]
                                        .questions[j].questionId
                                    )
                                  "
                                >
                                  <mat-icon
                                    class="mr-2"
                                    svgIcon="mat:file_upload"
                                  ></mat-icon>
                                  Upload File
                                </button>
                                <div class="flex-row">
                                  <mat-label class="field-label"
                                    >Description</mat-label
                                  >
                                  <mat-form-field
                                    class="input-field"
                                    appearance="outline"
                                  >
                                    <textarea
                                      matInput
                                      rows="3"
                                      formControlName="comment"
                                      [placeholder]="'comment'"
                                    >
                                    </textarea>
                                  </mat-form-field>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div *ngSwitchCase="'Select from range'">
                      <div class="my-2">
                        <ul class="question-type question">
                          <li
                            *ngFor="
                              let optionCtrl of repeatQuestionsFormGroup(i)
                                .controls;
                              let j = index
                            "
                          >
                            <div
                              class="flex items-start flex-wrap"
                              [formGroupName]="j"
                            >
                              <div class="flex-1">
                                <div class="flex column items-start my-2">
                                  <h5>
                                    {{
                                      j +
                                        1 +
                                        "- " +
                                        workPermitForm.get("sections").value[i]
                                          .questions[j].questionText
                                    }}
                                  </h5>
                                  <mat-radio-group
                                    [disabled]="
                                      workPermitForm.get('sections').value[i]
                                        .questions[j].isNotApplicable
                                    "
                                    (change)="GetScorePercentage()"
                                    formControlName="userAnswer"
                                    class="ml-4 mr-4"
                                  >
                                    <mat-radio-button
                                      class="ml-3 p-2.5"
                                      *ngFor="let r of ranges"
                                      [style]="'color: #008000;'"
                                      [value]="r.value"
                                    >
                                      {{ r.value }}</mat-radio-button
                                    >
                                  </mat-radio-group>
                                </div>
                                <mat-checkbox
                                  class="my-1 ml-2"
                                  formControlName="isNotApplicable"
                                  color="primary"
                                  (change)="notApplicableChange()"
                                >
                                  Not Applicable
                                </mat-checkbox>
                                <br />
                                <a
                                  mat-button
                                  *ngIf="
                                    workPermitForm.get('sections').value[i]
                                      .questions[j].media
                                  "
                                  color="primary"
                                  href="{{
                                    workPermitForm.get('sections').value[i]
                                      .questions[j].media
                                  }}"
                                  download
                                >
                                  <mat-icon
                                    class="mr-2"
                                    svgIcon="mat:download"
                                  ></mat-icon
                                  >View Attachment
                                </a>
                              </div>
                              <div class="flex-1">
                                <button
                                  mat-button
                                  color="primary"
                                  (click)="
                                    openMediaModal(
                                      workPermitForm.get('sections').value[i]
                                        .sectionId,
                                      workPermitForm.get('sections').value[i]
                                        .questions[j].questionId
                                    )
                                  "
                                >
                                  <mat-icon
                                    class="mr-2"
                                    svgIcon="mat:file_upload"
                                  ></mat-icon>
                                  Upload File
                                </button>
                                <div class="flex-row">
                                  <mat-label class="field-label"
                                    >Description</mat-label
                                  >
                                  <mat-form-field
                                    class="input-field"
                                    appearance="outline"
                                  >
                                    <textarea
                                      matInput
                                      rows="3"
                                      formControlName="comment"
                                      [placeholder]="'comment'"
                                    >
                                    </textarea>
                                  </mat-form-field>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div *ngSwitchCase="'Machine Parameter'">
                      <div>
                        <ul class="question-type">
                          <li
                            *ngFor="
                              let optionCtrl of repeatQuestionsFormGroup(i)
                                .controls;
                              let j = index
                            "
                          >
                            <div
                              class="flex items-start flex-wrap"
                              [formGroupName]="j"
                            >
                              <div class="flex-1">
                                <div class="flex column items-start my-2">
                                  <h5>
                                    {{
                                      j +
                                        1 +
                                        "- " +
                                        getParamterName(
                                          workPermitForm.get("sections").value[
                                            i
                                          ].questions[j].questionText,
                                          i,
                                          j
                                        )
                                    }}
                                  </h5>
                                  <mat-form-field
                                    class="full-width ml-4 mr-4"
                                    appearance="outline"
                                  >
                                    <input
                                      formControlName="userAnswer"
                                      (input)="GetScorePercentage()"
                                      maxlength="200"
                                      matInput
                                      [placeholder]="'Answer'"
                                      [readonly]="
                                        workPermitForm.get('sections').value[i]
                                          .questions[j].isNotApplicable
                                      "
                                    />
                                  </mat-form-field>
                                </div>
                                <mat-checkbox
                                  class="my-1 ml-2"
                                  formControlName="isNotApplicable"
                                  color="primary"
                                  (change)="notApplicableChange()"
                                >
                                  Not Applicable
                                </mat-checkbox>
                                <br />
                                <a
                                  mat-button
                                  *ngIf="
                                    workPermitForm.get('sections').value[i]
                                      .questions[j].media
                                  "
                                  color="primary"
                                  href="{{
                                    workPermitForm.get('sections').value[i]
                                      .questions[j].media
                                  }}"
                                  download
                                >
                                  <mat-icon
                                    class="mr-2"
                                    svgIcon="mat:download"
                                  ></mat-icon
                                  >View Attachment
                                </a>
                              </div>
                              <div class="flex-1">
                                <button
                                  mat-button
                                  color="primary"
                                  (click)="
                                    openMediaModal(
                                      workPermitForm.get('sections').value[i]
                                        .sectionId,
                                      workPermitForm.get('sections').value[i]
                                        .questions[j].questionId
                                    )
                                  "
                                >
                                  <mat-icon
                                    class="mr-2"
                                    svgIcon="mat:file_upload"
                                  ></mat-icon>
                                  Upload File
                                </button>
                                <div class="flex-row">
                                  <mat-label class="field-label"
                                    >Description</mat-label
                                  >
                                  <mat-form-field
                                    class="input-field"
                                    appearance="outline"
                                  >
                                    <textarea
                                      matInput
                                      rows="3"
                                      formControlName="comment"
                                      [placeholder]="'comment'"
                                    >
                                    </textarea>
                                  </mat-form-field>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div *ngSwitchCase="'Value Vs Target'">
                      <div>
                        <ul class="question-type">
                          <li
                            *ngFor="
                              let optionCtrl of repeatQuestionsFormGroup(i)
                                .controls;
                              let j = index
                            "
                          >
                            <div
                              class="flex items-start flex-wrap"
                              [formGroupName]="j"
                            >
                              <div class="flex-1">
                                <div class="flex column items-start my-2">
                                  <h5>
                                    {{
                                      j +
                                        1 +
                                        "- " +
                                        workPermitForm.get("sections").value[i]
                                          .questions[j].questionText
                                    }}
                                  </h5>
                                  <div
                                    class="flex my-1 justify-center items-center"
                                  >
                                    <h2 class="font-bold text-center mb-5">
                                      {{
                                        workPermitForm.get("sections").value[i]
                                          .questions[j].answer
                                      }}
                                    </h2>
                                    <mat-form-field
                                      class="full-width ml-4 mr-4"
                                      appearance="outline"
                                    >
                                      <input
                                        (input)="GetScorePercentage()"
                                        [readonly]="
                                          workPermitForm.get('sections').value[
                                            i
                                          ].questions[j].isNotApplicable
                                        "
                                        formControlName="userAnswer"
                                        matInput
                                        type="number"
                                        min="0"
                                      />
                                    </mat-form-field>
                                  </div>
                                </div>
                                <mat-checkbox
                                  class="my-1 ml-2"
                                  formControlName="isNotApplicable"
                                  color="primary"
                                  (change)="notApplicableChange()"
                                >
                                  Not Applicable
                                </mat-checkbox>
                                <br />
                                <a
                                  mat-button
                                  *ngIf="
                                    workPermitForm.get('sections').value[i]
                                      .questions[j].media
                                  "
                                  color="primary"
                                  href="{{
                                    workPermitForm.get('sections').value[i]
                                      .questions[j].media
                                  }}"
                                  download
                                >
                                  <mat-icon
                                    class="mr-2"
                                    svgIcon="mat:download"
                                  ></mat-icon
                                  >View Attachment
                                </a>
                              </div>
                              <div class="flex-1">
                                <button
                                  mat-button
                                  color="primary"
                                  (click)="
                                    openMediaModal(
                                      workPermitForm.get('sections').value[i]
                                        .sectionId,
                                      workPermitForm.get('sections').value[i]
                                        .questions[j].questionId
                                    )
                                  "
                                >
                                  <mat-icon
                                    class="mr-2"
                                    svgIcon="mat:file_upload"
                                  ></mat-icon>
                                  Upload File
                                </button>
                                <div class="flex-row">
                                  <mat-label class="field-label"
                                    >Description</mat-label
                                  >
                                  <mat-form-field
                                    class="input-field"
                                    appearance="outline"
                                  >
                                    <textarea
                                      matInput
                                      rows="3"
                                      formControlName="comment"
                                      [placeholder]="'comment'"
                                    >
                                    </textarea>
                                  </mat-form-field>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ol>
          </div>
          <div style="margin-left: 20px">
            <ngx-gauge
              [foregroundColor]="getGuageColors(gaugeValue)"
              [type]="'full'"
              [value]="gaugeValue"
              [label]=""
              [append]="'%'"
              [thick]="20"
            ></ngx-gauge>
            <h4 class="font-bold text-center">Response Score</h4>
          </div>
        </div>
      </div>
    </app-expandable-panel>
  </div>
</form>

<ng-template #imageBigView>
  <div class="flex items-center" mat-dialog-title>
    <img [src]="selectedImg?.imageUrl" class="avatar mr-5" />
    <h2 class="headline m-0 flex-auto">{{ data?.title }}</h2>
    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div class="flex">
    <div class="">
      <img [src]="selectedImg?.imageUrl" [alt]="data?.title" />
    </div>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
    <app-basic-button
      [btnType]="'Delete'"
      [btnText]="'Delete'"
      (onClick)="deleteImage()"
    ></app-basic-button>
  </div>
</ng-template>

<ng-template #mediaModal>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">Upload Media</h2>
    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div>
    <form [formGroup]="mediaForm">
      <div class="flex column">
        <file-upload
          formControlName="file"
          [multiple]="true"
          [animation]="true"
        ></file-upload>
      </div>
    </form>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
    <app-basic-button
      [btnType]="'Add'"
      [btnText]="'Submit'"
      [isDisabled]="!mediaForm.valid"
      (onClick)="uploadAnswerMedia()"
    ></app-basic-button>
  </div>
</ng-template>
