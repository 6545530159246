<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.Organizations' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <app-hid-subscription-info></app-hid-subscription-info>
  </hodhod-page-layout-content>
</hodhod-page-layout>
