<div class="">
  <div class="h-16  flex items-center justify-between">
    <div
      class="bg-foreground px-4 flex items-center flex-1 "
      style="border-radius: 5px"
    >
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input
        [formControl]="searchCtrl"
        class="px-4 py-2 border-0 outline-none w-full bg-transparent"
        [placeholder]="'MSDS.SearchMSDS' | translate"
        type="search"
      />
    </div>

    <app-basic-button
      *ngIf="hasCreatePermission | permissionChecker"
      [matTooltip]="'General.Buttons.AddNew' | translate"
      [btnType]="'Add'"
      [btnText]="'NewMSDS'"
      [link]="'add.svg'"
      (onClick)="addNewMsds()"
    ></app-basic-button>
  </div>

  <app-state-section
    body
    [state]="sectionState"
    [loadingLabel]="loadingLabel | translate"
    [flexibleHeight]="true"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <table [dataSource]="dataSource" class="w-full" mat-table matSort>
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <!-- text -->
        <ng-container
          *ngIf="
            column.type === 'text' &&
            column.property !== 'hazardIdentifications'
          "
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ "MSDS.Table." + column.label | translate }}&nbsp;
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            #div
            class="truncate max-w-0 p-3"
            mat-cell
          >
            <span
              [matTooltip]="
                div?.offsetWidth < div?.scrollWidth
                  ? row[column.property]
                  : null
              "
            >
              {{ row[column.property] }}
            </span>
          </td>
        </ng-container>

        <!-- Hazard Identifications -->
        <ng-container
          *ngIf="
            column.type === 'text' &&
            column.property === 'hazardIdentifications'
          "
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef mat-header-cell>
            <div
              style="width: 100%; position: relative"
              class="flex items-center"
            >
              <mat-icon
                app-click-stop-propagation
                style="width: 35px; height: 35px; cursor: pointer"
                svgIcon="mat:keyboard_arrow_down"
                (click)="select.open()"
                color="primary"
              ></mat-icon>
              {{ "MSDS.Table." + column.label | translate }}
              <mat-select
                class="select-options"
                #select
                multiple
                (selectionChange)="onSelectStatus($event)"
              >
                <mat-optgroup *ngFor="let group of groups" [label]="group.name">
                  <mat-option
                    class="opt"
                    *ngFor="let subItem of group.subOptions"
                    [value]="subItem"
                  >
                    {{ subItem.name }}
                  </mat-option>
                </mat-optgroup>
                <mat-option class="opt p-0" [value]="specialHazardStatus">
                  {{ specialHazardStatus.name }}
                </mat-option>
              </mat-select>
              <mat-select
                [value]="selectedStatus"
                (selectionChange)="onSelectStatus($event)"
                #select
                class="select-options"
                multiple
              >
                <mat-option
                  *ngFor="let statue of msdsHazardIdentifications | enumToArray"
                  [value]="statue.value"
                >
                  {{ "MSDS.Enum." + statue.value | translate }}
                </mat-option>
              </mat-select>
            </div>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div class="flex justify-center align-center flex-wrap">
              <img
                class="table-img"
                src="/assets/img/resources/flammable.png"
                *ngIf="row['hazardIdentifications']['flammable'] > 0"
              />
              <img
                class="table-img"
                src="/assets/img/resources/reactive.png"
                *ngIf="row['hazardIdentifications']['reactive'] > 0"
              />
              <img
                class="table-img"
                src="/assets/img/resources/health-hazard.png"
                *ngIf="row['hazardIdentifications']['healthHazard'] > 0"
              />
              <img
                class="table-img"
                src="/assets/img/resources/special-hazard.png"
                *ngIf="row['hazardIdentifications']['specialHazard'] !== 'None'"
              />
            </div>
          </td>
        </ng-container>

        <!-- status -->
        <ng-container
          *ngIf="column.type === 'boolean' && column.property === 'status'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div
              style="width: 100%; position: relative"
              class="flex items-center"
            >
              <mat-icon
                app-click-stop-propagation
                style="width: 35px; height: 35px; cursor: pointer"
                (click)="select.open()"
                svgIcon="mat:keyboard_arrow_down"
                color="primary"
              ></mat-icon>
              {{ "MSDS.Table." + column.label | translate }}
              <mat-select
                [value]="selectedStatus"
                (selectionChange)="onSelectStatusChange($event)"
                #select
                class="select-options"
                multiple
              >
                <mat-option
                  *ngFor="let statue of msdsStatus | enumToArray"
                  [value]="statue.value"
                >
                  {{ "Enum.MsdsStatus." + statue.value | translate }}
                </mat-option>
              </mat-select>
            </div>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div
              class="flex justify-center align-center"
              [attr.id]="row[column.property]"
            >
              <span>{{
                "Enum.MsdsStatus." + row[column.property] | translate
              }}</span>
            </div>
          </td>
        </ng-container>

        <!-- Date -->
        <ng-container
          *ngIf="column.type === 'date'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ "MSDS.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] | date : "medium" }}
          </td>
        </ng-container>

        <!-- Actions -->
        <ng-container
          stickyEnd
          *ngIf="column.type === 'button'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef mat-header-cell></th>

          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div (click)="$event.stopPropagation()">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon svgIcon="mat:more_vert"></mat-icon>
              </button>
              <mat-menu
                #menu="matMenu"
                yPosition="below"
                xPosition="before"
                class="shadow-lg shadow-gray-400 rounded-2xl min-h-0"
              >
                <div
                  mat-menu-item
                  class="flex items-center justify-between p-1 mat-div"
                  *ngIf="hasViewPermission | permissionChecker"
                  (click)="viewMsds(row?.id)"
                >
                  <button>
                    {{ "General.Buttons.View" | translate }}
                  </button>
                  <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
                </div>
                <div
                  mat-menu-item
                  class="flex items-center justify-between p-1 mat-div"
                  *ngIf="hasDeletePermission | permissionChecker"
                  (click)="deleteMsds(row?.id)"
                >
                  <button>
                    {{ "General.Buttons.Delete" | translate }}
                  </button>
                  <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
                </div>
              </mat-menu>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: visibleColumns"
        class="hover:bg-hover trans-ease-out cursor-pointer"
        mat-row
        (click)="viewMsds(row?.id)"
      ></tr>
    </table>

    <div
      *ngIf="dataSource?.filteredData.length === 0"
      @scaleFadeIn
      class="flex-auto flex flex-col items-center justify-center"
    >
      <img src="assets/img/resources/empty-state.svg" alt="not found" />
      <h2 class="headline m-0 text-center text-primary">
        {{ "General.EmptyTableList" | translate }}...
      </h2>
    </div>

    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      showFirstLastButtons
      class="sticky left-0"
    >
    </mat-paginator>
  </app-state-section>
</div>
