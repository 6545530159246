<ng-template #sidenavRef>
  <hodhod-sidenav [collapsed]="(sidenavCollapsed$ | async) || false"></hodhod-sidenav>
</ng-template>

<ng-template #toolbarRef>
  <hodhod-toolbar [hasShadow]="toolbarShadowEnabled$ | async"
               [mobileQuery]="!(isDesktop$ | async)"
               class="hodhod-toolbar"></hodhod-toolbar>
</ng-template>

<ng-template #footerRef>
  <!-- <hodhod-footer *ngIf="isFooterVisible$ | async" class="hodhod-footer"></hodhod-footer> -->
</ng-template>

<ng-template #quickpanelRef>
  <hodhod-quickpanel></hodhod-quickpanel>
</ng-template>

<hodhod-layout [footerRef]="footerRef"
            [quickpanelRef]="quickpanelRef"
            [sidenavRef]="sidenavRef"
            [toolbarRef]="toolbarRef"></hodhod-layout>

<hodhod-config-panel-toggle (openConfig)="configpanel.open()"></hodhod-config-panel-toggle>

<!-- CONFIGPANEL -->
<hodhod-sidebar #configpanel [invisibleBackdrop]="true" position="right">
  <hodhod-config-panel></hodhod-config-panel>
</hodhod-sidebar>
<!-- END CONFIGPANEL -->

