import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseApi } from 'src/backend/api/base-api';
import { Constants } from 'src/@hodhod/common/constants';

@Injectable({
  providedIn: 'root',
})
export class ChatbotService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  initializeChatSession(): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_INITIALIZE_CHAT_SESSION),
      {}
    );
  }

  processChatMessage(data: any, layer: string): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(
        Constants.API_INITIALIZE_CHAT_MESSAGE + layer + '/chat'
      ),
      data
    );
  }

  saveUserFeedBack(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_SAVE_USER_FEEDBACK),
      data
    );
  }

  getLinks(id): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_TRUSTED_LINKS + '/' + id),
      {}
    );
  }
  checkavAilability(id, name): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_NAME_AVAILABILITY_TRUSTED_LINKS + '/' + id + '/' + name
      ),
      {}
    );
  }
  getDocuments(companyId: string): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_GET_CHATBOT_DOCUMENTS + '/' + companyId
      )
    );
  }

  uploadDocuments(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_UPLOAD_CHATBOT_DOCUMENT),
      data
    );
  }

  updateDocument(id, data: any): Observable<any> {
    return this.http.patch<any>(
      this.baseApi.encodeURL(Constants.API_GET_CHATBOT_DOCUMENTS + '/' + id),
      data
    );
  }

  addLinks(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_ADD_TRUSTED_LINKS),
      data
    );
  }
  updateLinks(data: any, id: any): Observable<any> {
    return this.http.patch<any>(
      this.baseApi.encodeURL(Constants.API_ADD_TRUSTED_LINKS + id),
      data
    );
  }

  downloadDocument(docId: any): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_DOWNLOAD_CHATBOT_DOCUMENT + '/' + docId
      ),
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/octet-stream',
        },
        observe: 'body',
        responseType: 'blob' as 'json',
      }
    );
  }

  validateDocumentName(data: any): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_VALIDATE_CHATBOT_DOCUMENT_NAME +
          '/' +
          data.companyId +
          '/' +
          data.documentName
      )
    );
  }

  deleteDocument(id: any): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(Constants.API_GET_CHATBOT_DOCUMENTS + '/' + id)
    );
  }

  deleteAllDocuments(companyId): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(
        Constants.API_DELETE_ALL_CHATBOT_DOCUMENTS + '/' + companyId
      )
    );
  }
  deleteAllLinks(id): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(Constants.API_REMOVE_ALL_TRUSTED_LINKS + '/' + id),
      {}
    );
  }
  deleteSelectedLinks(data): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_REMOVE_SELECTED_TRUSTED_LINKS),
      data
    );
  }
  deleteSelectedDocuments(data): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_REMOVE_SELECTED_DOCUMENTS),
      data
    );
  }
  deleteLink(id: any): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(Constants.API_REMOVE_TRUSTED_LINKS + id)
    );
  }
  Reindex(): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_REINDEX),
      {}
    );
  }
}
