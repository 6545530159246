<app-state-section
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="
    (formMode === 'create' ? 'General.Saving' : 'General.Updating') | translate
  "
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <form [formGroup]="equipmentCategoryForm">
    <div class="flex items-center" mat-dialog-title>
      <label for="fileInput">
        <img
          [src]="
            categoryImage
              ? categoryImage
              : 'assets/img/resources/add-new-img.svg'
          "
          style="width: 50px; height: 50px; margin-inline-end: 10px"
          alt="Upload Image"
          class="rounded-full"
        />
        <input
          type="file"
          id="fileInput"
          accept="image/*"
          (change)="onFileSelected($event)"
          style="display: none"
          formControlName="categoryImage"
        />
      </label>
      <h2
        *ngIf="equipmentCategoryForm.get('name').value"
        class="headline m-0 flex-auto"
      >
        {{ equipmentCategoryForm.get("name").value }}
      </h2>
      <h2
        *ngIf="!equipmentCategoryForm.get('name').value"
        class="headline m-0 flex-auto"
      >
        {{ "General.AddNew" | translate }}
      </h2>
      <button
        class="text-secondary"
        (click)="closeDialog()"
        mat-icon-button
        type="button"
      >
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

    <mat-dialog-content class="flex column">
      <div class="flex-row">
        <!-- first row -->
        <div class="flex column">
          <mat-label class="field-label"
            >{{ "Machine.EquipmentsCategory.Name" | translate }}
            <span
              [ngClass]="
                equipmentCategoryForm.invalid &&
                equipmentCategoryForm.get('name').hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
              >*</span
            >
          </mat-label>

          <mat-form-field class="input-field" appearance="outline">
            <input
              [placeholder]="
                ('Placeholder.Insert' | translate) +
                ' ' +
                ('Machine.EquipmentsCategory.Name' | translate)
              "
              formControlName="name"
              matInput
              type="text"
              maxlength="100"
            />
            <mat-error
              *ngIf="
                equipmentCategoryForm.invalid &&
                equipmentCategoryForm.get('name').hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- second row -->
        <div class="flex justify-between flex-wrap-reverse">
          <div class="flex flex-1 items-center margin-end-2">
            <div class="flex-1 column">
              <mat-label class="field-label"
                >{{ "Machine.EquipmentsCategory.Code" | translate }}
                <span
                  [ngClass]="
                    equipmentCategoryForm.invalid &&
                    equipmentCategoryForm.get('code').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <input
                  [placeholder]="
                    ('Placeholder.Insert' | translate) +
                    ' ' +
                    ('Machine.EquipmentsCategory.Code' | translate)
                  "
                  formControlName="code"
                  matInput
                  type="text"
                  maxlength="10"
                />
                <mat-error
                  *ngIf="
                    equipmentCategoryForm.invalid &&
                    equipmentCategoryForm.get('code').hasError('required')
                  "
                >
                  <div class="flex justify-flex-start align-flex-center">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <img
                src="/assets/img/resources/info-icon.png"
                class="mx-2"
                [matTooltip]="
                  'Machine.EquipmentsCategory.CodeMessage' | translate
                "
                [matTooltipPosition]="'right'"
              />
            </div>
          </div>
          <div class="flex-1 column">
            <mat-label class="field-label"
              >{{ "Machine.EquipmentsCategory.Quantity" | translate }}
              <span
                [ngClass]="
                  equipmentCategoryForm.invalid &&
                  equipmentCategoryForm.get('equipmentQuantity').invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
                >*</span
              >
            </mat-label>
            <mat-form-field class="input-field" appearance="outline">
              <input
                [placeholder]="
                  ('Placeholder.Insert' | translate) +
                  ' ' +
                  ('Machine.EquipmentsCategory.Quantity' | translate)
                "
                formControlName="equipmentQuantity"
                matInput
                type="number"
                min="0"
                max="1000"
                (keydown)="onKeyDown($event)"
                (wheel)="onWheel($event)"
              />
              <mat-error
                *ngIf="
                  equipmentCategoryForm.invalid &&
                  equipmentCategoryForm
                    .get('equipmentQuantity')
                    .hasError('required')
                "
              >
                <div class="flex justify-flex-start align-flex-center">
                  <img
                    class="error-img"
                    src="assets/img/resources/error-icon.svg"
                  />
                  <span>{{ "Errors.Required" | translate }}</span>
                </div>
              </mat-error>
              <mat-error
                *ngIf="
                  equipmentCategoryForm.invalid &&
                  equipmentCategoryForm.get('equipmentQuantity').hasError('max')
                "
              >
                <div class="flex justify-flex-start align-flex-center">
                  <img
                    class="error-img"
                    src="assets/img/resources/error-icon.svg"
                  />
                  <span>{{ "Errors.valueLimit1000" | translate }}</span>
                </div>
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- third row -->
        <div class="flex justify-between flex-wrap-reverse">
          <div class="flex-1 margin-end-2">
            <div class="flex column">
              <mat-label class="field-label"
                >{{
                  "Machine.EquipmentsCategory.InspectionStartDate" | translate
                }}
                <span
                  [ngClass]="
                    equipmentCategoryForm.invalid &&
                    equipmentCategoryForm
                      .get('inspectionStartDate')
                      .hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field appearance="outline">
                <input
                  [placeholder]="'Placeholder.SelectDate' | translate"
                  matInput
                  [matDatepicker]="inspectionStartDate"
                  (click)="inspectionStartDate.open()"
                  formControlName="inspectionStartDate"
                  [min]="formMode == 'create' ? tomorrowDate : ''"
                  (dateChange)="onChangeStartDate($event)"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="inspectionStartDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #inspectionStartDate></mat-datepicker>
                <mat-error
                  *ngIf="
                    equipmentCategoryForm.invalid &&
                    equipmentCategoryForm
                      .get('inspectionStartDate')
                      .hasError('invalidDate')
                  "
                >
                  <div class="flex justify-flex-start align-flex-center">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span> Please, Enter Valid Date </span>
                  </div>
                </mat-error>
                <mat-error
                  *ngIf="
                    equipmentCategoryForm.invalid &&
                    equipmentCategoryForm
                      .get('inspectionStartDate')
                      .hasError('required')
                  "
                >
                  <div class="flex justify-flex-start align-flex-center">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="flex-1 column">
            <mat-label class="field-label">{{
              "Machine.EquipmentsCategory.MachineLocation" | translate
            }}</mat-label>
            <mat-form-field class="input-field" appearance="outline">
              <input
                [placeholder]="
                  ('Placeholder.Insert' | translate) +
                  ' ' +
                  ('Machine.EquipmentsCategory.MachineLocation' | translate)
                "
                formControlName="machinesLocation"
                matInput
                type="text"
                maxlength="100"
              />
            </mat-form-field>
          </div>
        </div>

        <!-- fourth row -->
        <div class="flex justify-between flex-wrap-reverse">
          <div class="flex flex-1 items-center">
            <div class="flex-1 column">
              <mat-label class="field-label"
                >{{ "Machine.EquipmentsCategory.Inspectors" | translate }}
                <span
                  [ngClass]="
                    equipmentCategoryForm.invalid &&
                    equipmentCategoryForm
                      .get('InspectorsIds')
                      .hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <mat-select
                  ngxMatSelect
                  #msf2="ngxMatSelect"
                  [displayMember]="'fullName'"
                  [valueMember]="'userId'"
                  [source]="inspectors"
                  [useInfiniteScroll]="true"
                  formControlName="InspectorsIds"
                  [hasPermission]="hasCreateUserPermission | permissionChecker"
                  [multiple]="true"
                  [manageRoute]="'/user-management/users'"
                  [placeholder]="'Placeholder.Select' | translate"
                >
                  <mat-option
                    [value]="item.userId"
                    *ngFor="let item of msf2.filteredSource"
                  >
                    {{ item.fullName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <img
                src="/assets/img/resources/info-icon.png"
                class="mx-2"
                [matTooltip]="
                  'Machine.EquipmentsCategory.InspectorsMessage' | translate
                "
                [matTooltipPosition]="'right'"
              />
            </div>
          </div>

          <div class="flex flex-1 margin-start-2 items-center">
            <div class="flex-1 column">
              <mat-label class="field-label"
                >{{ "Machine.EquipmentsCategory.CategoryInCharge" | translate }}
                <span
                  [ngClass]="
                    equipmentCategoryForm.invalid &&
                    equipmentCategoryForm
                      .get('reviewersIds')
                      .hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <mat-select
                  ngxMatSelect
                  #msf1="ngxMatSelect"
                  [displayMember]="'fullName'"
                  [valueMember]="'userId'"
                  [source]="reviewers"
                  [useInfiniteScroll]="true"
                  formControlName="reviewersIds"
                  [hasPermission]="hasCreateUserPermission | permissionChecker"
                  [multiple]="true"
                  [manageRoute]="'/user-management/users'"
                  [placeholder]="'Placeholder.Select' | translate"
                >
                  <mat-option
                    [value]="item.userId"
                    *ngFor="let item of msf1.filteredSource"
                  >
                    {{ item.fullName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <img
                src="/assets/img/resources/info-icon.png"
                class="mx-2"
                [matTooltip]="
                  'Machine.EquipmentsCategory.CategoryInChargeMessage'
                    | translate
                "
                [matTooltipPosition]="'right'"
              />
            </div>
          </div>
        </div>

        <!-- fifth row -->
        <div class="flex column">
          <mat-label class="field-label"
            >{{ "Machine.EquipmentsCategory.InspectionFrequency" | translate }}
            <span
              [ngClass]="
                equipmentCategoryForm.invalid &&
                equipmentCategoryForm
                  .get('inspectionFrequency')
                  .hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
              >*</span
            >
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <mat-select
              formControlName="inspectionFrequency"
              [placeholder]="'Placeholder.Select' | translate"
              (selectionChange)="onChangeFrequency($event)"
            >
              <mat-option
                *ngFor="let frequency of InspectionFrequencyTypes | enumToArray"
                [value]="frequency.value"
              >
                {{ frequency.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- shifts frequency -->
        <div
          class="flex column"
          *ngIf="
            equipmentCategoryForm.value.inspectionFrequency ===
            InspectionFrequencyTypes.Shift
          "
        >
          <mat-label class="field-label"
            >{{ "AddReportHazard.Shift" | translate }}
            <span
              [ngClass]="
                equipmentCategoryForm.invalid &&
                equipmentCategoryForm.get('shiftIds').hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
              >*</span
            >
          </mat-label>
          <mat-form-field appearance="outline">
            <mat-select
              ngxMatSelect
              #msf5="ngxMatSelect"
              [displayMember]="'shiftTitle'"
              [valueMember]="'shiftId'"
              [source]="shifts"
              [useInfiniteScroll]="true"
              [multiple]="true"
              [hasPermission]="hasCreateShiftsPermission | permissionChecker"
              [title]="'shifts'"
              [hasSearchBox]="false"
              [manageRoute]="'/company-setup/shifts-setting'"
              [placeholder]="'AddReportIncident.Select' | translate"
              formControlName="shiftIds"
            >
              <ng-container
                *ngFor="let shift of msf5.filteredSource; let i = index"
              >
                <mat-option [value]="shift.shiftId">
                  {{ shift.shiftTitle }}
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-error
              *ngIf="
                equipmentCategoryForm.invalid &&
                equipmentCategoryForm.get('shiftIds').hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- daily frequency -->
        <div
          class="flex column"
          *ngIf="
            equipmentCategoryForm.value.inspectionFrequency ===
            InspectionFrequencyTypes.Daily
          "
        >
          <div class="flex column">
            <mat-label class="field-label"
              >{{ "InspectionPlan.StartTime" | translate }}
              <span
                [ngClass]="
                  equipmentCategoryForm.invalid &&
                  equipmentCategoryForm
                    .get('inspectionResetHour')
                    .hasError('required')
                    ? 'is-invalid'
                    : 'is-valid'
                "
                >*</span
              >
            </mat-label>
            <mat-form-field class="input-field" appearance="outline">
              <input
                matInput
                [format]="24"
                [ngxMatTimepicker]="picker"
                formControlName="inspectionResetHour"
              />
              <ngx-mat-timepicker
                #picker
                [hoursOnly]="true"
              ></ngx-mat-timepicker>
            </mat-form-field>
          </div>
        </div>

        <!-- weekly frequency -->
        <div
          class="flex column"
          *ngIf="
            equipmentCategoryForm.value.inspectionFrequency ===
            InspectionFrequencyTypes.Weekly
          "
        >
          <mat-label class="field-label"
            >{{ "Machine.EquipmentsCategory.TaskDay" | translate }}
            <span
              [ngClass]="
                equipmentCategoryForm.invalid &&
                equipmentCategoryForm
                  .get('inspectionResetDay')
                  .hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
              >*</span
            >
          </mat-label>
          <mat-form-field appearance="outline">
            <mat-select
              formControlName="inspectionResetDay"
              [placeholder]="'Placeholder.Select' | translate"
            >
              <mat-option
                *ngFor="let item of days | enumToArray"
                [value]="item.value"
              >
                {{ item.value }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                equipmentCategoryForm.invalid &&
                equipmentCategoryForm
                  .get('inspectionResetDay')
                  .hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- monthly frequency -->
        <div
          class="flex column"
          *ngIf="
            equipmentCategoryForm.value.inspectionFrequency ===
            InspectionFrequencyTypes.Monthly
          "
        >
          <mat-label class="field-label"
            >{{ "Machine.EquipmentsCategory.WeekNumber" | translate }}
            <span
              [ngClass]="
                equipmentCategoryForm.invalid &&
                equipmentCategoryForm
                  .get('inspectionResetWeek')
                  .hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
              >*</span
            >
          </mat-label>
          <mat-form-field appearance="outline">
            <mat-select
              formControlName="inspectionResetWeek"
              [placeholder]="'Placeholder.Select' | translate"
            >
              <mat-option *ngFor="let item of weeksNumber" [value]="item">
                {{ item }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                equipmentCategoryForm.invalid &&
                equipmentCategoryForm
                  .get('inspectionResetWeek')
                  .hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div
          class="flex column"
          *ngIf="
            equipmentCategoryForm.value.inspectionFrequency ===
            InspectionFrequencyTypes.Monthly
          "
        >
          <mat-label class="field-label"
            >{{ "Machine.EquipmentsCategory.TaskDay" | translate }}
            <span
              [ngClass]="
                equipmentCategoryForm.invalid &&
                equipmentCategoryForm
                  .get('inspectionResetDay')
                  .hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
              >*</span
            >
          </mat-label>
          <mat-form-field appearance="outline">
            <mat-select
              formControlName="inspectionResetDay"
              [placeholder]="'Placeholder.Select' | translate"
            >
              <mat-option
                *ngFor="let item of days | enumToArray"
                [value]="item.value"
              >
                {{ item.value }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                equipmentCategoryForm.invalid &&
                equipmentCategoryForm
                  .get('inspectionResetDay')
                  .hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- sixth row -->
        <div class="flex column">
          <mat-label class="field-label"
            >{{ "Machine.EquipmentsCategory.InspectionChecklist" | translate }}
            <span
              [ngClass]="
                equipmentCategoryForm.invalid &&
                equipmentCategoryForm
                  .get('InspectionChecklistsIds')
                  .hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
              >*</span
            >
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <mat-select
              ngxMatSelect
              #msf3="ngxMatSelect"
              [displayMember]="'name'"
              [valueMember]="'id'"
              [source]="checklists"
              [useInfiniteScroll]="true"
              formControlName="InspectionChecklistsIds"
              [multiple]="true"
              [hasPermission]="
                hasCreateChecklistPermissions | permissionChecker
              "
              [title]="'Checklists'"
              [manageRoute]="'/safety/manage-checklist'"
              [placeholder]="'InspectionPlan.Checklist' | translate"
              (selectionChange)="resetStartDate()"
            >
              <mat-option
                *ngFor="let w of msf3.filteredSource"
                [value]="w.id"
                >{{ w.name }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                equipmentCategoryForm.invalid &&
                equipmentCategoryForm
                  .get('InspectionChecklistsIds')
                  .hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- seventh row -->
        <div class="flex column">
          <mat-label class="field-label">{{
            "Machine.EquipmentsCategory.Description" | translate
          }}</mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <textarea
              [placeholder]="
                ('Placeholder.Insert' | translate) +
                ' ' +
                ('Machine.EquipmentsCategory.Description' | translate)
              "
              formControlName="categoryDescription"
              matInput
              type="text"
              rows="5"
              maxlength="500"
            ></textarea>
            <mat-error
              *ngIf="
                equipmentCategoryForm.invalid &&
                equipmentCategoryForm
                  .get('categoryDescription')
                  .hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- eighth row -->
        <div class="flex column">
          <file-upload
            formControlName="attachments"
            [multiple]="true"
            [animation]="true"
          ></file-upload>
        </div>
        <div class="flex flex-wrap gap-2 my-4" *ngIf="data">
          <div class="relative" *ngFor="let img of images">
            <div
              class="cursor-pointer bg-primary text-white rounded-full absolute right-0 top-0 z-10 flex items-center justify-center"
              style="width: 20px; height: 20px"
              (click)="deleteImage(img)"
            >
              <mat-icon svgIcon="mat:close" class="icon-sm"></mat-icon>
            </div>
            <ngx-avatars
              class="cursor-pointer"
              size="60"
              [src]="getAttachmentImage(img.attachment)"
              [name]="getAttachementTypeName(img.attachment)"
              [textSizeRatio]="2"
              [round]="false"
              [initialsSize]="2"
              [cornerRadius]="10"
              [matTooltip]="img['fileName']"
              [borderColor]="'#1F36B4'"
              (clickOnAvatar)="openBigView(img.id)"
            >
            </ngx-avatars>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <div class="flex items-center">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel'"
          (onClick)="closeDialog()"
        ></app-basic-button>
        <app-basic-button
          [btnType]="formMode === 'create' ? 'Add' : 'Add'"
          [btnText]="formMode === 'create' ? 'Add' : 'Save'"
          [isDisabled]="!equipmentCategoryForm.valid"
          (onClick)="
            formMode === 'create' ? addNewCategory() : updateEquipmentCategory()
          "
        ></app-basic-button>
      </div>
    </mat-dialog-actions>
  </form>
</app-state-section>
