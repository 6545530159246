export interface ColorVariable {
  light: string;
  default: string;
  contrast: string;
}
const companyData = JSON.parse(localStorage.getItem('company'));

const hexToRgb = (hex) => {
  const rgbValues = hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => '#' + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));

  return `rgb(${rgbValues[0]},${rgbValues[1]},${rgbValues[2]})`;
};

export const colorVariables: Record<string, ColorVariable> = {
  customTheme: {
    light: companyData?.themeColor
      ? hexToRgb(companyData?.themeColor).replace(')', ',.1)')
      : '',
    default: companyData?.themeColor ? hexToRgb(companyData?.themeColor) : '',
    contrast: companyData?.secondaryColor
      ? hexToRgb(companyData?.secondaryColor)
      : '',
  },
  blue: {
    light: 'rgba(31,54,180, .1)',
    default: 'rgb(31,54,180)',
    contrast: 'rgb(255, 255, 255)',
  },
  gray: {
    light: 'rgba(158, 158, 158, 0.1)',
    default: 'rgb(158, 158, 158)',
    contrast: 'rgb(255, 255, 255)',
  },
  red: {
    light: 'rgba(213, 42, 70, 0.1)',
    default: 'rgb(213, 42, 70)',
    contrast: 'rgb(255, 255, 255)',
  },
  orange: {
    light: 'rgba(255, 152, 0, 0.1)',
    default: 'rgb(255, 152, 0)',
    contrast: 'rgb(0, 0, 0)',
  },
  'deep-orange': {
    light: 'rgba(255, 87, 34, 0.1)',
    default: 'rgb(255, 87, 34)',
    contrast: 'rgb(255, 255, 255)',
  },
  amber: {
    light: 'rgba(255, 193, 7, 0.1)',
    default: 'rgb(255, 193, 7)',
    contrast: 'rgb(0, 0, 0)',
  },
  green: {
    light: 'rgba(76, 175, 80, 0.1)',
    default: 'rgb(76, 175, 80)',
    contrast: 'rgb(255, 255, 255)',
  },
  teal: {
    light: 'rgba(0, 150, 136, 0.1)',
    default: 'rgb(0, 150, 136)',
    contrast: 'rgb(255, 255, 255)',
  },
  cyan: {
    light: 'rgba(0, 188, 212, 0.1)',
    default: 'rgb(0, 188, 212)',
    contrast: 'rgb(255, 255, 255)',
  },
  purple: {
    light: 'rgba(156, 39, 176, 0.1)',
    default: 'rgb(156, 39, 176)',
    contrast: 'rgb(255, 255, 255)',
  },
  'deep-purple': {
    light: 'rgba(103, 58, 183, 0.1)',
    default: 'rgb(103, 58, 183)',
    contrast: 'rgb(255, 255, 255)',
  },
  pink: {
    light: 'rgba(233, 30, 99, 0.1)',
    default: 'rgb(233, 30, 99)',
    contrast: 'rgb(255, 255, 255)',
  },
};
