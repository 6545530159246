import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { AddUpdateMsdsComponent } from '../add-update-msds/add-update-msds.component';
import { MsdsHazardIdentifications, MsdsStatus } from 'src/@hodhod/common/enum';
import { MsdsService } from 'src/backend/services/msds/msds.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { takeUntil, merge, tap } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-msds-list',
  templateUrl: './msds-list.component.html',
  styleUrls: ['./msds-list.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class MsdsListComponent extends BaseComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  loadingLabel: string = '';
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public sectionStateModal: SectionStateStatus = SectionStateStatus.Ready;

  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  timer = null;

  msdsList: any = [];
  msdsStatus = MsdsStatus;

  dataSource: MatTableDataSource<any> | null;
  columns: TableColumn<any>[] = [
    {
      label: 'MaterialCode',
      property: 'materialCode',
      type: 'text',
      visible: true,
    },
    {
      label: 'MaterialName',
      property: 'materialName',
      type: 'text',
      visible: true,
    },
    {
      label: 'Department',
      property: 'departmentParentName',
      type: 'text',
      visible: true,
    },
    {
      label: 'SubDepartment',
      property: 'departmentName',
      type: 'text',
      visible: true,
    },
    {
      label: 'StorageLocation',
      property: 'storageLocation',
      type: 'text',
      visible: true,
    },
    {
      label: 'Hazards',
      property: 'hazardIdentifications',
      type: 'text',
      visible: true,
    },
    {
      label: 'Status',
      property: 'status',
      type: 'boolean',
      visible: true,
    },
    {
      label: 'UpdateAt',
      property: 'updatedOn',
      type: 'date',
      visible: true,
    },
    {
      label: 'Actions',
      property: 'actions',
      type: 'button',
      visible: true,
      cssClasses: ['action-cell'],
    },
  ];

  public msdsHazardIdentifications = MsdsHazardIdentifications;
  public selectedStatus: string[] = [];
  public selectedMsdsStatus: string[] = [];

  groups = [
    {
      name: 'Flammable',
      subOptions: [
        { name: 'All', value: 'Flammable-all' },
        { name: '1', value: 'Flammable-1' },
        { name: '2', value: 'Flammable-2' },
        { name: '3', value: 'Flammable-3' },
        { name: '4', value: 'Flammable-4' },
      ],
    },
    {
      name: 'Reactive',
      subOptions: [
        { name: 'All', value: 'Reactive-all' },
        { name: '1', value: 'Reactive-1' },
        { name: '2', value: 'Reactive-2' },
        { name: '3', value: 'Reactive-3' },
        { name: '4', value: 'Reactive-4' },
      ],
    },
    {
      name: 'Health Hazard',
      subOptions: [
        { name: 'All', value: 'HealthHazard-all' },
        { name: '1', value: 'HealthHazard-1' },
        { name: '2', value: 'HealthHazard-2' },
        { name: '3', value: 'HealthHazard-3' },
        { name: '4', value: 'HealthHazard-4' },
      ],
    },
  ];

  specialHazardStatus = {
    name: 'Special Hazard',
    value: 'specialHazard',
  };

  flammableSeverity: any = [];
  reactiveSeverity: any = [];
  healthySeverity: any = [];
  specialHazard: any = '';

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;

  hasViewPermission = ApplicationPermission.MSDS_VIEW;
  hasCreatePermission = ApplicationPermission.MSDS_CREATE;
  hasUpdatePermission = ApplicationPermission.MSDS_UPDATE;
  hasDeletePermission = ApplicationPermission.MSDS_DELETE;

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  private translationsList: any = {};

  constructor(
    private dialog: MatDialog,
    private feedBackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private msdsServices: MsdsService
  ) {
    super();
    this.translate
      .get([
        'Errors',
        'Success',
        'confirmDeactiveRecord',
        'ConfirmDeleteRecord',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit(): void {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = Constants.PAGE_SIZE;
    this.sort.sortChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.getMsds())
      )
      .subscribe();

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getMsds();
  }

  addNewMsds() {
    const msdsDialog = this.dialog.open(AddUpdateMsdsComponent, {
      height: '90vh',
      width: '70%',
      data: { formMode: 'create' },
    });

    msdsDialog.afterClosed().subscribe((res) => {
      if (res?.event === 'createdNew') {
        this.getMsds();
      }
    });
  }

  onSelectStatus(event: MatSelectChange) {
    this.flammableSeverity = [];
    this.reactiveSeverity = [];
    this.healthySeverity = [];
    this.specialHazard = '';
    event.value.forEach((element) => {
      if (element?.value.includes('Flammable')) {
        if (element.value.includes('Flammable-all')) {
          this.flammableSeverity = [1, 2, 3, 4];
        } else {
          if (!this.flammableSeverity.some((item) => item == element.name)) {
            this.flammableSeverity.push(element.name);
          }
        }
      } else if (element?.value.includes('Reactive')) {
        if (element.value.includes('Reactive-all')) {
          this.reactiveSeverity = [1, 2, 3, 4];
        } else {
          if (!this.reactiveSeverity.some((item) => item == element.name)) {
            this.reactiveSeverity.push(element.name);
          }
        }
      } else if (element?.value.includes('HealthHazard')) {
        if (element.value.includes('HealthHazard-all')) {
          this.healthySeverity = [1, 2, 3, 4];
        } else {
          if (!this.healthySeverity.some((item) => item == element.name)) {
            this.healthySeverity.push(element.name);
          }
        }
      } else if (element.value.includes('specialHazard')) {
        this.specialHazard = 'specialHazard';
      }
    });

    this.getMsds();
  }

  onSelectStatusChange(event: MatSelectChange) {
    this.selectedMsdsStatus = event.value;
    this.getMsds();
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    clearTimeout(this.timer);
    this.paginator.pageIndex = 0;
    this.timer = setTimeout(() => {
      this.getMsds();
    }, 700);
  }

  getMsds() {
    const filteredParams = {
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      searchValue: this.searchValue,
      sortField: this.sort.active ? this.sort.active : null,
      sortDirection: this.sort.direction,
      status: this.selectedMsdsStatus.toString(),
      flammableSeverity: this.flammableSeverity.toString(),
      reactiveSeverity: this.reactiveSeverity.toString(),
      healthySeverity: this.healthySeverity.toString(),
      specialHazard: this.specialHazard ? 'true' : '',
    };
    this.sectionState = SectionStateStatus.Loading;

    this.msdsServices.GetMsdsList(filteredParams).subscribe({
      next: (response) => {
        this.msdsList = response.data.map((item: any) => {
          return {
            ...item,
            departmentParentName: item.departmentParentName
              ? item.departmentParentName
              : item.departmentName,
            departmentName: item.departmentParentName
              ? item.departmentName
              : '',
          };
        });
        this.paginator.length = response.totalCount;
        this.sectionState = SectionStateStatus.Ready;
        this.dataSource = new MatTableDataSource(this.msdsList);
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  viewMsds(msdsId) {
    const msdsDialog = this.dialog.open(AddUpdateMsdsComponent, {
      height: '90vh',
      width: '90%',
      data: { formMode: 'view', msdsId: msdsId },
    });
    msdsDialog.afterClosed().subscribe((res) => {
      this.getMsds();
    });
  }

  deleteMsds(msdsId) {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['ConfirmDeleteRecord']['Title'],
          this.translationsList['ConfirmDeleteRecord']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.msdsServices.DeleteMsds(msdsId).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
              this.getMsds();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
}
