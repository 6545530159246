<div class="card rounded-2xl">
  <div class="">
    <div class="py-4 px-6">
      <div class="flex justify-between">
        <div class="flex items-center">
          <h2
            class="font-semibold my-0 ltr:pr-4 rtl:pl-4 hidden sm:block text-base flex-none"
          >
            <span>{{ "Chatbot.TrustedSources" | translate }}</span>
          </h2>
          <span
            class="rounded-full px-2 py-0.5 bg-green-100 h-fit text-green-900 font-semibold text-xs"
            >{{ collection?.length }} {{ "Chatbot.Links" | translate }}</span
          >
        </div>
        <app-basic-button
          [btnType]="'Add'"
          [btnText]="'AddLink'"
          [link]="'link.svg'"
          (onClick)="createNewUrl()"
        ></app-basic-button>
      </div>
    </div>
    <div class="flex justify-between py-4 px-6">
      <div
        class="bg-foreground rounded-xl px-4 max-w-[400px] flex-auto flex items-center border border-gray-300"
      >
        <img src="assets/img/resources/Icon.svg" alt="" srcset="" />
        <input
          [formControl]="searchCtrl"
          class="px-2 py-2 border-0 outline-none w-full bg-transparent"
          [placeholder]="'General.Search' | translate"
          type="search"
        />
      </div>
      <div class="flex">
        <app-basic-button
          *ngIf="selectedIDs.length > 0"
          [btnType]="'Cancel'"
          [btnText]="'Delete'"
          [link]="'trash.svg'"
          (onClick)="deleteAll()"
        ></app-basic-button>
        <app-basic-button
          [matMenuTriggerFor]="columnFilterMenu"
          [btnType]="'Cancel fitWidth'"
          [btnText]="''"
          [link]="'filter-1.svg'"
        ></app-basic-button>
      </div>
    </div>
  </div>

  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionState"
    [loadingLabel]="loadingLabel | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
    class="overflow-auto"
  >
    <div class="new-table">
      <table
        @stagger
        [dataSource]="dataSource"
        class="w-full"
        mat-table
        matSort
      >
        <!-- Image Column -->
        <ng-container matColumnDef="organizationImage">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
            <ngx-avatars
              class="logo box"
              size="32"
              [src]="row['organizationImage']"
              [name]="row['organizationName']"
              [textSizeRatio]="2"
              [round]="true"
              [initialsSize]="2"
            ></ngx-avatars>
          </td>
        </ng-container>
        <!-- features icons Column -->
        <ng-container matColumnDef="features">
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            {{ "Chatbot.Table.Features" | translate }}
          </th>
          <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
            <div class="flex items-center flex-wrap" style="width: 200px">
              <img
                width="30"
                height="30"
                class="p-1 transition-all duration-500 hover:scale-125"
                *ngFor="let f of row['features']"
                src="assets/img/resources/{{ f.featureIcon }}"
                [alt]="'Feature.' + f.featureName | translate"
                [matTooltip]="'Feature.' + f.featureName | translate"
              />
            </div>
          </td>
        </ng-container>
        <!-- Text Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container
            *ngIf="column.type === 'checkbox'"
            [matColumnDef]="column.property"
          >
            <th *matHeaderCellDef mat-header-cell class="w-8 min-w-8 p-0">
              <button class="checkbox-item p-0">
                <mat-checkbox
                  color="primary"
                  (change)="toggleAllRows($event.checked)"
                  [checked]="
                    selectedIDs.length !== 0 &&
                    selectedIDs.length == collection?.length
                  "
                >
                </mat-checkbox>
              </button>
            </th>
            <td
              *matCellDef="let row"
              [ngClass]="column.cssClasses"
              mat-cell
              class="w-8 min-w-8 p-0"
            >
              <button class="checkbox-item pe-0">
                <mat-checkbox
                  color="primary"
                  [checked]="row?.selected"
                  (change)="toggleRow(row, $event.checked)"
                >
                </mat-checkbox>
              </button>
            </td>
          </ng-container>
          <ng-container
            *ngIf="column.type === 'text' && column.property !== 'category'"
            [matColumnDef]="column.property"
          >
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header
            >
              {{ "Chatbot.Table." + column.label | translate }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ row[column.property] }}
            </td>
          </ng-container>
          <ng-container
            *ngIf="column.type === 'text' && column.property === 'category'"
            [matColumnDef]="column.property"
          >
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header
            >
              {{ "Chatbot.Table." + column.label | translate }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <span
                *ngIf="row[column.property] === 'Security'"
                class="rounded-full px-2 py-1 bg-green-100 h-fit text-green-900 font-semibold text-xs"
                >{{ row[column.property] }}</span
              >
              <span
                *ngIf="row[column.property] === 'Safety'"
                class="rounded-full px-2 py-1 bg-blue-100 h-fit text-blue-900 font-semibold text-xs"
                >{{ row[column.property] }}</span
              >
            </td>
          </ng-container>
          <ng-container
            *ngIf="column.type === 'date'"
            [matColumnDef]="column.property"
          >
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header
            >
              {{ "Chatbot.Table." + column.label | translate }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ row[column.property] | date : "dd-MM-yyyy" }}
            </td>
          </ng-container>
          <ng-container
            *ngIf="column.type === 'boolean'"
            [matColumnDef]="column.property"
          >
            <th *matHeaderCellDef mat-header-cell class="w-8 min-w-8 p-0">
              {{ "Chatbot.Table." + column.label | translate }}
            </th>
            <td
              *matCellDef="let row"
              [ngClass]="column.cssClasses"
              mat-cell
              class="w-8 min-w-8 p-0"
            >
              <div class="custom-toggle">
                <div class="custom-slide-toggle">
                  <mat-slide-toggle></mat-slide-toggle>
                </div>
              </div>
            </td>
          </ng-container>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="uppercase" mat-header-cell></th>

          <td *matCellDef="let row" mat-cell>
            <div class="flex">
              <div
                class="flex items-center justify-between p-3 mat-div"
                (click)="copyMessage(row)"
                [matTooltip]="'General.Buttons.Copy' | translate"
              >
                <mat-icon>
                  <img src="assets/img/resources/copy.svg" alt="" srcset="" />
                </mat-icon>
              </div>
              <div
                class="flex items-center justify-between lg:p-3 mat-div"
                (click)="updateNewUrl(row)"
                [matTooltip]="'General.Buttons.Update' | translate"
              >
                <mat-icon>
                  <img src="assets/img/resources/edit.svg" alt="" srcset="" />
                </mat-icon>
              </div>
              <div
                class="flex items-center justify-between p-3 mat-div"
                (click)="deleteURL(row?.id)"
                [matTooltip]="'General.Buttons.Delete' | translate"
              >
                <mat-icon>
                  <img src="assets/img/resources/trash.svg" alt="" srcset="" />
                </mat-icon>
              </div>
            </div>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <tr
          *matRowDef="let row; columns: visibleColumns"
          @fadeInUp
          class="hover:bg-hover trans-ease-out cursor-pointer"
          mat-row
        ></tr>
      </table>
    </div>
    <div
      *ngIf="dataSource.filteredData.length === 0"
      @scaleFadeIn
      class="flex-auto flex flex-col items-center justify-center"
    >
      <img src="assets/img/resources/empty-state.svg" alt="not found" />
      <h2 class="headline m-0 text-center text-primary">
        {{ "General.EmptyTableList" | translate }}...
      </h2>
    </div>
  </app-state-section>
</div>
<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button
    (click)="toggleColumnVisibility(column, $event)"
    *ngFor="let column of filterMenuItems"
    class="checkbox-item mat-menu-item"
  >
    <mat-checkbox
      (click)="$event.stopPropagation()"
      [(ngModel)]="column.visible"
      color="primary"
    >
      {{ "Chatbot.Table." + column.label | translate }}
    </mat-checkbox>
  </button>
</mat-menu>
<ng-template #AddUpdteURLPopup>
  <div>
    <div>
      <mat-label class="font-semibold text-lg">{{
        "Chatbot.AddSource" | translate
      }}</mat-label>
      <p class="py-2">
        {{ "General.TrustedSourcesDesc" | translate }}
      </p>
    </div>
    <form action="" [formGroup]="sourcesForm" class="m-1">
      <div formArrayName="urls" class="mt-2">
        <ol>
          <li
            class="py-2"
            *ngFor="let url of urlsFormArray?.controls; let i = index"
            [ngClass]="
              urlsFormArray?.controls.length < 1 ||
              i + 1 == urlsFormArray?.controls.length
                ? ''
                : 'border-b-2 border-gray-100'
            "
          >
            <div [formGroupName]="i" class="overflow-hidden">
              <mat-label class="field-label"
                >{{ "Chatbot.LinkName" | translate }}
                <span
                  [ngClass]="
                    url.get('name').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                >
                  *
                </span>
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <input
                  #name
                  [placeholder]="
                    ('Placeholder.Insert' | translate) +
                    ' ' +
                    ('Title' | translate)
                  "
                  formControlName="name"
                  matInput
                  type="text"
                  maxlength="100"
                  (blur)="checkNameAvailablity(name.value, i)"
                />
                <mat-icon
                  matSuffix
                  svgIcon="mat:info"
                  class="text-neutral-400"
                  [matTooltip]="'Name should not be duplicated'"
                >
                </mat-icon>
                <mat-error
                  *ngIf="
                    sourcesForm.invalid &&
                    url.get('name').hasError('nameNotAvailable')
                  "
                >
                  <div class="flex justify-flex-start align-flex-center">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{
                      url.get("name").getError("nameNotAvailable")
                    }}</span>
                  </div>
                </mat-error>
                <mat-error
                  *ngIf="
                    sourcesForm.invalid && url.get('name').hasError('required')
                  "
                >
                  <div class="flex justify-flex-start align-flex-center">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
                <mat-error *ngIf="url.get('name').hasError('duplicate')">
                  This Name is already added.
                </mat-error>
              </mat-form-field>
              <mat-label class="field-label"
                >{{ "Chatbot.Link" | translate }}
                <span
                  [ngClass]="
                    url.get('url').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                >
                  *
                </span>
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <input
                  [placeholder]="('Placeholder.Insert' | translate) + ' URL'"
                  formControlName="url"
                  matInput
                  type="text"
                  maxlength="1000"
                />
                <mat-icon
                  matSuffix
                  svgIcon="mat:info"
                  class="text-neutral-400"
                  [matTooltip]="'Link should start with http or https'"
                ></mat-icon>
                <mat-error
                  *ngIf="
                    sourcesForm.invalid && url.get('url').hasError('pattern')
                  "
                >
                  <div class="flex justify-flex-start align-flex-center">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.InvalidLink" | translate }}</span>
                  </div>
                </mat-error>
                <mat-error *ngIf="url.get('url').hasError('duplicate')">
                  This link is already added.
                </mat-error>
                <mat-error
                  *ngIf="
                    sourcesForm.invalid && url.get('url').hasError('required')
                  "
                >
                  <div class="flex justify-flex-start align-flex-center">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
              <div class="flex column">
                <mat-label class="field-label"
                  >{{ "Chatbot.Category" | translate }}
                  <span
                    [ngClass]="
                      url.get('url').hasError('required')
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  >
                    *
                  </span>
                </mat-label>
                <mat-radio-group
                  formControlName="category"
                  class="new-radio font-medium"
                >
                  <mat-radio-button value="Safety">{{
                    "Chatbot.Safety" | translate
                  }}</mat-radio-button>
                  <mat-radio-button class="ml-3" value="Security"
                    >{{ "Chatbot.Security" | translate }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <button
              *ngIf="addFlag && urlsFormArray?.controls.length > 1"
              class="text-red-700 underline font-medium my-1"
              (click)="removeLevel(i)"
              type="button"
            >
              {{ "Chatbot.RemoveLink" | translate }}
            </button>
          </li>
        </ol>
      </div>
      <div class="flex my-3">
        <button
          *ngIf="addFlag"
          (click)="onAddNewURL()"
          class="text-primary underline font-medium flex items-center"
          color="primary"
          [matTooltip]="'Placeholder.AddLevel' | translate"
          type="button"
        >
          <span>{{ "Chatbot.AddLink" | translate }}</span>
          <mat-icon svgIcon="mat:add" class="p-1"></mat-icon>
        </button>
      </div>
      <div class="flex items-center justify-end">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel'"
          (onClick)="navigateBack()"
        ></app-basic-button>
        <app-basic-button
          [btnType]="'Add'"
          [btnText]="'Save'"
          [isDisabled]="sourcesForm.invalid"
          (onClick)="addFlag ? addURL() : updateUrl()"
        ></app-basic-button>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #viewURLPopup>
  <div>
    <h3>{{ URLToView["name"] }}</h3>
    <textarea
      disabled
      cols="50"
      rows="10"
      class="border-slate-200 placeholder-slate-400 contrast-more:border-slate-400 contrast-more:placeholder-slate-500"
    >
    {{ URLToView["url"] }}
  </textarea
    >
  </div>
</ng-template>
