<hodhod-page-layout class="relative">
  <hodhod-page-layout-content class="p-0">
    <app-state-section
      body
      [flexibleHeight]="true"
      [state]="sectionState"
      [loadingLabel]="loadingLabel | translate"
      [errorLabel]="'Errors.ErrorHappened' | translate"
    >
      <div class="flex column justify-content-start" style="padding: 0 10px">
        <div content>
          <form [formGroup]="searchForm">
            <mat-form-field
              [class]="
                currentLanguage?.isoCode === 'ar'
                  ? 'icon-field-rtl'
                  : 'icon-field'
              "
            >
              <button
                mat-mini-fab
                color="primary"
                style="background-color: #1f36b4"
                class="absolute top-56 right-0"
              >
                <mat-icon
                  style="transform: scale(0.8)"
                  svgIcon="mat:alarm_on"
                ></mat-icon>
              </button>
              <mat-select
                class="alarm-dropdown"
                trigger="alarmTrigger"
                #alarmSelect
                formControlName="menu"
                (selectionChange)="onChangeCalenderDD()"
              >
                <mat-option
                  *ngFor="let item of calenderMenu"
                  [value]="item.name"
                >
                  {{ "VideoAnalyticsDashboard." + item.name | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
          <div
            class="flex justify-start items-center"
            *ngIf="carouselItems.length < 5 || carouselItems.length > 0"
          >
            <div
              class="db-card-wrapper"
              style="width: 20%"
              *ngFor="let item of carouselItems"
            >
              <div class="card-body">
                <div
                  [ngClass]="{
                    'content-card-section1': item.color === 'red',
                    'content-card-section2': item.color === 'green',
                    'content-card-section3': item.color === 'blue'
                  }"
                >
                  <div
                    class="navigate-btn-wrapper"
                    (click)="onClickNew(item.voilationName)"
                  >
                    <span>New</span>
                  </div>
                  <div class="content-card-left-side">
                    <div class="card-heading">
                      {{ item.voilationName }}
                    </div>
                    <div class="card-value">
                      {{ item.y }}
                    </div>
                  </div>
                  <div class="content-card-right-side">
                    <div class="action-img">
                      <img
                        class="icon"
                        *ngIf="item.path"
                        [src]="
                          item.path
                            ? '/assets/img/' +
                              item.voilationName +
                              '_' +
                              item.color +
                              '.png'
                            : '/assets/img/card-icon1.png'
                        "
                      />
                      <img
                        class="icon"
                        *ngIf="!item.path && item.color === 'red'"
                        [src]="
                          item.path
                            ? '/assets/img/' +
                              item.voilationName +
                              '_' +
                              item.color +
                              '.png'
                            : '/assets/img/card-icon1.png'
                        "
                      />
                      <img
                        class="icon"
                        *ngIf="!item.path && item.color === 'green'"
                        [src]="
                          item.path
                            ? '/assets/img/' +
                              item.voilationName +
                              '_' +
                              item.color +
                              '.png'
                            : '/assets/img/card-icon2.png'
                        "
                      />
                      <img
                        class="icon"
                        *ngIf="!item.path && item.color === 'blue'"
                        [src]="
                          item.path
                            ? '/assets/img/' +
                              item.voilationName +
                              '_' +
                              item.color +
                              '.png'
                            : '/assets/img/card-icon3.png'
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-carousel
            *ngIf="carouselItems.length > 5"
            class="carousel box grow-fixed"
            #carouselRef="ngCarousel"
            [config]="config"
          >
            <ng-template [ngCarouselSlide]="carouselRef" let-item>
              <div class="carousel-cell">
                <div class="db-card-wrapper">
                  <div class="card-body">
                    <div
                      [ngClass]="{
                        'content-card-section1': item.color === 'red',
                        'content-card-section2': item.color === 'green',
                        'content-card-section3': item.color === 'blue'
                      }"
                    >
                      <div
                        class="navigate-btn-wrapper"
                        (click)="onClickNew(item.voilationName)"
                      >
                        <span>New</span>
                      </div>
                      <div class="content-card-left-side">
                        <div class="card-heading">
                          {{ item.voilationName }}
                        </div>
                        <div class="card-value">
                          {{ item.y }}
                        </div>
                      </div>
                      <div class="content-card-right-side">
                        <div class="action-img">
                          <img
                            class="icon"
                            *ngIf="item.path"
                            [src]="
                              item.path
                                ? '/assets/img/' +
                                  item.voilationName +
                                  '_' +
                                  item.color +
                                  '.png'
                                : '/assets/img/card-icon1.png'
                            "
                          />
                          <img
                            class="icon"
                            *ngIf="!item.path && item.color === 'red'"
                            [src]="
                              item.path
                                ? '/assets/img/' +
                                  item.voilationName +
                                  '_' +
                                  item.color +
                                  '.png'
                                : '/assets/img/card-icon1.png'
                            "
                          />
                          <img
                            class="icon"
                            *ngIf="!item.path && item.color === 'green'"
                            [src]="
                              item.path
                                ? '/assets/img/' +
                                  item.voilationName +
                                  '_' +
                                  item.color +
                                  '.png'
                                : '/assets/img/card-icon2.png'
                            "
                          />
                          <img
                            class="icon"
                            *ngIf="!item.path && item.color === 'blue'"
                            [src]="
                              item.path
                                ? '/assets/img/' +
                                  item.voilationName +
                                  '_' +
                                  item.color +
                                  '.png'
                                : '/assets/img/card-icon3.png'
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-carousel>
          <div class="flex w-full">
            <div id="db-left" #dbLeft class="grid_wrap flex-grow">
              <app-voilation-drilldown-column-chart
                *ngFor="let data of columnChartData"
                [data]="data"
                (onClickCalendar)="
                  onChangeCalenderIndividualChart($event, data.customTitle)
                "
              >
              </app-voilation-drilldown-column-chart>
            </div>
            <div
              id="db-right"
              #dbRight
              class="side_violation_list w-20 ml-2 mr-2 flex-grow"
            >
              <app-voilation-dashboard-list [data]="violationlist">
              </app-voilation-dashboard-list>
            </div>
          </div>
        </div>

        <ng-template #RangePicker>
          <div class="flex items-center" mat-dialog-title>
            <h2 class="headline m-0 flex-auto">Date Range</h2>
            <button
              class="text-secondary"
              (click)="closeDialog()"
              mat-icon-button
              type="button"
            >
              <mat-icon svgIcon="mat:close"></mat-icon>
            </button>
          </div>
          <div>
            <form [formGroup]="searchForm" content>
              <div class="flex items-center gap-3">
                <div class="flex column flex-grow">
                  <mat-label class="field-label">{{
                    "Task.StartDate" | translate
                  }}</mat-label>
                  <mat-form-field appearance="outline">
                    <input
                      [placeholder]="'Placeholder.SelectDate' | translate"
                      matInput
                      [matDatepicker]="startDate"
                      (click)="startDate.open()"
                      formControlName="start"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="startDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #startDate></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="flex column flex-grow">
                  <mat-label class="field-label">{{
                    "Task.EndDate" | translate
                  }}</mat-label>
                  <mat-form-field appearance="outline">
                    <input
                      [placeholder]="'Placeholder.SelectDate' | translate"
                      matInput
                      [matDatepicker]="endDate"
                      (click)="endDate.open()"
                      formControlName="end"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="endDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #endDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </form>
          </div>
          <div class="flex justify-end items-center mt-4">
            <app-basic-button
              [btnType]="'Cancel'"
              [btnText]="'Cancel'"
              (onClick)="closeDialog()"
            ></app-basic-button>
            <app-basic-button
              [btnType]="'Add'"
              [btnText]="'Submit'"
              (onClick)="onSelectCustomDate()"
            >
            </app-basic-button>
          </div>
        </ng-template>
        <!-- <ng-template #RangePicker>
              <form [formGroup]="searchForm" content>
          
                <div class="fields flex">
                  <div class="field flex column" style="width: 100%;">
                    <label>{{'VideoAnalyticsDashboard.StartDate' | translate }}</label>
                    <mat-form-field class="input-field select-options" appearance="outline" style="width: 90%;">
                      <input matInput formControlName="start" [matDatepicker]="startDatePicker"
                        placeholder="{{'VideoAnalyticsDashboard.SelectDate' | translate}}" autocomplete="off"
                        (click)="startDatePicker.open()" (dateChange)="onChangeStartDate()" />
                      <mat-datepicker-toggle matPrefix [for]="startDatePicker"></mat-datepicker-toggle>
                      <mat-datepicker #startDatePicker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="field flex column" style="margin-left: 5px; width: 100%;">
                    <label>{{'VideoAnalyticsDashboard.EndDate' | translate }}</label>
                    <mat-form-field class="input-field select-options" appearance="outline" style="width: 90%;">
                      <input matInput formControlName="end" [matDatepicker]="endDatePicker"
                        placeholder="{{'VideoAnalyticsDashboard.SelectDate' | translate}}" autocomplete="off"
                        (click)="endDatePicker.open()" (dateChange)="onChangeEndDate()" />
                      <mat-datepicker-toggle matPrefix [for]="endDatePicker"></mat-datepicker-toggle>
                      <mat-datepicker #endDatePicker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
          
              </form>
            </ng-template> -->
      </div>
    </app-state-section>
  </hodhod-page-layout-content>
</hodhod-page-layout>
