import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-update-organization-page',
  templateUrl: './update-organization-page.component.html',
  styleUrls: ['./update-organization-page.component.scss'],
})
export class UpdateOrganizationPageComponent implements OnInit {
  public orgId: number = 0;
  private destroy$ = new Subject();

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const Id = params.get('id') as string;
        this.orgId = parseInt(Id, 10);
      }
    });
  }
}
