import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { Feature } from 'src/backend/models/features/feature';
import { OrganizationService } from 'src/backend/services/organization.service';
import { OrganizationDetail } from '../../models/organization-detail';

@Component({
  selector: 'app-organization-view-page',
  templateUrl: './organization-view-page.component.html',
  styleUrls: ['./organization-view-page.component.scss'],
})
export class OrganizationViewPageComponent implements OnInit {
  public orgInfo: OrganizationDetail;
  public orgLicenseInfo: OrganizationDetail;
  public features: Feature[] = [];
  private destroy$ = new Subject();
  public orgCode: string = '';
  public selectedFeatures: Feature[] = [];

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  constructor(
    private route: ActivatedRoute,
    private orgService: OrganizationService,
    private feedBackService: AsyncFeedbackService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        this.orgCode = params.get('id') as string;
        this.getOrgInfo();
      }
    });
  }

  getOrgInfo(): void {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.orgService.getOrganizationDetails(this.orgCode).subscribe({
      next: (response) => {
        this.orgInfo = response;
        let security: any = response?.features?.find(
          (element) => element['module'] === '1'
        );
        let safety: any = response['features']?.find(
          (element) => element['module'] === '0'
        );
        if (safety?.features || security?.features) {
          this.selectedFeatures = [
            ...(safety?.features || []),
            ...(security?.features || []),
          ];
        }
        security?.features ? this.getOrgCardInfo() : null;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Error;
      },
    });
  }
  getOrgCardInfo(): void {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.orgService.getOrganizationCardDetails(this.orgCode).subscribe({
      next: (response) => {
        this.orgLicenseInfo = response['message'];
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
}
