import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SubscriptionType } from 'src/@hodhod/common/enum';
import {
  EmailValidator,
  noWhitespaceValidator,
  PhoneNumberValidator,
} from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { Feature } from 'src/backend/models/features/feature';
import { FeatureService } from 'src/backend/services/feature.service';
import { OrganizationService } from 'src/backend/services/organization.service';

@Component({
  selector: 'app-create-organization',
  templateUrl: './create-organization.component.html',
  styleUrls: ['./create-organization.component.scss'],
})
export class CreateOrganizationComponent implements OnInit, OnDestroy {
  @ViewChild('allSafetySelected') private allSafetySelected: MatOption;
  @ViewChild('allSecuritySelected') private allSecuritySelected: MatOption;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  createOrgForm!: FormGroup;
  public features: Feature[] = [];
  public selectedFeatures: Feature[] = [];
  public selectedFeatureCodes: string[] = [];
  secFeatures: Feature[] = [];
  sefetyFeatures: Feature[] = [];
  setLicenseQuota: boolean = false;
  private destroy$ = new Subject();
  public translationsList: any = {};
  public loadingLabel: string = 'BuildingOrganizationForm';
  public subscriptionType = SubscriptionType;
  public adminKeys = [];
  public quotaLimit = 1;

  constructor(
    private formBuilder: FormBuilder,
    private featureService: FeatureService,
    private feedBackService: AsyncFeedbackService,
    private orgService: OrganizationService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {
    this.translate
      .get(['Errors', 'Success', 'organization'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.sectionState = SectionStateStatus.Loading;
    setTimeout(() => {
      this.sectionState = SectionStateStatus.Ready;
    }, 2000);
    this.createOrgForm = this.formBuilder.group({
      orgName: ['', [Validators.required, noWhitespaceValidator]],
      // orgImage: [[]],
      noOfCompanies: ['', Validators.required],
      noOfUsers: ['', Validators.required],
      subscriptionType: [null, Validators.required],
      // description: [''],
      orgAdminFirstName: ['', [Validators.required, noWhitespaceValidator]],
      orgAdminLastName: ['', Validators.required],
      orgAdminEmail: ['', [Validators.required, EmailValidator]],
      orgPhoneNumber: [
        '',
        [Validators.pattern('^[0-9]*$'), Validators.required],
      ],
      safetyFeatures: [[]],
      securityFeatures: [[]],
      OrgQuota: [1],
      partNumberHIDIds: [[]],
      // primary: ['#1F36B4'],
      // secondary: ['#ffffff'],
    });

    this.createOrgForm.get('securityFeatures').valueChanges.subscribe((res) => {
      if (res.length > 0) {
        this.setLicenseQuota = true;
        this.createOrgForm
          .get('partNumberHIDIds')
          .setValidators(Validators.required);
        this.createOrgForm.get('OrgQuota').setValidators(Validators.required);
      } else {
        this.setLicenseQuota = false;
        this.createOrgForm.get('partNumberHIDIds').setValue([]);
        this.createOrgForm.get('OrgQuota').setValue(1);
        this.createOrgForm.get('partNumberHIDIds').clearValidators();
        this.createOrgForm.get('OrgQuota').clearValidators();
      }
      this.createOrgForm.get('partNumberHIDIds').updateValueAndValidity();
      this.createOrgForm.get('OrgQuota').updateValueAndValidity();
    });
    this.getAllFeatures();
    this.getAdminKeys();
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  getAdminKeys(): void {
    this.orgService.getAdminKeys().subscribe({
      next: (response) => {
        this.adminKeys = response;
        console.log(response);
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getAllFeatures(): void {
    this.featureService.getAllFeatures().subscribe({
      next: (response) => {
        this.features = response;
        let security = response?.find(
          (element) => element['module'] === 'Security'
        );
        let safety = response?.find(
          (element) => element['module'] === 'Safety'
        );
        this.secFeatures = security['features'];
        this.sefetyFeatures = safety['features'];
        this.quotaLimit = security['quotaLimit'];
        this.createOrgForm
          .get('OrgQuota')
          .setValidators(Validators.max(this.quotaLimit));
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  createOrganization(): void {
    const {
      orgName,
      // description,
      orgAdminFirstName,
      orgAdminLastName,
      orgAdminEmail,
      orgPhoneNumber,
      safetyFeatures,
      // orgImage,
      noOfCompanies,
      securityFeatures,
      OrgQuota,
      noOfUsers,
      // primary,
      // secondary,
      subscriptionType,
      partNumberHIDIds,
    } = this.createOrgForm.value;
    const formData = new FormData();
    // formData.append('file', orgImage[0] ? orgImage[0] : null);
    const organizationData = {
      orgName,
      // description,
      orgAdminFirstName,
      orgAdminLastName,
      orgAdminEmail,
      orgPhoneNumber,
      OrgQuota,
      features: safetyFeatures.concat(securityFeatures),
      noOfCompanies,
      noOfUsers,
      // themeColor: primary,
      // secondaryColor: secondary,
      subscriptionType: Number(subscriptionType),
      partNumberHIDIds,
    };

    formData.append('content', JSON.stringify(organizationData));
    this.loadingLabel = 'CreatingOrganization';
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['organization']['CreateConfirm']['Title'],
          this.translationsList['organization']['CreateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.orgService.createOrganization(formData).subscribe({
            next: (respose) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, respose?.message)
              );
              this.router.navigate([
                '/' +
                  SharedConstants.ORGANIZATION_SETUP +
                  '/' +
                  SharedConstants.ORGANIZATIONS,
              ]);
              this.resetForm();
            },
            error: ({ error }) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
            },
          });
        }
      });
  }

  onSelectSafetyFeature(all) {
    if (this.allSafetySelected.selected) {
      this.allSafetySelected.deselect();
    }
    if (
      this.createOrgForm.get('safetyFeatures').value.length ==
      this.sefetyFeatures.length
    ) {
      this.allSafetySelected.select();
    }
    console.log(this.createOrgForm.get('safetyFeatures').value);
  }

  onSelectAllSafetyFeature() {
    if (this.allSafetySelected.selected) {
      this.createOrgForm
        .get('safetyFeatures')
        .patchValue([
          ...this.sefetyFeatures.map((item) => item.featureCode),
          0,
        ]);
      this.selectedFeatures = [...this.sefetyFeatures];
    } else {
      this.createOrgForm.get('safetyFeatures').patchValue([]);
    }
  }

  onSelectSecurityFeature(all) {
    if (this.allSecuritySelected.selected) {
      this.allSecuritySelected.deselect();
    }
    if (
      this.createOrgForm.get('securityFeatures').value.length ==
      this.secFeatures.length
    ) {
      this.allSecuritySelected.select();
    }
  }

  onSelectAllSecurityFeature() {
    if (this.allSecuritySelected.selected) {
      this.createOrgForm
        .get('securityFeatures')
        .patchValue([...this.secFeatures.map((item) => item.featureCode), 0]);
    } else {
      this.createOrgForm.get('securityFeatures').patchValue([]);
    }
  }

  allSelectedFeatures() {
    const features = this.sefetyFeatures.concat(this.secFeatures);
    const codes = this.createOrgForm
      .get('safetyFeatures')
      .value.concat(this.createOrgForm.get('securityFeatures').value);

    const selectedFeatures: any = [
      ...features.filter((item) => codes.includes(item.featureCode)),
    ];

    return selectedFeatures;
  }

  resetForm(): void {
    this.createOrgForm.get('orgName')?.setValue('');
    this.createOrgForm.get('orgImage')?.setValue([]);
    this.createOrgForm.get('description')?.setValue('');
    this.createOrgForm.get('noOfCompanies')?.setValue('');
    this.createOrgForm.get('noOfUsers')?.setValue('');
    this.createOrgForm.get('orgAdminFirstName')?.setValue('');
    this.createOrgForm.get('orgAdminLastName')?.setValue('');
    this.createOrgForm.get('orgAdminEmail')?.setValue('');
    this.createOrgForm.get('orgPhoneNumber')?.setValue('');
    this.createOrgForm.get('safetyFeatures')?.setValue([]);
    this.selectedFeatures = [];
  }
  cancelOrganizationForm() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['organization']['CancelForm']['Title'],
          this.translationsList['organization']['CancelForm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.router.navigate([
            '/' +
              SharedConstants.ORGANIZATION_SETUP +
              '/' +
              SharedConstants.ORGANIZATIONS,
          ]);
          this.resetForm();
        }
      });
  }

  onKeyDown(event: KeyboardEvent) {
    if (
      event.key === '-' ||
      event.key === '.' ||
      (!event.key.match(/^[0-9]+$/) &&
        event.key !== 'Backspace' &&
        event.key !== 'Delete' &&
        event.key !== 'ArrowLeft' &&
        event.key !== 'ArrowRight' &&
        event.key !== 'Home' &&
        event.key !== 'End')
    ) {
      event.preventDefault();
    }
  }
}
