import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import { DashboardSmartSafetyComponent } from './components/dashboard-smart-safety/dashboard-smart-safety.component';
import { DashboardChartComponent } from './components/dashboard-chart/dashboard-chart.component';
import { DashboardFullscreenComponent } from './components/dashboard-fullscreen/dashboard-fullscreen.component';
import { DashboardIotComponent } from './components/dashboard-iot/dashboard-iot.component';
import { AddIotDashboardComponent } from './components/add-iot-dashboard/add-iot-dashboard.component';
import { DigitalIotDashboardComponent } from './components/digital-iot-dashboard/digital-iot-dashboard.component';
import { SmartMonitoringModule } from '../smart-monitoring/smart-monitoring.module';
import { SmartMonitoringRoutingModule } from '../smart-monitoring/smart-monitoring-routing.module';
import { SafetyKpiDashboardsComponent } from './components/safety-kpi-dashboards/safety-kpi-dashboards.component';
import { SafetyTriangleDashboardComponent } from './components/safety-triangle-dashboard/safety-triangle-dashboard.component';
import { SafemanHoursCardComponent } from './components/safeman-hours-card/safeman-hours-card.component';
import { SafemanHoursComponent } from './components/safeman-hours-settings/safeman-hours.component';
import { MatMenuModule } from '@angular/material/menu';
import { DashboardCalenderComponent } from './components/dashboard-calender/dashboard-calender.component';
import { AddDashboardTargetComponent } from './components/add-dashboard-target/add-dashboard-target.component';
import { DashboardTargetComponent } from './pages/dashboard-target/dashboard-target.component';
import { EditDashboardTargetComponent } from './components/edit-dashboard-target/edit-dashboard-target.component';
import { EditDashboardTargetPopupComponent } from './components/edit-dashboard-target-popup/edit-dashboard-target-popup.component';
import { SafetyTriangleComponent } from './components/define-safety-triangle-levels/safety-triangle/safety-triangle.component';
import { SafetyKpiSetupComponent } from './pages/safety-kpi-setup/safety-kpi-setup.component';
import { DashboardSettingsComponent } from './pages/dashboard-settings/dashboard-settings.component';
import { SafetyKpisComponent } from './pages/safety-kpis/safety-kpis.component';
import { DefineSafetyTriangleLevelsComponent } from './components/define-safety-triangle-levels/define-safety-triangle-levels.component';
import { ManualLevelsFormComponent } from './components/define-safety-triangle-levels/manual-levels-form/manual-levels-form.component';
import { AutoLevelsFormComponent } from './components/define-safety-triangle-levels/auto-levels-form/auto-levels-form.component';

@NgModule({
  declarations: [
    DashboardPageComponent,
    DashboardCardComponent,
    DashboardSmartSafetyComponent,
    DashboardChartComponent,
    DashboardFullscreenComponent,
    DashboardIotComponent,
    AddIotDashboardComponent,
    DigitalIotDashboardComponent,
    SafetyKpiDashboardsComponent,
    SafetyTriangleDashboardComponent,
    SafemanHoursCardComponent,
    SafemanHoursComponent,
    DashboardCalenderComponent,
    AddDashboardTargetComponent,
    DashboardTargetComponent,
    EditDashboardTargetComponent,
    EditDashboardTargetPopupComponent,
    SafetyTriangleComponent,
    SafetyKpiSetupComponent,
    DashboardSettingsComponent,
    SafetyKpisComponent,
    DefineSafetyTriangleLevelsComponent,
    ManualLevelsFormComponent,
    AutoLevelsFormComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    SmartMonitoringModule,
    SmartMonitoringRoutingModule,
    MatMenuModule,
  ],
})
export class DashboardModule {}
