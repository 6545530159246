<app-state-section
  body
  [flexibleHeight]="true"
  [state]="sectionState"
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <div [formGroup]="inviteUserForm">
    <div class="flex items-center" mat-dialog-title>
      <h2 class="headline m-0 flex-auto">
        {{ "ManageAccessControl.InviteUser" | translate }}
      </h2>
      <button
        class="text-secondary"
        (click)="closeDialog()"
        mat-icon-button
        type="button"
      >
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

    <mat-dialog-content class="flex flex-col justify-between">
      <!-- title -->
      <div class="field-full flex column">
        <!-- <mat-label class="field-label"
          >{{ "ManageAccessControl.InviteUser" | translate }}
          <span
            [ngClass]="
              inviteUserForm.invalid &&
              inviteUserForm.get('userId').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <div
          class="bg-foreground rounded-full px-4 flex-auto flex items-center border border-gray-300"
        >
          <input
            formControlName="userId"
            class="px-4 py-2 border-0 outline-none w-full bg-transparent"
            [placeholder]="'ManageAccessControl.SelectUser' | translate"
            type="search"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option
              *ngFor="let option of filteredUsersWithoutAccessControl | async"
              [value]="option"
            >
              {{ option?.fullName }} ( {{ option?.email }} )
            </mat-option>
          </mat-autocomplete>
        </div> -->

        <div class="field flex column" [style.width.%]="100">
          <div class="form-field flex column">
            <mat-label class="field-label"
              >{{ "ManageAccessControl.InviteUser" | translate }}
              <span
                [ngClass]="
                  inviteUserForm.invalid &&
                  inviteUserForm.get('userIds').invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
                >*</span
              >
            </mat-label>
            <mat-form-field
              class="w-full custom-invite-user-input"
              appearance="outline"
            >
              <mat-chip-list #chipGrid aria-label="user selection">
                <mat-chip
                  class="custom-chip"
                  *ngFor="let user of selectedInviteUsers"
                >
                  {{ user.fullName }}
                </mat-chip>
              </mat-chip-list>
              <input
                [placeholder]="'ManageAccessControl.InviteUser' | translate"
                #userInput
                [formControl]="usersCtrl"
                formControlName="userIds"
                [matChipInputFor]="chipGrid"
                [matAutocomplete]="auto"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                matInput
              />

              <mat-autocomplete
                #auto="matAutocomplete"
                [displayWith]="displayFn"
              >
                <mat-option
                  style="height: fit-content; margin: 5px 0; padding: 5px"
                  *ngFor="let user of filteredUsersWithoutAccessControl | async"
                  [value]="user.id"
                >
                  <div (click)="optionClicked($event, user)">
                    <mat-checkbox
                      [checked]="user.selected"
                      (change)="toggleSelection(user)"
                      (click)="$event.stopPropagation()"
                    >
                      {{ user?.fullName }} ( {{ user?.email }} )
                    </mat-checkbox>
                  </div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div *ngIf="inviteUserForm.get('userIds').hasError('required')">
              <div class="flex justify-flex-start items-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span class="text-[#f44336]">{{
                  "Errors.Required" | translate
                }}</span>
              </div>
            </div>
            <div *ngIf="inviteUserForm.get('userIds').hasError('maxlength')">
              <div class="flex justify-flex-start items-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span class="text-[#f44336]">{{
                  "Errors.MaxInvitedUser" | translate
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-end pt-2" *ngIf="cards.length > 0">
        <div class="custom-slide-toggle">
          <mat-slide-toggle formControlName="issueCard"></mat-slide-toggle>
        </div>
        <div class="mx-2">
          <div class="flex items-center">
            <span class="toggle-label">
              {{ "Chatbot.IssueCardType" | translate }}
            </span>
            <mat-icon
              class="mx-1"
              svgIcon="mat:info"
              [matTooltip]="'Chatbot.OptionalAssign' | translate"
            ></mat-icon>
          </div>
          <div>{{ "Chatbot.OptionalAssign" | translate }}</div>
        </div>
      </div>

      <div *ngIf="inviteUserForm.get('issueCard').value">
        <div class="flex justify-between gap-6">
          <div
            *ngFor="let card of cards"
            (click)="onCardSelect(card.partNumber)"
            [class.selected]="card.partNumber === selectedCard"
            class="p-0 h-[140px] cursor-pointer transition hover:shadow-lg border-2 border-[#F3F3F3] rounded-[15px] flex flex-col mt-1 mb-1"
          >
            <div class="p-2">
              <div class="mb-2 flex justify-between">
                <img
                  class="w-[30%] object-contain"
                  src="assets/img/resources/saned-logo.png"
                  alt="saned"
                />
                <mat-checkbox
                  class="step-checkbox"
                  disabled
                  [checked]="card.partNumber === selectedCard"
                ></mat-checkbox>
              </div>

              <div class="flex items-center justify-between mt-4">
                <div class="flex items-center">
                  <strong>{{ card?.friendlyName }}</strong>
                </div>
              </div>
            </div>

            <div class="flex justify-between hid-cover h-full">
              <div class="flex items-end flex-grow p-2">
                <img
                  class="w-[20%]"
                  src="assets/img/resources/hid-logo.svg"
                  alt="saned"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <div class="flex items-center">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel'"
          (onClick)="closeDialog()"
        ></app-basic-button>
        <app-basic-button
          [btnType]="'Add'"
          [btnText]="'Add'"
          (onClick)="addUser()"
          [isDisabled]="!inviteUserForm.valid"
        ></app-basic-button>
      </div>
    </mat-dialog-actions>
  </div>
</app-state-section>
