import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { HodhodModule } from 'src/@hodhod/hodhod.module';
import { PrivateLayoutModule } from './private-layout/private-layout.module';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { PublicLayoutModule } from './public-layout/public-layout.module';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LocaleIdHandler } from './shared/helpers/locale-id-handler';
import { UserManagementModule } from './user-management/user-management.module';
import { SecurityModule } from './security/security.module';
import { SafetyModule } from './safety/safety.module';
import { OrganizationSetupModule } from './organization-setup/organization-setup.module';
import { CompanySetupModule } from './company-setup/company-setup.module';
import { WorkPermitModule } from './work-permit/work-permit.module';
import { ReportingModule } from './reporting/reporting.module';
import { TokenInterceptor } from 'src/backend/helpers/token.interceptor';
import { AuthService } from 'src/backend/services/auth.service';
import { BaseApi } from 'src/backend/api/base-api';
import { UserService } from 'src/backend/services/user.service';
import { Constants } from 'src/@hodhod/common/constants';
import { JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './shared/guards/auth.guard';
import { PermissionGuard } from './shared/guards/permission.guard';
import { PermissionService } from 'src/backend/services/permission.service';
import { NotFoundPageComponent } from './static/not-found-page/not-found-page.component';
import { AccessDeniedComponent } from './static/access-denied/access-denied.component';
import { FeatureService } from 'src/backend/services/feature.service';
import { ConfirmEmailComponent } from './static/confirmEmail/confirmEmail.component';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { TaskTrackerModule } from './task-tracker/task-tracker.module';
import { ActionTrackerModule } from './action-tracker/action-tracker.module';
import { SmartMonitoringModule } from './smart-monitoring/smart-monitoring.module';
import { NgxGaugeModule } from 'ngx-gauge';
import { ResetPasswordComponent } from './static/reset-password/reset-password.component';
import { WorkFlowService } from 'src/backend/services/work-flow.service';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { MessagingService } from 'src/backend/services/messaging.service';
import { NotificationService } from 'src/backend/services/notification.service';
import { UserDeviceService } from 'src/backend/services/user-device.service';
import { MediaService } from 'src/backend/services/media.service';
import { GlobalSearchService } from 'src/backend/services/global-search.service';
import { NgxHotjarModule, NgxHotjarRouterModule } from 'ngx-hotjar';
import { SafetyAnnouncementModule } from './safety-announcement/safety-announcement.module';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MSDSModule } from './msds/msds.module';
import { PenaltyManagementModule } from './penalty-management/penalty-management.module';
import { InspectionManagementModule } from './inspection-management/inspection-management.module';
import { AccessControlSetupModule } from './access-control-setup/access-control-setup.module';
import { DashboardModule } from './dashboard/dashboard.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/locales/', '.json');
}

export function getToken() {
  const token = localStorage.getItem(Constants.AUTH_TOKEN);
  if (token) {
    return token;
  }
  return null;
}

const JWT_Module_Options: JwtModuleOptions = {
  config: {
    tokenGetter: getToken,
    allowedDomains: environment.white_listed_Domains,
    headerName: 'Authorization',
    authScheme: 'Bearer ',
  },
};
@NgModule({
  declarations: [
    AppComponent,
    NotFoundPageComponent,
    AccessDeniedComponent,
    ConfirmEmailComponent,
    ResetPasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    HodhodModule,
    PrivateLayoutModule,
    PublicLayoutModule,
    DashboardModule,
    UserManagementModule,
    SecurityModule,
    SafetyModule,
    OrganizationSetupModule,
    CompanySetupModule,
    WorkPermitModule,
    ReportingModule,
    TaskTrackerModule,
    ActionTrackerModule,
    SmartMonitoringModule,
    SafetyAnnouncementModule,
    MSDSModule,
    PenaltyManagementModule,
    InspectionManagementModule,
    AccessControlSetupModule,
    NgxGaugeModule,
    JwtModule.forRoot(JWT_Module_Options),
    NgxHotjarModule.forRoot('3321635'),
    NgxHotjarRouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
  ],
  providers: [
    TranslateService,
    { provide: LOCALE_ID, useClass: LocaleIdHandler, deps: [TranslateService] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    AuthGuard,
    PermissionGuard,
    AuthService,
    BaseApi,
    UserService,
    PermissionService,
    FeatureService,
    DepartmentsService,
    WorkFlowService,
    MessagingService,
    NotificationService,
    UserDeviceService,
    MediaService,
    GlobalSearchService,
    MatChipsModule,
    MatGridListModule,
    MatTooltipModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
