import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { GeneralStatus, SortDirection } from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { UnitOfMeasureService } from 'src/backend/services/master/unit-of-measure.service';
import { PermissionService } from 'src/backend/services/permission.service';
import { UnitOfMeasure } from '../../models/unit-of-measure';
import { ActivatedRoute, Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-unit-of-measures',
  templateUrl: './unit-of-measures.component.html',
  styleUrls: ['./unit-of-measures.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class UnitOfMeasuresComponent extends BaseComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('unitCreateUpdateModal') unitCreateUpdateModal!: TemplateRef<any>;
  unitForm: FormGroup;
  formMode: 'create' | 'update' = 'create';

  unitOfMeasures: UnitOfMeasure[];
  columns: TableColumn<UnitOfMeasure>[] = [
    {
      label: 'Unit',
      property: 'unit',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Created At',
      property: 'createdOn',
      type: 'date',
      visible: false,
    },
    {
      label: 'Updated At',
      property: 'updatedOn',
      type: 'date',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<UnitOfMeasure>([]);
  selection = new SelectionModel<UnitOfMeasure>(true, []);
  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  timer = null;
  loadingLabel: string = '';
  unitId: number = 0;
  isReadonlyMode = false;

  UnitStatuses = GeneralStatus;
  SharedConstants = SharedConstants;

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public sectionStateModal: SectionStateStatus = SectionStateStatus.Ready;
  public selectedUnitsStatus: string[] = [];

  private translationsList: any = {};

  hasAccessPermissions = ApplicationPermission.UNIT_OF_MEASURES_ACCESS;
  hasWritePermissions = ApplicationPermission.UNIT_OF_MEASURES_CREATE;
  hasEditPermissions = ApplicationPermission.UNIT_OF_MEASURES_UPDATE;
  hasActivatePermissions = ApplicationPermission.UNIT_OF_MEASURES_ACTIVATE;

  currentQueryParams = null;
  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private unitService: UnitOfMeasureService,
    private feedBackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService,
    private permissionService: PermissionService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'Role', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  override ngOnInit(): void {
    this.unitForm = this.fb.group({
      unit: ['', [Validators.required, noWhitespaceValidator]],
    });
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.sort = this.sort;
    this.currentQueryParams = this.route.snapshot.queryParams;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);

    this.pagination.pageIndex = this.currentQueryParams?.pageIndex
      ? Number(this.currentQueryParams?.pageIndex)
      : 0;
    this.pagination.pageSize = this.currentQueryParams?.pageSize
      ? Number(this.currentQueryParams?.pageSize)
      : 10;

    this.selectedUnitsStatus = this.currentQueryParams?.orgStatus
      ? this.currentQueryParams?.orgStatus.split(',')
      : [];

    this.columns.forEach((item) => {
      if (this.currentQueryParams[item.property] === 'true') {
        item.visible = true;
      } else if (this.currentQueryParams[item.property] === 'false') {
        item.visible = false;
      }
    });

    this.sort.active = this.currentQueryParams?.sortName;
    this.sort.direction = this.currentQueryParams?.sortDirection;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        sortName: res.active,
        sortDirection: res.direction,
        pageIndex:0
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.currentQueryParams,
      });
      this.pagination.pageIndex = 0;
      this.getData()
    });

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getData();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  getData() {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }
    const filteredParams = {
      sortDirection: this.currentQueryParams?.sortDirection || '',
      sortField,
      pageIndex: this.currentQueryParams?.pageIndex
        ? this.currentQueryParams?.pageIndex
        : this.pagination.pageIndex,
      pageSize: this.currentQueryParams?.pageSize
        ? this.currentQueryParams?.pageSize
        : this.pagination.pageSize,
      searchValue: this.currentQueryParams?.searchValue
        ? this.currentQueryParams?.searchValue
        : this.searchValue,
      status: this.currentQueryParams?.orgStatus,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.unitService.getFilteredUnitOfMeasures(filteredParams).subscribe({
      next: (response) => {
        this.unitOfMeasures = response?.data;
        this.dataSource = new MatTableDataSource(response?.data);
        this.pagination.length = response?.totalCount;
        this.dataSource.sort = this.sort;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  onSelectStatus(event: MatSelectChange) {
    this.selectedUnitsStatus = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgStatus: event.value.toString(),
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      searchValue: this.searchValue,
      pageIndex:0
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.pagination.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      [column.property]: column.visible,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  deactivateUnit(event: any, id: number): void {
    // checking permissions
    if (
      !this.permissionService.isUserHasPermission(this.hasActivatePermissions)
    ) {
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          this.translationsList['StaticPages']['AccessDenied']['Title']
        )
      );
      return;
    }
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'General.Refreshing';
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.unitService.deActivate(id).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }
  activateUnit(event: any, id: number): void {
    // checking permissions
    if (
      !this.permissionService.isUserHasPermission(this.hasActivatePermissions)
    ) {
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          this.translationsList['StaticPages']['AccessDenied']['Title']
        )
      );
      return;
    }
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'General.Refreshing';
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.unitService.activate(id).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }
  viewUnit(event: any, id: number): void {
    this.isReadonlyMode = true;
    this.resetForm();
    this.unitId = id;
    this.unitForm
      .get('unit')
      ?.setValue(this.unitOfMeasures.find((x) => x.id === id).unit);

    this.dialog.open(this.unitCreateUpdateModal, {
      height: 'auto',
      width: '40%',
      disableClose: true,
    });
  }

  openUpdateUnitModal(event: any, id: number): void {
    this.isReadonlyMode = false;
    this.formMode = 'update';
    this.resetForm();
    this.unitId = id;
    this.unitForm
      .get('unit')
      ?.setValue(this.unitOfMeasures.find((x) => x.id === id).unit);

    this.dialog.open(this.unitCreateUpdateModal, {
      height: 'auto',
      width: '40%',
      disableClose: true,
    });
  }
  openCreateUnitModal(): void {
    this.isReadonlyMode = false;
    this.formMode = 'create';
    this.resetForm();
    this.dialog.open(this.unitCreateUpdateModal, {
      height: 'auto',
      width: '40%',
      disableClose: true,
    });
  }

  createUnit() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionStateModal = SectionStateStatus.Loading;
          const unit = { unit: this.unitForm.value.unit };
          this.unitService.createUnitOfMeasure(unit).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
              this.closeDialog();
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  updateUnit(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['Role']['UpdateConfirm']['Title'],
          this.translationsList['Role']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionStateModal = SectionStateStatus.Loading;
          const unit = {
            id: this.unitId,
            unit: this.unitForm.value.unit,
          };
          this.unitService.editUnitOfMeasure(unit).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
              this.closeDialog();
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  resetForm(): void {
    this.unitForm.get('unit')?.setValue('');
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }

  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
}
