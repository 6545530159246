<div class="card overflow-auto mt-2">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <div
      class="bg-foreground rounded-full px-4 max-w-[300px] flex-auto flex items-center border border-gray-300"
    >
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input
        [formControl]="searchCtrl"
        class="px-4 py-2 border-0 outline-none w-full bg-transparent"
        [placeholder]="'General.Search' | translate"
        type="search"
      />
    </div>

    <span class="flex-1"></span>

    <app-basic-button
      *ngIf="hasCreatePermission | permissionChecker"
      [btnType]="'Add'"
      [btnText]="'InviteEmployee'"
      [link]="'add.svg'"
      (onClick)="addUser()"
    ></app-basic-button>
  </div>
  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionState"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
      <!-- Text Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container
          *ngIf="column.type === 'text'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "ManageAccessControl.Table." + column.label | translate }}&nbsp;
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            #div
            class="truncate max-w-0 p-3"
            mat-cell
          >
            <span
              [matTooltip]="
                div?.offsetWidth < div?.scrollWidth
                  ? row[column.property]
                  : null
              "
            >
              {{ row[column.property] }}
            </span>
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'date'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "ManageAccessControl.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] | date : "mediumDate" }}
          </td>
        </ng-container>
        <ng-container
          *ngIf="
            column.type === 'boolean' &&
            column.property === 'lastInvitationStatus'
          "
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div
              style="width: 100%; position: relative"
              class="flex items-center"
            >
              {{ "ManageAccessControl.Table." + column.label | translate }}
            </div>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div
              class="flex justify-center align-center"
              [attr.id]="row[column.property]"
            >
              <span>
                {{
                  "ManageAccessControl.Status." + row[column.property]
                    | translate
                }}
              </span>
            </div>
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'boolean' && column.property === 'userStatus'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div
              style="width: 100%; position: relative"
              class="flex items-center"
            >
              {{ "ManageAccessControl.Table." + column.label | translate }}
            </div>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div
              class="flex justify-center align-center"
              [attr.id]="row[column.property]"
            >
              <span>
                {{ row[column.property] }}
              </span>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <!-- ACTIONS Column -->
      <ng-container matColumnDef="actions" stickyEnd>
        <th *matHeaderCellDef mat-header-cell></th>

        <td *matCellDef="let row" mat-cell>
          <div
            (click)="$event.stopPropagation()"
            *ngIf="
              row?.userStatus !== 'DELETING' && row?.userStatus !== 'INACTIVE'
            "
          >
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon svgIcon="mat:more_vert"></mat-icon>
            </button>
            <mat-menu
              #menu="matMenu"
              yPosition="below"
              xPosition="before"
              class="shadow-lg shadow-gray-400 rounded-2xl min-h-0"
            >
              <div
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                (click)="viewUserAccess(row?.id)"
              >
                <button>
                  {{ "General.Buttons.View" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
              <div
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                (click)="
                  row['lastInvitationStatus'] == 'PENDING'
                    ? resendOTP(row?.id)
                    : onResendInvitationClick(row?.id)
                "
              >
                <button>
                  {{
                    row["lastInvitationStatus"] == "PENDING"
                      ? ("General.Buttons.ResendOTP" | translate)
                      : ("General.Buttons.ResendInvite" | translate)
                  }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
              <div
                *ngIf="row['lastInvitationStatus'] == 'PENDING'"
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                (click)="cancelInvitation(row?.id)"
              >
                <button>
                  {{ "General.Buttons.Cancel" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
              <div
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                (click)="deleteUser(row?.id)"
              >
                <button>
                  {{ "General.Buttons.Delete" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: visibleColumns"
        @fadeInUp
        class="hover:bg-hover trans-ease-out cursor-pointer"
        mat-row
        (click)="
          row?.userStatus !== 'DELETING' && row?.userStatus !== 'INACTIVE'
            ? viewUserAccess(row?.id)
            : ''
        "
      ></tr>
    </table>
    <div
      *ngIf="dataSource.filteredData.length === 0"
      @scaleFadeIn
      class="flex-auto flex flex-col items-center justify-center"
    >
      <img src="assets/img/resources/empty-state.svg" alt="not found" />
      <h2 class="headline m-0 text-center text-primary">
        {{ "General.EmptyTableList" | translate }}...
      </h2>
    </div>

    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      [length]="pagination.length"
      [pageSize]="pagination.pageSize"
      [pageIndex]="pagination.pageIndex"
      (page)="handlePageEvent($event)"
      showFirstLastButtons
      class="sticky left-0"
    >
    </mat-paginator>
  </app-state-section>
</div>

<ng-template #resendInviteModal>
  <div>
    <div class="flex items-center" mat-dialog-title>
      <h2 class="headline m-0 flex-auto">
        {{ "ManageAccessControl.InviteUser" | translate }}
      </h2>
      <button
        class="text-secondary"
        (click)="closeDialog()"
        mat-icon-button
        type="button"
      >
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

    <mat-dialog-content
      class="flex flex-col justify-between"
      *ngIf="cards.length > 0"
    >
      <div class="flex items-center pt-2">
        <div class="custom-slide-toggle">
          <mat-slide-toggle
            [(ngModel)]="isIssueCard"
            (change)="startIssueCard($event)"
          ></mat-slide-toggle>
        </div>
        <div class="mx-1">
          <div class="flex items-center">
            <span class="toggle-label">
              {{ "Chatbot.IssueCardType" | translate }}
            </span>
            <mat-icon
              class="mx-1"
              svgIcon="mat:info"
              [matTooltip]="'Chatbot.OptionalAssign' | translate"
            ></mat-icon>
          </div>
          <div>{{ "Chatbot.OptionalAssign" | translate }}</div>
        </div>
      </div>

      <div *ngIf="isIssueCard">
        <div class="flex justify-between gap-6">
          <div
            *ngFor="let card of cards"
            (click)="onCardSelect(card.partNumber)"
            [class.selected]="card.partNumber === selectedCard"
            class="p-0 h-[140px] cursor-pointer transition hover:shadow-lg border-2 border-[#F3F3F3] rounded-[15px] flex flex-col mt-1 mb-1"
          >
            <div class="p-2">
              <div class="mb-2 flex justify-between">
                <img
                  class="w-[30%] object-contain"
                  src="assets/img/resources/saned-logo.png"
                  alt="saned"
                />
                <mat-checkbox
                  class="step-checkbox"
                  disabled
                  [checked]="card.partNumber === selectedCard"
                ></mat-checkbox>
              </div>

              <div class="flex items-center justify-between mt-4">
                <div class="flex items-center">
                  <strong>{{ card?.friendlyName }}</strong>
                </div>
              </div>
            </div>

            <div class="flex justify-between hid-cover h-full">
              <div class="flex items-end flex-grow p-2">
                <img
                  class="w-[20%]"
                  src="assets/img/resources/hid-logo.svg"
                  alt="saned"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <div class="flex items-center">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel'"
          (onClick)="closeDialog()"
        ></app-basic-button>
        <app-basic-button
          [btnType]="'Add'"
          [btnText]="'ResendInvite'"
          [isDisabled]="isIssueCard && !selectedCard"
          (onClick)="resendInvitation()"
        ></app-basic-button>
      </div>
    </mat-dialog-actions>
  </div>
</ng-template>
