import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  EmailValidator,
  noWhitespaceValidator,
  PhoneNumberValidator,
} from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BaseApi } from 'src/backend/api/base-api';
import { Feature } from 'src/backend/models/features/feature';
import { CompanyService } from 'src/backend/services/company.service';
import { FeatureService } from 'src/backend/services/feature.service';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.scss'],
})
export class CreateCompanyComponent implements OnInit, OnDestroy {
  @ViewChild('allSafetySelected') private allSafetySelected: MatOption;
  @ViewChild('allSecuritySelected') private allSecuritySelected: MatOption;
  @ViewChild('uploadLogo') uploadLogo!: TemplateRef<any>;

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  createCompForm!: FormGroup;
  public features: Feature[] = [];
  secFeatures: Feature[] = [];
  sefetyFeatures: Feature[] = [];
  setLicenseQuota: boolean = false;

  public selectedFeatures: Feature[] = [];
  public selectedFeatureCodes: any[] = [];

  private destroy$ = new Subject();
  public translationsList: any = {};
  public loadingLabel: string = 'BuildingCompanyForm';
  public sessionOrg: any;
  public orgParam: string;
  public orgParamId: string;
  public currentQueryParams: any = null;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedImageUrl: any = '';
  isSuperAdmin;
  quotaLimit: number = 1;
  adminKeys: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private featureService: FeatureService,
    private feedBackService: AsyncFeedbackService,
    private compService: CompanyService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private baseApi: BaseApi,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog
  ) {
    this.translate
      .get(['Errors', 'Success', 'Company', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.sectionState = SectionStateStatus.Loading;
    this.currentQueryParams = this.route.snapshot.queryParams;
    setTimeout(() => {
      this.sectionState = SectionStateStatus.Ready;
    }, 2000);
    this.createCompForm = this.formBuilder.group({
      companyName: ['', [Validators.required, noWhitespaceValidator]],
      companyImage: [[]],
      description: [''],
      companyAdminFirstName: ['', [Validators.required, noWhitespaceValidator]],
      companyAdminLastName: [''],
      companyAdminEmail: ['', [Validators.required, EmailValidator]],
      companyPhoneNumber: ['', [PhoneNumberValidator]],
      safetyFeatures: [[]],
      securityFeatures: [[]],
      CompQuota: [1],
      partNumberHIDIds: [[]],
      primary: ['#1f36b4'],
      secondary: ['#ffffff'],
      companyLogo: [[]],
    });
    this.getSessionOrganization();
    this.getAdminKeys();
    const roleIds: (number | string)[] = JSON.parse(
      localStorage.getItem('logged_in_user')
    )['roleIds'];
    this.isSuperAdmin = roleIds.includes(
      '9af4aa46-9587-42d3-81a7-86f54ac9ed30'
    );

    this.createCompForm
      .get('securityFeatures')
      .valueChanges.subscribe((res) => {
        if (res.length > 0) {
          this.setLicenseQuota = true;
          this.createCompForm
            .get('partNumberHIDIds')
            .setValidators(Validators.required);
          this.createCompForm
            .get('CompQuota')
            .setValidators(Validators.required);
        } else {
          this.setLicenseQuota = false;
          this.createCompForm.get('CompQuota').setValue(1);
          this.createCompForm.get('partNumberHIDIds').setValue([]);
          this.createCompForm.get('partNumberHIDIds').clearValidators();
          this.createCompForm.get('CompQuota').clearValidators();
        }
        this.createCompForm.get('partNumberHIDIds').updateValueAndValidity();
        this.createCompForm.get('CompQuota').updateValueAndValidity();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }

  getSessionOrganization(): void {
    this.route.queryParams.subscribe((params) => {
      this.orgParam = params['org'];
      this.orgParamId = params['id'];
      if (this.orgParam) {
        this.getAllFeatures(this.orgParam);
      } else {
        this.sessionOrg = this.baseApi.getOrganizationFromStorage();
        const orgCode = this.sessionOrg?.orgCode
          ? this.sessionOrg?.orgCode
          : '';
        console.log(orgCode);
        this.getAllFeatures(orgCode);
      }
    });
  }

  getAdminKeys(): void {
    this.compService.getAdminKeysByOrgId(this.orgParamId).subscribe({
      next: (response) => {
        this.adminKeys = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getAllFeatures(orgCode: string): void {
    this.featureService.getFeaturesByOrganization(orgCode).subscribe({
      next: (response) => {
        let security = response?.find(
          (element) => element['module'] === 'Security'
        );
        let safety = response?.find(
          (element) => element['module'] === 'Safety'
        );
        this.secFeatures = security ? security['features'] : [];
        this.sefetyFeatures = safety ? safety['features'] : [];
        this.quotaLimit = security['quotaLimit'];
        this.createCompForm
          .get('CompQuota')
          .setValidators(Validators.max(this.quotaLimit));
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  onSelectSafetyFeature(all) {
    if (this.allSafetySelected.selected) {
      this.allSafetySelected.deselect();
    }
    if (
      this.createCompForm.get('safetyFeatures').value.length ==
      this.sefetyFeatures.length
    ) {
      this.allSafetySelected.select();
    }
    console.log(this.createCompForm.get('safetyFeatures').value);
  }

  onSelectAllSafetyFeature() {
    if (this.allSafetySelected.selected) {
      this.createCompForm
        .get('safetyFeatures')
        .patchValue([
          ...this.sefetyFeatures.map((item) => item.featureCode),
          0,
        ]);
      this.selectedFeatures = [...this.sefetyFeatures];
    } else {
      this.createCompForm.get('safetyFeatures').patchValue([]);
    }
  }

  onSelectSecurityFeature(all) {
    if (this.allSecuritySelected.selected) {
      this.allSecuritySelected.deselect();
    }
    if (
      this.createCompForm.get('securityFeatures').value.length ==
      this.secFeatures.length
    ) {
      this.allSecuritySelected.select();
    }
  }

  onSelectAllSecurityFeature() {
    if (this.allSecuritySelected.selected) {
      this.createCompForm
        .get('securityFeatures')
        .patchValue([...this.secFeatures.map((item) => item.featureCode), 0]);
    } else {
      this.createCompForm.get('securityFeatures').patchValue([]);
    }
  }

  openLogoDialog() {
    this.dialog.open(this.uploadLogo, {
      maxHeight: 'auto',
      width: '40%',
      disableClose: false,
    });
  }
  cancelDialog() {
    this.croppedImageUrl = '';
    this.dialog.closeAll();
  }
  createCompany(): void {
    const {
      companyName,
      description,
      companyAdminFirstName,
      companyAdminLastName,
      companyAdminEmail,
      companyPhoneNumber,
      CompQuota,
      companyImage,
      primary,
      secondary,
      partNumberHIDIds,
      safetyFeatures,
      securityFeatures,
    } = this.createCompForm.value;
    const formData = new FormData();
    formData.append('file', companyImage[0]);
    if (this.croppedImage) {
      formData.append(
        'CompanyLogo',
        this.croppedImage ? this.croppedImage : null
      );
    }

    const companyData = {
      companyName,
      description,
      companyAdminFirstName,
      companyAdminLastName,
      companyAdminEmail,
      companyPhoneNumber,
      CompQuota,
      partNumberHIDIds,
      features: safetyFeatures.concat(securityFeatures),
      organizationId: this.orgParamId
        ? this.orgParamId
        : this.sessionOrg?.orgId
        ? this.sessionOrg?.orgId
        : 0,
      organizationCode: this.orgParam
        ? this.orgParam
        : this.sessionOrg?.orgCode
        ? this.sessionOrg?.orgCode
        : '',
      themeColor: primary,
      secondaryColor: secondary,
    };

    formData.append('content', JSON.stringify(companyData));
    this.loadingLabel = 'CreatingCompany';
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['Company']['CreateConfirm']['Title'],
          this.translationsList['Company']['CreateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.compService.createCompany(formData).subscribe({
            next: (respose) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, respose?.message)
              );
              if (this.orgParamId) {
                this.router.navigate([
                  '/' +
                    SharedConstants.ORGANIZATION_SETUP +
                    '/' +
                    SharedConstants.ORGANIZATION_VIEW +
                    '/' +
                    this.orgParamId,
                ]);
              } else {
                this.router.navigate([
                  SharedConstants.COMPANY_SETUP +
                    '/' +
                    SharedConstants.COMPANIES,
                ]);
              }

              this.resetForm();
            },
            error: ({ error }) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
            },
          });
        }
      });
  }

  cancelForm(): void {
    console.log(this.orgParam);
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['CancelForm']['Title'],
          this.translationsList['User']['CancelForm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          if (this.orgParam) {
            this.router.navigate([
              '/' +
                SharedConstants.ORGANIZATION_SETUP +
                '/' +
                SharedConstants.ORGANIZATION_VIEW +
                '/' +
                this.orgParamId,
            ]);
          } else {
            this.router.navigate([
              SharedConstants.COMPANY_SETUP + '/' + SharedConstants.COMPANIES,
            ]);
          }
          this.resetForm();
        }
      });
  }
  fileChangeEvent(event: any): void {
    const selectedFile: File = event.target.files[0];
    if (this.validateImageFile(selectedFile)) {
      this.imageChangedEvent = event;
    } else {
      this.createCompForm.get('companyLogo').invalid;
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          'Invalid file type. Please select an image.'
        )
      );
    }
  }

  validateImageFile(file: File): boolean {
    return file && file.type.includes('image');
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImageUrl = this.sanitizer.bypassSecurityTrustUrl(
      event.objectUrl
    );
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    this.croppedImage =
      this.croppedImage['changingThisBreaksApplicationSecurity'];
    this.convertBlobToFile(this.croppedImage).then((file) => {
      this.croppedImage = file;
    });
  }
  convertBlobToFile(croppedImage): Promise<File> {
    return new Promise((resolve, reject) => {
      fetch(croppedImage)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], 'filename.png', { type: blob.type });
          resolve(file);
        })
        .catch((error) => {
          console.error('Error fetching or creating file:', error);

          // Reject the Promise with the error
          reject(error);
        });
    });
  }
  resetForm(): void {
    this.createCompForm.get('companyName')?.setValue('');
    this.createCompForm.get('companyImage')?.setValue([]);
    this.createCompForm.get('description')?.setValue('');
    this.createCompForm.get('companyAdminFirstName')?.setValue('');
    this.createCompForm.get('companyAdminLastName')?.setValue('');
    this.createCompForm.get('companyAdminEmail')?.setValue('');
    this.createCompForm.get('companyPhoneNumber')?.setValue('');
    this.createCompForm.get('features')?.setValue([]);
    this.selectedFeatures = [];
  }
}
