import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { ApplicationPermission } from '../shared/models/application-permission';
import { SharedConstants } from '../shared/models/shared-constants';
import { ExecuteChecklistPageComponent } from './pages/execute-checklist-page/execute-checklist-page/execute-checklist-page.component';
import { InspectionPlanPageComponent } from './pages/inspection-plan-page/inspection-plan-page.component';
import { ViewTaskTrackerPageComponent } from './pages/view-task-tracker-page/view-task-tracker-page/view-task-tracker-page.component';
import { TaskTrackerComponent } from './pages/task-tracker/task-tracker.component';

const routes: Routes = [
  {
    path: SharedConstants.INSPECTION_PLAN,
    component: InspectionPlanPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.INSPECTION_PLAN_ACCESS,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'CreateAudit',
      },
    },
  },
  {
    path: SharedConstants.TASK_TRACKER_List,
    component: TaskTrackerComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.TASK_TRACKER_ACCESS,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'YourAudits',
      },
    },
  },
  {
    path:
      SharedConstants.TASK_TRACKER_List +
      '/' +
      SharedConstants.View_TASK_TRACKER +
      '/:id',
    component: ViewTaskTrackerPageComponent,
    // canActivate: [PermissionGuard],
    data: {
      // permissions: ApplicationPermission.SECURITY_MANAGE_IDCARDS_CREATE,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
  {
    path:
      SharedConstants.TASK_TRACKER_List +
      '/' +
      SharedConstants.EXECUTE_TASK_TRACKER +
      '/:id',
    component: ExecuteChecklistPageComponent,
    // canActivate: [PermissionGuard],
    data: {
      // permissions: ApplicationPermission.SECURITY_MANAGE_IDCARDS_CREATE,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'Execute',
        isActive: true,
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TaskTrackerRoutingModule {}
