<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.Organizations' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <div>
      <app-update-organization
        [orgId]="orgId"
        [isAdmin]="true"
      ></app-update-organization>
    </div>
  </hodhod-page-layout-content>
</hodhod-page-layout>
