<app-state-section
  body
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="'organization.LoadingInfo' | translate"
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <app-card [title]="licenseData?.name + ' license data'" *ngIf="licenseData">
    <div body class="flex justify-between flex-wrap-reverse">
      <div class="flex-1">
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">
              {{
                licenseInfo?.licenseInfo
                  ? licenseInfo?.licenseInfo[0]["availableQty"]
                  : licenseInfo?.available
              }}
            </p>
            <p class="m-0 caption text-hint">available</p>
          </div>
        </div>
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">
              {{
                licenseInfo?.licenseInfo
                  ? licenseInfo?.licenseInfo[0]["consumedQty"]
                  : licenseData?.active
              }}
            </p>
            <p class="m-0 caption text-hint">ِActive</p>
          </div>
        </div>

        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">
              {{
                licenseInfo?.licenseInfo
                  ? licenseInfo?.licenseInfo[0]["total"]
                  : licenseData?.accessControlLicenseQuota
              }}
            </p>
            <p class="m-0 caption text-hint">Total</p>
          </div>
        </div>
      </div>
      <div class="flex-1" *ngIf="licenseData?.licenseInfo">
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">{{ licenseData?.activeUsers }}</p>
            <p class="m-0 caption text-hint">ِActive users</p>
          </div>
        </div>
      </div>
      <div class="flex-1" *ngIf="!licenseData?.licenseInfo">
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">{{ licenseData?.subscriptionDate | date }}</p>
            <p class="m-0 caption text-hint">subscription Date</p>
          </div>
        </div>
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">{{ licenseData?.updatedAt | date }}</p>
            <p class="m-0 caption text-hint">updated At</p>
          </div>
        </div>
      </div>
    </div>
  </app-card>
</app-state-section>
