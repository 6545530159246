<div class="login_wrapper flex column justify-center">

  <!-- Login Form -->
  <div class="form-container flex column">
    <div class="pb-16">
      <app-language-button></app-language-button>
    </div>
    <div class="pb-12">
      <img src="assets/img/resources/Sanedlogo.png" class="w-7/12" alt="" />
    </div>
    <span class="title text-start">{{ "Login.Title" | translate }}</span>
    <app-dismiss-alert
      *ngIf="authService.signInError"
      [message]="authService.signInError"
    ></app-dismiss-alert>
    <form class="form-group flex column" [formGroup]="form">
      <div style="color: #1F36B4;">
        <h5 class="font-medium">{{ "General.Buttons.Login" | translate }}</h5>
        <p class="forgot-password">{{ "Login.loginTitle" | translate }}</p>
      </div>

      <div class="input flex column">
        <mat-form-field appearance="outline">
          <mat-label class="font-semibold">{{
            "General.Inputs.Email" | translate
          }}</mat-label>
          <input
            matInput
            type="email"
            formControlName="email"
            autocomplete="email"
          />
          <mat-error
            *ngIf="form.invalid && form.get('email').hasError('required')"
            >{{ "Errors.Required" | translate }}</mat-error
          >
          <mat-error
            *ngIf="form.invalid && form.get('email').hasError('invalidEmail')"
            >{{ "Errors.InvalidEmail" | translate }}</mat-error
          >
        </mat-form-field>
      </div>

      <div class="input flex column">
        <mat-form-field appearance="outline">
          <mat-label class="font-semibold">{{
            "General.Inputs.Password" | translate
          }}</mat-label>
          <input
            matInput
            [type]="hidePassword ? 'password' : 'text'"
            [placeholder]="'Placeholder.EnterYourPassword' | translate"
            formControlName="password"
            autocomplete="current-password"
          />
          <mat-icon
            class="password-visibility"
            matSuffix
            (click)="hidePassword = !hidePassword"
            [svgIcon]="hidePassword ? 'mat:visibility_off' : 'mat:visibility'"
          >
          </mat-icon>
          <mat-error
            *ngIf="form.invalid && form.get('password').hasError('required')"
            >{{ "Errors.Required" | translate }}</mat-error
          >
        </mat-form-field>
      </div>
      <div class="flex justify-between pt-3">
        <mat-checkbox mat-button class="forgot-password block" formControlName="rememberMe">
          {{ "Login.RememberMe" | translate }}
        </mat-checkbox>

        <a
          class="forgot-password"
          color="#1F36B4"
          [routerLink]="[
            '/' + SharedConstant.AUTH + '/' + SharedConstant.FORGOT_PASSWORD
          ]"
          >{{ "Login.ForgotPassword" | translate }}</a
        >
      </div>
      <button
        mat-button
        [disabled]="form.invalid"
        mat-raised-button
        color="color-on-error"
        class="login-button"
        (click)="onLoginButtonClicked()"
      >
        {{ "General.Buttons.Login" | translate }}
      </button>
    </form>
  </div>
</div>
