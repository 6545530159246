import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { ManageAccessControlService } from 'src/backend/services/manage-access-control/manage-access-control.service';

@Component({
  selector: 'app-user-mobile-devices',
  templateUrl: './user-mobile-devices.component.html',
  styleUrls: ['./user-mobile-devices.component.scss'],
})
export class UserMobileDevicesComponent implements OnInit, OnChanges {
  @ViewChild('cardsContainer') cardsContainer!: ElementRef;
  @ViewChild('issueMobileIdModal') issueMobileIdModal!: TemplateRef<any>;

  @Input() mobileDevices: any;
  @Input() userDetails: any;

  @Output() onDeleteDevice: EventEmitter<string> = new EventEmitter();
  @Output() onIssueMobileIds: EventEmitter<any> = new EventEmitter();
  @Output() onInvokeCredential: EventEmitter<any> = new EventEmitter();

  trackByIndex = (index, item) => index;
  mobileDevicesClone: any;

  cards: any[] = [];
  filteredCards: any[] = [];
  devicesAvailableCards: any[] = [];
  selectedDeviceId: string;
  selectedCardsIds: any[] = [];

  constructor(
    private dialog: MatDialog,
    private feedBackService: AsyncFeedbackService,
    private loadingService: LoadingService,
    private manageAccessControlService: ManageAccessControlService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mobileDevices']?.currentValue) {
      this.mobileDevicesClone = structuredClone(this.mobileDevices);
      this.mobileDevicesClone = this.mobileDevicesClone.map((item) => {
        return {
          ...item,
          activeCredentials: item.credentials
            ? item.credentials.filter((item) => item.status !== 'REVOKED')
            : [],
          revokedCredentials: item.credentials
            ? item.credentials.filter((item) => item.status === 'REVOKED')
            : [],
        };
      });
      this.mobileDevices = this.mobileDevicesClone;
    }
  }

  ngOnInit(): void {
    this.getCards();
  }

  slideRight(index, cardName) {
    document.getElementById(cardName + index).scrollLeft += 500;
  }
  slideLeft(index, cardName) {
    document.getElementById(cardName + index).scrollLeft -= 500;
  }

  deleteDevice(id) {
    this.onDeleteDevice.emit(id);
  }

  checkSelectedCards(index, isShowCards?: boolean) {
    const revokedPartNumbers = this.mobileDevicesClone[index].credentials
      .filter((credential) => credential.status === 'REVOKED')
      .map((credential) => credential.partNumber);

    const result = this.cards.filter((item) => {
      const isInArray1 = this.mobileDevicesClone[index].credentials.some(
        (credential) => credential.partNumber === item.partNumber
      );
      const isRevoked = revokedPartNumbers.includes(item.partNumber);
      return !isInArray1 || isRevoked;
    });

    if (isShowCards) {
      this.filteredCards = result;
    }

    return result.length > 0 ? false : true;
  }

  getCards() {
    this.loadingService.startLoading(true);
    this.manageAccessControlService.getCards().subscribe({
      next: (res) => {
        this.cards = res;
        this.loadingService.stopLoading();
      },
      error: (error) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  openIssueMobileId(i) {
    this.checkSelectedCards(i, true);
    this.selectedDeviceId = this.mobileDevicesClone[i]?.id;
    this.selectedCardsIds = [];
    this.filteredCards = this.filteredCards.map((item) => {
      return {
        selected: false,
        ...item,
      };
    });

    this.dialog.open(this.issueMobileIdModal, {
      height: 'auto',
      width: '60%',
      disableClose: true,
    });
  }

  toggleSelection(card: any) {
    card.selected = !card.selected;
    if (!this.selectedCardsIds.some((item) => item.id == card.id)) {
      this.selectedCardsIds.push(card);
    } else {
      this.selectedCardsIds = this.selectedCardsIds.filter(function (obj) {
        return obj.id !== card.id;
      });
    }
  }

  confirmIssueMobileId() {
    const data = {
      credentialContainerId: this.selectedDeviceId,
      partNumbers: this.selectedCardsIds.map((item) => item.partNumber),
    };
    this.onIssueMobileIds.emit(data);
  }

  invokeCredential(credentialId) {
    this.onInvokeCredential.emit(credentialId);
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
