<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.Organizations' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <app-available-part-numbers-list></app-available-part-numbers-list>
    <app-assigned-part-numbers-list></app-assigned-part-numbers-list>
  </hodhod-page-layout-content>
</hodhod-page-layout>
