import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageUserAccessControlComponent } from './pages/manage-user-access-control/manage-user-access-control.component';
import { AccessControlSetupRoutingModule } from './access-control-setup-routing.module';
import { SharedModule } from '../shared/shared.module';
import { EmployeesListComponent } from './components/employees-list/employees-list.component';
import { AccessControlUserDetailsComponent } from './pages/access-control-user-details/access-control-user-details.component';
import { UserDetailsCardComponent } from './components/user-details-card/user-details-card.component';
import { UserInvitationsLogCardComponent } from './components/user-invitations-log-card/user-invitations-log-card.component';
import { UserMobileDevicesComponent } from './components/user-mobile-devices/user-mobile-devices.component';
import { MobileCardIdComponent } from './components/mobile-card-id/mobile-card-id.component';
import { QRCodeModule } from 'angularx-qrcode';
import { LicenseDataCardComponent } from './components/license-data-card/license-data-card.component';
import { VisitorsListComponent } from './components/visitors-list/visitors-list.component';
import { InviteEmployeeComponent } from './components/invite-employee/invite-employee.component';
import { InviteVisitorComponent } from './components/invite-visitor/invite-visitor.component';

@NgModule({
  declarations: [
    ManageUserAccessControlComponent,
    EmployeesListComponent,
    AccessControlUserDetailsComponent,
    UserDetailsCardComponent,
    UserInvitationsLogCardComponent,
    UserMobileDevicesComponent,
    MobileCardIdComponent,
    LicenseDataCardComponent,
    VisitorsListComponent,
    InviteEmployeeComponent,
    InviteVisitorComponent,
  ],
  imports: [
    CommonModule,
    AccessControlSetupRoutingModule,
    SharedModule,
    QRCodeModule,
  ],
})
export class AccessControlSetupModule {}
