<div class="for_wrapper flex column justify-start align-top">
  <div class="language-dropdown pb-16">
    <app-language-button></app-language-button>
  </div>
  <div
    *ngIf="!recoverEmailSent"
    class="form-container flex column justify-space-around"
  >
  <div class="pb-12">
    <img src="assets/img/resources/Sanedlogo.png" class="w-7/12" alt="" />
  </div>
    <span class="title">{{ "ForgotPassword.Title" | translate }}</span>
    <span class="font-medium text-lg py-1">{{
      "General.Buttons.RecoverPassword" | translate
    }}</span>
    <span class="body pb-2">{{ "ForgotPassword.Body" | translate }}</span>
    <form
      class="form-group flex column justify-flex-start align-flex-start"
      [formGroup]="form"
    >
      <div class="input flex column">
        <mat-form-field appearance="outline">
          <mat-label class="font-semibold">{{
            "General.Inputs.Email" | translate
          }}</mat-label>
          <input
            matInput
            type="email"
            formControlName="email"
            autocomplete="email"
          />
          <mat-error
            *ngIf="form.invalid && form.get('email').hasError('required')"
            >{{ "Errors.Required" | translate }}</mat-error
          >
          <mat-error
            *ngIf="form.invalid && form.get('email').hasError('invalidEmail')"
            >{{ "Errors.InvalidEmail" | translate }}</mat-error
          >
        </mat-form-field>
      </div>
      <a
        class="login"
        mat-button
        color="primary"
        [routerLink]="[
          '/' + SharedConstant.AUTH + '/' + SharedConstant.SIGN_IN
        ]"
        >{{ "ForgotPassword.SignIn" | translate }}</a
      >
      <button
        mat-button
        [disabled]="form.invalid"
        mat-raised-button
        color="color-on-error"
        class="recover-password-button"
        (click)="onRecoverPasswordButtonClicked()"
      >
        {{ "General.Buttons.RecoverPassword" | translate }}
      </button>
    </form>

    <div class="language-dropdown">
      <app-language-button></app-language-button>
    </div>
  </div>
  <div
    *ngIf="recoverEmailSent"
    class="form-container flex column justify-space-around"
  >
    <app-show-result
      [iconColor]="'text-lime-600'"
      [icon]="'check_circle'"
      [title]="'ForgotPassword.EmailSent'"
      [details]="'ForgotPassword.CheckEmail'"
      [iconHeight]="100"
      [iconWidth]="100"
    ></app-show-result>
    <div class="loginButton flex justify-center">
      <a
        [routerLink]="[
          '/' + SharedConstant.AUTH + '/' + SharedConstant.SIGN_IN
        ]"
        >{{ "General.Buttons.Login" | translate }}</a
      >
    </div>
  </div>
</div>
