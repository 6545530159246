import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivateLayoutComponent } from './private-layout.component';
import { LayoutModule } from 'src/@hodhod/layout/layout.module';
import { SidenavModule } from 'src/@hodhod/layout/sidenav/sidenav.module';
import { ToolbarModule } from 'src/@hodhod/layout/toolbar/toolbar.module';
import { FooterModule } from 'src/@hodhod/layout/footer/footer.module';
import { ConfigPanelModule } from 'src/@hodhod/components/config-panel/config-panel.module';
import { SidebarModule } from 'src/@hodhod/components/sidebar/sidebar.module';
import { QuickpanelModule } from 'src/@hodhod/layout/quickpanel/quickpanel.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    SidenavModule,
    ToolbarModule,
    FooterModule,
    ConfigPanelModule,
    SidebarModule,
    QuickpanelModule,
    TranslateModule,
  ],
  declarations: [PrivateLayoutComponent],
})
export class PrivateLayoutModule {}
