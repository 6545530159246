<mat-card class="rounded-xl">
  <form [formGroup]="checkListForm" autocomplete="off">
    <div class="flex">
      <div class="flex column w-6/12 m-2">
        <mat-label class="field-label"
          >{{ "CheckList.ChecklistName" | translate }}
          <span
            [ngClass]="
              checkListForm.invalid &&
              checkListForm.get('checklistName').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            [placeholder]="'Placeholder.AddText' | translate"
            matInput
            type="text"
            formControlName="checklistName"
          />
          <mat-error
            *ngIf="
              checkListForm.invalid &&
              checkListForm.get('checklistName').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              checkListForm.invalid &&
              checkListForm.get('checklistName').hasError('whitespace')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.InValidData" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex column w-6/12 m-2">
        <mat-label class="field-label"
          >{{ "CheckList.ChecklistType" | translate }}
          <span
            [ngClass]="
              checkListForm.invalid &&
              checkListForm.get('checklisttype').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field p-0" appearance="outline">
          <mat-select
            formControlName="checklisttype"
            [placeholder]="'CheckList.ChecklistType' | translate"
          >
            <mat-option
              [value]="'Audit'"
              *ngIf="hasAuditCreatePermission | permissionChecker"
              >{{ "Enum.ChecklistType.Audit" | translate }}</mat-option
            >
            <mat-option
              [value]="'WorkCondition'"
              *ngIf="hasWorkConditionsCreatePermission | permissionChecker"
              >{{ "Enum.ChecklistType.WorkCondition" | translate }}</mat-option
            >
            <mat-option
              [value]="'Inspection'"
              *ngIf="hasInspectionCreatePermission | permissionChecker"
              >{{ "Enum.ChecklistType.Inspection" | translate }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              checkListForm.invalid &&
              checkListForm.get('checklisttype').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div
        class="flex column pb-2 w-6/12 m-2"
        *ngIf="
          checkListForm.get('checklisttype').value === 'Audit' ||
          checkListForm.get('checklisttype').value === 'Inspection'
        "
      >
        <mat-label class="field-label"
          >{{ "General.MinimumScore" | translate }}
          <span
            *ngIf="this.checkListForm.get('checklisttype').value === 'Audit'"
            [ngClass]="
              checkListForm.invalid &&
              checkListForm.get('minimumScore').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            [placeholder]="'Placeholder.EnterValue' | translate"
            matInput
            type="number"
            step="0.1"
            oninput="validity.valid||(value='');"
            formControlName="minimumScore"
            [min]="0"
            [max]="100"
          />
          <mat-error
            *ngIf="
              checkListForm.invalid &&
              checkListForm.get('minimumScore').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error *ngIf="checkListForm.controls['minimumScore'].errors">
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.valueLimit" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="survey-options">
      <div formArrayName="sections" class="poll-options">
        <ol class="list-style">
          <li
            class="rounded p-2 mb-2"
            style="background: var(--background-base)"
            *ngFor="
              let sectionCtrl of repeatSectionFormGroup.controls;
              let i = index
            "
          >
            <div [formGroupName]="i">
              <div>
                <div class="relative">
                  <mat-form-field class="w-4/6" appearance="outline">
                    <input
                      formControlName="title"
                      maxlength="200"
                      matInput
                      [placeholder]="'CheckList.SectionTitle' | translate"
                      [required]="true"
                    />
                  </mat-form-field>
                  <mat-form-field class="w-4/6" appearance="outline">
                    <mat-select
                      formControlName="questionType"
                      [placeholder]="'CheckList.SectionType' | translate"
                      ng-model="myVar"
                      (selectionChange)="
                        onSeletSectionType(
                          checkListForm.get('sections').value[i].questionType,
                          i
                        )
                      "
                      [required]="true"
                    >
                      <mat-option
                        *ngFor="let type of questionTypes | enumToArray"
                        [value]="type.value"
                      >
                        {{
                          "Enum.ChecklistSectionType." + type.value | translate
                        }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <button
                    class="absolute top-0 right-0"
                    color="primary "
                    *ngIf="this.editMode !== 'view'"
                    (click)="onRemoveSection(i)"
                    [matTooltip]="
                      (numOfSections < 2
                        ? 'General.Buttons.CantRemoveSection'
                        : 'General.Buttons.RemoveSection'
                      ) | translate
                    "
                    type="button"
                  >
                    <mat-icon svgIcon="mat:delete"></mat-icon>
                  </button>
                </div>
                <div
                  formArrayName="questions"
                  [ngSwitch]="
                    checkListForm.get('sections').value[i].questionType
                  "
                >
                  <div *ngSwitchCase="'Yes/No'">
                    <div>
                      <ul class="question-type">
                        <li
                          *ngFor="
                            let optionCtrl of repeatQuestionsFormGroup(i)
                              .controls;
                            let j = index
                          "
                        >
                          <div [formGroupName]="j" class="flex items-center">
                            <mat-form-field class="w-4/6" appearance="outline">
                              <input
                                formControlName="questionText"
                                matInput
                                [placeholder]="
                                  'CheckList.QuestionTitle' | translate
                                "
                                maxlength="300"
                                [required]="true"
                              />
                            </mat-form-field>
                            <div style="min-width: 100px" class="flex">
                              <mat-radio-group
                                formControlName="answer"
                                class="mr-4 ml-4"
                              >
                                <mat-radio-button value="Yes">{{
                                  "General.Yes" | translate
                                }}</mat-radio-button>
                                <mat-radio-button class="ml-3" value="No"
                                  >{{ "General.No" | translate }}
                                </mat-radio-button>
                              </mat-radio-group>
                            </div>
                            <mat-icon
                              color="primary"
                              *ngIf="this.editMode !== 'view'"
                              (click)="removeQuestion(i, j)"
                              class="ml-4 flex-none"
                              [matTooltip]="
                                (j < 1
                                  ? 'General.Buttons.CantRemoveQuestion'
                                  : 'General.Buttons.RemoveQuestion'
                                ) | translate
                              "
                              svgIcon="mat:delete_outline"
                            >
                            </mat-icon>
                          </div>
                        </li>
                      </ul>
 
                      <app-basic-button
                      *ngIf="this.editMode !== 'view'"
                      [btnType]="'Black'"
                      [btnText]="'AddQuestions'"
                      (onClick)="addQuestion(i)"
                    ></app-basic-button>
                      
                    </div>
                  </div>
                  <div *ngSwitchCase="'Select from range'">
                    <div>
                      <ul class="question-type">
                        <li
                          *ngFor="
                            let optionCtrl of repeatQuestionsFormGroup(i)
                              .controls;
                            let j = index
                          "
                        >
                          <div [formGroupName]="j" class="flex items-center">
                            <mat-form-field class="w-4/6" appearance="outline">
                              <input
                                formControlName="questionText"
                                matInput
                                [placeholder]="
                                  'CheckList.QuestionTitle' | translate
                                "
                                maxlength="300"
                                [required]="true"
                              />
                            </mat-form-field>
                            <mat-radio-group
                              formControlName="answer"
                              class="ml-4 mr-4 Range-btns"
                            >
                              <mat-radio-button
                                class="ml-3 p-2.5"
                                #btn
                                [ngClass]="{
                                  'border-secondary background-checked':
                                    btn?.checked
                                }"
                                *ngFor="let r of ranges"
                                [value]="r.id"
                                >{{ r.value }}</mat-radio-button
                              >
                            </mat-radio-group>
                            <mat-icon
                              color="primary"
                              *ngIf="this.editMode !== 'view'"
                              (click)="removeQuestion(i, j)"
                              class="ml-4 flex-none"
                              [matTooltip]="
                                (j < 1
                                  ? 'General.Buttons.CantRemoveQuestion'
                                  : 'General.Buttons.RemoveQuestion'
                                ) | translate
                              "
                              svgIcon="mat:delete_outline"
                            >
                            </mat-icon>
                          </div>
                        </li>
                      </ul>
                      <!-- <button
                        *ngIf="this.editMode !== 'view'"
                        mat-raised-button
                        type="button"
                        (click)="addQuestion(i)"
                        class="mat-sm-button add-option-btn"
                        color="accent"
                      >
                        {{ "General.Buttons.AddQuestions" | translate }}
                      </button> -->
                      <app-basic-button
                      *ngIf="this.editMode !== 'view'"
                      [btnType]="'Black'"
                      [btnText]="'AddQuestions'"
                      (onClick)="addQuestion(i)"
                    ></app-basic-button>
                    </div>
                  </div>
                  <div *ngSwitchCase="'Machine Parameter'">
                    <div>
                      <ul class="question-type">
                        <li
                          *ngFor="
                            let optionCtrl of repeatQuestionsFormGroup(i)
                              .controls;
                            let j = index
                          "
                        >
                          <div [formGroupName]="j" class="flex items-center">
                            <mat-form-field
                              class="full-width"
                              appearance="outline"
                            >
                              <mat-select
                                formControlName="answer"
                                (selectionChange)="
                                  onMachineChange($event, i, j)
                                "
                                [placeholder]="
                                  'CheckList.SelectMachine' | translate
                                "
                                [required]="true"
                              >
                                <mat-option
                                  *ngFor="let machine of machines"
                                  [value]="machine.id"
                                >
                                  {{ machine.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field
                              class="full-width ml-4 mr-4"
                              appearance="outline"
                            >
                              <mat-select
                                formControlName="questionText"
                                [placeholder]="
                                  'CheckList.SelectParameter' | translate
                                "
                                [required]="true"
                              >
                                <mat-option
                                  *ngFor="
                                    let param of checkListForm
                                      .get('sections')
                                      ['controls'][i].get('questions')
                                      ['controls'][j].get('parameters')?.value
                                  "
                                  [value]="param.id"
                                >
                                  {{ param.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-icon
                              color="primary"
                              *ngIf="this.editMode !== 'view'"
                              (click)="removeQuestion(i, j)"
                              class="ml-4 flex-none"
                              [matTooltip]="
                                (j < 1
                                  ? 'General.Buttons.CantRemoveQuestion'
                                  : 'General.Buttons.RemoveQuestion'
                                ) | translate
                              "
                              svgIcon="mat:delete_outline"
                            >
                            </mat-icon>
                          </div>
                        </li>
                      </ul>
                      <!-- <button
                        *ngIf="this.editMode !== 'view'"
                        mat-raised-button
                        type="button"
                        (click)="addQuestion(i)"
                        class="mat-sm-button add-option-btn"
                        color="accent"
                      >
                        {{ "General.Buttons.AddQuestions" | translate }}
                      </button> -->
                      <app-basic-button
                      *ngIf="this.editMode !== 'view'"
                      [btnType]="'Black'"
                      [btnText]="'AddQuestions'"
                      (onClick)="addQuestion(i)"
                    ></app-basic-button>
                    </div>
                  </div>
                  <div *ngSwitchCase="'Value Vs Target'">
                    <div>
                      <ul class="question-type">
                        <li
                          *ngFor="
                            let optionCtrl of repeatQuestionsFormGroup(i)
                              .controls;
                            let j = index
                          "
                        >
                          <div [formGroupName]="j" class="flex items-center">
                            <mat-form-field class="w-4/6" appearance="outline">
                              <input
                                formControlName="questionText"
                                matInput
                                [placeholder]="
                                  'CheckList.QuestionTitle' | translate
                                "
                                maxlength="300"
                                [required]="true"
                              />
                            </mat-form-field>
                            <div
                              class="range-container flex row justify-space-between ml-4 mr-4 mb-6"
                            >
                              <mat-select
                                class="select"
                                formControlName="answer"
                              >
                                <mat-option
                                  *ngFor="
                                    let item of OperatorType | enumToArray
                                  "
                                  [value]="item.value"
                                >
                                  {{ item.value }}
                                </mat-option>
                              </mat-select>
                              <input
                                class="value"
                                formControlName="target"
                                type="number"
                              />
                            </div>
                            <mat-icon
                              color="primary"
                              *ngIf="this.editMode !== 'view'"
                              (click)="removeQuestion(i, j)"
                              class="ml-4 flex-none"
                              [matTooltip]="
                                (j <= 0
                                  ? 'General.Buttons.CantRemoveQuestion'
                                  : 'General.Buttons.RemoveQuestion'
                                ) | translate
                              "
                              svgIcon="mat:delete_outline"
                            >
                            </mat-icon>
                          </div>
                        </li>
                      </ul>
                      <!-- <button
                        *ngIf="this.editMode !== 'view'"
                        mat-raised-button
                        type="button"
                        (click)="addQuestion(i)"
                        class="mat-sm-button add-option-btn"
                        color="accent"
                      >
                        {{ "General.Buttons.AddQuestions" | translate }}
                      </button> -->
                      <app-basic-button
                      *ngIf="this.editMode !== 'view'"
                      [btnType]="'Black'"
                      [btnText]="'AddQuestions'"
                      (onClick)="addQuestion(i)"
                    ></app-basic-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </div>

    <div class="button-row" *ngIf="this.editMode !== 'view'">
      <!-- <button
        mat-raised-button
        type="button"
        (click)="onAddSection()"
        class="mat-sm-button add-option-btn"
        color="accent"
      >
        {{ "General.Buttons.AddSections" | translate }}
      </button> -->
      <!-- <button
        mat-raised-button
        type="button"
        (click)="save()"
        [disabled]="checkListForm.invalid"
        class="mat-sm-button add-option-btn mx-2"
        color="primary"
      >
        {{ "General.Buttons.SaveChecklist" | translate }}
      </button> -->
      <app-basic-button
      [btnType]="'Black'"
      [btnText]="'AddSections'"
      [isDisabled]="checkListForm.invalid"
      (onClick)="onAddSection()"
    ></app-basic-button>
      <app-basic-button
      [btnType]="'Add'"
      [btnText]="'SaveChecklist'"
      [isDisabled]="checkListForm.invalid"
      (onClick)="save()"
    ></app-basic-button>

      <!-- <button
        class="full-width ml-4 mr-4"
        color="primary"
        type="button"
        (click)="createCheckList()"
        mat-raised-button
        [disabled]="
          !checkListForm.valid ||
          checkListForm.get('sections').value?.length === 0
        "
      >
        <span>{{ "General.Buttons.SaveChecklist" | translate }}</span>
      </button> -->
    </div>
  </form>
</mat-card>
