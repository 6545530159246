<div>
  <form action="" [formGroup]="orgForm">
    <mat-label class="field-label text-primary font-semibold">{{
      "organization.UpdateQuota" | translate
    }}</mat-label>
    <mat-form-field class="input-field" appearance="outline">
      <input
        type="number"
        [placeholder]="'Placeholder.Insert' | translate"
        formControlName="orgQouta"
        matInput
        [max]="data?.quota"
      />
      <mat-error *ngIf="orgForm.get('orgQouta').hasError('required')">
        <div class="flex justify-flex-start align-flex-center">
          <img class="error-img" src="assets/img/resources/error-icon.svg" />
          <span>{{ "Errors.Required" | translate }}</span>
        </div>
      </mat-error>
      <mat-error *ngIf="orgForm.get('orgQouta').hasError('max')">
        <div class="flex justify-flex-start align-flex-center">
          <img class="error-img" src="assets/img/resources/error-icon.svg" />
          <span>{{ "Errors.MaxQuotaExceed" | translate }}</span>
        </div>
      </mat-error>
      <mat-hint></mat-hint>
    </mat-form-field>
    <div class="flex items-center justify-end">
      <app-basic-button
        [btnType]="'Cancel'"
        [btnText]="'Cancel'"
        (onClick)="closeDialog()"
      ></app-basic-button>
      <app-basic-button
        [btnType]="'Add'"
        [btnText]="'Edit'"
        [isDisabled]="orgForm.invalid"
        (onClick)="updateQouta()"
      ></app-basic-button>
    </div>
  </form>
</div>
