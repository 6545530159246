<app-state-section
  [flexibleHeight]="true"
  [state]="sectionStateModal"
  [loadingLabel]="
    (data.formMode === 'create'
      ? 'User.AddingLoadingUser'
      : 'User.UpdatingLoadingUser'
    ) | translate
  "
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <div class="flex items-center justify-end" mat-dialog-title>
    <button
      class="text-primary bg-transparent"
      mat-dialog-close
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="flex">
      <div class="flex column w-full mx-2 my-2">
        <div class="flex column mb-4">
          <div class="flex">
            <span class="label">
              {{ "SafetyAnnouncement.Form.AnnouncementTitle" | translate }}
            </span>
            <span>{{ selectedAnnouncement?.title }}</span>
          </div>
        </div>
        <div class="flex column mb-4">
          <div class="flex">
            <span class="label">{{'SafetyAnnouncement.Form.Tags'|translate}}</span>
            <mat-chip-list
              class="mat-chip-list-tags"
              #chipGridTags
              aria-label="Enter Tags"
            >
              <mat-chip
                *ngFor="let tag of tags; let i = index"
                [ngClass]="i % 2 == 0 ? 'tag-span-yellow' : 'tag-span-blue'"
              >
                {{ tag }}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </div>

      <div class="flex column w-full my-2">
        <div class="flex mb-4">
          <span class="label">{{'SafetyAnnouncement.Form.Receivers'|translate}}</span>
          <mat-chip-list #chipGrid aria-label="receiver selection">
            <mat-chip
              *ngFor="let receiver of selectedAnnouncement?.receivers"
              class="selected-span"
            >
              {{ receiver.receiverName }}
            </mat-chip>
          </mat-chip-list>
        </div>

        <div class="flex mb-4">
          <span class="label"
            >{{ "SafetyAnnouncement.Form.Type" | translate }}
          </span>
          <span>
            {{ selectedAnnouncement?.type }}
          </span>
        </div>
      </div>
    </div>

    <hr />
    <div class="text-center">
      <div class="text-center">
        <h2 class="py-2" [style.fontSize]="'1.2rem'">
          {{ selectedAnnouncement?.title }}
        </h2>
      </div>
      <div class="text-start flex items-center mb-2 justify-around">
        <div
          class="flex items-center group relative"
          *ngFor="let file of files; let i = index"
          style="margin: 5px"
        >
            <ngx-avatars download
            class="cursor-pointer"
            size="80"
            [src]="getAttachmentImage(file.attachmentUrl)"
            [textSizeRatio]="2"
            [round]="false"
            [initialsSize]="2"
            [cornerRadius]="10"
            [matTooltip]="file['fileName']"
            [borderColor]="'#1F36B4'"
            (clickOnAvatar)="openBigView(file.id)"
                  >
            </ngx-avatars>

        </div>
      </div>
      <div class="flex column description-card mt-4" [style.fontSize]="'1rem'">
        {{ selectedAnnouncement?.description }}
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions
    align="end"
    *ngIf="selectedAnnouncement?.notificationId && !selectedAnnouncement.isRead"
  >
    <div class="flex items-center">
      <app-basic-button
        [btnType]="'Cancel'"
        [btnText]="'Cancel'"
        (onClick)="closeDialog()"
      ></app-basic-button>
      <app-basic-button
        [btnType]="'Add'"
        [btnText]="'MarkAsRead'"
        (onClick)="markAsRead()"
      ></app-basic-button>
    </div>
  </mat-dialog-actions>
</app-state-section>
