<mat-expansion-panel
  #expansionPanel
  class="mat-collabse audit-collabse"
  hideToggle
  [expanded]="expanded.isOpen"
  (expandedChange)="getTaskById($event)"
>
  <mat-expansion-panel-header class="mat-header">
    <mat-panel-title class="justify-between">
      <span
        [matTooltip]="
          task.title + '\n Due Date: ' + (task.taskDate | date : 'mediumDate')
        "
        matTooltipClass="line-broken-tooltip"
        class="task-title text-sm"
      >
        {{ task.title }}
      </span>
      <div class="flex justify-center items-center">
        <span [id]="task.status" class="status-title">
          {{ task.status }}
        </span>
        <div>
          <button
            (click)="$event.stopPropagation()"
            mat-icon-button
            [matMenuTriggerFor]="menu"
          >
            <mat-icon svgIcon="mat:more_vert"></mat-icon>
          </button>
          <mat-menu
            yPosition="below"
            xPosition="before"
            #menu="matMenu"
            class="shadow-lg shadow-gray-400 rounded-2xl min-h-0"
          >
            <div
              *ngIf="
                (hasEditPermissions | permissionChecker) &&
                (task?.status === TasksStatus.NEW ||
                  task?.status === TasksStatus.RESCHEDULE) &&
                task?.createdBy === loggedUser?.userId
              "
              mat-menu-item
              class="flex items-center justify-between p-1 mat-div"
              (click)="editAudit()"
            >
              <button>
                {{ "General.Buttons.Edit" | translate }}
              </button>
              <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
            </div>

            <div
              *ngIf="
                (hasDeletePermissions | permissionChecker) &&
                (task?.status === TasksStatus.NEW ||
                  task?.status === TasksStatus.RESCHEDULE) &&
                task?.createdBy === loggedUser?.userId
              "
              mat-menu-item
              class="flex items-center justify-between p-1 mat-div"
              (click)="deleteAudit()"
            >
              <button>
                {{ "General.Buttons.Delete" | translate }}
              </button>
              <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
            </div>

            <div
              *ngIf="hasViewPermissions | permissionChecker"
              mat-menu-item
              class="flex items-center justify-between p-1 mat-div"
              (click)="viewAudit()"
            >
              <button>
                {{ "General.Buttons.View" | translate }}
              </button>
              <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
            </div>

            <div
              *ngIf="isWithinRange()"
              mat-menu-item
              class="flex items-center justify-between p-1 mat-div"
              (click)="executeAudit()"
            >
              <button>
                {{ "General.Buttons.Execute" | translate }}
              </button>
              <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
            </div>
          </mat-menu>
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-panel-description class="flex justify-center m-0">
    <mat-spinner
      [diameter]="50"
      *ngIf="!taskDetails; else content"
    ></mat-spinner>
    <ng-template #content>
      <div class="content">
        <p class="content-text">
          Checklist Name : {{ taskDetails?.checkList }}
        </p>
        <p class="content-text">Assigned To : {{ taskDetails?.user }}</p>
        <p class="content-text">Assigned By : {{ taskDetails?.createdBy }}</p>
        <p class="content-text">
          Start date : {{ taskDetails?.startTaskDate | date : "mediumDate" }}
        </p>
        <p class="content-text">
          Due Date : {{ taskDetails?.taskDate | date : "mediumDate" }}
        </p>
      </div>
    </ng-template>
  </mat-panel-description>
</mat-expansion-panel>

<ng-template #editDialog>
  <form [formGroup]="editDialogForm">
    <div mat-dialog-content>
      <p style="font-size: 25px">Do you want to Edit this task?</p>

      <div style="padding: 15px; margin-top: 21px">
        <mat-checkbox matInput formControlName="check" [color]="'warn'"
          >Edit Complete Series
        </mat-checkbox>
      </div>
    </div>
    <div mat-dialog-actions style="float: right">
      <app-basic-button
        [btnType]="'Cancel'"
        [btnText]="'Cancel'"
        mat-dialog-close
      >
      </app-basic-button>
      <app-basic-button
        [btnType]="'Add'"
        [btnText]="'Edit'"
        (click)="onClickEditDialog()"
      >
      </app-basic-button>
    </div>
  </form>
</ng-template>

<ng-template #deleteDialog>
  <form [formGroup]="deleteDialogForm">
    <div mat-dialog-content>
      <p style="font-size: 25px">Do you want to Delete this task?</p>

      <div style="padding: 15px; margin-top: 21px">
        <mat-checkbox matInput formControlName="check" [color]="'warn'"
          >Delete Complete Series
        </mat-checkbox>
      </div>
    </div>
    <div mat-dialog-actions style="float: right">
      <app-basic-button
        [btnType]="'Cancel'"
        [btnText]="'Cancel' | translate"
        mat-dialog-close
      >
      </app-basic-button>
      <app-basic-button
        [btnType]="'Add'"
        [btnText]="'Delete' | translate"
        (click)="onClickDeleteDialog()"
      >
      </app-basic-button>
    </div>
  </form>
</ng-template>
