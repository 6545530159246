<app-state-section
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="'User.LoadingUserInfo' | translate"
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <div class="flex justify-between">
    <div
      class="bg-foreground rounded-full px-4 max-w-[300px] flex-auto flex items-center border border-gray-300"
    >
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input
        [formControl]="searchCtrl"
        class="px-4 py-2 border-0 outline-none w-full bg-transparent"
        [placeholder]="'General.Search' | translate"
        type="search"
      />
    </div>
    <button
      [matMenuTriggerFor]="columnFilterMenu"
      class="ml-4 flex-none"
      mat-icon-button
      [matTooltip]="'General.Buttons.FilterColumns' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:filter_list"></mat-icon>
    </button>
  </div>
  <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
    <!-- Text Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
      <ng-container
        *ngIf="column.type === 'text'"
        [matColumnDef]="column.property"
      >
        <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>
          {{ "BulkUpload.Table." + column.label | translate }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
          {{ row[column.property] }}
          <span
            *ngIf="column.property === 'roleName' && row?.isDefault"
            class="bg-primary text-white rounded-full p-1 font-normal px-3"
            >Default</span
          >
        </td>
      </ng-container>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef class="uppercase" mat-header-cell>
        {{ "BulkUpload.Table.Actions" | translate }}
      </th>
      <td *matCellDef="let row" mat-cell>
        <div class="flex">
          <app-action-button
            [btnBackgroundClass]="'bg-teal-100'"
            [btnTextClass]="'text-teal-700'"
            [buttonToolTip]="'Confirm'"
            [buttonIcon]="'recommend'"
            (onClick)="openConfirmuserModal(row)"
          ></app-action-button>
          <!-- <app-action-button
            [btnBackgroundClass]="'bg-red-100'"
            [btnTextClass]="'text-red-700'"
            [buttonToolTip]="'Remove'"
            [buttonIcon]="'delete'"
            (onClick)="removeUser(row?.id)"
          >
          </app-action-button> -->
        </div>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
    <tr
      *matRowDef="let row; columns: visibleColumns"
      @fadeInUp
      class="hover:bg-hover trans-ease-out cursor-pointer"
      mat-row
    ></tr>
  </table>
  <div
    *ngIf="dataSource.filteredData.length === 0"
    @scaleFadeIn
    class="flex-auto flex flex-col items-center justify-center"
  >
    <img src="assets/img/resources/empty-state.svg" alt="not found" />
    <h2 class="headline m-0 text-center text-primary">
      {{ "General.EmptyTableList" | translate }}...
    </h2>
  </div>

  <mat-paginator
    [class.hidden]="dataSource.filteredData.length === 0"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    showFirstLastButtons
    class="sticky left-0"
  ></mat-paginator>
</app-state-section>
<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button
    (click)="toggleColumnVisibility(column, $event)"
    *ngFor="let column of columns"
    class="checkbox-item mat-menu-item"
  >
    <mat-checkbox
      (click)="$event.stopPropagation()"
      [(ngModel)]="column.visible"
      color="primary"
    >
      {{ "BulkUpload.Table." + column.label | translate }}
    </mat-checkbox>
  </button>
</mat-menu>

<ng-template #confirmBulkModal>
  <app-state-section
    [flexibleHeight]="true"
    [state]="sectionStateModal"
    [loadingLabel]="'' | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <form [formGroup]="confirmtionForm">
      <div class="flex items-center" mat-dialog-title>
        <h2
          *ngIf="
            confirmtionForm.get('firstName').value ||
            confirmtionForm.get('lastName').value
          "
          class="headline m-0 flex-auto"
        >
          {{
            confirmtionForm.get("firstName").value +
              " " +
              confirmtionForm.get("lastName").value
          }}
        </h2>
        <h2
          *ngIf="
            !confirmtionForm.get('firstName').value &&
            !confirmtionForm.get('lastName').value
          "
          class="headline m-0 flex-auto"
        >
          {{ "User.AddNewUser" | translate }}
        </h2>
        <button
          class="text-secondary"
          mat-dialog-close
          mat-icon-button
          type="button"
        >
          <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
      </div>

      <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

      <mat-dialog-content class="flex flex-wrap justify-between">
        <div class="field flex column">
          <mat-label class="field-label">{{
            "User.Form.FirstName" | translate
          }}</mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              [placeholder]="'Placeholder.AddText' | translate"
              matInput
              type="text"
              formControlName="firstName"
            />
            <mat-error
              *ngIf="
                confirmtionForm.invalid &&
                confirmtionForm.get('firstName').hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
            <mat-error
              *ngIf="
                confirmtionForm.invalid &&
                confirmtionForm.get('firstName').hasError('whitespace')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.InValidData" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field flex column">
          <mat-label class="field-label">{{
            "User.Form.LastName" | translate
          }}</mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              [placeholder]="'Placeholder.AddText' | translate"
              matInput
              type="text"
              formControlName="lastName"
            />
            <mat-error
              *ngIf="
                confirmtionForm.invalid &&
                confirmtionForm.get('lastName').hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
            <mat-error
              *ngIf="
                confirmtionForm.invalid &&
                confirmtionForm.get('lastName').hasError('whitespace')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.InValidData" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field flex column">
          <mat-label class="field-label">{{
            "User.Form.Phone" | translate
          }}</mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              [placeholder]="'Placeholder.AddText' | translate"
              matInput
              type="text"
              formControlName="phoneNumber"
            />
            <mat-error
              *ngIf="
                confirmtionForm.invalid &&
                confirmtionForm.get('phoneNumber').hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
            <mat-error
              *ngIf="
                confirmtionForm.invalid &&
                confirmtionForm.get('phoneNumber').hasError('whitespace')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.InValidData" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field flex column">
          <mat-label class="field-label">{{
            "General.Inputs.Email" | translate
          }}</mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              matInput
              type="email"
              [placeholder]="'Placeholder.EnterEmail' | translate"
              formControlName="email"
              autocomplete="email"
            />
            <mat-error
              *ngIf="
                confirmtionForm.invalid &&
                confirmtionForm.get('email').hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
            <mat-error
              *ngIf="
                confirmtionForm.invalid &&
                confirmtionForm.get('email').hasError('invalidEmail')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.InvalidEmail" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <!-- Roles -->
        <div class="field flex column">
          <mat-label class="field-label">{{
            "User.Form.Role" | translate
          }}</mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <mat-select
              ngxMatSelect
              #msf1="ngxMatSelect"
              [displayMember]="'roleName'"
              [valueMember]="'roleId'"
              [source]="roles"
              [useInfiniteScroll]="true"
              [hasPermission]="hasCreateRolesPermission | permissionChecker"
              [title]="'roles'"
              [placeholder]="'Placeholder.Select' | translate"
              formControlName="roles"
              [multiple]="true"
            >
              <mat-option
                *ngFor="let role of msf1.filteredSource"
                [value]="role.roleId"
                >{{ role.roleName }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                confirmtionForm.invalid &&
                confirmtionForm.get('roles').hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <!-- Department -->
        <div class="field flex column">
          <mat-label class="field-label">{{
            "User.Form.Department" | translate
          }}</mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <mat-select
              ngxMatSelect
              #msf2="ngxMatSelect"
              [displayMember]="'departmentName'"
              [valueMember]="'departmentId'"
              [source]="departments"
              [useInfiniteScroll]="true"
              [hasPermission]="
                hasCreateDepartmentsPermission | permissionChecker
              "
              [title]="'departments'"
              [placeholder]="'Placeholder.SelectDepartment' | translate"
              [manageRoute]="'/company-setup/departments'"
              multiple
              formControlName="departments"
            >
              <!-- <mat-option
                *ngIf="departments.length !== 0"
                (click)="selectedvalue($event)"
                [value]="'ALL'"
              >
                All
              </mat-option> -->
              <mat-option
                (click)="selectAll($event)"
                *ngFor="let dep of msf2.filteredSource"
                [value]="dep.departmentId"
              >
                {{ dep.departmentName }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                confirmtionForm.invalid &&
                confirmtionForm.get('departments').hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <!-- Report To Users -->
        <div class="field flex column">
          <mat-label class="field-label">{{
            "User.Form.ReportTo" | translate
          }}</mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <mat-select
              ngxMatSelect
              #msf3="ngxMatSelect"
              [displayMember]="'fullName'"
              [valueMember]="'userId'"
              [source]="reportUsers"
              [useInfiniteScroll]="true"
              [hasPermission]="hasCreateUserPermission | permissionChecker"
              [title]="'users'"
              [manageRoute]="'/user-management/users'"
              [placeholder]="'Placeholder.Select' | translate"
              formControlName="reportTo"
            >
              <mat-option
                *ngFor="let user of msf3.filteredSource"
                [value]="user.userId"
                >{{ user.fullName }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Id Number -->
        <div class="field flex column">
          <mat-label class="field-label">{{
            "ManageUsers.Table.IdNumber" | translate
          }}</mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              [placeholder]="'Placeholder.AddText' | translate"
              matInput
              type="text"
              formControlName="idNumber"
            />
          </mat-form-field>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions align="end">
        <div class="flex items-center">
          <app-basic-button
            [btnType]="'Cancel'"
            [btnText]="'Cancel'"
            (onClick)="closeDialog()"
          ></app-basic-button>
          <app-basic-button
            [btnType]="'Add'"
            [btnText]="'Save'"
            (onClick)="saveConfirmedUser()"
            [isDisabled]="!confirmtionForm.valid"
          ></app-basic-button>
        </div>
      </mat-dialog-actions>
    </form>
  </app-state-section>
</ng-template>
