import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { EmailValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BaseApi } from 'src/backend/api/base-api';
import { ManageAccessControlService } from 'src/backend/services/manage-access-control/manage-access-control.service';

@Component({
  selector: 'app-invite-visitor',
  templateUrl: './invite-visitor.component.html',
  styleUrls: ['./invite-visitor.component.scss'],
})
export class InviteVisitorComponent extends BaseComponent implements OnInit {
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;

  inviteVisitorForm: FormGroup;
  cards: any = [];
  selectedCard: string;
  translationsList: any = {};
  minDate: any;
  minEndDate: any;
  today: Date 

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<InviteVisitorComponent>,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private feedBackService: AsyncFeedbackService,
    private baseApi: BaseApi,
    private translate: TranslateService,
    private manageAccessControlService: ManageAccessControlService
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit(): void {
    this.setMinDate();
    this.inviteVisitorForm = this.fb.group({
      email: ['', [Validators.required, EmailValidator]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[0-9]*$/),
          Validators.min(0),
        ],
      ],
      isExternal: [false],
      isGuest: [true],
      reasonForVisit: ['', Validators.required],
      startDate: ['', Validators.required],
      expiryDate: ['', Validators.required],
      startTime: ['', Validators.required],
      expiryTime: ['', Validators.required],
      isCardIssued: [false, Validators.required],
      partNumber: [''],
    });

    this.inviteVisitorForm
      .get('isCardIssued')
      .valueChanges.subscribe((value) => {
        if (value) {
          this.inviteVisitorForm
            .get('partNumber')
            .setValidators(Validators.required);
        } else {
          this.inviteVisitorForm.get('partNumber').clearValidators();
          this.inviteVisitorForm.get('partNumber').setValue('');
          this.selectedCard = '';
        }
        this.inviteVisitorForm.get('partNumber').updateValueAndValidity();
      });

    this.getCards();
  }
  setMinDate() {
    const now = new Date();
    this.today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0); 
  }
  getCards() {
    this.sectionState = SectionStateStatus.Loading;
    this.manageAccessControlService.getCards().subscribe({
      next: (res) => {
        this.cards = res;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  onCardSelect(partNumber: string) {
    this.selectedCard = partNumber;
    this.inviteVisitorForm.get('partNumber').setValue(partNumber);
  }

  addUser() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          const org = this.baseApi.getOrganizationFromStorage();
          const data = {
            ...this.inviteVisitorForm.value,
            organizationCode: org?.orgCode,
          };
          this.manageAccessControlService.inviteVisitor(data).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.dialogRef.close({ created: true });
              this.sectionState = SectionStateStatus.Ready;
            },
            error: (error) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  validateDateRange(): void {
    const { startDate } = this.inviteVisitorForm.value;
    this.minEndDate = new Date(startDate);
    this.inviteVisitorForm.get('expiryDate')?.setValue(this.minEndDate);
  }
  closeDialog() {
    this.dialog.closeAll();
  }
}
