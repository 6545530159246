<hodhod-page-layout>
    <hodhod-secondary-toolbar [current]="'NavigationBar.Organizations' | translate">
      <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
    </hodhod-secondary-toolbar>
    <hodhod-page-layout-content class="container-fluid">
        <app-state-section
        body
        [flexibleHeight]="true"
        [state]="sectionState"
        [loadingLabel]="'organization.LoadingInfo' | translate"
        [errorLabel]="'Errors.ErrorHappened' | translate"
      >
      <app-security-features></app-security-features>

      </app-state-section>
    </hodhod-page-layout-content>
  </hodhod-page-layout>