import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import { Organization } from 'src/app/organization-setup/models/organization';
import { OrganizationDetail } from 'src/app/organization-setup/models/organization-detail';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { BaseApi } from '../api/base-api';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  getOrganizations(data: any): Observable<DataMapper<Organization>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.orgStatus) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.orgStatus;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<DataMapper<Organization>>(
      this.baseApi.encodeURL(Constants.API_ORGANIZATION_GET_ALL + '?' + params)
    );
  }
  createOrganization(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_ORGANIZATION_CREATE),
      data
    );
  }
  editOrganization(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_ORGANIZATION_EDIT),
      data
    );
  }

  getOrganizationById(orgId: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_ORGANIZATION_BY_ID + '/' + orgId)
    );
  }
  getOrganizationDetails(code: any): Observable<OrganizationDetail> {
    return this.http.get<OrganizationDetail>(
      this.baseApi.encodeURL(Constants.API_ORGANIZATION_DETAILS + '/' + code)
    );
  }
  getOrganizationCardDetails(code: any): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_ORGANIZATION_LICENSE_CARD + '/' + code
      )
    );
  }
  activateOrganization(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_ORGANIZATION_ACTIVATE + '/' + id)
    );
  }
  deactivateOrganization(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_ORGANIZATION_DEACTIVATE + '/' + id)
    );
  }
  licenseInformation(): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_ORGANIZATION_LICENSE_INFORMATION)
    );
  }
  updateOrgQuota(data: any): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_UPDATE_ORG_QUOTA),
      data
    );
  }
  updateComQuota(data: any): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_UPDATE_COM_QUOTA),
      data
    );
  }
  getAvailablePartNumbersList(data: any): Observable<any> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.orgStatus) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.orgStatus;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_GET_AVAILABLE_PART_NUMBERS_LIST + '?' + params
      )
    );
  }
  getAssignedPartNumbersList(data: any): Observable<any> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.orgStatus) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.orgStatus;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_GET_Assigned_PART_NUMBERS_LIST + '?' + params
      )
    );
  }
  getSyncData(): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_SYNCHRONIZED_DATA)
    );
  }

  getAdminKeys(): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_ADMIN_KEYS)
    );
  }
}
