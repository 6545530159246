<app-state-section
  body
  [flexibleHeight]="true"
  [state]="sectionState"
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <div [formGroup]="inviteVisitorForm">
    <div class="flex items-center" mat-dialog-title>
      <h2 class="headline m-0 flex-auto">
        {{ "ManageAccessControl.Visitor.InviteVisitor" | translate }}
      </h2>
      <button
        class="text-secondary"
        (click)="closeDialog()"
        mat-icon-button
        type="button"
      >
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

    <mat-dialog-content class="justify-between">
      <div class="flex justify-between">
        <div class="field flex column">
          <mat-label class="field-label"
            >{{ "ManageAccessControl.Visitor.FirstName" | translate }}
            <span
              [ngClass]="
                inviteVisitorForm.get('firstName').hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
            >
              *
            </span>
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              [placeholder]="'Placeholder.AddText' | translate"
              matInput
              type="text"
              formControlName="firstName"
              (keydown.space)="$event.preventDefault()"
            />
            <mat-error
              *ngIf="inviteVisitorForm.get('firstName').hasError('required')"
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field flex column">
          <mat-label class="field-label"
            >{{ "ManageAccessControl.Visitor.LastName" | translate }}
            <span
              [ngClass]="
                inviteVisitorForm.get('lastName').hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
            >
              *
            </span>
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              [placeholder]="'Placeholder.AddText' | translate"
              matInput
              type="text"
              formControlName="lastName"
              (keydown.space)="$event.preventDefault()"
            />
            <mat-error
              *ngIf="inviteVisitorForm.get('lastName').hasError('required')"
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="field-full flex column">
        <mat-label class="field-label"
          >{{ "ManageAccessControl.Visitor.Email" | translate }}
          <span
            [ngClass]="
              inviteVisitorForm.get('email').invalid ? 'is-invalid' : 'is-valid'
            "
          >
            *
          </span>
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            [placeholder]="'Placeholder.AddText' | translate"
            matInput
            type="text"
            formControlName="email"
          />
          <mat-error
            *ngIf="inviteVisitorForm.get('email').hasError('required')"
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="inviteVisitorForm.get('email').hasError('invalidEmail')"
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.InvalidEmail" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="field-full flex column">
        <mat-label class="field-label"
          >{{ "ManageAccessControl.Visitor.Phone" | translate }}
          <span
            [ngClass]="
              inviteVisitorForm.get('phoneNumber').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
          >
            *
          </span>
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            [placeholder]="'Placeholder.AddText' | translate"
            matInput
            type="number"
            min="0"
            formControlName="phoneNumber"
          />

          <mat-error
            *ngIf="
              inviteVisitorForm.controls['phoneNumber']?.errors &&
              !inviteVisitorForm.get('phoneNumber').hasError('required') &&
              inviteVisitorForm.controls['phoneNumber']?.touched
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.InvalidPhone" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="inviteVisitorForm.get('phoneNumber').hasError('required')"
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="field-full flex column">
        <mat-label class="field-label"
          >{{ "ManageAccessControl.Visitor.ReasonForVisit" | translate }}
          <span
            [ngClass]="
              inviteVisitorForm.get('reasonForVisit').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
          >
            *
          </span>
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <textarea
            [placeholder]="'Placeholder.AddText' | translate"
            matInput
            formControlName="reasonForVisit"
          ></textarea>
          <mat-error
            *ngIf="inviteVisitorForm.get('reasonForVisit').hasError('required')"
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="flex justify-between">
        <div class="field flex column">
          <mat-label class="field-label"
            >{{ "ManageAccessControl.Visitor.StartDate" | translate }}
            <span
              [ngClass]="
                inviteVisitorForm.get('startDate').hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
            >
              *
            </span>
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              [placeholder]="'InspectionPlan.StartDate' | translate"
              matInput
              formControlName="startDate"
              [matDatepicker]="startpicker"
              [min]="today"
              (click)="startpicker.open()"
              (dateChange)="validateDateRange()"
            />
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle
              matSuffix
              [for]="startpicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #startpicker></mat-datepicker>
            <mat-error
              *ngIf="inviteVisitorForm.get('startDate').hasError('required')"
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field flex column">
          <mat-label class="field-label"
            >{{ "ManageAccessControl.Visitor.Time" | translate }}
            <span
              [ngClass]="
                inviteVisitorForm.get('startTime').hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
            >
              *
            </span>
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              [format]="24"
              [ngxMatTimepicker]="starttimepicker"
              matInput
              [placeholder]="'InspectionPlan.StartTime' | translate"
              (click)="starttimepicker.open()"
              formControlName="startTime"
            />
            <mat-icon
              matSuffix
              svgIcon="mat:watch_later"
              (click)="starttimepicker.open()"
            >
            </mat-icon>
            <ngx-mat-timepicker #starttimepicker></ngx-mat-timepicker>
            <mat-error
              *ngIf="inviteVisitorForm.get('startTime').hasError('required')"
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="flex justify-between">
        <div class="field flex column">
          <mat-label class="field-label"
            >{{ "ManageAccessControl.Visitor.ExpiryDate" | translate }}
            <span
              [ngClass]="
                inviteVisitorForm.get('expiryDate').hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
            >
              *
            </span>
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              [placeholder]="'InspectionPlan.EndDate' | translate"
              matInput
              formControlName="expiryDate"
              [matDatepicker]="expirypicker"
              [min]="minEndDate"
              (click)="expirypicker.open()"
            />
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle
              matSuffix
              [for]="expirypicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #expirypicker></mat-datepicker>
            <mat-error
              *ngIf="inviteVisitorForm.get('expiryDate').hasError('required')"
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field flex column">
          <mat-label class="field-label"
            >{{ "ManageAccessControl.Visitor.Time" | translate }}
            <span
              [ngClass]="
                inviteVisitorForm.get('expiryTime').hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
            >
              *
            </span>
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              [format]="24"
              [ngxMatTimepicker]="expirytimepicker"
              matInput
              [placeholder]="'InspectionPlan.EndTime' | translate"
              (click)="expirytimepicker.open()"
              formControlName="expiryTime"
            />
            <mat-icon
              matSuffix
              svgIcon="mat:watch_later"
              (click)="expirytimepicker.open()"
            >
            </mat-icon>
            <ngx-mat-timepicker #expirytimepicker></ngx-mat-timepicker>
            <mat-error
              *ngIf="inviteVisitorForm.get('expiryTime').hasError('required')"
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="flex items-center pt-2" *ngIf="cards.length > 0">
        <div class="custom-slide-toggle">
          <mat-slide-toggle formControlName="isCardIssued"></mat-slide-toggle>
        </div>
        <div class="mx-2">
          <div class="flex items-center">
            <span class="toggle-label">
              {{ "Chatbot.IssueCardType" | translate }}
            </span>
            <mat-icon
              class="mx-1"
              svgIcon="mat:info"
              [matTooltip]="'Chatbot.OptionalAssign' | translate"
            ></mat-icon>
          </div>
          <div>{{ "Chatbot.OptionalAssign" | translate }}</div>
        </div>
      </div>

      <div *ngIf="inviteVisitorForm.get('isCardIssued').value">
        <div class="flex justify-between gap-6">
          <div
            *ngFor="let card of cards"
            (click)="onCardSelect(card.partNumber)"
            [class.selected]="card.partNumber === selectedCard"
            class="p-0 h-[140px] cursor-pointer transition hover:shadow-lg border-2 border-[#F3F3F3] rounded-[15px] flex flex-col mt-1 mb-1"
          >
            <div class="p-2">
              <div class="mb-2 flex justify-between">
                <img
                  class="w-[30%] object-contain"
                  src="assets/img/resources/saned-logo.png"
                  alt="saned"
                />
                <mat-checkbox
                  class="step-checkbox"
                  disabled
                  [checked]="card.partNumber === selectedCard"
                ></mat-checkbox>
              </div>

              <div class="flex items-center justify-between mt-4">
                <div class="flex items-center">
                  <strong>{{ card?.friendlyName }}</strong>
                </div>
              </div>
            </div>

            <div class="flex justify-between hid-cover h-full">
              <div class="flex items-end flex-grow p-2">
                <img
                  class="w-[20%]"
                  src="assets/img/resources/hid-logo.svg"
                  alt="saned"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <div class="flex items-center">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel'"
          (onClick)="closeDialog()"
        ></app-basic-button>
        <app-basic-button
          [btnType]="'Add'"
          [btnText]="'Add'"
          (onClick)="addUser()"
          [isDisabled]="!inviteVisitorForm.valid"
        ></app-basic-button>
      </div>
    </mat-dialog-actions>
  </div>
</app-state-section>
