<app-card [title]="'ManageAccessControl.InvitationsLogs.Title' | translate">
  <div body>
    <table class="w-full" [dataSource]="dataSource" mat-table matSort>
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container
          *ngIf="column.type === 'text' && column.property !== 'typeOfReport'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{
              "ManageAccessControl.InvitationsLogs." + column.label | translate
            }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            #div
            class="truncate max-w-0 p-3"
            mat-cell
          >
            <span
              [matTooltip]="
                div?.offsetWidth < div?.scrollWidth
                  ? row[column.property]
                  : null
              "
            >
              {{ row[column.property] }}
            </span>
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'date'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{
              "ManageAccessControl.InvitationsLogs." + column.label | translate
            }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] | date : "mediumDate" }}
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'boolean' && column.property === 'status'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{
              "ManageAccessControl.InvitationsLogs." + column.label | translate
            }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div
              class="flex justify-center align-center"
              [attr.id]="row[column.property]"
            >
              <span>
                {{
                  "ManageAccessControl.Status." + row[column.property]
                    | translate
                }}
              </span>
            </div>
          </td>
        </ng-container>
      </ng-container>
      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: visibleColumns" mat-row></tr>
    </table>
    <div
      *ngIf="dataSource?.filteredData?.length === 0"
      @scaleFadeIn
      class="flex-auto flex flex-col items-center justify-center"
    >
      <img src="assets/img/resources/empty-state.svg" alt="not found" />
      <h2 class="headline m-0 text-center text-primary">
        {{ "General.EmptyTableList" | translate }}...
      </h2>
    </div>
  </div>
</app-card>
