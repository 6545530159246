import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ChatbotService } from 'src/backend/services/chatbot/chatbot.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { LoadingService } from 'src/app/shared/helpers/loading.service';

@Component({
  selector: 'app-chatbot-upload-documents',
  templateUrl: './chatbot-upload-documents.component.html',
  styleUrls: ['./chatbot-upload-documents.component.scss'],
})
export class ChatbotUploadDocumentsComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild('uploadDocumentModal') uploadDocumentModal!: TemplateRef<any>;

  documents: string[] = [];

  media: FormControl = new FormControl();
  private translationsList: any = {};
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;

  constructor(
    private chatbotService: ChatbotService,
    private dialog: MatDialog,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private feedbackService: AsyncFeedbackService,
    private loadingService: LoadingService
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit(): void {
    this.getDocuments();
  }

  getDocuments() {
    // this.sectionState = SectionStateStatus.Loading;
    // this.chatbotService.getDocuments().subscribe({
    //   next: (res) => {
    //     this.documents = res.Documents;
    //     this.sectionState = SectionStateStatus.Ready;
    //   },
    //   error: (error) => {
    //     this.feedbackService.showFeedback(
    //       new FeedbackModel(FeedbackType.Failure, error?.message)
    //     );
    //     this.sectionState = SectionStateStatus.Ready;
    //   },
    // });
  }

  openUploadDocumentModal() {
    this.dialog
      .open(this.uploadDocumentModal, {
        width: '30%',
        height: 'auto',
      })
      .afterClosed()
      .subscribe(() => {
        this.media.setValue(null);
      });
  }

  uploadDocument() {
    const formData = new FormData();
    this.media.value.forEach((element) => {
      formData.append('documents', element);
    });
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value) {
          this.loadingService.startLoading(true);
          this.chatbotService.uploadDocuments(formData).subscribe({
            next: (res) => {
              this.feedbackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.detail)
              );
              this.getDocuments();
              this.loadingService.stopLoading();
              this.closeDialog();
            },
            error: (error) => {
              this.feedbackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
              this.closeDialog();
            },
          });
        }
      });
  }

  downloadDocument(documentName: string) {
    const data = {
      document_name: documentName,
    };
    this.loadingService.startLoading(true);
    this.chatbotService.downloadDocument(data).subscribe({
      next: (res) => {
        const blobData = new Blob([res], {
          type: 'application/octet-stream',
        });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blobData);
        link.download = documentName;
        link.click();
        window.URL.revokeObjectURL(link.href);
        this.loadingService.stopLoading();
      },
      error: (error) => {
        this.loadingService.stopLoading();
      },
    });
  }

  deleteDocument(documentName: string) {
    const data = {
      document_name: documentName,
    };
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value) {
          this.loadingService.startLoading(true);
          this.chatbotService.deleteDocument(data).subscribe({
            next: (res) => {
              this.feedbackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.detail)
              );
              this.getDocuments();
              this.loadingService.stopLoading();
            },
            error: (error) => {
              this.feedbackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.getDocuments();
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }

  deleteAllDocuments() {}

  getAttachmentImage(url: string) {
    const extension = url.split('.').pop()?.toLocaleLowerCase();
    if (extension.includes('xlsx') || extension.includes('xls')) {
      return '../../../../assets/img/resources/xls.png';
    } else if (extension.includes('docx') || extension.includes('doc')) {
      return '../../../../assets/img/resources/doc.png';
    } else if (extension.includes('pdf')) {
      return '../../../../assets/img/resources/pdf.png';
    } else {
      return url;
    }
  }

  getAttachmentTypeName(url: string): any {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
    const extension = url.split('.').pop();
    if (imageExtensions.includes(extension)) {
      return 'Image';
    }
    return 'Document';
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
