<div class="flex justify-end mr-1 mb-4">
  <app-basic-button
    [btnType]="'Add'"
    [btnText]="'View'"
    [isDisabled]="false"
    (onClick)="viewCamera(data?.id)"
  ></app-basic-button>
</div>
<app-state-section
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="'' | translate"
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <app-card [title]="'VideoAnalytics.View.Edit' | translate">
    <div body *ngIf="data">
      <form [formGroup]="cameraForm">
        <div class="flex flex-row flex-wrap justify-between">
          <div class="field flex column">
            <mat-label class="field-label">{{
              "VideoAnalytics.View.CameraID" | translate
            }}</mat-label>
            <mat-form-field class="input-field" appearance="outline">
              <input
                formControlName="cameraId"
                [placeholder]="'Placeholder.AddText' | translate"
                matInput
                type="text"
                readonly
              />
              <mat-error
                *ngIf="
                  cameraForm.invalid &&
                  cameraForm.get('cameraId').hasError('required')
                "
              >
                <div class="flex justify-flex-start align-flex-center">
                  <img
                    class="error-img"
                    src="assets/img/resources/error-icon.svg"
                  />
                  <span>{{ "Errors.Required" | translate }}</span>
                </div>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="field flex ml-2 column">
            <mat-label class="field-label">{{
              "VideoAnalytics.View.Department" | translate
            }}</mat-label>
            <mat-form-field class="input-field" appearance="outline">
              <mat-select
                ngxMatSelect
                #msf1="ngxMatSelect"
                [displayMember]="'departmentName'"
                [valueMember]="'departmentId'"
                [source]="departments"
                [useInfiniteScroll]="true"
                [multiple]="false"
                [hasPermission]="hasDepartmentPermission | permissionChecker"
                [hasSearchBox]="false"
                [title]="'departments'"
                [manageRoute]="'/company-setup/departments'"
                [placeholder]="'Placeholder.Select' | translate"
                formControlName="department"
                [disabled]="false"
              >
                <mat-option
                  *ngIf="msf1.filteredSource.length === 0"
                  style="height: 0"
                ></mat-option>
                <mat-optgroup
                  *ngFor="let group of departments"
                  [label]="group.parentName"
                >
                  <mat-option
                    *ngFor="let detail of group.detail"
                    [value]="detail.departmentCode"
                  >
                    {{ detail.departmentName }}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
              <mat-error
                *ngIf="
                  cameraForm.invalid &&
                  cameraForm.get('department').hasError('required')
                "
              >
                <div class="flex justify-flex-start align-flex-center">
                  <img
                    class="error-img"
                    src="assets/img/resources/error-icon.svg"
                  />
                  <span>{{ "Errors.Required" | translate }}</span>
                </div>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="field flex column">
            <mat-label class="field-label">{{
              "VideoAnalytics.View.CameraName" | translate
            }}</mat-label>
            <mat-form-field class="input-field" appearance="outline">
              <input
                formControlName="cameraName"
                [placeholder]="'Placeholder.AddText' | translate"
                matInput
                type="text"
              />
              <mat-error
                *ngIf="
                  cameraForm.invalid &&
                  cameraForm.get('cameraName').hasError('required')
                "
              >
                <div class="flex justify-flex-start align-flex-center">
                  <img
                    class="error-img"
                    src="assets/img/resources/error-icon.svg"
                  />
                  <span>{{ "Errors.Required" | translate }}</span>
                </div>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="field flex column">
            <mat-label class="field-label">{{
              "VideoAnalytics.View.AdditionalApprove" | translate
            }}</mat-label>
            <mat-form-field appearance="outline">
              <mat-select
                ngxMatSelect
                #msf2="ngxMatSelect"
                [displayMember]="'fullName'"
                [valueMember]="'userId'"
                [source]="users"
                [useInfiniteScroll]="true"
                formControlName="name"
                [multiple]="false"
                [hasPermission]="hasCreateUserPermissions | permissionChecker"
                [title]="'users'"
                [manageRoute]="'/user-management/users'"
                [placeholder]="'Placeholder.Select' | translate"
                formControlName="additionalApprv"
                multiple
              >
                <mat-option
                  *ngFor="let user of msf2.filteredSource"
                  [value]="user.userId"
                  >{{ user.fullName }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  cameraForm.invalid &&
                  cameraForm.get('additionalApprv').hasError('required')
                "
              >
                <div class="flex justify-flex-start align-flex-center">
                  <img
                    class="error-img"
                    src="assets/img/resources/error-icon.svg"
                  />
                  <span>{{ "Errors.Required" | translate }}</span>
                </div>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="field flex column">
            <mat-label class="field-label">{{
              "VideoAnalytics.View.CameraPosition" | translate
            }}</mat-label>
            <mat-form-field class="input-field" appearance="outline">
              <input
                formControlName="cameraPosition"
                [placeholder]="'Placeholder.AddText' | translate"
                matInput
                type="text"
              />
              <mat-error
                *ngIf="
                  cameraForm.invalid &&
                  cameraForm.get('cameraPosition').hasError('required')
                "
              >
                <div class="flex justify-flex-start align-flex-center">
                  <img
                    class="error-img"
                    src="assets/img/resources/error-icon.svg"
                  />
                  <span>{{ "Errors.Required" | translate }}</span>
                </div>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="flex items-center justify-end my-3">
          <app-basic-button
            [btnType]="'Add'"
            [btnText]="'Submit'"
            [isDisabled]="!cameraForm.valid"
            (onClick)="editCamera()"
          ></app-basic-button>
        </div>
      </form>
    </div>
  </app-card>
</app-state-section>
