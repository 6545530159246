<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.newPenaltyMangment' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <app-expandable-panel
      [expanded]="true"
      #expandablePanel
      [title]="'NavigationBar.PenaltyManagement' | translate"
    >
      <div body>
        <app-state-section
          body
          [flexibleHeight]="true"
          [state]="sectionState"
          [errorLabel]="'Errors.ErrorHappened' | translate"
        >
          <form [formGroup]="penaltyMangmentForm">
            <div class="flex">
              <div class="flex column w-6/12 p-3">
                <div class="flex column">
                  <mat-label class="field-label"
                    >{{ "PenaltyManagement.Table.WorkOrderNo" | translate }}
                    <span
                      [ngClass]="
                        penaltyMangmentForm.invalid &&
                        penaltyMangmentForm
                          .get('workerOrderNO')
                          .hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <input
                      [placeholder]="
                        'Enter a work order num in less than 30 characters'
                      "
                      maxlength="30"
                      matInput
                      type="text"
                      formControlName="workerOrderNO"
                    />
                    <mat-error>
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="flex column">
                  <mat-label class="field-label"
                    >{{ "WorkPermit.Form.Title" | translate }}
                    <span
                      [ngClass]="
                        penaltyMangmentForm.invalid &&
                        penaltyMangmentForm.get('title').hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <input
                      [placeholder]="
                        'Enter a penalty title in less than 50 characters'
                      "
                      maxlength="50"
                      matInput
                      type="text"
                      formControlName="title"
                    />
                    <mat-error>
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="flex column">
                  <mat-label class="field-label"
                    >{{ "PenaltyManagement.Table.Description" | translate }}
                    <span
                      [ngClass]="
                        penaltyMangmentForm.invalid &&
                        penaltyMangmentForm
                          .get('description')
                          .hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    ></span>
                  </mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <textarea
                      matInput
                      rows="5"
                      maxlength="500"
                      [placeholder]="'Placeholder.Comment' | translate"
                      formControlName="description"
                    >
                    </textarea>
                  </mat-form-field>
                </div>
                <div class="flex column">
                  <mat-label class="field-label"
                    >{{ "PenaltyManagement.Table.DateOfReceiving" | translate }}
                    <span
                      [ngClass]="
                        penaltyMangmentForm.invalid &&
                        penaltyMangmentForm
                          .get('DayOfMonth')
                          .hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>
                  <mat-form-field appearance="outline">
                    <input
                      [placeholder]="'Placeholder.SelectDate' | translate"
                      matInput
                      [max]="today"
                      [matDatepicker]="startDate"
                      (click)="startDate.open()"
                      formControlName="DayOfMonth"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="startDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #startDate></mat-datepicker>
                    <mat-error
                      *ngIf="
                        penaltyMangmentForm.invalid &&
                        penaltyMangmentForm
                          .get('DayOfMonth')
                          .hasError('required')
                      "
                    >
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="flex column">
                  <mat-label class="field-label"
                    >{{ "PenaltyManagement.Table.TimeReceived" | translate }}
                    <span
                      [ngClass]="
                        penaltyMangmentForm.invalid &&
                        penaltyMangmentForm
                          .get('TimeReceived')
                          .hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <input
                      [format]="24"
                      matInput
                      [ngxMatTimepicker]="starttimepicker"
                      [placeholder]="'Placeholder.Select' | translate"
                      formControlName="TimeReceived"
                      readonly
                    />
                    <mat-error
                      *ngIf="
                        penaltyMangmentForm.invalid &&
                        penaltyMangmentForm
                          .get('TimeReceived')
                          .hasError('required')
                      "
                    >
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>

                    <mat-icon
                      matSuffix
                      svgIcon="mat:watch_later"
                      (click)="starttimepicker.open()"
                    >
                    </mat-icon>
                    <ngx-mat-timepicker #starttimepicker></ngx-mat-timepicker>
                  </mat-form-field>
                </div>
                <div class="flex column">
                  <mat-label class="field-label"
                    >{{ "PenaltyManagement.Table.Area" | translate }}
                    <span
                      [ngClass]="
                        penaltyMangmentForm.invalid &&
                        penaltyMangmentForm.get('area').hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    ></span>
                  </mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <mat-select
                      ngxMatSelect
                      #msf7="ngxMatSelect"
                      [useInfiniteScroll]="true"
                      [source]="listOfAreas"
                      [title]="'area'"
                      formControlName="area"
                      [multiple]="false"
                      [placeholder]="
                      'Enter Area'
                    "                    >
                      <mat-option
                        *ngFor="let area of msf7.filteredSource"
                        [value]="area?.id"
                      >
                        {{ area?.name }}
                      </mat-option>
                    </mat-select>

                  </mat-form-field>
                </div>
              </div>
              <div class="flex column w-6/12 p-3">
                <!-- //approver -->
                <div class="flex column">
                  <mat-label class="field-label"
                    >{{ "PenaltyManagement.Table.Approvers" | translate }}
                    <span
                      [ngClass]="
                        penaltyMangmentForm.invalid &&
                        penaltyMangmentForm
                          .get('approvers')
                          .hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <mat-select
                      ngxMatSelect
                      #msf5="ngxMatSelect"
                      [useInfiniteScroll]="true"
                      [source]="listOfApprovers"
                      [title]="'approvers'"
                      [formControlName]="'approvers'"
                      [multiple]="true"
                      (selectionChange)="selectApprovers($event)"
                      [placeholder]="
                        'PenaltyManagement.Table.Approvers' | translate
                      "
                    >
                      <mat-option
                        *ngFor="let user of msf5.filteredSource"
                        [value]="user?.userId"
                      >
                        {{ user?.fullName }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        penaltyMangmentForm.invalid &&
                        penaltyMangmentForm
                          .get('approvers')
                          .hasError('required')
                      "
                    >
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- resbonsable team -->
                <div class="flex column">
                  <mat-label class="field-label"
                    >{{ "PenaltyManagement.Table.ResponsibleTeam" | translate }}
                    <span
                      [ngClass]="
                        penaltyMangmentForm.invalid &&
                        penaltyMangmentForm
                          .get('ResponsibleTeam')
                          .hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    ></span>
                  </mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <mat-select
                      ngxMatSelect
                      #msf6="ngxMatSelect"
                      [useInfiniteScroll]="true"
                      [source]="listOfTeams"
                      [title]="'ResponsibleTeam'"
                      formControlName="ResponsibleTeam"
                      [multiple]="false"
                      [placeholder]="
                      'Enter Responsible Team'
                    "                    >
                      <mat-option
                        *ngFor="let team of msf6.filteredSource"
                        [value]="team?.id"
                      >
                        {{ team?.name }}
                      </mat-option>
                    </mat-select>

                  </mat-form-field>

                </div>
                <div class="flex column">
                  <mat-label class="field-label"
                    >{{ "PenaltyManagement.Table.Cost" | translate }}
                  </mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <input
                      [placeholder]="'Enter cost'"
                      matInput
                      type="number"
                      formControlName="cost"
                      (wheel)="onWheel($event)"
                      [min]="0"
                    />
                    <mat-error *ngIf="penaltyMangmentForm.invalid">
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.InValidData" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- attachments -->
                <div class="flex column">
                  <mat-label class="field-label">{{
                    "PenaltyManagement.Table.Attachments" | translate
                  }}</mat-label>
                  <file-upload
                    formControlName="attachments"
                    [multiple]="true"
                    [animation]="true"
                    (change)="validateFiles()"
                  ></file-upload>
                </div>
                <div class="flex flex-wrap gap-2 my-4">
                  <div
                    class="relative"
                    *ngFor="let img of foundPenalty?.penaltyAttachments"
                  >
                    <div
                      class="cursor-pointer bg-red-700 text-white rounded-full absolute right-0 top-0 z-10 flex items-center justify-center"
                      style="width: 20px; height: 20px"
                      (click)="deleteAttachment(img.id)"
                    >
                      <mat-icon svgIcon="mat:close" class="icon-sm"></mat-icon>
                    </div>
                    <ngx-avatars
                      class="cursor-pointer"
                      size="60"
                      [src]="getAttachmentImage(img.imageUrl)"
                      [name]="getAttachementTypeName(img.imageUrl)"
                      [textSizeRatio]="2"
                      [round]="false"
                      [initialsSize]="2"
                      [cornerRadius]="10"
                      [matTooltip]="img['fileName']"
                      [borderColor]="'#1F36B4'"
                    >
                    </ngx-avatars>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex items-center justify-end my-3">
              <app-basic-button
                [btnType]="'Cancel'"
                [btnText]="'Cancel'"
                (click)="goBack()"
              ></app-basic-button>
              <app-basic-button
                [btnType]="'Add'"
                [isDisabled]="penaltyMangmentForm.invalid"
                [btnText]="'Submit'"
                (click)="
                  editMode == 'true'
                    ? editPenaltyMangment()
                    : createPenaltyMangment()
                "
              ></app-basic-button>
            </div>
          </form>
        </app-state-section>
      </div>
    </app-expandable-panel>
  </hodhod-page-layout-content>
</hodhod-page-layout>
