import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import { Company } from 'src/app/company-setup/models/company';
import { CompanyDetail } from 'src/app/company-setup/models/company-detail';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { BaseApi } from '../api/base-api';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  getCompaniesByOrg(data: any, orgId: number): Observable<DataMapper<Company>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<DataMapper<Company>>(
      this.baseApi.encodeURL(
        Constants.API_COMPANY_GET_ALL + '/' + orgId + '?' + params
      )
    );
  }

  getCompaniesByOrgIdForDropdown(
    orgId: number
  ): Observable<DataMapper<Company>> {
    return this.http.get<DataMapper<Company>>(
      this.baseApi.encodeURL(
        Constants.API_COMPANY_GET_ALL_FOR_DROPDOWN + '/' + orgId
      )
    );
  }
  GetMobileAccessCompaniesByOrg(
    orgId: number
  ): Observable<DataMapper<Company>> {
    return this.http.get<DataMapper<Company>>(
      this.baseApi.encodeURL(Constants.API_GET_COMPANIES_LIST + '/' + orgId)
    );
  }

  createCompany(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_COMPANY_CREATE),
      data
    );
  }
  editCompany(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_COMPANY_EDIT),
      data
    );
  }

  getCompanyById(compId: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_COMPANY_BY_ID + '/' + compId)
    );
  }
  getCompanyDetails(code: number): Observable<CompanyDetail> {
    return this.http.get<CompanyDetail>(
      this.baseApi.encodeURL(Constants.API_COMPANY_DETAILS + '/' + code)
    );
  }
  getCompCardDetails(code: number): Observable<CompanyDetail> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_COMPANY_LICENSE_CARD + '/' + code)
    );
  }
  activateCompany(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_COMPANY_ACTIVATE + '/' + id)
    );
  }
  deactivateCompany(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_COMPANY_DEACTIVATE + '/' + id)
    );
  }
  deleteCompany(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_COMPANY_DELETE + '/' + id)
    );
  }

  getAdminKeysByOrgId(id: string): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_ADMIN_KEYS_BY_ORG_ID + '/' + id)
    );
  }
}
