import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BaseApi } from 'src/backend/api/base-api';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { ChatbotService } from 'src/backend/services/chatbot/chatbot.service';
@UntilDestroy()
@Component({
  selector: 'app-chatbot-documents-list',
  templateUrl: './chatbot-documents-list.component.html',
  styleUrls: ['./chatbot-documents-list.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class ChatbotDocumentsListComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('AddDocumentsPopup') AddDocumentsPopup: TemplateRef<any>;
  @ViewChild('editDocumentPopup') editDocumentPopup: TemplateRef<any>;

  documents: any[];
  columns: TableColumn<any>[] = [
    {
      label: 'Checkbox',
      property: 'Checkbox',
      type: 'checkbox',
      visible: true,
      cssClasses: ['font-bold'],
    },
    {
      label: 'Title',
      property: 'name',
      type: 'text',
      visible: true,
      cssClasses: ['font-bold'],
    },
    {
      label: 'CreatedAt',
      property: 'created_at',
      type: 'date',
      visible: true,
    },
    {
      label: 'UpdatedAt',
      property: 'updated_at',
      type: 'date',
      visible: true,
    },
    {
      label: 'Category',
      property: 'category',
      type: 'text',
      visible: true,
    },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  filterMenuItems: any = [];

  public pageSize = Constants.PAGE_SIZE;
  public pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  public dataSource = new MatTableDataSource<any>([]);
  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  timer = null;
  selectedIDs = [];

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  loadingLabel: string = '';

  documentsForm: FormGroup;
  updateDocumentsForm: FormGroup;

  addFlag: boolean;

  public loggedUser: LoggedUser;

  selectedFileNames: any = [];
  duplicatedFiles: any = [];

  translationsList: any = {};
  selectedUpdateDocId: string;

  constructor(
    private chatbotService: ChatbotService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private baseApi: BaseApi,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private confirmationService: ConfirmationService,
    private feedBackService: AsyncFeedbackService
  ) {
    super();
    this.translate
      .get([
        'Errors',
        'Success',
        'confirmDeactiveRecord',
        'Role',
        'User',
        'StaticPages',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.filterMenuItems = this.columns.slice(1);
    this.loggedUser = this.baseApi.getUserSession();
    this.documentsForm = this.fb.group({
      documents: this.fb.array([]),
      category: ['', Validators.required],
      overwrite: [false],
    });

    this.updateDocumentsForm = this.fb.group({
      name: ['', Validators.required],
      category: ['', Validators.required],
    });

    this.sort.sortChange.pipe(takeUntil(this.destroy$));

    this.onAddDocument();

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));

    this.getData();
  }

  get files(): FormArray {
    return this.documentsForm.get('documents') as FormArray;
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  getData() {
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.LoadingTransparent;
    const companyId = localStorage.getItem('company_id');
    this.chatbotService.getDocuments(companyId).subscribe({
      next: (response) => {
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.sort = this.sort;
        this.documents = response;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  createNewDocuments() {
    this.addFlag = true;
    this.documentsForm.reset();
    this.files.controls = [];
    this.duplicatedFiles = [];
    this.onAddDocument();
    this.dialog.open(this.AddDocumentsPopup, {
      height: 'auto',
      width: '30%',
      maxHeight: '90vh',
      disableClose: false,
    });
  }

  removeLevel(i: number) {
    if (i >= 0) {
      this.files.removeAt(i);
    }
    this.files.updateValueAndValidity();
  }

  onAddDocument() {
    const fileGroup = this.fb.group({
      file: [null, Validators.required],
      isValid: [null],
    });
    this.files.push(fileGroup);
    this.files.updateValueAndValidity();
  }

  onChooseFile(index) {
    const fileGroup = this.files.controls[index];
    const file = this.files.controls[index].value.file;
    const fileName = this.files.controls[index].value.file[0].name;

    if (file && !this.isFileDuplicated(fileName)) {
      const companyId = localStorage.getItem('company_id');
      const data = {
        companyId: companyId,
        documentName: file[0]?.name,
      };
      this.chatbotService.validateDocumentName(data).subscribe({
        next: (res) => {
          fileGroup.get('isValid').setValue(res.available);
          if (!res.available) {
            this.duplicatedFiles.push(file[0]?.name);
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
      this.files.updateValueAndValidity();
    } else {
      this.removeLevel(index);
      const fileGroup = this.fb.group({
        file: [null, Validators.required],
        isValid: [null],
      });
      this.files.insert(index, fileGroup);
      this.files.updateValueAndValidity();
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Warning,
          'A file with this name already taken!'
        )
      );
    }
  }

  isFileDuplicated(fileName: string): boolean {
    const [files, lastElementArray] = this.removeLastAndAssign(
      this.files.controls
    );
    return files.some(
      (control) => control.value && control.value.file[0].name === fileName
    );
  }

  removeLastAndAssign<T>(sourceArray: T[]): [T[], T[]] {
    const sourceArrayCopy = [...sourceArray];
    const lastElement = sourceArrayCopy.pop();
    if (lastElement === undefined) {
      return [sourceArray, []];
    }
    const newArray = [lastElement];

    return [sourceArrayCopy, newArray];
  }

  resetFileGroup(index) {
    this.files.controls[index].reset();
  }

  isFoundDuplicated() {
    return this.files.controls.filter((item) => item.value.isValid == false);
  }

  validateName(value) {
    const companyId = localStorage.getItem('company_id');

    const data = {
      companyId: companyId,
      documentName: value,
    };
    this.chatbotService.validateDocumentName(data).subscribe({
      next: (res) => {
        if (res?.available) {
          this.updateDocumentsForm.get('name')?.setErrors(null);
        } else {
          this.updateDocumentsForm.get('name')?.setErrors({
            nameNotAvailable: res?.message || 'Name is not available',
          });
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  addDocuments() {
    const formData = new FormData();
    const companyId = localStorage.getItem('company_id');

    this.documentsForm.value?.documents.forEach((element) => {
      formData.append('documents', element.file[0]);
    });

    formData.append('company_id', companyId);
    formData.append('user_id', this.loggedUser.userId);
    formData.append(
      'overwrite',
      this.documentsForm.get('overwrite').value != null
        ? this.documentsForm.get('overwrite').value
        : false
    );
    formData.append('category', this.documentsForm.get('category').value);
    this.loadingService.startLoading(true);
    this.chatbotService.uploadDocuments(formData).subscribe({
      next: (res) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Success,
            'Documents Added Successfully!'
          )
        );
        this.navigateBack();
        this.getData();
        this.loadingService.stopLoading();
      },
      error: (error) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  downloadDocument(row) {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.chatbotService.downloadDocument(row.id).subscribe({
      next: (res) => {
        const fileUrl = window.URL.createObjectURL(res);
        // Create a temporary anchor element to trigger download
        const anchor = document.createElement('a');
        anchor.href = fileUrl;
        anchor.download = row.filename; // File name and extension
        document.body.appendChild(anchor); // Append to the body to make it work in Firefox
        anchor.click(); // Trigger download
        document.body.removeChild(anchor); // Clean up after download
        window.URL.revokeObjectURL(fileUrl); // Free up memory
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  onEditDocument(row) {
    this.updateDocumentsForm.patchValue({
      name: row.name,
      category: row.category,
    });

    this.selectedUpdateDocId = row.id;

    this.dialog.open(this.editDocumentPopup, {
      height: 'auto',
      width: '30%',
      maxHeight: '90vh',
      disableClose: false,
    });
  }

  editDocument() {
    const data = {
      name: this.updateDocumentsForm.value?.name,
      category: this.updateDocumentsForm.value?.category,
      updated_by_user_id: this.loggedUser.userId,
    };

    this.loadingService.startLoading(true);
    this.chatbotService
      .updateDocument(this.selectedUpdateDocId, data)
      .subscribe({
        next: (res) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Success, res?.message)
          );
          this.navigateBack();
          this.getData();
          this.loadingService.stopLoading();
        },
        error: (error) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.loadingService.stopLoading();
        },
      });
  }

  deleteDocument(row) {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['Role']['UpdateConfirm']['Title'],
          this.translationsList['Role']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.chatbotService.deleteDocument(row.id).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.detail)
              );
              this.selectedIDs = [];
              this.getData();
              this.sectionState = SectionStateStatus.Ready;
            },
            error: (error) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, 'Faild to Remove')
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  deleteSelected() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['Role']['UpdateConfirm']['Title'],
          this.translationsList['Role']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          const companyId = localStorage.getItem('company_id');
          if (this.selectedIDs.length == this.documents?.length) {
            this.chatbotService.deleteAllDocuments(companyId).subscribe({
              next: (res) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, res?.message)
                );
                this.selectedIDs = [];
                this.getData();
                this.sectionState = SectionStateStatus.Ready;
              },
              error: (error) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.sectionState = SectionStateStatus.Ready;
              },
            });
          } else {
            this.chatbotService
              .deleteSelectedDocuments({ document_ids: this.selectedIDs })
              .subscribe({
                next: (res) => {
                  this.feedBackService.showFeedback(
                    new FeedbackModel(FeedbackType.Success, res?.message)
                  );
                  this.selectedIDs = [];
                  this.getData();
                  this.sectionState = SectionStateStatus.Ready;
                },
                error: (error) => {
                  this.feedBackService.showFeedback(
                    new FeedbackModel(FeedbackType.Failure, error?.message)
                  );
                  this.sectionState = SectionStateStatus.Ready;
                },
              });
          }
        }
      });
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.dataSource = new MatTableDataSource(
        this.documents.filter((doc) => doc.name.includes(value))
      );
    }, 700);
  }

  toggleAllRows(checked: boolean) {
    if (checked) {
      this.documents.map((link) => {
        link.selected = true;
      });
      this.selectedIDs = this.documents.map((item) => item.id);
    } else {
      this.documents.map((link) => {
        link.selected = false;
      });
      this.selectedIDs = [];
    }
  }

  toggleRow(row: any, checked) {
    if (checked) {
      row.selected = true;
      this.selectedIDs.push(row.id);
    } else {
      row.selected = false;
      this.selectedIDs = this.selectedIDs.filter((item) => item !== row.id);
    }
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  navigateBack() {
    this.dialog.closeAll();
  }
}
