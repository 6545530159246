import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApi } from 'src/backend/api/base-api';
import { Observable, Subject } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';

@Injectable({
  providedIn: 'root',
})
export class ManageAccessControlService {
  private licenseDataUpdateSubject = new Subject<void>();

  // Observable for other components to subscribe to
  licenseDataUpdated$ = this.licenseDataUpdateSubject.asObservable();

  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  // Method to trigger the update event
  triggerLicenseDataUpdate(): void {
    this.licenseDataUpdateSubject.next();
  }

  getUsersWithAccessControl(data: any): Observable<any> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_GET_USERS_WITH_ACCESS_CONTROL + '?' + params
      )
    );
  }

  getVisitorsUsers(data: any): Observable<any> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_VISITORS_USERS + '?' + params)
    );
  }

  getUsersWithoutAccessControl(): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_USERS_WITHOUT_ACCESS_CONTROL)
    );
  }

  getCompanyLicenseData(id: string): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_COMPANY_LICENSE_DATA + '/' + id)
    );
  }

  createUser(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_MANAGE_ACCESS_CREATE_USER),
      data
    );
  }

  cancelInvitation(userId: string): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(
        Constants.API_MANAGE_ACCESS_CANCEL_INVITATION + '/' + userId
      )
    );
  }

  resendOTP(userId: string): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(
        Constants.API_MANAGE_ACCESS_RESEND_OTP_INVITATION + '/' + userId
      ),
      {}
    );
  }

  resendInvitation(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_MANAGE_ACCESS_RESEND_INVITATION),
      data
    );
  }

  deleteUser(userId: string): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(
        Constants.API_MANAGE_ACCESS_DELETE_USER + '/' + userId
      )
    );
  }

  getUserDetails(userId: string): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_MANAGE_ACCESS_GET_USER_DETAILS + '/' + userId
      )
    );
  }

  deleteDevice(id: string): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(
        Constants.API_MANAGE_ACCESS_DELETE_USER_DEVICE + '/' + id
      )
    );
  }

  getCards(): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_MANAGE_ACCESS_GET_CARDS)
    );
  }

  getHIDAdminPage(): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_HID_ADMIN_PAGE)
    );
  }

  issueMobileId(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_MANAGE_ACCESS_ISSUE_MOBILE_ID),
      data
    );
  }

  revokeCredential(id: any): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(
        Constants.API_MANAGE_ACCESS_DELETE_CREDENTIAL + '/' + id
      )
    );
  }

  inviteVisitor(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_INVITE_USER),
      data
    );
  }
}
