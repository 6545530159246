import { Component, OnInit } from '@angular/core';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';

@Component({
  selector: 'app-security-features-page',
  templateUrl: './security-features-page.component.html',
  styleUrls: ['./security-features-page.component.scss']
})
export class SecurityFeaturesPageComponent implements OnInit {
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;

  constructor() { }

  ngOnInit(): void {
  }

}
