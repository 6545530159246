import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { Constants } from 'src/@hodhod/common/constants';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { GeneralStatus, SortDirection } from 'src/@hodhod/common/enum';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { PermissionService } from 'src/backend/services/permission.service';
import { takeUntil} from 'rxjs';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService } from 'src/backend/services/organization.service';

@UntilDestroy()
@Component({
  selector: 'app-assigned-part-numbers-list',
  templateUrl: './assigned-part-numbers-list.component.html',
  styleUrls: ['./assigned-part-numbers-list.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],

})
export class AssignedPartNumbersListComponent   extends BaseComponent
implements OnInit, OnDestroy
{
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  shiftCreateUpdateModal!: TemplateRef<any>;
  formMode: 'create' | 'update' = 'create';

  AssignedList: any[];
  columns: TableColumn<any>[] = [
    {
      label: 'OrgCode',
      property: 'organizationCode',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'OrgName',
      property: 'organizationName',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'PartNumbers',
      property: 'partNumber',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'FriendlyName',
      property: 'friendlyName',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Description',
      property: 'description',
      type: 'text',
      visible: false,
      cssClasses: ['font-medium'],
    },
    {
      label: 'CreatedOn',
      property: 'createdOn',
      type: 'date',
      visible: false,
    },
    {
      label: 'UpdatedOn',
      property: 'updatedOn',
      type: 'date',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'AssignedToOrgOn',
      property: 'assignedToOrgOn',
      type: 'date',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'SynchronizedOn',
      property: 'synchronizedOn',
      type: 'date',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
  ];

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<any>([]);
  selection = new SelectionModel<any>(true, []);
  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  timer = null;
  loadingLabel: string = '';
  shiftId: number = 0;
  isReadonlyMode = false;

  ShiftStatuses = GeneralStatus;
  SharedConstants = SharedConstants;

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public sectionStateModal: SectionStateStatus = SectionStateStatus.Ready;
  public selectedShiftStatus: string[] = [];

  private translationsList: any = {};

  hasViewPermissions = ApplicationPermission.SHIFT_VIEW;
  hasWritePermissions = ApplicationPermission.SHIFT_CREATE;
  hasEditPermissions = ApplicationPermission.SHIFT_UPDATE;
  hasActivatePermissions = ApplicationPermission.SHIFT_ACTIVATE;

  currentQueryParams = null;
  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private feedBackService: AsyncFeedbackService,
    private permissionService: PermissionService,
    private router: Router,
    private route: ActivatedRoute,
    private orgService: OrganizationService
  ) {
    super();
    this.translate
      .get([
        'Errors',
        'Success',
        'confirmDeactiveRecord',
        'Role',
        'User',
        'StaticPages',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit(): void {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.sort = this.sort;
    this.currentQueryParams = this.route.snapshot.queryParams;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);

    this.pagination.pageIndex = this.currentQueryParams?.pageIndex
      ? Number(this.currentQueryParams?.pageIndex)
      : 0;
    this.pagination.pageSize = this.currentQueryParams?.pageSize
      ? Number(this.currentQueryParams?.pageSize)
      : 10;

    this.selectedShiftStatus = this.currentQueryParams?.orgStatus
      ? this.currentQueryParams?.orgStatus.split(',')
      : [];

    this.columns.forEach((item) => {
      if (this.currentQueryParams[item.property] === 'true') {
        item.visible = true;
      } else if (this.currentQueryParams[item.property] === 'false') {
        item.visible = false;
      }
    });

    this.sort.active = this.currentQueryParams?.sortName;
    this.sort.direction = this.currentQueryParams?.sortDirection;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        sortName: res.active,
        sortDirection: res.direction,
        pageIndex: 0,
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.currentQueryParams,
      });
      this.pagination.pageIndex = 0;
      this.getData();
    });

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getData();
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      [column.property]: column.visible,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
  }

  getData() {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }
    const filteredParams = {
      sortDirection: this.currentQueryParams?.sortDirection || '',
      sortField,
      pageIndex: this.currentQueryParams?.pageIndex
        ? this.currentQueryParams?.pageIndex
        : this.pagination.pageIndex,
      pageSize: this.currentQueryParams?.pageSize
        ? this.currentQueryParams?.pageSize
        : this.pagination.pageSize,
      searchValue: this.currentQueryParams?.searchValue
        ? this.currentQueryParams?.searchValue
        : this.searchValue,
      status: this.currentQueryParams?.orgStatus,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.orgService.getAssignedPartNumbersList(filteredParams).subscribe({
      next: (response) => {
        this.AssignedList = response['data'];
        this.dataSource = new MatTableDataSource(response['data']);
        if (this.dataSource.data.length === 0) {
          // Ensure paginator handles an empty data set
          this.paginator.pageIndex = 0;
          this.paginator.length = 0;
          this.paginator.pageSize = this.pagination.pageSize;
        }
        this.pagination.length = response?.totalCount;
        this.dataSource.sort = this.sort;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      searchValue: this.searchValue,
      pageIndex: 0,
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.pagination.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
}
