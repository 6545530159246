import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';

@Component({
  selector: 'app-user-invitations-log-card',
  templateUrl: './user-invitations-log-card.component.html',
  styleUrls: ['./user-invitations-log-card.component.scss'],
  animations: [scaleFadeIn400ms],
})
export class UserInvitationsLogCardComponent implements OnInit, OnChanges {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() invitationsLogs: any;

  columns: TableColumn<any>[] = [
    {
      label: 'InvitedBy',
      property: 'invitedBy',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'CreatedAt',
      property: 'createdOn',
      type: 'date',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'ExpireAt',
      property: 'expirationDate',
      type: 'date',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
  ];

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<any>([]);
  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['invitationsLogs']) {
      this.dataSource = new MatTableDataSource(
        changes['invitationsLogs'].currentValue
      );
      this.dataSource.sort = this.sort;
    }
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource([]);
    this.pagination.pageIndex = 0;
    this.pagination.pageSize = 10;
  }
}
