import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { map, startWith, takeUntil } from 'rxjs';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { MsdsService } from 'src/backend/services/msds/msds.service';
import { PpeService } from 'src/backend/services/ppe-services/ppe.service';
import { MsdsQrcodeComponent } from '../msds-qrcode/msds-qrcode.component';
import { Router } from '@angular/router';
import { MsdsSpecialHazard } from 'src/@hodhod/common/enum';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { BaseApi } from 'src/backend/api/base-api';
import { UserService } from 'src/backend/services/user.service';
import jsPDF from 'jspdf';
import moment from 'moment';
import { LanguageService } from 'src/@hodhod/services/language.service';
import * as htmlToImage from 'html-to-image';

@Component({
  selector: 'app-add-update-msds',
  templateUrl: './add-update-msds.component.html',
  styleUrls: ['./add-update-msds.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class AddUpdateMsdsComponent extends BaseComponent implements OnInit {
  @ViewChild('ppeInput') ppeInput: ElementRef<HTMLInputElement>;
  @ViewChild('rejectModal') rejectModal!: TemplateRef<any>;

  public expanded: boolean = false;
  public msdsForm: FormGroup;
  public departments: any = [];
  public subDepartments: any = [];

  public print: boolean = false;

  public sectionState = SectionStateStatus.Ready;

  public hazardIdentificationPanel = { isOpen: true };
  public firstAidPanel = { isOpen: false };
  public fireFightingMeasuresPanel = { isOpen: false };
  public measuresInCaseOfSpillagePanel = { isOpen: false };
  public handlingAndStoragePanel = { isOpen: false };
  public ppeIdsPanel = { isOpen: false };
  public attachmentsPanel = { isOpen: false };
  public historyLogsPanel = { isOpen: false };

  public ppeCtrl = new FormControl(null);
  public ppes: any = [];
  public allPpes: any = [];
  public selectedPpes: any = [];
  public filteredPpes: any = [];
  public filteredPpesLength: any;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  public showSpecialHazard: boolean = false;
  public SpecialHazardSymbols = MsdsSpecialHazard;
  hazardIdentificationSquare = document.getElementById(
    `hazardIdentificationSquare`
  ) as HTMLElement;

  files: any[] = [];
  oldFiles: any[] = [];
  public selectedFile;

  public selectedMsds: any;
  public rtl: string;

  translationsList: any = {};

  approversList: any = [];

  rejectReason: string = '';
  rejectModalRef;

  hasUpdatePermission = ApplicationPermission.MSDS_UPDATE;
  hasApproveRejectPermission = ApplicationPermission.MSDS_APPROVE;
  hasReviewerAccessPermission = ApplicationPermission.MSDS_REVIEWER_ACCESS;
  hasCreateDepartmentPermission = ApplicationPermission.DEPARTMENTS_CREATE;

  public historyLogs: MatTableDataSource<any> | null;
  columns: TableColumn<any>[] = [
    {
      label: 'UpdatedBy',
      property: 'createdBy',
      type: 'text',
      visible: true,
    },
    {
      label: 'UpdatedAt',
      property: 'dateOfAction',
      type: 'date',
      visible: true,
    },
    {
      label: 'Action',
      property: 'status',
      type: 'text',
      visible: true,
    },
  ];
  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;

  public loggedUser: LoggedUser;

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  getAttachmentImage(url: string) {
    const extension = url.split('.').pop()?.toLocaleLowerCase();
    if (extension.includes('xlsx') || extension.includes('xls')) {
      return '../../../../assets/img/resources/xls.png';
    } else if (extension.includes('docx') || extension.includes('doc')) {
      return '../../../../assets/img/resources/doc.png';
    } else if (extension.includes('pdf')) {
      return '../../../../assets/img/resources/pdf.png';
    } else {
      return url;
    }
  }
  openBigView(id: number): void {
    const img = this.files.find((x) => x.attachId === id);
    const downloadTag = document.createElement('a');
    downloadTag.href = img?.url;
    downloadTag.addEventListener('click', () => {
      downloadTag.download;
    });
    downloadTag.click();
  }

  constructor(
    private fb: FormBuilder,
    private ppeService: PpeService,
    private departmentService: DepartmentsService,
    private asyncFeedbackService: AsyncFeedbackService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private dialog: MatDialogRef<AddUpdateMsdsComponent>,
    private matDialog: MatDialog,
    private msdsService: MsdsService,
    private confirmDialog: MatDialogRef<ConfirmationComponent>,
    private loadingService: LoadingService,
    private router: Router,
    private baseApi: BaseApi,
    private userService: UserService,
    private langService: LanguageService,

    @Inject(MAT_DIALOG_DATA)
    public data: { formMode: string; msdsId?: number }
  ) {
    super();
    this.filterData();
    this.translate
      .get(['Errors', 'Success', 'ConfirmDeleteRecord', 'User', 'MSDS'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit(): void {
    this.loggedUser = this.baseApi.getUserSession();
    this.msdsForm = this.fb.group({
      materialCode: ['', Validators.required],
      materialName: ['', Validators.required],
      departmentCode: [''],
      subDepartment: [''],
      storageLocation: [''],
      materialDescription: ['', Validators.required],
      flammable: [
        0,
        [Validators.required, Validators.min(0), Validators.max(4)],
      ],
      reactive: [
        0,
        [Validators.required, Validators.min(0), Validators.max(4)],
      ],
      healthHazard: [
        0,
        [Validators.required, Validators.min(0), Validators.max(4)],
      ],
      specialHazard: ['None'],
      firstAid: [''],
      handlingAndStorage: [''],
      measuresInCaseOfSpillage: [''],
      fireFightingMeasures: [''],
      ppeIds: [''],
      PendingApprovers: [[]],
      attachments: new FormArray([]),
    });

    this.historyLogs = new MatTableDataSource();
    this.filteredPpes = this.ppeCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const ppeName = typeof value === 'string' ? value : value?.ppeName;
        return ppeName ? this._filter(ppeName as string) : this.ppes.slice();
      })
    );
    this.filteredPpes.subscribe((res) => {
      this.filteredPpesLength = res.length;
    });

    this.getPpes();
    this.getDepartments();
    this.getApproversList();
    if (this.data.formMode == 'view' || this.data.formMode == 'edit') {
      this.getMsdsById();
    }
    this.getApproversList();
    this.langService.currentLanguage$.subscribe((language) => {
      this.rtl = language.isoCode;
    });
  }

  startPublish() {
    this.data.formMode = 'publish';
    this.msdsForm.get('departmentCode').setValidators(Validators.required);
    this.msdsForm.get('storageLocation').setValidators(Validators.required);
    this.msdsForm.get('departmentCode').enable();
    this.msdsForm.get('storageLocation').enable();
    this.msdsForm.get('subDepartment').enable();
    this.msdsForm.get('departmentCode').markAsTouched();
    this.msdsForm.get('storageLocation').markAsTouched();
    this.msdsForm.get('departmentCode').updateValueAndValidity();
    this.msdsForm.get('storageLocation').updateValueAndValidity();
  }

  publish(msdsPdf) {
    const { departmentCode, storageLocation, subDepartment } =
      this.msdsForm.value;
    const data = {
      ID: this.selectedMsds.id,
      SubDepartmentCode: subDepartment ? subDepartment : departmentCode,
      StorageLocation: storageLocation,
    };
    const formData = new FormData();

    formData.append('content', JSON.stringify(data));
    formData.append(
      'pdffile',
      this.base64ToBlob(msdsPdf),
      this.msdsForm.get('materialName').value + ' - MSDS.pdf'
    );
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.msdsService.PublishMsds(formData).subscribe({
      next: (response) => {
        this.getMsdsById();
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, response?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  openRejectModal() {
    this.rejectModalRef = this.matDialog.open(this.rejectModal, {
      height: 'auto',
      width: '60%',
    });
  }

  reject() {
    const data = {
      ID: this.selectedMsds.id,
      comment: this.rejectReason,
    };
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingService.startLoading(true);
          this.msdsService.RejectMsds(data).subscribe({
            next: (response) => {
              this.getMsdsById();
              this.rejectModalRef.close();
              this.loadingService.stopLoading();
              this.asyncFeedbackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, response?.message)
              );
            },
            error: (error) => {
              this.loadingService.stopLoading();
              this.asyncFeedbackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
            },
          });
        }
      });
  }

  closeDialog(): void {
    this.matDialog.closeAll();
  }

  closeRejectDialog(): void {
    this.rejectModalRef.close();
  }

  startAddAdditionalApprovers() {
    this.data.formMode = 'additionalUser';
    this.msdsForm.get('departmentCode').setValidators(Validators.required);
    this.msdsForm.get('departmentCode').enable();
    this.msdsForm.get('subDepartment').enable();
    this.msdsForm.get('departmentCode').markAsTouched();
    this.msdsForm.get('PendingApprovers').setValidators(Validators.required);
    this.msdsForm.get('PendingApprovers').enable();
    this.msdsForm.get('PendingApprovers').markAsTouched();
  }

  getApproversList() {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.msdsForm.get('PendingApprovers').setValue([]);

    this.msdsService.GetApproversList().subscribe({
      next: (response) => {
        this.sectionState = SectionStateStatus.Ready;
        this.approversList = response;
        this.msdsForm
          .get('PendingApprovers')
          .setValue(
            this.selectedMsds?.approvers.map((item) => item.approverId)
          );
      },
      error: (error) => {
        this.sectionState = SectionStateStatus.Ready;
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  addAdditionalUsers() {
    const data = {
      id: this.selectedMsds.id,
      departmentCode: this.msdsForm.get('subDepartment').value
        ? this.msdsForm.get('subDepartment').value
        : this.msdsForm.get('departmentCode').value,
      pendingApprovers: this.msdsForm
        .get('PendingApprovers')
        .value.map((item) => {
          return {
            approverId: item,
          };
        }),
    };
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.msdsService.AddAdditionalApprovers(data).subscribe({
      next: (response) => {
        this.sectionState = SectionStateStatus.Ready;
        this.data.formMode = 'view';
        this.getMsdsById();
      },
      error: (error) => {
        this.sectionState = SectionStateStatus.Ready;
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  approveMsds() {
    const id = this.selectedMsds.id;
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.msdsService.ApproveMsds(id).subscribe({
            next: (response) => {
              this.sectionState = SectionStateStatus.Ready;
              this.getMsdsById();
            },
            error: (error) => {
              this.sectionState = SectionStateStatus.Ready;
              this.asyncFeedbackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
            },
          });
        }
      });
  }

  checkApprove() {
    const approver = this.selectedMsds?.approvers.find(
      (item) => item.approverId === this.loggedUser.userId
    );
    if (approver && !approver?.isApproved) {
      return true;
    } else {
      return false;
    }
  }

  getMsdsById() {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.msdsService.GetMsdsById(this.data?.msdsId)?.subscribe({
      next: (response) => {
        this.selectedMsds = response;
        this.files = response?.attachments;
        this.oldFiles = [...response?.attachments];
        this.selectedPpes = response.ppeDtos;
        this.historyLogs = new MatTableDataSource(response.historyLogsDtos);
        this.data.formMode = 'view';
        this.initFormUpdate(response);
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.loadingService.stopLoading();
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  initFormUpdate(data?: any) {
    this.msdsForm.get('materialCode').setValue(data.materialCode);
    this.msdsForm.get('materialName').setValue(data.materialName);
    this.msdsForm.get('storageLocation').setValue(data.storageLocation);
    this.msdsForm.get('materialDescription').setValue(data.materialDescription);
    this.msdsForm
      .get('flammable')
      .setValue(data.hazardIdentifications.flammable);
    this.msdsForm.get('reactive').setValue(data.hazardIdentifications.reactive);
    this.msdsForm
      .get('healthHazard')
      .setValue(data.hazardIdentifications.healthHazard);
    this.msdsForm
      .get('specialHazard')
      .setValue(data.hazardIdentifications.specialHazard);
    this.msdsForm.get('firstAid').setValue(data.firstAid);
    this.msdsForm.get('handlingAndStorage').setValue(data.handlingAndStorage);
    this.msdsForm
      .get('measuresInCaseOfSpillage')
      .setValue(data.measuresInCaseOfSpillage);
    this.msdsForm
      .get('fireFightingMeasures')
      .setValue(data.fireFightingMeasures);
    this.msdsForm
      .get('ppeIds')
      .setValue(this.selectedPpes.map((item) => item.ppeId).toString());

    if (data?.departmentParentId) {
      const departmentCode = this.departments[0]?.detail.find(
        (item) => item.departmentId == data?.departmentParentId
      )?.departmentCode;
      this.msdsForm.get('departmentCode')?.setValue(departmentCode);
      this.onChangeDepartment({ value: departmentCode });
      this.msdsForm.get('subDepartment')?.setValue(data?.subDepartmentCode);
    } else {
      if (data?.subDepartmentCode) {
        this.onChangeDepartment({ value: data?.subDepartmentCode });
      }
      this.msdsForm.get('departmentCode')?.setValue(data?.subDepartmentCode);
    }

    if (this.data.formMode == 'view') {
      this.msdsForm.disable();
    }
  }

  createMsds() {
    const attachment = this.msdsForm.value.attachments;
    const fileSize = attachment.reduce((accumulator, object) => {
      return accumulator + object.size;
    }, 0);
    const sizeInMB = fileSize / 1024 / 1024;
    if (sizeInMB <= SharedConstants.WORK_PERMIT_FILE_SIZE) {
      const formData = new FormData();
      const files = this.msdsForm.get('attachments').value;
      files.forEach((element) => {
        formData.append('files', element);
      });
      const data = {
        materialCode: this.msdsForm.get('materialCode').value,
        materialName: this.msdsForm.get('materialName').value,
        storageLocation: this.msdsForm.get('storageLocation').value,
        materialDescription: this.msdsForm.get('materialDescription').value,
        hazardIdentifications: {
          flammable: this.msdsForm.get('flammable').value,
          reactive: this.msdsForm.get('reactive').value,
          healthHazard: this.msdsForm.get('healthHazard').value,
          specialHazard: this.msdsForm.get('specialHazard').value,
        },
        firstAid: this.msdsForm.get('firstAid').value,
        fireFightingMeasures: this.msdsForm.get('fireFightingMeasures').value,
        measuresInCaseOfSpillage: this.msdsForm.get('measuresInCaseOfSpillage')
          .value,
        handlingAndStorage: this.msdsForm.get('handlingAndStorage').value,
        ppeIds: this.selectedPpes.map((item) => item.ppeId).toString(),
      };
      formData.append('content', JSON.stringify(data));

      this.loadingService.startLoading(true);
      this.msdsService.CreateMsds(formData).subscribe({
        next: (response) => {
          this.print = false;
          this.loadingService.stopLoading();
          this.dialog.close({ event: 'createdNew' });
          this.asyncFeedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Success, response.message)
          );
        },
        error: ({ error }) => {
          this.loadingService.stopLoading();
          this.asyncFeedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
        },
      });
    } else {
      this.asyncFeedbackService.showFeedback(
        new FeedbackModel(FeedbackType.Warning, 'maximum file size allowed 5Mb')
      );
      this.loadingService.stopLoading();
    }
  }

  editMsds() {
    const attachment = this.msdsForm.value.attachments;
    const fileSize = attachment.reduce((accumulator, object) => {
      return accumulator + object.size;
    }, 0);
    const sizeInMB = fileSize / 1024 / 1024;
    if (sizeInMB <= SharedConstants.WORK_PERMIT_FILE_SIZE) {
      const formData = new FormData();
      const files = this.msdsForm.get('attachments').value;
      files.forEach((element) => {
        formData.append('files', element);
      });
      const data = {
        ID: this.selectedMsds.id,
        materialCode: this.msdsForm.get('materialCode').value,
        materialName: this.msdsForm.get('materialName').value,

        storageLocation: this.msdsForm.get('storageLocation').value,
        materialDescription: this.msdsForm.get('materialDescription').value,
        hazardIdentifications: {
          flammable: this.msdsForm.get('flammable').value,
          reactive: this.msdsForm.get('reactive').value,
          healthHazard: this.msdsForm.get('healthHazard').value,
          specialHazard: this.msdsForm.get('specialHazard').value,
        },
        firstAid: this.msdsForm.get('firstAid').value,
        fireFightingMeasures: this.msdsForm.get('fireFightingMeasures').value,
        measuresInCaseOfSpillage: this.msdsForm.get('measuresInCaseOfSpillage')
          .value,
        handlingAndStorage: this.msdsForm.get('handlingAndStorage').value,
        ppeIds: this.selectedPpes.map((item) => item.ppeId).toString(),
        attachments: this.oldFiles.map((item) => item && item),
      };
      formData.append('content', JSON.stringify(data));
      // formData.append(
      //   'pdffile',
      //   this.base64ToBlob(msdsPdf),
      //   this.msdsForm.get('materialName').value + ' - MSDS.pdf'
      // );
      this.loadingService.startLoading(true);
      this.msdsService.EditMsds(formData).subscribe({
        next: (response) => {
          this.print = false;
          this.loadingService.stopLoading();
          this.dialog.close({ event: 'edited' });
          this.asyncFeedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Success, response?.message)
          );
        },
        error: ({ error }) => {
          this.loadingService.stopLoading();
          this.asyncFeedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
        },
      });
    } else {
      this.asyncFeedbackService.showFeedback(
        new FeedbackModel(FeedbackType.Warning, 'maximum file size allowed 5Mb')
      );
      this.loadingService.stopLoading();
    }
  }

  getDepartments(): void {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.departmentService.getDepartmentsParentChildByUserId().subscribe({
      next: (response) => {
        this.departments = response;
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  getPpes() {
    this.sectionState = SectionStateStatus.Loading;
    this.ppeService.getActivePpes().subscribe({
      next: (response) => {
        this.ppes = response.map((item) => {
          return {
            ppeId: item.id,
            ppeName: item.ppeName,
            imageUrl: item.imageUrl,
            selected: false,
          };
        });

        if (this.selectedPpes.length > 0) {
          this.selectedPpes.forEach((ele) => {
            if (this.ppes.some((item) => item.ppeName == ele.ppeName)) {
              const index = this.ppes.findIndex(
                (item) => item.ppeId == ele.ppeId
              );
              if (index >= 0) {
                this.ppes[index].selected = true;
              }
            }
          });
        }

        this.allPpes = response;
        this.filterData();
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  optionClicked = (event: Event, data: any): void => {
    event.stopPropagation();
    this.toggleSelection(data);
  };

  toggleSelection = (data: any): void => {
    data.selected = !data.selected;

    if (data.selected === true) {
      this.selectedPpes.push(data);
    } else {
      const i = this.selectedPpes.findIndex(
        (value) => value.ppeId === data.ppeId
      );
      this.selectedPpes.splice(i, 1);
    }
    this.msdsForm
      .get('ppeIds')
      .setValue(this.selectedPpes.map((item) => item.ppeId));
  };

  selectAllPpe() {
    this.selectedPpes = [];
    this.ppes.map((item) => {
      item.selected = true;
    });
    this.selectedPpes.push(...this.ppes);
    this.filterData();
  }

  removePpe(ppe) {
    const currentPpe = this.ppes.find((item) => (item.ppeId = ppe.ppeId));
    currentPpe.selected = false;
    const index = this.selectedPpes.findIndex(
      (item) => item.ppeId == ppe.ppeId
    );
    if (index >= 0) {
      this.selectedPpes.splice(index, 1);
      this.msdsForm
        .get('ppeIds')
        .patchValue(this.selectedPpes.map((item) => item.ppeId));
    }
  }

  filterData() {
    this.filteredPpes = this.ppeCtrl.valueChanges.pipe(
      startWith(''),
      map((ppeName) => (ppeName ? this._filter(ppeName) : this.ppes.slice()))
    );
    this.filteredPpes.subscribe((res) => {
      this.filteredPpesLength = res.length;
    });
  }

  displayFn(option: { name: string; value: number }): string {
    return option && option.name ? option.name : '';
  }

  openNextPanel(currentPanel: any, nextPanel: any): void {
    currentPanel.isOpen = false;
    nextPanel.isOpen = true;
  }

  enableEditMsds() {
    this.data.formMode = 'edit';
    this.msdsForm.enable();
  }

  async takePrint(): Promise<void> {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then(async (value) => {
        if (value === true) {
          const doc = new jsPDF({
            orientation: 'portrait',
          }).setProperties({ title: 'MSDS' });
          doc.addFont('assets/fonts/Amiri-Regular.ttf', 'Amiri', 'normal');
          doc.setFont('Amiri', 'italic', 'bold');
          let y = 5;
          const companyLogo =
            JSON.parse(localStorage.getItem('company')).companyImage || '';
          if (companyLogo) {
            doc.addImage(companyLogo, 'PNG', 10, y, 20, 20); // Adjust positioning and size as needed
            doc.text(
              JSON.parse(localStorage.getItem('company')).companyName,
              35,
              y + 10
            );
            y += 45;
          } else {
            doc.text(
              JSON.parse(localStorage.getItem('company')).companyName,
              10,
              y,
              {
                align: 'center',
              }
            );
            y += 20;
          }
          // Title Line
          doc.setLineWidth(1);
          doc.line(10, 30, 200, 30);
          doc.addImage(
            '../../../../assets/img/resources/saned-white-logo.png',
            'PNG',
            140,
            24,
            40,
            10
          );
          y += 10;
          // Report Title
          doc.setFontSize(18);
          doc.text('MSDS', 105, 40, { align: 'center' });
          doc.setFont('Amiri', 'normal');
          const details = [
            {
              label: 'Material Code',
              arLabel: 'كود المادة ',
              value: this.msdsForm.get('materialCode').value,
              x: 12,
              yOffset: 0,
            },

            {
              label: 'Department',
              arLabel: 'القسم',
              value: this.departments[0]?.detail.find(
                (detail) =>
                  detail.departmentCode ===
                  this.msdsForm.get('departmentCode').value
              )?.departmentName,
              x: 12,
              yOffset: 10,
            },

            {
              label: 'Storage Location',
              arLabel: 'مكان التخزين',
              value: this.msdsForm.get('storageLocation').value,
              x: 12,
              yOffset: 30,
            },
          ];
          const secDetails = [
            {
              label: 'Material Name',
              arLabel: 'اسم المادة',
              value: this.msdsForm.get('materialName').value,
              x: 12,
              yOffset: 0,
            },
            {
              label: 'Sub Department',
              arLabel: 'القسم الفرعي',
              value:
                this.subDepartments.find(
                  (dep) =>
                    dep?.code === this.msdsForm.get('subDepartment').value
                )?.name || '--',
              x: 12,
              yOffset: 20,
            },
          ];
          let Y = y;

          doc.setFontSize(12);
          details.forEach((detail) => {
            doc.setTextColor('#1F36B4');
            doc.addImage(
              'assets/img/resources/pdfArrow.png',
              'PNG',
              8,
              y - 3,
              5,
              5
            );
            if (this.rtl === 'ar') {
              doc.text(detail?.arLabel, 15, y);
            } else {
              doc.text(detail?.label, 15, y);
            }
            doc.setTextColor('black');
            doc.text(detail.value || '', 15, y + 5, { maxWidth: 90 });
            y += 15;
          });
          secDetails.forEach((detail) => {
            doc.setTextColor('#1F36B4');
            doc.addImage(
              'assets/img/resources/pdfArrow.png',
              'PNG',
              103,
              Y - 3,
              5,
              5
            );
            if (this.rtl === 'ar') {
              doc.text(detail.arLabel, 110, Y);
            } else {
              doc.text(detail.label, 110, Y);
            }
            doc.setTextColor('black');
            doc.text(detail.value || '', 110, Y + 5, { maxWidth: 90 });
            Y += 15;
          });

          //hazard square
          y += 10;
          let element = document.getElementById(
            'hazardIdentificationSquare'
          ) as HTMLElement;

          if (element !== null) {
            const previousDisplay = element.style.display;
            if (previousDisplay === 'none' || previousDisplay === '') {
              element.style.display = 'block';
            }

            try {
              const url = await htmlToImage.toPng(element);
              doc.addImage(url, 'PNG', 130, y - 10, 60, 60);
            } catch (error) {
            } finally {
              element.style.display = previousDisplay;
            }
          } else {
          }
          doc.addImage(
            'assets/img/resources/flammable.png',
            'PNG',
            15,
            y - 5,
            8,
            8
          );
          doc.setTextColor('#595d60');
          doc.setFontSize(14);

          if (this.rtl === 'ar') {
            doc.text('قابل للإشتعال', 25, y);
          } else {
            doc.text('Flammable', 25, y);
          }
          doc.setTextColor('#1F36B4');
          doc.text(this.msdsForm.get('flammable').value.toString(), 60, y);
          doc.setFontSize(12);
          y += 5;
          doc.text(
            '- ' +
              this.translationsList['MSDS']['FlammableDetails'][
                this.msdsForm.get('flammable').value
              ],
            25,
            y,
            { maxWidth: 110 }
          );
          y += 10;

          doc.addImage(
            'assets/img/resources/reactive.png',
            'PNG',
            15,
            y - 5,
            8,
            8
          );
          doc.setTextColor('#595d60');
          doc.setFontSize(14);
          if (this.rtl === 'ar') {
            doc.text('قابل للتفاعل', 25, y);
          } else {
            doc.text('Reactive', 25, y);
          }
          doc.setTextColor('#1F36B4');
          doc.text(this.msdsForm.get('reactive').value.toString(), 60, y);
          doc.setFontSize(12);
          y += 5;
          doc.text(
            '- ' +
              this.translationsList['MSDS']['ReactiveDetails'][
                this.msdsForm.get('reactive').value
              ],
            25,
            y,
            { maxWidth: 110 }
          );
          y += 10;
          doc.addImage(
            'assets/img/resources/health-hazard.png',
            'PNG',
            15,
            y - 5,
            8,
            8
          );
          doc.setTextColor('#595d60');
          doc.setFontSize(14);
          if (this.rtl === 'ar') {
            doc.text('الخطورة الصحية', 25, y);
          } else {
            doc.text('Health Hazard', 25, y);
          }
          doc.setTextColor('#1F36B4');
          doc.text(this.msdsForm.get('healthHazard').value.toString(), 60, y);
          doc.setFontSize(12);
          y += 5;
          doc.text(
            '- ' +
              this.translationsList['MSDS']['HealthHazardDetails'][
                this.msdsForm.get('healthHazard').value
              ],
            25,
            y,
            { maxWidth: 110 }
          );
          y += 10;
          doc.addImage(
            'assets/img/resources/special-hazard.png',
            'PNG',
            15,
            y - 5,
            8,
            8
          );
          doc.setTextColor('#595d60');
          doc.setFontSize(14);
          if (this.rtl === 'ar') {
            doc.text('خطورة خاصة', 25, y);
          } else {
            doc.text('Special Hazrad', 25, y);
          }
          doc.setTextColor('#1F36B4');
          doc.text(
            this.translationsList['MSDS']['SpecialHazard'][
              this.msdsForm.get('specialHazard').value
            ],
            60,
            y
          );

          doc.setFontSize(12);
          y += 15;
          // Description
          let x = 15;

          //////firstAid
          const yPosition = { value: y };
          this.addingDescription(
            doc,
            yPosition,
            this.msdsForm.get('materialDescription').value,
            'الوصف',
            'Description',
            true
          );
          this.addingDescription(
            doc,
            yPosition,
            this.msdsForm.get('handlingAndStorage').value,
            'المناولة و التخزين',
            'Handling And Storage',
            true
          );
          this.addingDescription(
            doc,
            yPosition,
            this.msdsForm.get('firstAid').value,
            'الأسعافات الأوليه',
            'First Aid',
            true
          );
          this.addingDescription(
            doc,
            yPosition,
            this.msdsForm.get('fireFightingMeasures').value,
            'إجراءات مكافحة الحريق',
            'Fire Fighting Measures',
            true
          );
          this.addingDescription(
            doc,
            yPosition,
            this.msdsForm.get('measuresInCaseOfSpillage').value,
            'التدابير المتخذة في حالة الانسكاب',
            'Measures In Case Of Spillage',
            true
          );

          y = yPosition.value;
          if (this.selectedPpes.length > 0) {
            y += 5;
            doc.setTextColor('#1F36B4');
            doc.addImage(
              'assets/img/resources/pdfArrow.png',
              'PNG',
              8,
              y - 3,
              5,
              5
            );
            doc.setFontSize(16);
            if (this.rtl === 'ar') {
              doc.text('معدات الحماية', 15, y);
            } else {
              doc.text('PPE List', 15, y);
            }
            y += 5;
            doc.setLineWidth(0.5);
            doc.line(10, y, 200, y);
            y += 5;
            Y = y;
            x = 15;
            const pageHeight = doc.internal.pageSize.height;
            const bottomMargin = 20;
            const lineHeight = 5;

            for (let j = 0; j < this.selectedPpes.length; j++) {
              if (this.selectedPpes[j]) {
                doc.setTextColor('black');
                doc.setFontSize(12);
                doc.text(j + 1 + '-', x - 2, y + 5);
                if (this.selectedPpes[j]?.imageUrl) {
                  doc.addImage(
                    this.selectedPpes[j]?.imageUrl,
                    'PNG',
                    x + 3,
                    y,
                    10,
                    10
                  );
                }

                doc.text(this.selectedPpes[j]?.ppeName || '', x + 15, y + 5, {
                  maxWidth: 200,
                });
                if (y + lineHeight > pageHeight - bottomMargin) {
                  doc.addPage();

                  y = 10;
                }

                y += 12;
              }
            }
          }
          const pdfDocGenerator = doc.output('datauristring').split(',')[1];
          this.publish(pdfDocGenerator);
        }
      });
    ////////////////////////////////////////////////////////////////////
  }
  addingDescription(doc, yPosition, value, arText, enText, desc?) {
    if (value && value.trim() !== '') {
      const pageHeight = doc.internal.pageSize.height;
      const bottomMargin = 15;
      const lineHeight = 5;
      const text = value || '';
      const maxWidth = 210;
      const x = 15;
      const lines = doc.splitTextToSize(text, maxWidth);

      doc.setLineWidth(0.5);
      doc.line(30, yPosition.value, 180, yPosition.value);
      yPosition.value += 10;

      // Add section header
      doc.setTextColor('#1F36B4');
      doc.addImage(
        'assets/img/resources/pdfArrow.png',
        'PNG',
        8,
        yPosition.value - 3,
        5,
        5
      );
      doc.setFontSize(16);
      if (this.rtl === 'ar') {
        doc.text(arText, 15, yPosition.value);
      } else {
        doc.text(enText, 15, yPosition.value);
      }
      yPosition.value += 10;
      // Add section content
      doc.setTextColor('black');
      doc.setFontSize(12);
      for (let i = 0; i < lines.length; i++) {
        if (yPosition.value + lineHeight > pageHeight - bottomMargin) {
          doc.addPage();
          yPosition.value = 10;
        }
        if (desc) {
          if (this.checkIfArabic(lines[i])) {
            doc.text(lines[i], 190, yPosition.value, { align: 'right' });
          } else {
            doc.text(lines[i], x, yPosition.value);
          }
        } else {
          doc.text(lines[i], x, yPosition.value);
        }
        // doc.text(lines[i], x, yPosition.value);
        yPosition.value += lineHeight;
      }
    }
  }
  checkIfArabic(text: string) {
    if (text.includes('ا' || 'أ' || 'ل')) {
      return true;
    } else {
      return false;
    }
  }
  base64ToBlob(base64Data: string): Blob {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: 'application/pdf' });
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL('image/png');

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }

  onFileSelected(event) {
    if (event.target.files.length > 0) {
      const files = event.target.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.msdsForm.get('attachments').value.push(file);
      }
    }
    for (let file of event.target.files) {
      this.files.push({
        name: file.name,
        type: file.type,
      });
    }
  }

  getFileName(value: string) {
    return value.substring(value.lastIndexOf('/') + 1);
  }

  removeFile(fileName: number) {
    for (let i = 0; i < this.files.length; i++) {
      if (this.files[i]?.name === fileName) {
        this.files.splice(i, 1);
        this.msdsForm.get('attachments').value.splice(i, 1);
        break;
      }
    }
  }

  deleteDirectlyFile(id: number): void {
    const file = this.files.find((x) => x.attachId === id);
    if (file) {
      this.selectedFile = file;
      this.deleteFile();
    }
  }

  deleteFile(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.Loading;
          this.msdsService
            .DeleteMsdsAttachments(
              this.selectedFile ? this.selectedFile.attachId : 0
            )
            .subscribe({
              next: (response) => {
                this.sectionState = SectionStateStatus.Ready;
                this.files = this.files.filter(
                  (x) => x.attachId !== this.selectedFile.attachId
                );
                this.asyncFeedbackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, response?.message)
                );
              },
              error: ({ error }) => {
                this.asyncFeedbackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.text)
                );
                this.confirmDialog.close();
                this.sectionState = SectionStateStatus.Ready;
              },
            });
        }
      });
  }

  generateQrCode() {
    this.matDialog.open(MsdsQrcodeComponent, {
      height: '515px',
      width: '430px',
      panelClass: 'qrcode-dialog',
      id: 'msds-qrcode-dialog',
      data: {
        type: 'msds',
        Url: this.selectedMsds.downloadPdf,
        Title: this.selectedMsds.materialName,
        Code: this.selectedMsds.materialCode,
      },
    });
  }

  isImage(url) {
    const imageExtensions = ['jpg', 'jpeg', 'gif', 'png', 'bmp', 'svg'];
    const extension = url.split('.').pop().toLowerCase();
    return imageExtensions.includes(extension);
  }

  toggleMore(type: string) {
    if (type === 'Flammable') {
      let value = this.msdsForm.get('flammable').value;
      if (value < 4) {
        value++;
        this.msdsForm.get('flammable').setValue(value);
      }
    }
    if (type === 'Reactive') {
      let value = this.msdsForm.get('reactive').value;
      if (value < 4) {
        value++;
        this.msdsForm.get('reactive').setValue(value);
      }
    } else if (type == 'HealthHazard') {
      let value = this.msdsForm.get('healthHazard').value;
      if (value < 4) {
        value++;
        this.msdsForm.get('healthHazard').setValue(value);
      }
    }
  }

  toggleLess(type: string) {
    if (type === 'Flammable') {
      let value = this.msdsForm.get('flammable').value;
      if (value > 0) {
        value--;
        this.msdsForm.get('flammable').setValue(value);
      }
    } else if (type === 'Reactive') {
      let value = this.msdsForm.get('reactive').value;
      if (value > 0) {
        value--;
        this.msdsForm.get('reactive').setValue(value);
      }
    } else if (type == 'HealthHazard') {
      let value = this.msdsForm.get('healthHazard').value;
      if (value > 0) {
        value--;
        this.msdsForm.get('healthHazard').setValue(value);
      }
    }
  }

  goToPpe() {
    this.router.navigate(['/company-setup/ppe-setting']);
    this.dialog.close();
  }

  private _filter(value: string): any[] {
    let filterValue = '';
    if (typeof value == 'string') {
      filterValue = value.toLowerCase();
    }
    return this.ppes.filter((option) =>
      option.ppeName.toLowerCase().includes(filterValue)
    );
  }

  onChangeDepartment(event) {
    this.msdsForm.get('subDepartment').setValue('');
    this.sectionState = SectionStateStatus.LoadingTransparent;
    const departmentId = this.departments[0]?.detail.find(
      (item) => item.departmentCode == event.value
    )?.departmentId;
    this.departmentService.getSubDepartmentsById(departmentId).subscribe({
      next: (response) => {
        this.subDepartments = response;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
}
