import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WorkPermitApproverTypes } from 'src/@hodhod/common/enum';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { PermissionService } from 'src/backend/services/permission.service';
import { WorkPermitService } from 'src/backend/services/work-permits/work-permit.service';

@Component({
  selector: 'app-edit-view-work-permit-page',
  templateUrl: './edit-view-work-permit-page.component.html',
  styleUrls: ['./edit-view-work-permit-page.component.scss'],
})
export class EditViewWorkPermitPageComponent implements OnInit, OnDestroy {
  public mode: 'view' | 'edit' | 'add' = 'view';
  public workPermitId: number = 0;

  private destroy$ = new Subject();
  public workPermit: any;
  hasPermissionEdit = ApplicationPermission.WORK_PERMIT_LIST_EDIT;
  hasPermissionView = ApplicationPermission.WORK_PERMIT_LIST_ACCESS;
  formMode: any;
  constructor(
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private workPermitService: WorkPermitService,
    private asyncFeedbackService: AsyncFeedbackService,

    private router: Router
  ) {}

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const id: string = params.get('id') as string;
        this.workPermitId = parseInt(id, 10);
        this.getWorkPermit();
      }
    });
    this.route.queryParamMap.subscribe((queryParam) => {
      const formMode = queryParam.get('editMode') as any;
      this.mode = formMode === 'true' ? 'edit' : 'view';
    });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  editWorkPermit(event): void {
    this.loadingService.startLoading(true, '');
    const {
      title,
      plannedActivityDescription,
      equipmentDescription,
      pPE,
      departmentId,
      subDepartment,
      workConditions,
      startDate,
      endDate,
      startTime,
      endTime,
      description,
      attachments,
      workerInfos,
      jobSafetyAnalyiss,
      sections,
      approver,
      additionalApprovers,
    } = event;
    const approvers = approver?.map((ele) => {
      return {
        approverId: 0,
        approver: ele,
        approverType: WorkPermitApproverTypes.InitialApprover,
      };
    });
    const formData = new FormData();
    const data = {
      title: title,
      plannedActivityDescription: plannedActivityDescription,
      equipmentDescription: equipmentDescription,
      pPE: pPE.toString(),
      workPermitId: this.workPermitId,
      departmentCode: subDepartment ? subDepartment : departmentId,
      description: description,
      startDate: moment(new Date(startDate)).format('YYYY-MM-DDThh:mm:ssZ'),
      endDate: moment(new Date(endDate)).format('YYYY-MM-DDThh:mm:ssZ'),
      startTime: startTime,
      endTime: endTime,
      workConditions: workConditions,
      workerInfos: workerInfos,
      jobSafetyAnalyisses: jobSafetyAnalyiss,
      additionalApprovers: [
        ...approvers,
        ...additionalApprovers?.map((ele) => {
          return {
            approverId: 0,
            approver: ele,
            approverType: WorkPermitApproverTypes.AdditionalApprover,
          };
        }),
      ],
      sections: sections?.map((ele) => {
        if (ele.questionType === 'Value Vs Target') {
          return {
            sectionId: ele.sectionId,
            sectionTitle: ele.title,
            questions: ele.questions?.map((ques) => {
              return {
                questionId: ques.questionId,
                userAnswer: ques.isNotApplicable
                  ? ''
                  : ques.answer + ' ' + ques.userAnswer.toString(),
                comment: ques.comment,
                isNotApplicable: ques.isNotApplicable,
                media: ques.media,
              };
            }),
          };
        } else {
          return {
            sectionId: ele.sectionId,
            sectionTitle: ele.title,
            questions: ele.questions?.map((ques) => {
              return {
                questionId: ques.questionId,
                userAnswer: ques.isNotApplicable
                  ? ''
                  : ques.userAnswer.toString(),
                comment: ques.comment,
                isNotApplicable: ques.isNotApplicable,
                media: ques.media,
              };
            }),
          };
        }
      }),
    };
    attachments.forEach((element) => {
      formData.append('files', element);
    });
    formData.append('content', JSON.stringify(data));
    this.workPermitService.editWorkPermit(formData).subscribe({
      next: (response) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, response?.message)
        );
        this.loadingService.stopLoading();
        this.goBack();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  getWorkPermit(): void {
    this.loadingService.startLoading(true, '');

    this.workPermitService.getWorkPermitById(this.workPermitId).subscribe({
      next: (response) => {
        this.workPermit = response;

        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  goBack(): void {
    this.router.navigate([
      '/' +
        SharedConstants.WORK_PERMIT +
        '/' +
        SharedConstants.WORK_PERMIT_LIST,
    ]);
  }
  reloadData(event: boolean): void {
    this.getWorkPermit();
  }
}
