import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { ApplicationPermission } from '../shared/models/application-permission';
import { SharedConstants } from '../shared/models/shared-constants';
import { ManageUserAccessControlComponent } from './pages/manage-user-access-control/manage-user-access-control.component';
import { AccessControlUserDetailsComponent } from './pages/access-control-user-details/access-control-user-details.component';

const routes: Routes = [
  {
    path: SharedConstants.MANAGE_USERS_ACCESS_CONTROL,
    component: ManageUserAccessControlComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.ACCESS_CONTROL_CREATE,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'ManageAccessControl',
      },
    },
  },
  {
    path: SharedConstants.MANAGE_USERS_ACCESS_CONTROL_USER_DETAILS + '/:id',
    component: AccessControlUserDetailsComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.ACCESS_CONTROL_CREATE,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'ManageAccessControlUserDetails',
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccessControlSetupRoutingModule {}
