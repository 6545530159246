import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, startWith } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ManageAccessControlService } from 'src/backend/services/manage-access-control/manage-access-control.service';

@Component({
  selector: 'app-invite-employee',
  templateUrl: './invite-employee.component.html',
  styleUrls: ['./invite-employee.component.scss'],
})
export class InviteEmployeeComponent extends BaseComponent implements OnInit {
  @ViewChild('inviteUserInput') inviteUserInput: ElementRef<HTMLInputElement>;

  public usersCtrl = new FormControl(null);
  usersWithoutAccessControl: any[] = [];
  allUsersWithoutAccessControl: any[] = [];
  filteredUsersWithoutAccessControl: any = [];
  selectedInviteUsers: any = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];
  cards: any[] = [];
  selectedCardId: any;
  inviteUserForm: FormGroup;
  showIssueCard: boolean = false;
  selectedCard: string;

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private feedBackService: AsyncFeedbackService,
    private dialogRef: MatDialogRef<InviteEmployeeComponent>,
    private manageAccessControlService: ManageAccessControlService
  ) {
    super();
    this.filterData();
  }

  override ngOnInit(): void {
    this.inviteUserForm = this.fb.group({
      userIds: [[], [Validators.required, Validators.maxLength(5)]],
      issueCard: [false],
      partNumber: [''],
    });

    this.inviteUserForm.get('issueCard').valueChanges.subscribe((value) => {
      if (value) {
        this.inviteUserForm
          .get('partNumber')
          .setValidators(Validators.required);
      } else {
        this.inviteUserForm.get('partNumber').clearValidators();
        this.inviteUserForm.get('partNumber').setValue('');
        this.selectedCard = '';
      }
      this.inviteUserForm.get('partNumber').updateValueAndValidity();
    });

    this.filteredUsersWithoutAccessControl = this.usersCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        console.log(value);

        const userName = typeof value === 'string' ? value : value?.fullName;
        return userName
          ? this._filter(userName as string)
          : this.usersWithoutAccessControl.slice();
      })
    );

    this.getCards();
    this.getUsersWithoutAccessControl();

    this.filterData();
  }

  optionClicked = (event: Event, data: any): void => {
    event.stopPropagation();
    this.toggleSelection(data);
  };

  toggleSelection = (data: any): void => {
    data.selected = !data.selected;

    if (data.selected === true) {
      this.selectedInviteUsers.push(data);
    } else {
      const i = this.selectedInviteUsers.findIndex(
        (value) => value.ppeId === data.ppeId
      );
      this.selectedInviteUsers.splice(i, 1);
    }
    this.inviteUserForm
      .get('userIds')
      .setValue(this.selectedInviteUsers.map((item) => item.id));
  };

  getUsersWithoutAccessControl() {
    this.sectionState = SectionStateStatus.Loading;
    this.manageAccessControlService.getUsersWithoutAccessControl().subscribe({
      next: (res) => {
        this.usersWithoutAccessControl = [...res];
        this.allUsersWithoutAccessControl = res;
        this.filteredUsersWithoutAccessControl = [...res];
        this.filterData();
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  filterData() {
    this.filteredUsersWithoutAccessControl = this.usersCtrl.valueChanges.pipe(
      startWith(''),
      map((value) =>
        value ? this._filter(value) : this.usersWithoutAccessControl.slice()
      )
    );
  }

  getCards() {
    this.sectionState = SectionStateStatus.Loading;
    this.manageAccessControlService.getCards().subscribe({
      next: (res) => {
        this.cards = res;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  addUser() {
    console.log(this.inviteUserForm.value);
    const data = {
      userIds: this.inviteUserForm.get('userIds').value,
      partNumber: this.inviteUserForm.get('partNumber').value,
    };

    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.manageAccessControlService.createUser(data).subscribe({
      next: (res) => {
        this.sectionState = SectionStateStatus.Ready;
        this.dialogRef.close({ created: true });
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, res?.message)
        );
      },
      error: (error) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  onCardSelect(partNumber: string) {
    this.selectedCard = partNumber;
    this.inviteUserForm.get('partNumber').setValue(partNumber);
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  displayFn(user: any): string {
    return user ? user.fullName : '';
  }

  private _filter(searchTerm: string): any[] {
    console.log(searchTerm);

    const filterValue = searchTerm.toLowerCase();
    return filterValue
      ? this.usersWithoutAccessControl.filter(
          (user) =>
            user.fullName.toLowerCase().includes(filterValue) ||
            user.email.toLowerCase().includes(filterValue)
        )
      : this.usersWithoutAccessControl;
  }
}
