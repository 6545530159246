import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { Feature } from 'src/backend/models/features/feature';
import { OrganizationService } from 'src/backend/services/organization.service';
import { OrganizationDetail } from '../../models/organization-detail';
import { SharedConstants } from 'src/app/shared/models/shared-constants';

@Component({
  selector: 'app-view-organization-org-admin',
  templateUrl: './view-organization-org-admin.component.html',
  styleUrls: ['./view-organization-org-admin.component.scss'],
})
export class ViewOrganizationOrgAdminComponent implements OnInit {
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public orgInfo: OrganizationDetail;
  public orgLicenseInfo: OrganizationDetail;
  public features: Feature[] = [];
  public orgCode: string = '';
  public selectedFeatures: Feature[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orgService: OrganizationService,
    private feedBackService: AsyncFeedbackService
  ) {}

  ngOnInit(): void {
    this.orgCode = JSON.parse(window.localStorage.getItem('organization'))[
      'orgId'
    ];
    this.getOrgInfo();
  }
  Edit() {
    this.router.navigate([
      '/' +
        SharedConstants.ORGANIZATION_SETUP +
        '/' +
        SharedConstants.ORGANIZATION_UPDATE_ORG_ADMIN,
    ]);
  }
  getOrgInfo(): void {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.orgService.getOrganizationDetails(this.orgCode).subscribe({
      next: (response) => {
        this.orgInfo = response;
        let security: any = response?.features?.find(
          (element) => element['module'] === '1'
        );
        let safety: any = response['features']?.find(
          (element) => element['module'] === '0'
        );
        if (safety?.features || security?.features) {
          this.selectedFeatures = [
            ...(safety?.features || []),
            ...(security?.features || []),
          ];
        }
        security?.features ? this.getOrgCardInfo() : null;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Error;
      },
    });
  }
  getOrgCardInfo(): void {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.orgService.getOrganizationCardDetails(this.orgCode).subscribe({
      next: (response) => {
        this.orgLicenseInfo = response['message'];
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Error;
      },
    });
  }
}
