<div class="card rounded-2xl">
  <div class="">
    <div class="py-4 px-6">
      <div class="flex justify-between">
        <div class="flex items-center">
          <h2
            class="font-semibold my-0 ltr:pr-4 rtl:pl-4 hidden sm:block text-base flex-none"
          >
            <span>{{ "Chatbot.UploadDocument" | translate }}</span>
          </h2>
          <span
            class="rounded-full px-2 py-0.5 bg-green-100 h-fit text-green-900 font-semibold text-xs"
            >{{ documents?.length }} {{ "Chatbot.Documents" | translate }}</span
          >
        </div>
        <app-basic-button
          [btnType]="'Add'"
          [btnText]="'UploadDocument'"
          [link]="'link.svg'"
          (onClick)="createNewDocuments()"
        ></app-basic-button>
      </div>
    </div>
    <div class="flex justify-between py-4 px-6">
      <div
        class="bg-foreground rounded-xl px-4 max-w-[400px] flex-auto flex items-center border border-gray-300"
      >
        <img src="assets/img/resources/Icon.svg" alt="" srcset="" />
        <input
          [formControl]="searchCtrl"
          class="px-2 py-2 border-0 outline-none w-full bg-transparent"
          [placeholder]="'General.Search' | translate"
          type="search"
        />
      </div>
      <div class="flex">
        <app-basic-button
          *ngIf="selectedIDs.length > 0"
          [btnType]="'Cancel'"
          [btnText]="'Delete'"
          [link]="'trash.svg'"
          (click)="deleteSelected()"
        ></app-basic-button>
        <app-basic-button
          [matMenuTriggerFor]="columnFilterMenu"
          [btnType]="'Cancel fitWidth'"
          [btnText]="''"
          [link]="'filter-1.svg'"
        ></app-basic-button>
      </div>
    </div>
  </div>

  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionState"
    [loadingLabel]="loadingLabel | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
    class="overflow-auto"
  >
    <div class="new-table">
      <table
        @stagger
        [dataSource]="dataSource"
        class="w-full"
        mat-table
        matSort
      >
        <!-- Text Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container
            *ngIf="column.type === 'checkbox'"
            [matColumnDef]="column.property"
          >
            <th
              *matHeaderCellDef
              mat-header-cell
              class="w-8 min-w-8 p-0 text-center"
            >
              <button class="checkbox-item p-0">
                <mat-checkbox
                  color="primary"
                  [checked]="
                    selectedIDs.length == documents?.length &&
                    selectedIDs.length != 0
                  "
                  (change)="toggleAllRows($event.checked)"
                >
                </mat-checkbox>
              </button>
            </th>
            <td
              *matCellDef="let row"
              [ngClass]="column.cssClasses"
              mat-cell
              class="w-8 min-w-8 p-0 text-center"
            >
              <button class="checkbox-item pe-0">
                <mat-checkbox
                  color="primary"
                  [checked]="row?.selected"
                  (change)="toggleRow(row, $event.checked)"
                >
                </mat-checkbox>
              </button>
            </td>
          </ng-container>

          <ng-container
            *ngIf="column.type === 'text' && column.property !== 'category'"
            [matColumnDef]="column.property"
          >
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header
            >
              {{ "Chatbot.Table." + column.label | translate }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ row[column.property] }}
            </td>
          </ng-container>
          <ng-container
            *ngIf="column.type === 'text' && column.property === 'category'"
            [matColumnDef]="column.property"
          >
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header
            >
              {{ "Chatbot.Table." + column.label | translate }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <span
                *ngIf="row[column.property] === 'Security'"
                class="rounded-full px-2 py-1 bg-green-100 h-fit text-green-900 font-semibold text-xs"
                >{{ row[column.property] }}</span
              >
              <span
                *ngIf="row[column.property] === 'Safety'"
                class="rounded-full px-2 py-1 bg-blue-100 h-fit text-blue-900 font-semibold text-xs"
                >{{ row[column.property] }}</span
              >
            </td>
          </ng-container>
          <ng-container
            *ngIf="column.type === 'date'"
            [matColumnDef]="column.property"
          >
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header
            >
              {{ "Chatbot.Table." + column.label | translate }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ row[column.property] | date : "dd-MM-yyyy" }}
            </td>
          </ng-container>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="uppercase" mat-header-cell></th>
          <td *matCellDef="let row" mat-cell>
            <div class="flex">
              <div
                class="flex items-center justify-between p-3 mat-div"
                (click)="downloadDocument(row)"
              >
                <img src="assets/img/resources/document-download.svg" />
              </div>
              <div
                class="flex items-center justify-between p-3 mat-div"
                (click)="onEditDocument(row)"
              >
                <img src="assets/img/resources/edit.svg" />
              </div>
              <div
                class="flex items-center justify-between p-3 mat-div"
                (click)="deleteDocument(row)"
              >
                <img src="assets/img/resources/trash.svg" />
              </div>
            </div>

            <!-- <div (click)="$event.stopPropagation()">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon svgIcon="mat:more_vert"></mat-icon>
              </button>
              <mat-menu
                #menu="matMenu"
                yPosition="below"
                xPosition="before"
                class="shadow-lg shadow-gray-400 rounded-2xl min-h-0"
              >
                <div
                  mat-menu-item
                  class="flex items-center justify-between p-1 mat-div"
                  (click)="viewURL(row)"
                >
                  <button>
                    {{ "General.Buttons.View" | translate }}
                  </button>
                  <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
                </div>
                <div
                  mat-menu-item
                  class="flex items-center justify-between p-1 mat-div"
                  (click)="updateNewUrl(row)"
                >
                  <button>
                    {{ "General.Buttons.Edit" | translate }}
                  </button>
                  <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
                </div>
                <div
                  mat-menu-item
                  class="flex items-center justify-between p-1 mat-div"
                  (click)="deleteDocument(row?.id)"
                >
                  <button>
                    {{ "General.Buttons.Delete" | translate }}
                  </button>
                  <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
                </div>
              </mat-menu>
            </div> -->
          </td>
        </ng-container>

        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <tr
          *matRowDef="let row; columns: visibleColumns"
          @fadeInUp
          class="hover:bg-hover trans-ease-out cursor-pointer"
          mat-row
        ></tr>
      </table>
    </div>
    <div
      *ngIf="dataSource.filteredData.length === 0"
      @scaleFadeIn
      class="flex-auto flex flex-col items-center justify-center"
    >
      <img src="assets/img/resources/empty-state.svg" alt="not found" />
      <h2 class="headline m-0 text-center text-primary">
        {{ "General.EmptyTableList" | translate }}...
      </h2>
    </div>

    <!-- <mat-paginator
        [class.hidden]="dataSource.filteredData.length === 0"
        [pageSizeOptions]="pageSizeOptions"
        [pageSize]="pageSize"
        showFirstLastButtons
        class="sticky left-0"
      ></mat-paginator> -->
    <!-- <ul>
        <li
          *ngFor="
            let item of documents
              | paginate : { itemsPerPage: 10, currentPage: p }
          "
        >
          ...
        </li>
      </ul>
  
      <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
  </app-state-section>
</div>
<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button
    (click)="toggleColumnVisibility(column, $event)"
    *ngFor="let column of filterMenuItems"
    class="checkbox-item mat-menu-item"
  >
    <mat-checkbox
      (click)="$event.stopPropagation()"
      [(ngModel)]="column.visible"
      color="primary"
    >
      {{ "Chatbot.Table." + column.label | translate }}
    </mat-checkbox>
  </button>
</mat-menu>

<ng-template #AddDocumentsPopup>
  <div>
    <div>
      <mat-label class="font-semibold text-lg">{{
        "Chatbot.UploadFile" | translate
      }}</mat-label>
      <p class="py-2">
        To enhance the quality of responses from the bot, we recommend uploading
        your files in Word format (.doc or .docx).
      </p>
    </div>
    <form [formGroup]="documentsForm" class="m-1">
      <div formArrayName="documents" class="mt-2 mb-2">
        <div *ngFor="let document of files?.controls; let i = index">
          <div [formGroupName]="i">
            <file-upload
              class="new-upload-file p-0 border-solid rounded-[15px] outline outline-offset-0"
              [ngClass]="[
                document.valid ? 'min-h-max' : '',
                document.get('isValid').value === false
                  ? 'outline-[#F8A654]'
                  : document.get('isValid').value === true
                  ? 'outline-[#709DFF]'
                  : 'outline-[#D0D5DD]'
              ]"
              formControlName="file"
              [multiple]="false"
              [animation]="true"
              (change)="onChooseFile(i)"
              (drop)="onChooseFile(i)"
              [accept]="'.doc,.docx,.ppt,.pptx,.csv,.xlsx,.pdf'"
            >
              <ng-template
                let-isFileDragDropAvailable="isFileDragDropAvailable"
                #placeholder
              >
                <div
                  class="flex flex-col items-center"
                  *ngIf="document.invalid"
                >
                  <div class="icon">
                    <img src="assets/img/resources/upload-icon.svg" />
                  </div>

                  <ng-container>
                    <div class="upload-text">
                      <p>
                        <strong class="text-primary">Click to upload</strong>
                        or drag and drop
                      </p>
                      <p>doc, docx, ppt, pptx, csv, xlsx, pdf</p>
                    </div>
                  </ng-container>
                </div>
              </ng-template>

              <ng-template let-file="file" let-control="control" #item>
                <div class="flex justify-between p-2">
                  <div class="overlay flex items-center">
                    <img
                      class="mx-1"
                      [src]="
                        document.get('isValid').value === false
                          ? 'assets/img/resources/warning-icon.svg'
                          : 'assets/img/resources/file-icon.svg'
                      "
                    />
                    <div class="text-[#344054]">
                      <p *ngIf="document.get('isValid').value === false">
                        {{ "Chatbot.FileErrorMsg" | translate }}
                      </p>
                      <p>{{ file.name }}</p>
                      <p *ngIf="document.get('isValid').value !== false">
                        {{ file.size | fileSize }}
                      </p>
                    </div>
                  </div>
                  <div class="flex h-6">
                    <img
                      class="mx-1 cursor-pointer"
                      src="assets/img/resources/trash.svg"
                      (click)="control.removeFile(file); resetFileGroup(i)"
                    />
                    <!-- <img
                    class="cursor-pointer"
                    src="assets/img/resources/document-reupload.svg"
                    (click)="control.addFile()"
                  /> -->
                  </div>
                </div>
              </ng-template>
            </file-upload>

            <button
              *ngIf="addFlag && files?.controls.length > 1"
              class="text-red-700 underline font-medium my-1"
              (click)="removeLevel(i)"
              type="button"
            >
              {{ "Chatbot.RemoveDocument" | translate }}
            </button>
          </div>
        </div>
      </div>

      <div class="flex my-3">
        <button
          *ngIf="addFlag"
          class="text-primary underline font-medium flex items-center"
          color="primary"
          type="button"
          (click)="onAddDocument()"
        >
          <span>Add Document</span>
          <mat-icon svgIcon="mat:add" class="p-1"></mat-icon>
        </button>
      </div>

      <div class="flex flex-col">
        <mat-label class="field-label"
          >{{ "Chatbot.Category" | translate }}
          <span
            [ngClass]="
              documentsForm.get('category').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
          >
            *
          </span>
        </mat-label>

        <mat-radio-group
          formControlName="category"
          class="new-radio font-medium"
        >
          <mat-radio-button value="Safety">{{
            "Chatbot.Safety" | translate
          }}</mat-radio-button>
          <mat-radio-button class="ml-3" value="Security"
            >{{ "Chatbot.Security" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="mt-2" *ngIf="isFoundDuplicated().length > 0">
        <mat-checkbox color="primary" formControlName="overwrite">
          {{ "Chatbot.Overwrite" | translate }}
          <span
            [ngClass]="
              documentsForm.get('overwrite').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
          >
            *
          </span>
        </mat-checkbox>
        <p class="overwrite-msg">
          {{ "Chatbot.OverwriteMsg" | translate }}
        </p>
      </div>

      <div class="flex items-center justify-end mt-2">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel'"
          (onClick)="navigateBack()"
        ></app-basic-button>
        <app-basic-button
          [btnType]="'Add'"
          [btnText]="'Save'"
          [isDisabled]="documentsForm.invalid"
          (onClick)="addDocuments()"
        ></app-basic-button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #editDocumentPopup>
  <div [formGroup]="updateDocumentsForm">
    <mat-label class="field-label"
      >{{ "Chatbot.DocumentName" | translate }}
      <span
        [ngClass]="
          updateDocumentsForm.get('name').hasError('required')
            ? 'is-invalid'
            : 'is-valid'
        "
      >
        *
      </span>
    </mat-label>
    <mat-form-field class="input-field" appearance="outline">
      <input
        #name
        [placeholder]="
          ('Placeholder.Insert' | translate) + ' ' + ('Title' | translate)
        "
        formControlName="name"
        matInput
        type="text"
        maxlength="100"
        (blur)="validateName(name.value)"
      />
      <mat-icon
        matSuffix
        svgIcon="mat:info"
        class="text-neutral-400"
        [matTooltip]="'Name should not be duplicated'"
      >
      </mat-icon>
      <mat-error
        *ngIf="updateDocumentsForm.get('name').hasError('nameNotAvailable')"
      >
        <div class="flex justify-flex-start align-flex-center">
          <img class="error-img" src="assets/img/resources/error-icon.svg" />
          <span>{{
            updateDocumentsForm.get("name").getError("nameNotAvailable")
          }}</span>
        </div>
      </mat-error>
      <mat-error *ngIf="updateDocumentsForm.get('name').hasError('required')">
        <div class="flex justify-flex-start align-flex-center">
          <img class="error-img" src="assets/img/resources/error-icon.svg" />
          <span>{{ "Errors.Required" | translate }}</span>
        </div>
      </mat-error>
    </mat-form-field>

    <div class="flex flex-col">
      <mat-label class="field-label"
        >{{ "Chatbot.Category" | translate }}
        <span
          [ngClass]="
            documentsForm.get('category').hasError('required')
              ? 'is-invalid'
              : 'is-valid'
          "
        >
          *
        </span>
      </mat-label>

      <mat-radio-group formControlName="category" class="new-radio font-medium">
        <mat-radio-button value="Safety">{{
          "Chatbot.Safety" | translate
        }}</mat-radio-button>
        <mat-radio-button class="ml-3" value="Security"
          >{{ "Chatbot.Security" | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="flex items-center justify-end mt-2">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="navigateBack()"
    ></app-basic-button>
    <app-basic-button
      [btnType]="'Add'"
      [btnText]="'Save'"
      [isDisabled]="updateDocumentsForm.invalid"
      (onClick)="editDocument()"
    ></app-basic-button>
  </div>
</ng-template>
