<div class="card overflow-auto">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2
      class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none"
    >
      <span>{{ "ViewReportNearMiss.ActionsTitle" | translate }}</span>
    </h2>
    <div
      class="bg-foreground rounded-full px-4 max-w-[300px] flex-auto flex items-center border border-gray-300"
    >
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input
        [formControl]="searchCtrl"
        class="px-4 py-2 border-0 outline-none w-full bg-transparent"
        [placeholder]="'Placeholder.Search' | translate"
        type="search"
      />
    </div>

    <span class="flex-1"></span>

    <button
      [matMenuTriggerFor]="columnFilterMenu"
      class="ml-4 flex-none"
      mat-icon-button
      [matTooltip]="'General.Buttons.FilterColumns' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:filter_list"></mat-icon>
    </button>
    <button
      *ngIf="hasCreateActionPermission | permissionChecker"
      (click)="openCreateActionModal()"
      class="ml-4 flex-none"
      color="primary"
      mat-mini-fab
      [matTooltip]="'AddReportIncident.ReportActions.AddActions' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:add"></mat-icon>
    </button>
  </div>
  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionState"
    [loadingLabel]="loadingLabel | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
      <!-- Text Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container
          *ngIf="column.type === 'image'"
          matColumnDef="closedMedia"
        >
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
            <ngx-avatars
              class="logo box"
              size="32"
              [src]="row['closedMedia']"
              [name]="row['title']"
              [textSizeRatio]="2"
              (clickOnAvatar)="openBigView(row?.id)"
              [round]="true"
              [initialsSize]="2"
            ></ngx-avatars>
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'text'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{
              "AddReportIncident.ReportActions.Table." + column.label
                | translate
            }}&nbsp;
          </th>
          <td
            *matCellDef="let row"
            #div
            class="truncate max-w-0 p-3"
            mat-cell
            [ngClass]="column.cssClasses"
            mat-cell
          >
            <span
              [matTooltip]="
                div?.offsetWidth < div?.scrollWidth
                  ? row[column.property]
                  : null
              "
              >{{ row[column.property] }}</span
            >
          </td></ng-container
        >

        <ng-container
          *ngIf="column.type === 'date' && column.property === 'targetDate'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{
              "AddReportIncident.ReportActions.Table." + column.label
                | translate
            }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] | date : "mediumDate" }}
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'date' && column.property === 'closedDate'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "ActionsTracker.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <span
              *ngIf="
                row?.actionStatus !== ActionStatus.INPROGRESS &&
                row?.actionStatus !== ActionStatus.OVERDUE &&
                row?.actionStatus !== ActionStatus.DUE
              "
            >
              {{ row[column.property] | date : "mediumDate" }}
            </span>
          </td>
        </ng-container>
        <ng-container
          *ngIf="
            column.type === 'boolean' && column.property === 'actionStatus'
          "
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div
              style="width: 100%; position: relative"
              class="flex items-center"
            >
              <mat-icon
                app-click-stop-propagation
                style="width: 35px; height: 35px; cursor: pointer"
                (click)="select.open()"
                svgIcon="mat:keyboard_arrow_down"
                color="primary"
              ></mat-icon>
              {{ "User.Table." + column.label | translate }}
              <mat-select
                [value]="selectedStatus"
                (selectionChange)="onSelectStatus($event)"
                #select
                class="select-options"
                multiple
              >
                <mat-option
                  *ngFor="let statue of ActionStatus | enumToArray"
                  [value]="statue.value"
                >
                  {{ "Enum.ActionStatus." + statue.value | translate }}
                </mat-option>
              </mat-select>
            </div>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div
              class="flex justify-center align-center"
              [attr.id]="row[column.property]"
            >
              <span>
                {{ "Enum.ActionStatus." + row[column.property] | translate }}
              </span>
            </div>
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'boolean' && column.property === 'priority'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div
              style="width: 100%; position: relative"
              class="flex items-center"
            >
              <mat-icon
                app-click-stop-propagation
                style="width: 35px; height: 35px; cursor: pointer"
                (click)="select.open()"
                svgIcon="mat:keyboard_arrow_down"
                color="primary"
              ></mat-icon>
              {{
                "AddReportIncident.ReportActions.Table." + column.label
                  | translate
              }}
              <mat-select
                [value]="selectedPriority"
                (selectionChange)="onSelectPriority($event)"
                #select
                class="select-options"
                multiple
              >
                <mat-option
                  *ngFor="let statue of ActionPriority | enumToArray"
                  [value]="statue.value"
                >
                  {{ "Enum.ActionPriority." + statue.value | translate }}
                </mat-option>
              </mat-select>
            </div>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div class="flex priority" [attr.id]="row[column.property]">
              <span
                [matTooltip]="
                  'Enum.ActionPriority.' + row[column.property] | translate
                "
                >{{
                  "Enum.ActionPriority." + row[column.property] | translate
                }}</span
              >
            </div>
          </td>
        </ng-container>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions" stickyEnd class="bg-white">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let row" mat-cell>
          <div (click)="$event.stopPropagation()">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon svgIcon="mat:more_vert"></mat-icon>
            </button>
            <mat-menu
              #menu="matMenu"
              yPosition="below"
              xPosition="before"
              class="shadow-lg shadow-gray-400 rounded-2xl min-h-0"
            >
              <div
                mat-menu-item
                (click)="viewAction($event, row?.id)"
                class="flex items-center justify-between p-1 mat-div"
              >
                <button>
                  {{ "General.Buttons.View" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
              <div
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                *ngIf="
                  loggedUser.userId === row['createdBy'] &&
                  row['actionStatus'] !== ActionStatus.CLOSE &&
                  (hasUpdateActionPermission | permissionChecker)
                "
                (click)="openEditAction(row?.id)"
              >
                <button>
                  {{ "General.Buttons.Edit" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>

              <div
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                *ngIf="
                  row['actionStatus'] !== ActionStatus.CLOSE &&
                  row['createdBy'] === loggedUser?.userId &&
                  (hasApprovePermissions | permissionChecker)
                "
                (click)="reAssignActionModal($event, row?.id)"
              >
                <button>
                  {{ "General.Buttons.Reassign" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>

              <div
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                *ngIf="
                  loggedUser.userId === row['createdBy'] &&
                  (hasDeleteActionPermission | permissionChecker)
                "
                (click)="removeAction($event, row?.id)"
              >
                <button>
                  {{ "General.Buttons.Delete" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
              <div
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                *ngIf="
                  row['actionStatus'] !== ActionStatus.CLOSE &&
                  row['assignToId'] === loggedUser?.userId &&
                  (hasReadPermissions | permissionChecker)
                "
                (click)="openCloseModal($event, row?.id)"
              >
                <button>
                  {{ "General.Buttons.Close" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: visibleColumns"
        @fadeInUp
        class="hover:bg-hover trans-ease-out cursor-pointer"
        mat-row
        (click)="viewAction($event, row?.id)"
      ></tr>
    </table>
    <div
      *ngIf="dataSource.filteredData.length === 0"
      @scaleFadeIn
      class="flex-auto flex flex-col items-center justify-center"
    >
      <img src="assets/img/resources/empty-state.svg" alt="not found" />
      <h2 class="headline m-0 text-center text-primary">
        {{ "General.EmptyTableList" | translate }}...
      </h2>
    </div>

    <mat-paginator
      [class.hidden]="dataSource.filteredData.length === 0"
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      showFirstLastButtons
      class="sticky left-0"
    ></mat-paginator>
  </app-state-section>
</div>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button
    (click)="toggleColumnVisibility(column, $event)"
    *ngFor="let column of columns"
    class="checkbox-item mat-menu-item"
  >
    <mat-checkbox
      (click)="$event.stopPropagation()"
      [(ngModel)]="column.visible"
      color="primary"
    >
      {{ "AddReportIncident.ReportActions.Table." + column.label | translate }}
    </mat-checkbox>
  </button>
</mat-menu>

<ng-template #createActionModal>
  <app-state-section
    [flexibleHeight]="true"
    [state]="sectionStateModal"
    [loadingLabel]="'' | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <form [formGroup]="actionForm">
      <div class="flex items-center" mat-dialog-title>
        <h2
          *ngIf="actionForm.get('action').value"
          class="headline m-0 flex-auto"
        >
          {{ actionForm.get("action").value }}
        </h2>
        <h2
          *ngIf="!actionForm.get('action').value"
          class="headline m-0 flex-auto"
        >
          {{ "AddReportIncident.ReportActions.AddActions" | translate }}
        </h2>
        <button
          class="text-secondary"
          mat-dialog-close
          mat-icon-button
          type="button"
        >
          <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
      </div>
      <mat-divider class="-mx-6 text-border mb-2"></mat-divider>
      <mat-dialog-content>
        <div class="field-full flex column">
          <mat-label class="field-label"
            >{{
              "AddReportIncident.ReportActions.ActionModal.Action" | translate
            }}
            <span
              [ngClass]="
                actionForm.invalid &&
                actionForm.get('action').hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
              >*</span
            >
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              [placeholder]="'Placeholder.AddText' | translate"
              matInput
              type="text"
              formControlName="action"
            />
            <mat-error
              *ngIf="
                actionForm.invalid &&
                actionForm.get('action').hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
            <mat-error
              *ngIf="
                actionForm.invalid &&
                actionForm.get('action').hasError('whitespace')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.InValidData" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex flex-wrap justify-between">
          <!-- responsible user -->
          <div class="field flex column">
            <mat-label class="field-label"
              >{{
                "AddReportIncident.ReportActions.Table.Responsibility"
                  | translate
              }}
              <span
                [ngClass]="
                  actionForm.invalid &&
                  actionForm.get('assignToId').hasError('required')
                    ? 'is-invalid'
                    : 'is-valid'
                "
                >*</span
              >
            </mat-label>
            <mat-form-field class="input-field" appearance="outline">
              <mat-select
                ngxMatSelect
                #msf1="ngxMatSelect"
                [displayMember]="'fullName'"
                [valueMember]="'userId'"
                [source]="responsibles"
                [useInfiniteScroll]="true"
                formControlName="assignToId"
                [multiple]="false"
                [hasPermission]="hasCreateUsersPermissions | permissionChecker"
                [title]="'Users'"
                [manageRoute]="'/user-management/users'"
                [placeholder]="'Placeholder.Select' | translate"
              >
                <mat-option
                  [value]="item.userId"
                  *ngFor="let item of msf1.filteredSource"
                >
                  {{ item.fullName }}
                </mat-option>
              </mat-select>
              <mat-error>
                <div class="flex justify-flex-start align-flex-center">
                  <img
                    class="error-img"
                    src="assets/img/resources/error-icon.svg"
                  />
                  <span>{{ "Errors.Required" | translate }}</span>
                </div>
              </mat-error>
            </mat-form-field>
          </div>
          <!-- responsible user -->
          <div class="field flex column">
            <mat-label class="field-label"
              >{{
                "AddReportIncident.ReportActions.Table.Priority" | translate
              }}
              <span
                [ngClass]="
                  actionForm.invalid &&
                  actionForm.get('priority').hasError('required')
                    ? 'is-invalid'
                    : 'is-valid'
                "
                >*</span
              >
            </mat-label>
            <mat-form-field class="input-field" appearance="outline">
              <mat-select
                [placeholder]="'Placeholder.Select' | translate"
                formControlName="priority"
              >
                <mat-option
                  *ngFor="let pro of ActionPriority | enumToArray"
                  [value]="pro.value"
                >
                  {{
                    "Enum.ActionPriority." + pro.value | translate
                  }}</mat-option
                >
              </mat-select>
              <mat-error>
                <div class="flex justify-flex-start align-flex-center">
                  <img
                    class="error-img"
                    src="assets/img/resources/error-icon.svg"
                  />
                  <span>{{ "Errors.Required" | translate }}</span>
                </div>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-wrap items-center">
          <div class="field flex column">
            <mat-label class="field-label"
              >{{
                "AddReportIncident.ReportActions.Table.TargetDate" | translate
              }}
              <span
                [ngClass]="
                  actionForm.invalid &&
                  actionForm.get('targetDate').hasError('required')
                    ? 'is-invalid'
                    : 'is-valid'
                "
                >*</span
              >
            </mat-label>
            <mat-form-field appearance="outline">
              <input
                [placeholder]="'Placeholder.SelectDate' | translate"
                matInput
                [matDatepicker]="targetDate"
                (click)="targetDate.open()"
                formControlName="targetDate"
                [min]="minDate"
              />
              <mat-datepicker-toggle matSuffix [for]="targetDate"
                >here's where you wii add reassign</mat-datepicker-toggle
              >
              <mat-datepicker #targetDate></mat-datepicker>
              <mat-error>
                <div class="flex justify-flex-start align-flex-center">
                  <img
                    class="error-img"
                    src="assets/img/resources/error-icon.svg"
                  />
                  <span>{{ "Errors.Required" | translate }}</span>
                </div>
              </mat-error>
            </mat-form-field>
          </div>
          <div
            class="ml-2"
            *ngIf="singleAction?.closedMedia && formMode !== 'create'"
          >
            <ngx-avatars
              class="cursor-pointer"
              size="60"
              [src]="getAttachmentImage(singleAction?.closedMedia)"
              [name]="getAttachementTypeName(singleAction?.closedMedia)"
              [textSizeRatio]="2"
              [round]="false"
              [initialsSize]="2"
              [matTooltip]="singleAction?.closedMediaName"
              [cornerRadius]="10"
              [borderColor]="'#1F36B4'"
              (clickOnAvatar)="openBigViewImage(singleAction?.closedMedia)"
            >
            </ngx-avatars>
            <a
              mat-button
              color="primary"
              [href]="singleAction?.closedMedia"
              download
            >
              <mat-icon class="mr-2" svgIcon="mat:download"></mat-icon>
              Download Media
            </a>
          </div>
        </div>
        <div class="flex-row" *ngIf="formMode === 'view'">
          <mat-label class="field-label">Remarks</mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <textarea
              matInput
              rows="5"
              formControlName="remarks"
              [placeholder]="'enter remarks'"
              matInput
            >
            </textarea>
          </mat-form-field>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions align="end">
        <div class="flex items-center">
          <app-basic-button
            [btnType]="'Cancel'"
            [btnText]="'Cancel'"
            (onClick)="closeDialog()"
          ></app-basic-button>
          <app-basic-button
            *ngIf="formMode === 'create' || formMode === 'edit'"
            [btnType]="'Add'"
            [btnText]="formMode === 'edit' ? 'Save' : 'Add'"
            [isDisabled]="!actionForm.valid"
            (onClick)="formMode === 'edit' ? editAction() : createAction()"
          ></app-basic-button>
        </div>
      </mat-dialog-actions>
    </form>
  </app-state-section>
</ng-template>
<ng-template #reassignActionModal>
  <app-state-section
    [flexibleHeight]="true"
    [state]="sectionStateModal"
    [loadingLabel]="'' | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <form [formGroup]="reassignForm">
      <div class="flex items-center" mat-dialog-title>
        <h2 class="headline m-0 flex-auto">Reassign Action</h2>
        <button
          class="text-secondary"
          mat-dialog-close
          mat-icon-button
          type="button"
        >
          <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
      </div>
      <mat-divider class="-mx-6 text-border mb-2"></mat-divider>
      <mat-dialog-content>
        <div class="field-full flex column">
          <!-- responsible user -->
          <mat-label class="field-label"
            >{{
              "AddReportIncident.ReportActions.Table.Responsibility" | translate
            }}
          </mat-label>
          <mat-form-field appearance="outline">
            <mat-select
              ngxMatSelect
              #msf1="ngxMatSelect"
              [displayMember]="'fullName'"
              [valueMember]="'userId'"
              [source]="responsibles"
              [useInfiniteScroll]="true"
              formControlName="AssigneeId"
              [multiple]="false"
              [manageRoute]="'/user-management/users'"
              [placeholder]="'Placeholder.Select' | translate"
            >
              <mat-option
                [value]="item.userId"
                *ngFor="let item of msf1.filteredSource"
              >
                {{ item.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-label class="field-label"
            >{{
              "AddReportIncident.ReportActions.Table.TargetDate" | translate
            }}
          </mat-label>
          <mat-form-field appearance="outline">
            <input
              [placeholder]="'Placeholder.SelectDate' | translate"
              matInput
              [matDatepicker]="targetDate"
              (click)="targetDate.open()"
              formControlName="targetDate"
              [min]="minDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="targetDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #targetDate></mat-datepicker>
          </mat-form-field>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions align="end">
        <div class="flex items-center">
          <app-basic-button
            [btnType]="'Cancel'"
            [btnText]="'Cancel'"
            (onClick)="closeDialog()"
          ></app-basic-button>
          <app-basic-button
            [btnType]="'Add'"
            [btnText]="'Submit'"
            [isDisabled]="!reassignForm.valid"
            (onClick)="reAssignAction()"
          ></app-basic-button>
        </div>
      </mat-dialog-actions>
    </form>
  </app-state-section>
</ng-template>

<ng-template #closeModal>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">Close Action</h2>
    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div>
    <form [formGroup]="closeForm">
      <div class="flex-row">
        <mat-label class="field-label">Remarks</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <textarea
            matInput
            rows="5"
            formControlName="remarks"
            [placeholder]="'enter remarks'"
            matInput
          >
          </textarea>
        </mat-form-field>
      </div>
      <div class="flex column">
        <file-upload
          formControlName="media"
          [multiple]="false"
          [animation]="true"
        ></file-upload>
      </div>
    </form>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
    <app-basic-button
      [btnType]="'Add'"
      [btnText]="'Submit'"
      [isDisabled]="!closeForm.valid"
      (onClick)="closeAction()"
    ></app-basic-button>
  </div>
</ng-template>

<ng-template #imageBigView>
  <div class="flex items-center" mat-dialog-title>
    <img [src]="singleAction?.closedMedia" class="avatar mr-5" />
    <h2 class="headline m-0 flex-auto">{{ singleAction?.title }}</h2>
    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div class="flex">
    <div class="">
      <img [src]="singleAction?.closedMedia" [alt]="singleAction?.title" />
    </div>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
  </div>
</ng-template>
