<div
  class="container-modal"
  [ngClass]="{
    destructive: confirmData?.type == ConfirmationType.DestructiveAction
  }"
  *ngIf="isVisible"
>
  <div class="popup">
    <mat-icon
      class="close_icon"
      (click)="cancelAlert()"
      svgIcon="mat:close"
    ></mat-icon>
    <div class="indicator bg-primary"></div>
    <div *ngIf="confirmData?.headerTitle" class="title">
      {{ confirmData?.headerTitle }}
    </div>
    <div *ngIf="confirmData?.message" class="message">
      {{ confirmData?.message }}
    </div>
    <div
      *ngIf="confirmData?.checkboxOption"
      class="checkbox-option text-primary"
    >
      <mat-checkbox
        #checkbox
        value="true"
        (change)="checkboxValue = !checkboxValue"
        >{{ confirmData?.checkboxOption }}</mat-checkbox
      >
    </div>
    <div class="actions">
      <app-basic-button
        [btnType]="'Cancel'"
        [btnText]="confirmData?.cancelActionText"
        (onClick)="onActionButtonClicked(false, false)"
      ></app-basic-button>
      <app-basic-button
        [btnType]="'Add'"
        [btnText]="confirmData?.mainActionText"
        (onClick)="onActionButtonClicked(true, checkboxValue)"
      ></app-basic-button>

    </div>
  </div>
</div>
