<app-card [title]="'organization.Title' | translate">
  <div body class="flex justify-between flex-wrap-reverse" *ngIf="orgInfo">
    <div class="flex-1">
      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{ orgInfo?.organizationName }}</p>
          <p class="m-0 caption text-hint">Organization Name</p>
        </div>
      </div>
      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{ orgInfo?.noOfEligibleCompanies }}</p>
          <p class="m-0 caption text-hint">Eligible Companies</p>
        </div>
      </div>
      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{ orgInfo?.noOfEligibleUsers }}</p>
          <p class="m-0 caption text-hint">Eligible Users</p>
        </div>
      </div>
      <div *ngIf="!isAdmin">
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">
              {{ orgInfo?.updatedOn | date : "dd-MM-yyyy hh:mm:ss" }}
            </p>
            <p class="m-0 caption text-hint">Updated At</p>
          </div>
        </div>
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">
              {{ orgInfo?.createdOn | date : "dd-MM-yyyy hh:mm:ss" }}
            </p>
            <p class="m-0 caption text-hint">Created On</p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-1">
      <div @fadeInUp class="flex items-center">
        <p class="text-left font-bold text-primary mr-4 rtl:ml-3">
          {{ "General.Status" | translate }}
        </p>
        <app-boolean-view
          width="105px"
          [value]="orgInfo?.status === 'ACTIVE'"
          [text]="
            orgInfo?.status === 'ACTIVE'
              ? ('General.Active' | translate)
              : ('General.Inactive' | translate)
          "
        ></app-boolean-view>
      </div>
      <div
        @fadeInUp
        class="avatar-container cursor-pointer"
        (click)="fullImageView()"
      >
        <ngx-avatars
          bgColor="#2c3e5000"
          fgColor="#222222"
          class="avatar box"
          size="200"
          [ngStyle]="{
            'border-color':
              orgInfo?.status === 'ACTIVE' ? ACTIVE_COLOR : INACTIVE_COLOR
          }"
          [src]="orgInfo?.organizationImage"
          [name]="orgInfo?.organizationName | uppercase"
          [textSizeRatio]="3"
          [initialsSize]="2"
          [round]="true"
        ></ngx-avatars>
        <div
          class="label"
          [ngStyle]="{
            'background-color':
              orgInfo?.status === 'ACTIVE' ? ACTIVE_COLOR : INACTIVE_COLOR
          }"
        >
          <span *ngIf="orgInfo?.status === 'ACTIVE'">{{
            "General.Active" | translate | uppercase
          }}</span>
          <span *ngIf="orgInfo?.status === 'INACTIVE'">{{
            "General.Inactive" | translate | uppercase
          }}</span>
        </div>
      </div>
      <div class="my-2">
        <mat-label class="field-label">{{
          "organization.BasicForm.Theme" | translate
        }}</mat-label>

        <div class="flex items-center">
          <div class="border border-gray-200 p-2 m-1 w-4/12 rounded-lg">
            <input
              type="color"
              disabled
              [value]="orgInfo?.themeColor ? orgInfo?.themeColor : '#1F36B4'"
            />
            <label for="head" class="p-2">Primary</label>
          </div>
          <img
            src="/assets/img/resources/info-icon.png"
            class="mx-2"
            [matTooltip]="
              'Hint: Please, avoid bright colors for better contrast.'
            "
            [matTooltipPosition]="'right'"
          />
        </div>
        <div class="flex items-center">
          <div class="border border-gray-200 p-2 m-1 w-4/12 rounded-lg">
            <input
              type="color"
              id="body"
              disabled
              [value]="
                orgInfo?.secondaryColor ? orgInfo?.secondaryColor : '#ffffff'
              "
            />
            <label for="body" class="p-2">Secondary</label>
          </div>
          <img
            src="/assets/img/resources/info-icon.png"
            class="mx-2"
            [matTooltip]="
              'Hint: For better visibility, better to be black or white.'
            "
            [matTooltipPosition]="'right'"
          />
        </div>
      </div>
      <div *ngIf="!isAdmin">
        <button
          @fadeInUp
          mat-flat-button
          class="verify-button"
          (click)="resendVerificationCode()"
        >
          <span>{{ "General.Buttons.ResendCode" | translate }}</span>
        </button>
        <br />
        <button
          @fadeInUp
          mat-flat-button
          class="verify-button"
          (click)="openChangeEmailModal()"
        >
          <span>{{ "General.Inputs.Email" | translate }}</span>
        </button>
      </div>
    </div>
  </div>
  <div
    body
    class="py-3 flex items-center"
    *ngIf="
      orgLicenseInfo && orgLicenseInfo?.accessControlLicenseQuota && !isAdmin
    "
  >
    <div
      @scaleIn
      class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
    >
      <mat-icon
        class="icon-sm"
        svgIcon="mat:keyboard_double_arrow_right"
      ></mat-icon>
    </div>
    <div @fadeInRight>
      <p class="m-0 body-1">{{ orgLicenseInfo?.accessControlLicenseQuota }}</p>
      <p class="m-0 caption text-hint">License Quota</p>
    </div>
  </div>
  <div body class="py-3 flex items-center" *ngIf="orgInfo">
    <div
      @scaleIn
      class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
    >
      <mat-icon
        class="icon-sm"
        svgIcon="mat:keyboard_double_arrow_right"
      ></mat-icon>
    </div>
    <div @fadeInRight>
      <p class="m-0 body-1">{{ orgInfo?.description }}</p>
      <p class="m-0 caption text-hint">Description</p>
    </div>
  </div>
</app-card>
<div class="mt-3" *ngIf="orgInfo?.adminDto">
  <app-card [title]="'organization.Admin.AdminInfo' | translate">
    <div body class="flex justify-between flex-wrap-reverse my-3">
      <div class="flex-1">
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">{{ orgInfo?.adminDto["firstName"] }}</p>
            <p class="m-0 caption text-hint">
              {{ "organization.Admin.FirstName" | translate }}
            </p>
          </div>
        </div>
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">{{ orgInfo?.adminDto["lastName"] }}</p>
            <p class="m-0 caption text-hint">
              {{ "organization.Admin.LastName" | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex-1">
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">{{ orgInfo?.adminDto["phoneNumber"] }}</p>
            <p class="m-0 caption text-hint">
              {{ "organization.Admin.Phone" | translate }}
            </p>
          </div>
        </div>
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">{{ orgInfo?.adminDto["email"] }}</p>
            <p class="m-0 caption text-hint">
              {{ "organization.Admin.Email" | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </app-card>
</div>
<ng-template #orgImageBigView>
  <div class="flex items-center" mat-dialog-title>
    <img [src]="orgInfo?.organizationImage" class="avatar mr-5" />
    <h2 class="headline m-0 flex-auto">{{ orgInfo?.organizationName }}</h2>
    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div class="flex">
    <div class="image_wrapper">
      <img
        [src]="orgInfo?.organizationImage"
        [alt]="orgInfo?.organizationName"
      />
    </div>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
  </div>
</ng-template>

<!-- Change Email Model -->
<ng-template #emailChangeModal>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">
      {{ "General.Inputs.Email" | translate }}
    </h2>
    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div>
    <form [formGroup]="emailChangeForm">
      <div class="">
        <mat-label class="field-label">Email</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            matInput
            type="email"
            [placeholder]="'Placeholder.EnterEmail' | translate"
            formControlName="email"
            autocomplete="email"
          />
          <mat-error
            *ngIf="
              emailChangeForm.invalid &&
              emailChangeForm.get('email').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              emailChangeForm.invalid &&
              emailChangeForm.get('email').hasError('invalidEmail')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.InvalidEmail" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
    <app-basic-button
      [btnType]="'Add'"
      [btnText]="'Submit'"
      [isDisabled]="!emailChangeForm.valid"
      (onClick)="changeEmail()"
    ></app-basic-button>
  </div>
</ng-template>
