import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { BaseApi } from 'src/backend/api/base-api';

@Injectable({
  providedIn: 'root',
})
export class MsdsService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  GetMsdsList(data?: any): Observable<any> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += `sortField=` + data?.sortField;
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.flammableSeverity) {
      if (params.length > 0) {
        params += '&';
      }
      params += `flammableSeverity=` + data?.flammableSeverity;
    }
    if (data?.reactiveSeverity) {
      if (params.length > 0) {
        params += '&';
      }
      params += `reactiveSeverity=` + data?.reactiveSeverity;
    }
    if (data?.healthySeverity) {
      if (params.length > 0) {
        params += '&';
      }
      params += `healthySeverity=` + data?.healthySeverity;
    }
    if (data?.specialHazard) {
      if (params.length > 0) {
        params += '&';
      }
      params += `specialHazard=` + data?.specialHazard;
    }
    if (data?.sortDirection) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_MSDS_LIST + '?' + params)
    );
  }

  GetMsdsById(id: number) {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_MSDS_BY_ID + '/' + id)
    );
  }

  CreateMsds(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_CREATE_MSDS),
      data
    );
  }

  EditMsds(data: any): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_EDIT_MSDS),
      data
    );
  }

  PublishMsds(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_PUBLISH_MSDS),
      data
    );
  }

  RejectMsds(data: any): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_REJECT_MSDS),
      data
    );
  }

  GetApproversList(): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_APPROVERS_LIST)
    );
  }

  AddAdditionalApprovers(data: any): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_PENDING_APPROVERS),
      data
    );
  }

  ApproveMsds(id: any): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_APPROVE_MSDS + '/' + id)
    );
  }

  DeleteMsds(id: any): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(Constants.API_DELETE_MSDS + '/' + id)
    );
  }

  DeleteMsdsAttachments(id: any): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(Constants.API_DELETE_MSDS_ATTACHMENTS + '/' + id)
    );
  }
}
