import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-basic-button',
  templateUrl: './basic-button.component.html',
  styleUrls: ['./basic-button.component.scss']
})
export class BasicButtonComponent implements OnInit {

  @Input() btnText: string = 'Default';
  @Input() btnType: string = 'cancel';
  @Input() isDisabled: boolean = false;
  @Input() link: String = '';
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  onClickEvent(): void {
    this.onClick.emit("Clicked");
  }

}
