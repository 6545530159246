import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil, tap } from 'rxjs/operators';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { Organization } from '../../models/organization';
import { MatDialog } from '@angular/material/dialog';
import { ChatbotService } from 'src/backend/services/chatbot/chatbot.service';
import { OrganizationStatus } from 'src/@hodhod/common/enum';
@UntilDestroy()
@Component({
  selector: 'app-trusted-sources-list',
  templateUrl: './trusted-sources-list.component.html',
  styleUrls: ['./trusted-sources-list.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class TrustedSourcesListComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('AddUpdteURLPopup') AddUpdteURLPopup: TemplateRef<any>;
  @ViewChild('viewURLPopup') viewURLPopup: TemplateRef<any>;
  p: number = 1;
  collection: any[];
  columns: TableColumn<Organization>[] = [
    {
      label: 'Checkbox',
      property: 'Checkbox',
      type: 'checkbox',
      visible: true,
      cssClasses: ['font-bold'],
    },
    {
      label: 'Title',
      property: 'name',
      type: 'text',
      visible: true,
      cssClasses: ['font-bold'],
    },
    {
      label: 'URL',
      property: 'url',
      type: 'text',
      visible: true,
    },
    {
      label: 'Category',
      property: 'category',
      type: 'text',
      visible: true,
    },
    {
      label: 'CreatedAt',
      property: 'created_at',
      type: 'date',
      visible: true,
    },
    {
      label: 'UpdatedAt',
      property: 'updated_at',
      type: 'date',
      visible: true,
    },
    {
      label: 'Activation',
      property: 'activation',
      type: 'boolean',
      visible: true,
    },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];
  company_id;
  selectedIDs = [];
  nameUpdate = '';
  nameAvailablity: boolean = true;
  nameNotAvailabe: string;
  URLToView: string = '';
  public pageSize = Constants.PAGE_SIZE;
  public pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  public dataSource = new MatTableDataSource<Organization>([]);
  selection = new SelectionModel<any>(true, []);
  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  timer = null;
  trustedSourcesForm: FormGroup;
  loadingLabel: string = '';
  urlsFormArray: FormArray;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  sourcesForm: FormGroup;
  addFlag: boolean;
  OrganizationStatuses = OrganizationStatus;

  public selectedOrganizationStatus: string[] = [];
  public filterMenuItems: any[] = [];

  private translationsList: any = {};
  constructor(
    private translate: TranslateService,
    private fb: FormBuilder,
    private feedBackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService,
    private dialog: MatDialog,
    private clipboard: Clipboard,
    private chatbotService: ChatbotService
  ) {
    super();
    this.translate
      .get([
        'Errors',
        'Success',
        'confirmDeactiveRecord',
        'Role',
        'User',
        'StaticPages',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit(): void {
    this.company_id = localStorage.getItem('company_id');
    this.filterMenuItems = this.columns.slice(1);
    this.sourcesForm = this.fb.group({
      urls: this.fb.array([]),
    });

    this.urlsFormArray = this.sourcesForm.get('urls') as FormArray;
    this.dataSource.sort = this.sort;

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getData();
    this.onAddNewURL();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  toggleAllRows(checked: boolean) {
    if (checked) {
      this.collection.map((link) => {
        link.selected = true;
      });
      this.selectedIDs = this.collection.map((item) => item.id);
    } else {
      this.collection.map((link) => {
        link.selected = false;
      });
      this.selectedIDs = [];
    }
  }
  toggleRow(row: any, checked) {
    if (checked) {
      row.selected = true;
      this.selectedIDs.push(row.id);
    } else {
      row.selected = false;
      this.selectedIDs = this.selectedIDs.filter((item) => item !== row.id);
    }
  }
  validateName(string: any, i) {
    this.urlsFormArray.at(i).get('name');
    this.chatbotService.checkavAilability(this.company_id, string).subscribe({
      next: (res) => {
        if (res?.available) {
        } else {
          this.urlsFormArray
            .at(i)
            .get('name')
            ?.setErrors({
              nameNotAvailable: res?.message || 'Name is not available',
            });
        }
      },
    });
  }
  checkNameAvailablity(value, i) {
    if (value) {
      if (value !== this.nameUpdate) {
        this.validateName(value, i);
      }
    } else {
      this.validateName(value, i);
    }
  }
  uniqueinputValidator(urlsArray: FormArray, input): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const currentUrl = control.value;

      // Check if the entered URL already exists in the form array (excluding the current control)
      const isDuplicate = urlsArray.controls.some((group) => {
        return (
          group.get(input)?.value === currentUrl && group !== control.parent
        );
      });
      return isDuplicate ? { duplicate: true } : null;
    };
  }
  createUrlForm(): FormGroup {
    return this.fb.group({
      name: new FormControl('', [
        Validators.required,
        this.uniqueinputValidator(this.urlsFormArray, 'name'),
      ]),
      url: new FormControl('', [
        Validators.required,
        Validators.pattern('^(https?://).+'),
        this.uniqueinputValidator(this.urlsFormArray, 'url'),
      ]),
      category: new FormControl('', Validators.required),
    });
  }
  getData() {
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.chatbotService.getLinks(this.company_id).subscribe({
      next: (response) => {
        this.dataSource = new MatTableDataSource(response);
        this.collection = response;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    // this.paginator.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      // this.getData();
      this.dataSource = new MatTableDataSource(
        this.collection.filter((url) => url.name.includes(value))
      );
    }, 700);
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  removeLevel(i: number) {
    if (i >= 0) {
      this.urlsFormArray.removeAt(i);
    }
  }
  createNewUrl() {
    this.urlsFormArray.controls = [];
    this.onAddNewURL();
    this.addFlag = true;
    this.sourcesForm.reset();
    this.dialog.open(this.AddUpdteURLPopup, {
      height: 'auto',
      width: '30%',
      maxHeight: '90vh',
      disableClose: false,
    });
  }
  addURL() {
    let data = {
      urls: this.urlsFormArray.value,
      company_id: this.company_id,
      created_by_user_id: '',
    };
    this.dialog.closeAll();
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.chatbotService.addLinks(data).subscribe({
      next: (res) => {
        this.getData();
        if (res?.created_urls.length > 0) {
          this.feedBackService.showFeedback(
            new FeedbackModel(
              FeedbackType.Success,
              res?.created_urls.length +
                (res?.created_urls.length > 1 ? ' Links ' : ' Link ') +
                'Created Successfully'
            )
          );
        }
        if (res['existing_urls']?.count > 0) {
          this.feedBackService.showFeedback(
            new FeedbackModel(
              FeedbackType.Failure,
              res['existing_urls']?.count +
                (res['existing_urls']?.count > 1 ? ' Links ' : ' Link ') +
                'Already exists'
            )
          );
        }
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
        this.dialog.closeAll();
      },
    });
  }
  updateNewUrl(urlObj) {
    this.nameUpdate = urlObj.name;
    this.urlsFormArray.controls = [];
    this.onAddNewURL();
    this.sourcesForm.get('urls').patchValue([urlObj]);
    this.addFlag = false;
    this.URLToView = urlObj;
    this.dialog.open(this.AddUpdteURLPopup, {
      height: 'auto',
      width: '30%',
      maxHeight: '90vh',
      disableClose: false,
    });
  }
  updateUrl() {
    let id = this.URLToView['id'];
    let data = this.sourcesForm.get('urls').value[0];
    data.updated_by_user_id = '';
    this.dialog.closeAll();
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.chatbotService.updateLinks(data, id).subscribe({
      next: (res) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, 'Updated Successfully')
        );
        this.sectionState = SectionStateStatus.Ready;
        this.getData();
      },
      error: (res) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, res?.error['detail'])
        );
        this.sectionState = SectionStateStatus.Ready;
        this.dialog.closeAll();
      },
    });
  }
  copyMessage(message: string) {
    this.clipboard.copy(message['url']);
    this.feedBackService.showFeedback(
      new FeedbackModel(FeedbackType.Success, 'Link Copied Successfully')
    );
  }
  navigateBack() {
    this.dialog.closeAll();
  }
  viewURL(urlObj) {
    this.URLToView = urlObj;
    this.dialog.open(this.viewURLPopup, {
      height: 'auto',
      width: 'auto',
      maxHeight: '90vh',
      disableClose: false,
    });
  }
  deleteURL(id) {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['Role']['UpdateConfirm']['Title'],
          this.translationsList['Role']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.chatbotService.deleteLink(id).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.selectedIDs = [];
              this.getData();
              this.sectionState = SectionStateStatus.Ready;
            },
            error: (error) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, 'Faild to Remove')
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }
  deleteAll() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['Role']['UpdateConfirm']['Title'],
          this.translationsList['Role']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          if (this.selectedIDs.length == this.collection?.length) {
            this.chatbotService.deleteAllLinks(this.company_id).subscribe({
              next: (res) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, res?.message)
                );
                this.selectedIDs = [];
                this.getData();
                this.sectionState = SectionStateStatus.Ready;
              },
              error: (error) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.sectionState = SectionStateStatus.Ready;
              },
            });
          } else {
            this.chatbotService
              .deleteSelectedLinks({ url_ids: this.selectedIDs })
              .subscribe({
                next: (res) => {
                  this.feedBackService.showFeedback(
                    new FeedbackModel(FeedbackType.Success, res?.message)
                  );
                  this.selectedIDs = [];
                  this.getData();
                  this.sectionState = SectionStateStatus.Ready;
                },
                error: (error) => {
                  this.feedBackService.showFeedback(
                    new FeedbackModel(FeedbackType.Failure, error?.message)
                  );
                  this.sectionState = SectionStateStatus.Ready;
                },
              });
          }
        }
      });
  }
  onAddNewURL() {
    this.urlsFormArray.push(this.createUrlForm());
  }
}
