import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from 'src/@hodhod/common/constants';
import { generateGuid } from 'src/@hodhod/common/custom_methods';
import { Gender, ReportingTypes, RiskMatrix } from 'src/@hodhod/common/enum';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { BaseApi } from 'src/backend/api/base-api';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import {
  CreateInstanceParam,
  SetInstanceParam,
  WorkFlow,
  WorkFlowByScreenParam,
} from 'src/backend/models/work-flows/work-flow';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { IncidentCategoryService } from 'src/backend/services/incident-category/incident-category.service';
import { IncidentClassificationService } from 'src/backend/services/incident-classification/incident-classification.service';
import { MachineEquipmentService } from 'src/backend/services/machine-and-equipment/machine-equipment.service';
import { NatureOfInjuryService } from 'src/backend/services/nature-of-injuiry/nature-of-injury.service';
import { IncidentReportService } from 'src/backend/services/reportings/incident-report.service';
import { UserService } from 'src/backend/services/user.service';
import { WorkFlowService } from 'src/backend/services/work-flow.service';
import { BodySkeletonComponent } from '../../components/body-skeleton/body-skeleton.component';
import * as moment from 'moment';
import { PpeService } from 'src/backend/services/ppe-services/ppe.service';
import { ShiftsService } from 'src/backend/services/shifts/shifts.service';
import { Shift } from 'src/app/company-setup/models/shift';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { RootCauseService } from 'src/backend/services/root-cause/root-cause.service';

@Component({
  selector: 'app-incident-report-form',
  templateUrl: './incident-report-form.component.html',
  styleUrls: ['./incident-report-form.component.scss'],
})
export class IncidentReportFormComponent implements OnInit, OnChanges {
  @Input() selectedIncident: any = null;
  @Output() formSubmit = new EventEmitter<any>();

  @ViewChild('bodySkeletonComponent')
  bodySkeletonComponent: BodySkeletonComponent;
  shifts: Shift[];
  Gender = Gender;
  minDate = new Date();
  RiskMatrix = RiskMatrix;
  reportIncidentForm: FormGroup;
  injuredPersonFormArray: FormArray;
  private destroy$ = new Subject();
  public translationsList: any = {};
  public departments: any[] = [];
  public subDepartments: any[] = [];
  public typeOfIncidents: any[] = [];
  public machines: any[] = [];
  public incidentClassifications: any[] = [];
  public natureOfInjuries: any[] = [];
  public users: any[] = [];
  public ppes: any;
  mintime: string;
  public selectedPpes: any[] = [];
  public images: any[] = [];
  public selectedImg: any;

  hasCreateTypeOfIncidentsPermission =
    ApplicationPermission.CONTENT_REPORTING_INCIDENTCATEGORY_CREATE;
  hasCreateIncidentClassificationsPermission =
    ApplicationPermission.CONTENT_REPORTING_INCIDENTCLASSIFICATION_CREATE;
  hasCreateMachinesPermission = ApplicationPermission.MACHINE_CREATE;
  hasCreateReasonsPermission =
    ApplicationPermission.CONTENT_REPORTING_REASONS_CREATE;
  hasCreateShiftsPermission = ApplicationPermission.SHIFT_CREATE;
  hasDepartmentPermission = ApplicationPermission.DEPARTMENTS_CREATE;
  hasCreateUserPermission = ApplicationPermission.USER_CREATE;
  hasCreateNatureOfInjuryPermission =
    ApplicationPermission.CONTENT_REPORTING_NATUREINJURY_CREATE;
  hasCreatePpesPermission = ApplicationPermission.PPE_CREATE;
  hasRootCauseCreatePermission =
    ApplicationPermission.CONTENT_REPORTING_ROOTCAUSE_CREATE;
  hasRootCauseUpdatePermission =
    ApplicationPermission.CONTENT_REPORTING_ROOTCAUSE_UPDATE;

  reportType = ReportingTypes.Incident;
  rootCauses: any[] = [];

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private loadingService: LoadingService,
    private departmentService: DepartmentsService,
    private router: Router,
    private incidentService: IncidentCategoryService,
    private ppeService: PpeService,
    private machineService: MachineEquipmentService,
    private incidentClassService: IncidentClassificationService,
    private natureService: NatureOfInjuryService,
    private userService: UserService,
    private shiftService: ShiftsService,
    private rootService: RootCauseService,
    private incidentReportService: IncidentReportService,
    private feedBackService: AsyncFeedbackService
  ) {
    this.translate
      .get(['Errors', 'Success', 'ConfirmDeleteRecord', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedIncident'].currentValue) {
      this.populateForm();
    }
  }

  ngOnInit() {
    this.getCurrenttime();
    this.reportIncidentForm = this.fb.group({
      title: ['', [Validators.required, noWhitespaceValidator]],
      typeOfIndcident: [[], Validators.required],
      isAnyOneInjured: [false],
      shift: ['', Validators.required],
      whathappend: ['', Validators.required],
      incidentDate: [new Date(), Validators.required],
      departmentId: ['', Validators.required],
      subDepartment: [''],
      equipement: ['', Validators.required],
      incidentTime: [this.mintime, Validators.required],
      actionTaken: ['', Validators.required],
      attachments: [[]],
      incidentClassification: [''],
      incidentRisk: [''],
      witness: [''],
      rootCause: [''],
      injuredPersons: this.fb.array([]),
    });
    this.injuredPersonFormArray = this.reportIncidentForm.get(
      'injuredPersons'
    ) as FormArray;

    this.loadDDlsData();
  }

  loadDDlsData() {
    this.loadingService.startLoading(true);
    forkJoin({
      departments: this.departmentService.getDepartmentsParentChild(),
      typeOfIncidents: this.incidentService.getReportCategoriesByCompany(
        this.reportType
      ),
      machines: this.machineService.getMachinesByCompany(),
      incidentClassification:
        this.incidentClassService.getIncidentClassificationByCompany(),
      natureOfInjuries: this.natureService.getNatureOfInjureisByCompany(),
      users: this.userService.getReportToUsers(),
      ppes: this.ppeService.getActivePpes(),
      shifts: this.shiftService.getActiveShifts(),
      rootCauses: this.rootService.getRootCauseByCompany(this.reportType),
    }).subscribe((results) => {
      this.typeOfIncidents = results.typeOfIncidents;
      this.machines = results.machines;
      this.incidentClassifications = results.incidentClassification;
      this.natureOfInjuries = results.natureOfInjuries;
      this.users = results.users;
      this.ppes = results.ppes;
      this.shifts = results.shifts;
      this.rootCauses = results.rootCauses;

      this.departments = results.departments;
      if (
        this.departments.length == 1 &&
        this.departments[0].parentName == '---Parent Department---' &&
        this.departments[0].detail == null
      ) {
        this.departments = [];
      }

      if (this.selectedIncident != null) {
        if (this.selectedIncident?.departmentParentId) {
          const depParentCode = this.departments[0].detail.find(
            (item) =>
              item.departmentId == this.selectedIncident?.departmentParentId
          ).departmentCode;
          this.reportIncidentForm.get('departmentId')?.setValue(depParentCode);
          this.onChangeDepartment({ value: depParentCode });
          this.reportIncidentForm
            .get('subDepartment')
            ?.setValue(this.selectedIncident?.departmentCode);
        } else {
          this.onChangeDepartment({
            value: this.selectedIncident?.departmentCode,
          });
          this.reportIncidentForm
            .get('departmentId')
            ?.setValue(this.selectedIncident?.departmentCode);
        }
      }

      this.loadingService.stopLoading();
    });
  }

  populateForm(): void {
    this.loadingService.startLoading(true, '');
    this.reportIncidentForm
      .get('title')
      ?.setValue(this.selectedIncident?.title);
    this.reportIncidentForm
      .get('isAnyOneInjured')
      ?.setValue(this.selectedIncident?.isAnyOneInjured);
    this.reportIncidentForm
      .get('typeOfIndcident')
      ?.setValue(
        this.selectedIncident?.typeOfIncidentIds?.split(',').map(Number)
      );
    this.reportIncidentForm
      .get('shift')
      ?.setValue(this.selectedIncident?.shift);
    this.reportIncidentForm
      .get('whathappend')
      ?.setValue(this.selectedIncident?.whatHappend);
    this.reportIncidentForm
      .get('incidentDate')
      ?.setValue(new Date(this.selectedIncident?.incidentDate));
    this.reportIncidentForm
      .get('equipement')
      ?.setValue(this.selectedIncident?.machineId);
    this.reportIncidentForm
      .get('incidentTime')
      ?.setValue(this.selectedIncident?.incidentTimeString);
    this.reportIncidentForm
      .get('actionTaken')
      ?.setValue(this.selectedIncident?.actionTaken);
    this.reportIncidentForm
      .get('incidentClassification')
      ?.setValue(this.selectedIncident?.incidentClassificationId);
    this.reportIncidentForm
      .get('incidentRisk')
      ?.setValue(this.selectedIncident?.incidentRisk);
    this.reportIncidentForm
      .get('rootCause')
      ?.setValue(this.selectedIncident?.['rootCauseId']);
    this.reportIncidentForm
      .get('witness')
      ?.setValue(this.selectedIncident?.theWitness.map((item) => item.id));

    const injuredPersons: any[] = [];
    this.injuredPersonFormArray.clear();
    for (const ips of this.selectedIncident?.injuredPersons) {
      this.injuredPersonFormArray.push(this.createInjuredPersonFormGroup());
      injuredPersons.push({
        isEmployed:
          ips?.isEmployed == 'NO'
            ? 'false'
            : ips?.isEmployed == 'YES'
            ? 'true'
            : ips?.isEmployed,
        idNumber: ips?.idNumber,
        gender: ips?.gender,
        age: ips?.age,
        name: ips?.name,
        jobPosition: ips?.jobPosition,
        natureOfInjury: ips?.natureOfInjuryIds
          ?.split(',')
          ?.map((x) => parseInt(x, 10)),
        protectiveEquipment: ips?.protectiveEquipment
          .split(',')
          .map((item) => Number(item)),
        startDate: new Date(ips?.startDate),
        endDate: new Date(ips?.endDate),
        frontSkeletonParts: ips?.frontSkeletonParts.split(','),
        backSkeletonParts: ips?.backSkeletonParts.split(','),
      });
      this.selectedPpes = ips?.ppeData;
      this.minDate = new Date(ips?.endDate);
    }
    this.reportIncidentForm.get('injuredPersons')?.setValue(injuredPersons);
    this.images = this.selectedIncident?.images;
    this.loadingService.stopLoading();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  getSelectedPpes(event) {
    const ids = event.value;
    this.selectedPpes = [...this.ppes.filter((x) => ids.includes(x.id))];
  }

  filterSelectedPPes(arrayIds) {
    return this.ppes?.filter((item) => arrayIds.includes(item.id));
  }

  getCurrenttime(): void {
    const date = new Date();
    var n = date.toLocaleString([], { hour: '2-digit', minute: '2-digit' });
    this.mintime = n;
  }

  get repeatInjuredPersonFormGroup(): FormArray {
    return this.reportIncidentForm.get('injuredPersons') as FormArray;
  }
  createInjuredPersonFormGroup(): FormGroup {
    return this.fb.group({
      isEmployed: ['false', [Validators.required]],
      idNumber: [''],
      gender: ['', [Validators.required]],
      age: ['', [Validators.required]],
      name: ['', [Validators.required, noWhitespaceValidator]],
      jobPosition: ['', [Validators.required, noWhitespaceValidator]],
      natureOfInjury: [[], [Validators.required]],
      protectiveEquipment: [[], [Validators.required]],
      startDate: [new Date(), Validators.required],
      endDate: [new Date(), Validators.required],
      frontSkeletonParts: [[]],
      backSkeletonParts: [[]],
    });
  }

  addInjuredPersonRow(): void {
    this.injuredPersonFormArray.push(this.createInjuredPersonFormGroup());
  }
  removeInjuredPersonRow(index: number): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['ConfirmDeleteRecord']['Title'],
          this.translationsList['ConfirmDeleteRecord']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.injuredPersonFormArray.removeAt(index);
        }
      });
  }

  employeeTypeChange(index: number): void {
    const currentRow =
      this.reportIncidentForm.get('injuredPersons')?.value[index];
    currentRow.name = '';
    currentRow.jobPosition = '';
    currentRow.idNumber = '';
    this.reportIncidentForm
      .get('injuredPersons')
      ?.setValue(this.reportIncidentForm.value.injuredPersons);
  }
  onChangeUser(index: number): void {
    const currentRow =
      this.reportIncidentForm.get('injuredPersons')?.value[index];
    if (currentRow?.isEmployed === 'true') {
      const user = this.users.find((x) => x.userId === currentRow?.name);
      currentRow.jobPosition = user?.roles.toString();
      currentRow.idNumber = user.idNumber;
    } else {
      currentRow.name = '';
      currentRow.jobPosition = '';
    }
    this.reportIncidentForm
      .get('injuredPersons')
      ?.setValue(this.reportIncidentForm.value.injuredPersons);
  }

  onChangeAnyOne(): void {
    this.injuredPersonFormArray.clear();
    const isChecked = this.reportIncidentForm.get('isAnyOneInjured')?.value;
    if (isChecked) {
      this.injuredPersonFormArray.push(this.createInjuredPersonFormGroup());
    }
  }

  createReportIncident(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.formSubmit.emit(this.reportIncidentForm.value);
        }
      });
  }

  onChangeDepartment(event) {
    this.reportIncidentForm.get('subDepartment').setValue('');
    this.loadingService.startLoading(true);
    const departmentId = this.departments[0].detail.find(
      (item) => item.departmentCode == event.value
    )?.departmentId;
    this.departmentService.getSubDepartmentsById(departmentId).subscribe({
      next: (response) => {
        this.subDepartments = response;
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  goBack(): void {
    this.router.navigate([
      '/' +
        SharedConstants.REPORTING_MANAGEMENT +
        '/' +
        SharedConstants.REPORT_INCIDENT,
    ]);
  }

  getAttachmentImage(url: string) {
    const extension = url.split('.').pop()?.toLocaleLowerCase();
    if (extension.includes('xlsx') || extension.includes('xls')) {
      return '../../../../assets/img/resources/xls.png';
    } else if (extension.includes('docx') || extension.includes('doc')) {
      return '../../../../assets/img/resources/doc.png';
    } else if (extension.includes('pdf')) {
      return '../../../../assets/img/resources/pdf.png';
    } else {
      return url;
    }
  }

  openBigView(id: number): void {
    const img = this.selectedIncident?.images.find((x) => x.id === id);
    const downloadTag = document.createElement('a');
    downloadTag.href = img.attachment;
    downloadTag.addEventListener('click', () => {
      downloadTag.download;
    });
    downloadTag.click();
  }

  getAttachementTypeName(url: string): any {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
    const extension = url.split('.').pop();
    if (imageExtensions.includes(extension)) {
      return 'Image';
    }
    return 'Document';
  }

  deleteImage(img: any): void {
    this.selectedImg = img;
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingService.startLoading(true, '');
          this.incidentReportService
            .deleteIncidentReportImage(this.selectedImg.id)
            .subscribe({
              next: (response) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, response?.message)
                );
                this.images = this.images.filter(
                  (x) => x.id !== this.selectedImg.id
                );
                this.loadingService.stopLoading();
              },
              error: ({ error }) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.loadingService.stopLoading();
              },
            });
        }
      });
  }
}
