<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.AddWorkPermit' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">

    <app-work-permit-form  (formSubmit)="createWorkFlowInstance($event)"
  >
  </app-work-permit-form>
  </hodhod-page-layout-content>
</hodhod-page-layout>

