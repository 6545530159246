import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-update-organization-org-admin',
  templateUrl: './update-organization-org-admin.component.html',
  styleUrls: ['./update-organization-org-admin.component.scss'],
})
export class UpdateOrganizationOrgAdminComponent implements OnInit {
  public orgId: number = 0;
  constructor() {}

  ngOnInit(): void {
    this.orgId = JSON.parse(localStorage.getItem('organization'))['orgId'];
  }
}
