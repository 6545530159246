<div
  class="border-2 flex flex-col p-0 justify-between rounded-[15px] min-w-[305px] mx-1 border-[#D1D5DB] h-[200px] mt-2"
>
  <div class="p-3">
    <div class="mb-2 flex justify-between">
      <img
        class="w-[30%] object-contain"
        src="assets/img/resources/saned-logo.png"
        alt="saned"
        px
      />
      <div class="flex items-center">
        <p class="text-sm font-bold" [id]="cardDetails.status">
          {{ cardDetails?.status }}
        </p>
        <img
          *ngIf="cardDetails?.status == 'ISSUED'"
          class="cursor-pointer"
          src="assets/img/resources/delete-icon.svg"
          alt="revoke card"
          (click)="revokeCard()"
        />
      </div>
    </div>

    <div class="flex items-center justify-between mt-[2rem]">
      <div class="flex items-center">
        <div>
          <ngx-avatars
            class="avatar box"
            size="50"
            [src]="imageUrl"
            [name]="userDetails?.fullName"
            [textSizeRatio]="3"
            [initialsSize]="2"
            [round]="true"
            [cornerRadius]="'10px'"
          ></ngx-avatars>
        </div>
        <div class="mx-1">
          <p class="text-base font-bold">
            {{ userDetails?.fullName }}
          </p>
          <p class="text-base">{{ cardDetails?.cardNumber }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-between hid-cover h-full p-3">
    <div class="flex items-end flex-grow">
      <img
        class="w-[15%]"
        src="assets/img/resources/hid-logo.svg"
        alt="saned"
      />
    </div>
  </div>
</div>
