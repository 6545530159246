<app-card [title]="'Company.CompanyInfo' | translate">
  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionState"
    [loadingLabel]="'Company.LoadingInfo' | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <div class="flex justify-between flex-wrap-reverse" *ngIf="companyInfo">
      <div class="flex-1">
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">{{ companyInfo?.companyCode }}</p>
            <p class="m-0 caption text-hint">
              {{ "Company.BasicForm.Code" | translate }}
            </p>
          </div>
        </div>
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">{{ companyInfo?.companyName }}</p>
            <p class="m-0 caption text-hint">
              {{ "Company.BasicForm.Name" | translate }}
            </p>
          </div>
        </div>
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">
              {{ companyInfo?.updatedOn | date : "dd-MM-yyyy hh:mm:ss" }}
            </p>
            <p class="m-0 caption text-hint">
              {{ "Company.BasicForm.UpdatedAt" | translate }}
            </p>
          </div>
        </div>
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">
              {{ companyInfo?.createdOn | date : "dd-MM-yyyy hh:mm:ss" }}
            </p>
            <p class="m-0 caption text-hint">
              {{ "Company.BasicForm.CreatedOn" | translate }}
            </p>
          </div>
        </div>
        <div class="py-3 flex items-center" *ngIf="compLicenseInfo?.accessControlLicenseQuota">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">
              {{ compLicenseInfo?.accessControlLicenseQuota }}
            </p>
            <p class="m-0 caption text-hint">
              {{ 'License Quota' }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex-1">
        <div @fadeInUp class="flex items-center">
          <p class="text-left font-bold text-primary mr-4 rtl:ml-3">
            {{ "General.Status" | translate }}
          </p>
          <app-boolean-view
            width="105px"
            [value]="companyInfo?.status === 'ACTIVE'"
            [text]="
              companyInfo?.status === 'ACTIVE'
                ? ('General.Active' | translate)
                : ('General.Inactive' | translate)
            "
          >
          </app-boolean-view>
        </div>
        <div
          @fadeInUp
          class="avatar-container cursor-pointer"
          (click)="fullImageView()"
        >
          <ngx-avatars
            bgColor="#2c3e5000"
            fgColor="#222222"
            class="avatar box"
            size="200"
            [ngStyle]="{
              'border-color':
                companyInfo?.status === 'ACTIVE' ? ACTIVE_COLOR : INACTIVE_COLOR
            }"
            [src]="companyInfo?.companyImage"
            [name]="companyInfo?.companyName | uppercase"
            [textSizeRatio]="3"
            [initialsSize]="2"
            [round]="true"
          ></ngx-avatars>
          <div
            class="label"
            [ngStyle]="{
              'background-color':
                companyInfo?.status === 'ACTIVE' ? ACTIVE_COLOR : INACTIVE_COLOR
            }"
          >
            <span *ngIf="companyInfo?.status === 'ACTIVE'">{{
              "General.Active" | translate | uppercase
            }}</span>
            <span *ngIf="companyInfo?.status === 'INACTIVE'">{{
              "General.Inactive" | translate | uppercase
            }}</span>
          </div>
        </div>
        <div class="my-2" *ngIf="isSuperAdmin">
          <mat-label class="field-label">{{
            "Company.BasicForm.Theme" | translate
          }}</mat-label>
          <div class="bg-gray-50 p-3">
            <div class="flex items-center">
              <div class="border border-gray-200 p-2 m-1 w-4/12 rounded-lg">
                <input
                  type="color"
                  disabled
                  [value]="
                    compInfo?.themeColor ? compInfo?.themeColor : '#1F36B4'
                  "
                />
                <label for="head" class="p-2">Primary</label>
              </div>
              <img
                src="/assets/img/resources/info-icon.png"
                class="mx-2"
                [matTooltip]="
                  'Hint: Please, avoid bright colors for better contrast.'
                "
                [matTooltipPosition]="'right'"
              />
            </div>
            <div class="flex items-center">
              <div class="border border-gray-200 p-2 m-1 w-4/12 rounded-lg">
                <input
                  type="color"
                  id="body"
                  disabled
                  [value]="
                    compInfo?.secondaryColor
                      ? compInfo?.secondaryColor
                      : '#ffffff'
                  "
                />
                <label for="body" class="p-2">Secondary</label>
              </div>
              <img
                src="/assets/img/resources/info-icon.png"
                class="mx-2"
                [matTooltip]="
                  'Hint: For better visibility, better to be black or white.'
                "
                [matTooltipPosition]="'right'"
              />
            </div>
            <div class="flex column">
              <mat-label class="field-label"
                >{{ "Company.BasicForm.Logo" | translate }}:</mat-label
              >
              <div class="flex items-center">
                <img
                  class="border border-gray-200 m-1 w-4/12 rounded-lg"
                  [src]="compInfo?.companyLogo"
                />
              </div>
            </div>
          </div>
        </div>
        <button
          @fadeInUp
          mat-flat-button
          class="verify-button"
          (click)="resendVerificationCode()"
        >
          <span>{{ "General.Buttons.ResendCode" | translate }}</span>
        </button>
        <br />
        <button
          @fadeInUp
          mat-flat-button
          class="verify-button"
          (click)="openChangeEmailModal()"
        >
          <span>{{ "General.Buttons.ChangeEmail" | translate }}</span>
        </button>
      </div>
    </div>
    <div class="py-3 flex items-center" *ngIf="companyInfo">
      <div
        @scaleIn
        class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
      >
        <mat-icon
          class="icon-sm"
          svgIcon="mat:keyboard_double_arrow_right"
        ></mat-icon>
      </div>
      <div @fadeInRight>
        <p class="m-0 body-1">{{ companyInfo?.description }}</p>
        <p class="m-0 caption text-hint">
          {{ "Company.BasicForm.Description" | translate }}
        </p>
      </div>
    </div>
  </app-state-section>
</app-card>

<ng-template #compImageBigView>
  <div class="flex items-center" mat-dialog-title>
    <img [src]="companyInfo?.companyImage" class="avatar mr-5" />
    <h2 class="headline m-0 flex-auto">{{ companyInfo?.companyName }}</h2>
    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div class="flex">
    <div class="image_wrapper">
      <img [src]="companyInfo?.companyImage" [alt]="companyInfo?.companyName" />
    </div>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
  </div>
</ng-template>

<!-- Change Email Model -->
<ng-template #emailChangeModal>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">
      {{ "General.Buttons.ChangeEmail" | translate }}
    </h2>
    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div>
    <form [formGroup]="emailChangeForm">
      <div class="">
        <mat-label class="field-label">{{
          "Company.BasicForm.Email" | translate
        }}</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            matInput
            type="email"
            [placeholder]="'Placeholder.EnterEmail' | translate"
            formControlName="email"
            autocomplete="email"
          />
          <mat-error
            *ngIf="
              emailChangeForm.invalid &&
              emailChangeForm.get('email').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              emailChangeForm.invalid &&
              emailChangeForm.get('email').hasError('invalidEmail')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.InvalidEmail" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
    <app-basic-button
      [btnType]="'Add'"
      [btnText]="'Submit'"
      [isDisabled]="!emailChangeForm.valid"
      (onClick)="changeEmail()"
    ></app-basic-button>
  </div>
</ng-template>
